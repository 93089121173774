import { Component, OnInit } from '@angular/core';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { AuditorTransactionService } from 'src/app/Shared/auditortransaction/auditor-transaction.service';
import { UserlocationService } from 'src/app/Shared/UserLocationMaster/userlocation.service';
import { userlocation } from 'src/app/ViewModels/Role';

@Component({
  selector: 'app-userlocation-master',
  templateUrl: './userlocation-master.component.html',
  styleUrls: ['./userlocation-master.component.css']
})
export class UserlocationMasterComponent implements OnInit {
  closeResult: any;
  LocationName: any;
  BranchDesc: any;
  branchdesc: any;
  public dismissalert = false;
  notify: string;
  p: number = 1;
  editdesc: any;
  editbranchid: any;
  ubranchname: any;
  ubranchdesc: any;
  createdon: any;
  createdby: any;
  DeleteName: any;
  deleteid: any;
  uid: string;
  branchid: string;
  mobileview: boolean;
  GUID: string;
  userlocationname: any;
  UserLocationList: any;
  editLocationName: any;
  userfullname: string;
  newid: string;
  constructor(private modalService: NgbModal,private auditortransactionservice:AuditorTransactionService
    , private spinner: NgxSpinnerService,private UserLocationService: UserlocationService) { 
    if (localStorage.getItem("IsLoggedIn") == "true") {
      this.branchid = localStorage.getItem("branch");
      this.uid = localStorage.getItem("userId");   
     this.GUID = localStorage.getItem("GUID");
     this.userfullname = localStorage.getItem("UserFullName");
    }
    this.isMobileMenu();
  }

  ngOnInit(): void {
    this.UserLocation();
   // this.Branchdata();
    this.LocationName = "";
    this.BranchDesc = "";
  }
  isMobileMenu() {
    if ($(window).width() > 991) {
       this.mobileview = false;
    }
    else {
      this.mobileview= true;
    }
  };

  UserLocation() {
    this.spinner.show();
    this.newid = this.GUID +","+ this.userfullname;
    this.UserLocationService.getAllUserLocation(this.newid ).subscribe((Data: any) => {
      this.UserLocationList = Data;
      this.spinner.hide();
    });
  }

  // Branchdata() {
  //   this.spinner.show();
  //   this.UserLocationService.getAllBranchdata(this.branchid).subscribe((Data: any) => {
  //     this.BranchList = Data;
  //     this.spinner.hide();
  //   });
  // }
  addUserLocation(addbranch) {
    this.modalService.open(addbranch).result.then(
      (result) => {
        this.closeResult = `Closed with: ${result}`;
      },
      (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      }
    );
  }
  EditUserLocation(updatedata, updateuserlocation) {
    this.editLocationName = updatedata.locationName;
    this.editdesc = updatedata.branchDesc;
    this.editbranchid = updatedata.id;
    this.createdon = updatedata.createdOn;
    this.createdby = updatedata.createdBy;
    this.modalService.open(updateuserlocation).result.then(
      (result) => {
        this.closeResult = `Closed with: ${result}`;
      },
      (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      }
    );
  }
  deleteUserLocation(deletedata, deleteuserlocation) {
    this.DeleteName = deletedata.locationName;
    this.deleteid = deletedata.id;
    this.modalService.open(deleteuserlocation).result.then(
      (result) => {
        this.closeResult = `Closed with: ${result}`;
      },
      (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      }
    );
  }
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return "by pressing ESC";
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return "by clicking on a backdrop";
    } else {
      return `with: ${reason}`;
    }
  }
  SaveUserLocation() {
    const inputRequest: userlocation = {
      Id: 0,
      LocationName: this.userlocationname,
      CreatedBy: "",
      CreatedOn: "",
      UserId: this.uid,
      GUID:this.GUID,
      UserFullName :this.userfullname
    };
    this.spinner.show();
    this.UserLocationService.saveUserLocation(inputRequest).subscribe((data: any) => {
      if (data == "success") {
        this.notify = "Location added successfully";
        const audit = {
          TransactionName:"Master",
          TransactionType:"Create",
          TransactionDesc:"Location Master",

          }
          this.auditortransactionservice.AuditorTransactionDetails(audit);
        setTimeout(() => (this.dismissalert = true), 100);
        setTimeout(
          function () {
            this.dismissalert = false;
          }.bind(this),
          3000
        );
        this.spinner.hide();
      this.UserLocation();
      } else {
        this.notify = "Something Went Wrong. Try again.!!";
        setTimeout(() => (this.dismissalert = true), 100);
        setTimeout(
          function () {
            this.dismissalert = false;
          }.bind(this),
          3000
        );
        this.spinner.hide();
     this.UserLocation();
      }
    });
  }

  UpdateUserLocation() {
    const inputRequest1: userlocation = {
      Id: this.editbranchid,
      LocationName: this.editLocationName,
      CreatedBy: this.createdby,
      CreatedOn: this.createdon,
      UserId: this.uid ,
      GUID:this.GUID,
      UserFullName :this.userfullname


    };
    this.spinner.show();
    this.UserLocationService.Updateuserlocation(inputRequest1).subscribe((data: any) => {
      if (data == "success") {
        this.notify = "Location updated successfully";
        const audit = {
          TransactionName:"Master",
          TransactionType:"Update",
          TransactionDesc:"Location master",

          }
          this.auditortransactionservice.AuditorTransactionDetails(audit);
        setTimeout(() => (this.dismissalert = true), 100);
        setTimeout(
          function () {
            this.dismissalert = false;
          }.bind(this),
          3000
        );
      this.UserLocation();
        this.spinner.hide();
      } else {
        this.notify = "Something Went Wrong. Try again.!!";
        setTimeout(() => (this.dismissalert = true), 100);
        setTimeout(
          function () {
            this.dismissalert = false;
          }.bind(this),
          3000
        );
     this.UserLocation();
        this.spinner.hide();
      }
    });
  }
  DeleteUserLocRow() {
    this.spinner.show();
    this.newid = this.deleteid +","+ this.userfullname;
    this.UserLocationService.deleteuserlocation(this.newid).subscribe((data: any) => {
      if (data == "success") {
        this.notify = "Location deleted successfully";
        const audit = {
          TransactionName:"Master",
          TransactionType:"Delete",
          TransactionDesc:"Location master",

          }
          this.auditortransactionservice.AuditorTransactionDetails(audit);
        setTimeout(() => (this.dismissalert = true), 100);
        setTimeout(
          function () {
            this.dismissalert = false;
          }.bind(this),
          3000
        );
      this.UserLocation();
        this.spinner.hide();
      } else {
        this.notify = "Something Went Wrong. Try again.!!";
        setTimeout(() => (this.dismissalert = true), 100);
        setTimeout(
          function () {
            this.dismissalert = false;
          }.bind(this),
          3000
        );
        this.UserLocation();
        this.spinner.hide();
      }
    });
  }
  closealert(){
    this.dismissalert=false;
  }
}
