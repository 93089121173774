import { Component, OnInit } from '@angular/core';
import { AdminDashboardService } from '../Shared/AdminDashboard/admin-dashboard.service';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { stage } from '../ViewModels/stage';
import { status } from '../ViewModels/status';
import { NgxSpinnerService } from 'ngx-spinner';
import { comment } from '../ViewModels/comment';
import { Addmodule } from '../ViewModels/module';
import { projectrole } from '../ViewModels/projectrole';
import { TaskService } from '../Shared/Task/task.service';
import { addTask } from '../ViewModels/addTask';
import { Priority } from '../ViewModels/Priority';
import { TaskType } from '../ViewModels/TaskType';
import { UserDetailsService } from '../Shared/UserDetails/user-details.service';
import { RoleService } from '../Shared/Role/role.service';
import { HttpErrorResponse, HttpClient } from '@angular/common/http';
import { DatePipe } from '@angular/common';
import { DownloadFileVM } from '../ViewModels/DownloadFileVM';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import * as $ from 'jquery';

@Component({
  selector: 'app-project-view',
  templateUrl: './project-view.component.html',
  styleUrls: ['./project-view.component.css']
})
export class ProjectViewComponent implements OnInit {
  List: string;
  projectdetails: string;
  getMoveData: any;
  userrole: string;
  ProjectCurrentStage: any;
  projectLeadDisplayName: any;
  ProjectCurrentStatus: any;
  projectModules: any;
  taskcommentslist: any;
  projectUsers: any;
  uploadlist: any;
  CurrentProjectTasks: any;
  file2: any
  closeResult: string;
  msg: any;
  stageid: any;
  statusid: any;
  username: string;
  projectstages: any;
  projectstatus: any = [];
  public dismissalert = false;
  notify: string
  p: number = 1;
 
  comment: any
  taskName: any;
  module:any
  summary:any
  components:any
  estimatedtime:any
  detaileddescription:any
  AliasName:any
  Compensation:any
  roleid: any;
  prouserid: any;
  selectedproject: any;
  selectedmodule: any;
  projectlist: any;
  changeList: any;
  moduleList: any;
  modalOptionsissuetype:any
  selectedchange: any;
  Change: any;
  selectedtype: any;
  selectedpriority: any;
  prioritylist: any=[];
  issuetypelist: any=[];
  userList: any;
  projectRoles: any;
  uid: string;
  DateTime: Date;
  alldocuments: any;
  imgdata: any;
  imagefile: any;
  downpath: any;
  blkid: any;
  xlsdata: any;
  xlsfile: any;
  viewpptdata: any;
  pptdata: any;
  pptfile: any;
  worddata: any;
  wordfile: any;
  pdfdata: any;
  pdffile: any;
  displayxls: any;
  xlsiframedata: string;
  xlssantizedisplay: any;
  mobileview: boolean;
  GUID: any;
  projectteamname: any;
  projectteammembers: any;
  showteam: boolean = false;
  constructor(private admindashboardservice: AdminDashboardService, private modalService: NgbModal,
    private spinner: NgxSpinnerService,private taskservice:TaskService,private userservice:UserDetailsService,
    private httpService:HttpClient,private datepipe:DatePipe,private domSanitizer: DomSanitizer,
    private router:Router) {
    this.List = localStorage.getItem("ProjectList");
    this.projectdetails = localStorage.getItem("ProjectData");
    // this.projectData = JSON.parse(this.projectdetails);
    this.username = localStorage.getItem("UserFullName");
    this.uid = localStorage.getItem("userId");
    var getdata = localStorage.getItem("ProjectData");
    this.getMoveData = JSON.parse(getdata);
    this.userrole = localStorage.getItem("role");
    this.roleid = localStorage.getItem("role");
    this.isMobileMenu();
    this.GUID = localStorage.getItem("GUID");

  }

  ngOnInit(): void {
    this.GetProjectStage();
    this.GetProjectLeadName();
    this.GetProjectStatus();
    this.GetProjectModules();
    this.GetTaskComments();
    this.GetProjectUsers();
    this.GetProjectDocumentsList();
    this.GetProjectDocumentsList();
    this.GetCurrentProjectTasks();
    this.Getstages();
    this.Getstatus();
    this.getProjectName();
    this.GetAllDocuments();
    this.GetProjectTeamName();
    this.GetProTeamMembers();

    var prioritymajor: Priority = {
      PriorityId: 1,
      PriorityName: "Major"
    }
    this.prioritylist.push(prioritymajor);
    var prioritycritical: Priority = {
      PriorityId: 2,
      PriorityName: "Critical"
    }
    this.prioritylist.push(prioritycritical);
    var priorityminor: Priority = {
      PriorityId: 3,
      PriorityName: "Minor"
    }
    this.prioritylist.push(priorityminor);

    // Task Type
    var typeissue: TaskType = {
      TaskTypeId: 1,
      TaskTypeName: "Issue"
    }
    this.issuetypelist.push(typeissue);
    var typetask: TaskType = {
      TaskTypeId: 2,
      TaskTypeName: "Task"
    }
    this.issuetypelist.push(typetask);
    var typebug: TaskType = {
      TaskTypeId: 3,
      TaskTypeName: "Bug"
    }
    this.issuetypelist.push(typebug);
    var typenewfeature: TaskType = {
      TaskTypeId: 3,
      TaskTypeName: "New Feature"
    }
    this.issuetypelist.push(typenewfeature);
  }
  isMobileMenu() {
    if ($(window).width() > 991) {
       this.mobileview = false;
    }
    else {
      this.mobileview= true;
    }
  };

  GetProjectLeadName() {
    this.admindashboardservice.GetUserName(this.getMoveData.project.projectLead).subscribe((respose: any) => {
      this.projectLeadDisplayName = respose;
    });
  }
  GetProjectStage() {
    var res = this.getMoveData.project.uniqueProjectId + "," + this.GUID;
    this.admindashboardservice.getProjectStage(res).subscribe((respose: any) => {
      this.ProjectCurrentStage = respose;
    });
  }
  GetProjectStatus() {
    var res = this.getMoveData.project.uniqueProjectId + "," + this.GUID;
    this.admindashboardservice.getProjectstatus(res).subscribe((respose: any) => {
      this.ProjectCurrentStatus = respose;
    });
  }
  GetProjectModules() {
    var res = this.getMoveData.project.uniqueProjectId + "," + this.GUID;
    this.admindashboardservice.getProjectModules(res).subscribe((respose: any) => {
      this.projectModules = respose;
    });
  }
  GetTaskComments() {
    var res = this.getMoveData.project.uniqueProjectId + "," + this.GUID;
    this.admindashboardservice.getCurrentProjectComments(res).subscribe((respose: any) => {
      this.taskcommentslist = respose;
    });
  }
  GetProjectUsers() {
    var res = this.getMoveData.project.uniqueProjectId + "," + this.GUID;
    this.admindashboardservice.getProjectUsers(res).subscribe((respose: any) => {
      this.projectUsers = respose;
    });
  }
  GetProjectDocumentsList() {
    this.admindashboardservice.getProjectDocumentsList(this.getMoveData.project.uniqueProjectId).subscribe((respose: any) => {
      this.uploadlist = respose;
    });
  }
  //......
  GetProjectTeamName() {
    var res = this.getMoveData.project.uniqueProjectId + "," + this.GUID;
    this.admindashboardservice.getProjectTeamName(res).subscribe((respose: any) => {
      this.projectteamname = respose;
      if(this.projectteamname != null){
        this.showteam = true;
      }
    });
  }
  GetProTeamMembers() {
    var res = this.getMoveData.project.uniqueProjectId + "," + this.GUID;
    this.admindashboardservice.getProjectTeamMembers(res).subscribe((respose: any) => {
      this.projectteammembers = respose;
    });
  }
//......

  GetCurrentProjectTasks() {
    var res = this.getMoveData.project.uniqueProjectId + "," + this.GUID;
    this.admindashboardservice.getprojecttasks(res).subscribe((respose: any) => {
      this.CurrentProjectTasks = respose;
    });
  }
  GetProjectRoles() {
    this.spinner.show();
    this.admindashboardservice.getProjectRoles(0).subscribe((Data: any) => {
      this.projectRoles = Data;
      this.spinner.hide();
    });

  }
  addprojectuser(projectuser) {
    this.modalService.open(projectuser).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;

    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    
    });
    this.GetUsers();
    this.GetProjectRoles();

  }
  addProjectStages(foldercontent) {
    this.modalService.open(foldercontent).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;

    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }
  addProjectStatus(statuscontent) {
    this.modalService.open(statuscontent).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;

    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }
  GetUsers() {
     this.spinner.show();
    this.userservice.getAllUsers(this.GUID).subscribe((Data: any) => {
      this.userList = Data;
      this.spinner.hide();
    });
  }
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }
  onSelectstage(data: any) {
    this.stageid = data
  }
  onSelectstatus(data: any) {
    this.statusid = data
  }
    onSelectedProjectuser(data: any) {
    this.prouserid = data
  }
  onSelectedProject(id){
    this.selectedproject = id +','+this.GUID
    this.taskservice.getChangeDetails(this.selectedproject).subscribe((response: any) => {
      this.changeList = response.changelist;
      this.moduleList = response.modulelist;
      this.spinner.hide();
    })
  }
  onSelecteroleName(data: any){
    this.roleid = data
  }
  onSelectedmoduleName(id){
    this.selectedmodule = id
  }
  onSelectchange(id){
    this.selectedchange = id
  }
  onSelecttype(id){
    this.selectedtype=id
  }
  onSelectaPriority(id){
    this.selectedpriority=id;
  }
  Getstages() {
    this.admindashboardservice.getstages().subscribe((respose: any) => {
      this.projectstages = respose;
    });
  }
  Getstatus() {
    this.admindashboardservice.getstatus().subscribe((respose: any) => {
      this.projectstatus = respose;
    });
  }
  getProjectName() {
    this.spinner.show();
    this.taskservice.getTeamProject(this.GUID).subscribe((response: any) => {
      this.projectlist = response;
    })
  }
  Savestages() {
    const  stage = {
      ID: 0,
      ProjectID: this.getMoveData.project.uniqueProjectId,
      Stage: this.stageid,
      ChangedBy: this.username,
      GUID : this.GUID
    }
    this.spinner.show();
    this.admindashboardservice.savestage(stage).subscribe((data: any) => {
      if (data != null) {
        this.notify = "Stage updated successfully"
        setTimeout(() => this.dismissalert = true, 100);
        setTimeout(function () {
          this.dismissalert = false;
        }.bind(this), 3000);
        this.spinner.hide();
        this.GetProjectStage();
      }
      else {
        this.notify = "Something went wrong, Try again!!"
        setTimeout(() => this.dismissalert = true, 100);
        setTimeout(function () {
          this.dismissalert = false;
        }.bind(this), 3000);
        this.spinner.hide();
        this.GetProjectStage();
      }
    });
  }
  Savestatus() {
    const inputRequest: status = {
      ID: 0,
      ProjectID: this.getMoveData.project.uniqueProjectId,
      ChangedBy: this.username,
      Status: this.statusid,
      TaskID:null,
      GUID:this.GUID
    }
    this.spinner.show();
    this.admindashboardservice.savestatus(inputRequest).subscribe((data: any) => {
      if (data != null) {
        this.notify = "Status updated successfully"
        setTimeout(() => this.dismissalert = true, 100);
        setTimeout(function () {
          this.dismissalert = false;
        }.bind(this), 3000);
        this.spinner.hide();
        this.GetProjectStatus();
      }
      else {
        this.notify = "Something Went wrong, Try again!!"
        setTimeout(() => this.dismissalert = true, 100);
        setTimeout(function () {
          this.dismissalert = false;
        }.bind(this), 3000);
        this.spinner.hide();
        this.GetProjectStatus();
      }
    });
  }
  Savetaskdata() {
    const inputRequest: addTask = {
      ID: 0,
      ProjectName: this.selectedproject,
      Module: this.selectedmodule,
      Issue: this.taskName,
      IssueType: this.selectedtype,
      Priority: this.selectedpriority,
      Summary: this.summary,
      Components: this.components,
      ChangeId: this.Change,
      EstimatedTime: this.estimatedtime,
      DetailedDescription: this.detaileddescription,
      IsEnabled:true,
      UserId:this.uid,
      GUID:this.GUID
    }
    this.spinner.show();
    this.taskservice.saveTask(inputRequest).subscribe((data: any) => {
      if (data != null) {
        this.notify = "Task added successfully"
        this.selectedproject=null;
        this.selectedmodule=null;
        this.Change=null
        this.selectedtype=null
        this.selectedpriority=null;
        setTimeout(() => this.dismissalert = true, 100);
        setTimeout(function () {
          this.dismissalert = false;
        }.bind(this), 3000);
        this.spinner.hide();
        this.GetCurrentProjectTasks();
      }
      else {
        this.notify = "Something went wrong";
        setTimeout(() => this.dismissalert = true, 100);
        setTimeout(function () {
          this.dismissalert = false;
        }.bind(this), 3000);
        this.spinner.hide(); 
        this.selectedproject=null;
        this.selectedmodule=null;
        this.Change=null
        this.selectedtype=null
        this.selectedpriority=null;
        this.GetCurrentProjectTasks();

      }
    });
  }
  Addmodule(modulecontent) {
    this.modalService.open(modulecontent).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }
  AddTask(addtask) {
    this.modalService.open(addtask).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }
  AddComment(commentscontent) {
    this.modalService.open(commentscontent).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }
  Savecomment() {
    const inputRequest: comment = {
      ID: 0,
      Comment: this.comment,
      CommentFor: null,
      CommentedBy: this.username,
      CommentedOn: null,
      ProjectID: this.getMoveData.project.uniqueProjectId,
      TaskID: this.taskName,
      GUID:this.GUID
    }
    this.spinner.show();
    this.admindashboardservice.savecomment(inputRequest).subscribe((data: any) => {
      if (data != null) {
        this.notify = "Comment added successfully"
        setTimeout(() => this.dismissalert = true, 100);
        setTimeout(function () {
          this.dismissalert = false;
        }.bind(this), 3000);
        this.spinner.hide();
        this.GetTaskComments();

      }
      else {
        this.notify = "Something went wrong";
        setTimeout(() => this.dismissalert = true, 100);
        setTimeout(function () {
          this.dismissalert = false;
        }.bind(this), 3000);
        this.spinner.hide();   
        this.GetTaskComments();
      }
    });
  }
  Savemodule() {
    const inputRequest: Addmodule = {
      ID: 0,
      ProjectId:this.getMoveData.project.uniqueProjectId,
     ModuleName:this.module,
     IsEnabled:false,
     CreatedBy:this.username
    } 
    this.spinner.show();
    this.admindashboardservice.savemoduledata(inputRequest).subscribe((data:any)=>{
      if(data!=null){
        this.notify = "Module added successfully"
        setTimeout(() => this.dismissalert = true, 100);
        setTimeout(function () {
          this.dismissalert = false;
        }.bind(this), 3000);
        this.spinner.hide();
        this.GetProjectModules();
      }
      else{
        this.notify = "Something went wrong, Try again!!"
        setTimeout(() => this.dismissalert = true, 100);
        setTimeout(function () {
          this.dismissalert = false;
        }.bind(this), 3000);
        this.spinner.hide();
        this.GetProjectModules();
      }
    });
  }
  Saveprojectrole() {
    const inputRequest: projectrole = {
      ID: 0,
      ProjectId:this.getMoveData.project.uniqueProjectId,
      CurrentUserId:this.uid,
      AliasName:this.AliasName,
      RoleInProject:this.roleid,
      UserId:this.prouserid,
     
    } 
    this.spinner.show();
    this.admindashboardservice.saveprojectuser(inputRequest).subscribe((data:any)=>{
      if(data!=null){
        this.notify = "User added successfully"
        setTimeout(() => this.dismissalert = true, 100);
        setTimeout(function () {
          this.dismissalert = false;
        }.bind(this), 3000);
        this.spinner.hide();
        this.GetProjectUsers();
      }
      else{
        this.notify = "Something went wrong";
        this.notify = "Comment added successfully"
        setTimeout(() => this.dismissalert = true, 100);
        setTimeout(function () {
          this.dismissalert = false;
        }.bind(this), 3000);
        this.spinner.hide();  
      }
    });
  }
  onChangeUploadDoc(event: any) {
    this.DateTime = new Date();
    let latest_date = this.datepipe.transform(this.DateTime, 'dd-MM-yyyy hh:mm:ss a');
     let files = [].slice.call(event.target.files);
    const frmData = new FormData();
    for (var i = 0; i < files.length; i++) {
      frmData.append("fileUpload", files[i]);
    }
    frmData.append("ProjectId",this.getMoveData.project.uniqueProjectId)
    frmData.append("userId", this.uid);
    frmData.append("GUID", this.GUID);
    frmData.append("folderid", '0');
    frmData.append("latestdate", latest_date);
    this.spinner.show();
    this.httpService.post('https://blocdrivesaas.in/blocdrivesaasapi/api/Documents/NewDocumentUploadForProjects/', frmData).subscribe(
      data => {
        if(data==1){
          this.notify = "File uploaded successfully"
          setTimeout(() => this.dismissalert = true, 100);
          setTimeout(function () {
            this.dismissalert = false;
          }.bind(this), 3000);
          this.spinner.hide();
          this.GetAllDocuments();
        }
        else{
          this.notify = "Something went wrong";
          this.notify = "Comment added successfully"
          setTimeout(() => this.dismissalert = true, 100);
          setTimeout(function () {
            this.dismissalert = false;
          }.bind(this), 3000);
          this.spinner.hide(); 
          this.GetAllDocuments();
 
        }
      });
  }

  closealert() {
    this.dismissalert = false;
  }
GetAllDocuments(){
  this.spinner.show();
  var res = this.getMoveData.project.uniqueProjectId + "," + this.GUID;
  this.admindashboardservice.getalldocuments(res).subscribe((respose: any) => {
    this.alldocuments = respose;
    this.spinner.hide();

  });
}

DownloadFile(ret) {
  const inputRequest: DownloadFileVM = {
    Id: null,
    AUID: this.uid,
    FolderId: null,
    FolderName:null,
    BlockID: ret.blockId,
    GUID:this.GUID
  }
  this.spinner.show();
  this.admindashboardservice.downloadFile(inputRequest).subscribe((data: any) => {
    if (data != null) {
      this.downpath = data;
      window.open(this.downpath);
      this.spinner.hide();
    }
    else {
      this.notify = "Something went wrong. Try again.!!"
      setTimeout(() => this.dismissalert = true, 100);
      setTimeout(function () {
        this.dismissalert = false;
      }.bind(this), 3000);
      this.spinner.hide();
    }
  });

}
gotopdfView(file) {
  localStorage.removeItem('BlockId');
  localStorage.removeItem('EmailView');
  localStorage.removeItem('Pdffile');
  localStorage.removeItem('Fid');
  localStorage.removeItem('DocType'); 
  localStorage.removeItem('UploadedDate');
  localStorage.removeItem('FileType');
  localStorage.removeItem('IsAssignTaskDoc');

  console.log(file);
  this.spinner.show();
  localStorage.setItem('Fileview', file.signFile);
  localStorage.setItem('FileNameView', file.documentName);
  localStorage.setItem('BlockId', file.blockId);
  localStorage.setItem('Pdffile', file.pdfFile);
  localStorage.setItem('Fid', file.folderId);
  localStorage.setItem('DocType', file.documentType); 
  localStorage.setItem('UploadedDate', file.date);
  localStorage.setItem('FileType', "true");
      localStorage.setItem('IsAssignTaskDoc', "false");
  this.router.navigate(['fileview/'])
}

}