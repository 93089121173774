import { Component, OnInit } from '@angular/core';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { AuditorTransactionService } from 'src/app/Shared/auditortransaction/auditor-transaction.service';
import { BranchService } from 'src/app/Shared/BranchMaster/branch.service';
import { branch } from 'src/app/ViewModels/Role';

@Component({
  selector: 'app-branch',
  templateUrl: './branch.component.html',
  styleUrls: ['./branch.component.css']
})

export class BranchComponent implements OnInit {
    closeResult: any;
    BranchName: any;
    branchname: any;
    BranchDesc: any;
    branchdesc: any;
    public dismissalert = false;
    notify: string;
    BranchList: any;
    p: number = 1;
    editbranchName: any;
    editdesc: any;
    editbranchid: any;
    ubranchname: any;
    ubranchdesc: any;
    createdon: any;
    createdby: any;
    DeleteName: any;
    deleteid: any;
    uid: string;
    branchid: string;
    mobileview: boolean;
    GUID: string;
    userfullname: string;
  newid: string;
    constructor(private modalService: NgbModal,private auditortransactionservice:AuditorTransactionService
      , private spinner: NgxSpinnerService,private BranchService: BranchService) { 
      if (localStorage.getItem("IsLoggedIn") == "true") {
        this.branchid = localStorage.getItem("branch");
        this.uid = localStorage.getItem("userId");   
       this.GUID = localStorage.getItem("GUID");
       this.userfullname = localStorage.getItem("UserFullName");

      }
      this.isMobileMenu();
    }
  
    ngOnInit(): void {
      this.Branchs();
      this.Branchdata();
      this.BranchName = "";
      this.BranchDesc = "";
    }
    isMobileMenu() {
      if ($(window).width() > 991) {
         this.mobileview = false;
      }
      else {
        this.mobileview= true;
      }
    };
  
    Branchs() {
      this.spinner.show();
      this.newid = this.GUID +","+ this.userfullname;
      this.BranchService.getAllBranchs(this.newid ).subscribe((Data: any) => {
        this.BranchList = Data;
        this.spinner.hide();
      });
    }
  
    Branchdata() {
      this.spinner.show();
      this.newid = this.GUID +","+ this.userfullname;
      this.BranchService.getAllBranchdata(this.newid).subscribe((Data: any) => {
        this.BranchList = Data;
        this.spinner.hide();
      });
    }
    addBranch(addbranch) {
      this.modalService.open(addbranch).result.then(
        (result) => {
          this.closeResult = `Closed with: ${result}`;
        },
        (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        }
      );
    }
    EditBranch(updatedata, updatebranch) {
      this.editbranchName = updatedata.branchName;
      this.editdesc = updatedata.branchDesc;
      this.editbranchid = updatedata.id;
      this.createdon = updatedata.createdOn;
      this.createdby = updatedata.createdBy;
      this.modalService.open(updatebranch).result.then(
        (result) => {
          this.closeResult = `Closed with: ${result}`;
        },
        (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        }
      );
    }
    deleteBranch(deletedata, deletebranch) {
      this.DeleteName = deletedata.branchName;
      this.deleteid = deletedata.id;
      this.modalService.open(deletebranch).result.then(
        (result) => {
          this.closeResult = `Closed with: ${result}`;
        },
        (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        }
      );
    }
    private getDismissReason(reason: any): string {
      if (reason === ModalDismissReasons.ESC) {
        return "by pressing ESC";
      } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
        return "by clicking on a backdrop";
      } else {
        return `with: ${reason}`;
      }
    }
    SaveBranch() {
      const inputRequest: branch = {
        Id: 0,
        BranchName: this.branchname,
        BranchDesc: this.branchdesc,
        CreatedBy: "",
        CreatedOn: "",
        UserId: this.uid,
        GUID:this.GUID,
        UserFullName :this.userfullname 
      };
      this.spinner.show();
      this.BranchService.saveBranch(inputRequest).subscribe((data: any) => {
        if (data == "success") {
          this.notify = "Branch added successfully";
          const audit = {
            TransactionName:"Master",
            TransactionType:"Create",
            TransactionDesc:"Branch master",
  
            }
            this.auditortransactionservice.AuditorTransactionDetails(audit);
          setTimeout(() => (this.dismissalert = true), 100);
          setTimeout(
            function () {
              this.dismissalert = false;
            }.bind(this),
            3000
          );
          this.spinner.hide();
          this.Branchs();
        } else {
          this.notify = "Something went wrong.Please Try again.!!";
          setTimeout(() => (this.dismissalert = true), 100);
          setTimeout(
            function () {
              this.dismissalert = false;
            }.bind(this),
            3000
          );
          this.spinner.hide();
          this.Branchs();
        }
      });
    }
  
    UpdateBranch() {
      const inputRequest1: branch = {
        Id: this.editbranchid,
        BranchName: this.editbranchName,
        BranchDesc: this.editdesc,
        CreatedBy: this.createdby,
        CreatedOn: this.createdon,
        UserId: this.uid ,
        GUID:this.GUID,
        UserFullName :this.userfullname  
  
  
      };
      this.spinner.show();
      this.BranchService.UpdateBranch(inputRequest1).subscribe((data: any) => {
        if (data == "success") {
          this.notify = "Branch updated successfully";
          const audit = {
            TransactionName:"Master",
            TransactionType:"Update",
            TransactionDesc:"Branch master",
  
            }
            this.auditortransactionservice.AuditorTransactionDetails(audit);
          setTimeout(() => (this.dismissalert = true), 100);
          setTimeout(
            function () {
              this.dismissalert = false;
            }.bind(this),
            3000
          );
          this.Branchs();
          this.spinner.hide();
        } else {
          this.notify = "Something went wrong.Please Try Again.!!";
          setTimeout(() => (this.dismissalert = true), 100);
          setTimeout(
            function () {
              this.dismissalert = false;
            }.bind(this),
            3000
          );
          this.Branchs();
          this.spinner.hide();
        }
      });
    }
    DeleteBranchRow() {
      this.spinner.show();
      this.newid = this.deleteid +","+ this.userfullname;

      this.BranchService.deleteBranch(this.newid).subscribe((data: any) => {
        if (data == "success") {
          this.notify = "Branch deleted successfully";
          const audit = {
            TransactionName:"Master",
            TransactionType:"Delete",
            TransactionDesc:"Branch master",
  
            }
            this.auditortransactionservice.AuditorTransactionDetails(audit);
          setTimeout(() => (this.dismissalert = true), 100);
          setTimeout(
            function () {
              this.dismissalert = false;
            }.bind(this),
            3000
          );
          this.Branchs();
          this.spinner.hide();
        } else {
          this.notify = "Something went wrong.Please Try again.!!";
          setTimeout(() => (this.dismissalert = true), 100);
          setTimeout(
            function () {
              this.dismissalert = false;
            }.bind(this),
            3000
          );
          this.Branchs();
          this.spinner.hide();
        }
      });
    }
    closealert(){
      this.dismissalert=false;
    }
  }
  
