import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DeleteMulit } from 'src/app/ViewModels/DeleteMulit';
import { ShareOnlyFileVM } from 'src/app/ViewModels/ShareOnlyFileVM';
import { OtpViewModel } from 'src/app/ViewModels/OtpViewModel';
import { UserEncData } from 'src/app/ViewModels/UserEncData';
import { CloseRequest } from 'src/app/ViewModels/closerequest';
import { SendRequest } from 'src/app/ViewModels/SendRequest';
import { AppGlobals } from 'src/app/app.global';
import { SendRequestForExistingFile } from 'src/app/ViewModels/SendRequestForExistingFile';
import { RemoveClosedMultiRequest, RemoveClosedRequest } from 'src/app/ViewModels/RemoveClosedMultiRequest';
import { UpdateFileRequestVM } from 'src/app/ViewModels/UpdateFileRequestVM';
import { CloseMultiRequest } from 'src/app/ViewModels/CloseMultiRequest';
import { Search } from 'src/app/ViewModels/Search';
import { UpdateFullNameForFR } from 'src/app/ViewModels/UpdateFullNameForFR';
import { phone } from 'src/app/ViewModels/phone';
import { ShareIdShow } from 'src/app/ViewModels/shareidshow';
import { ShareThroughHaspit } from 'src/app/ViewModels/ShareThroughHaspit';

@Injectable({
  providedIn: 'root'
})
export class FilerequestService {

  readonly url = "https://blocdrivesaas.in/blocdrivesaasapi";
  constructor(private http: HttpClient, private _urls: AppGlobals) { }
 



  DeleteMore(data: DeleteMulit) {
    return this.http.post(this.url + "/api/Mulit/PostDeleteMore", data);
  }

  shareOnlyFile(data: ShareOnlyFileVM) {
    return this.http.post(this.url + "/api/ShareFileFolder/PostSharingFileOnly", data);
  }
  //login

  LoginVerification(data: OtpViewModel) {
    return this.http.post(this.url + "/api/LoginTest/LoginVerification", data);
  }
  getHaspitUsers(uid) {
    return this.http.get(this._urls.dmsurl + "/api/Recipient/GetBlocdriveUsers/" + uid);
  }

  getuserfolders(uid) {
    return this.http.get(this.url + "/api/Recipient/GetUserFolders/" + uid);
  }
  getopenrequest(uid) {
    return this.http.get(this.url + "/api/Recipient/GetOpenedFileRequests/" + uid);
  }
  getcloserequest(uid) {
    return this.http.get(this.url + "/api/Recipient/GetClosedFileRequests/" + uid);
  }
  sendFileRequest(data: SendRequest) {
    return this.http.post(this.url + "/api/Recipient/PostSaveRequestedFile", data);
  }
  getextratSeqlink(data) {
    return this.http.post(this.url + "/api/Recipient/PostUserlandingPage", data);
  }
  getfolders(id) {
    return this.http.get(this.url + "/api/FolderMasters/GetallFolder/" + id);
  }
  getuseremailid(uid) {
    return this.http.get(this.url + "/api/Recipient/GetEmailId/" + uid);
  }
  Getrequestedfile(data: UserEncData) {
    return this.http.post(this.url + "/api/Recipient/RequestedFilesDetails", data);
  }
  getbackdata(pid) {
    return this.http.get(this.url + "/api/UploadDocument/GetBackdocument/" + pid);
  }
  closefilerequest(fileid: CloseRequest) {
    return this.http.post(this.url + "/api/Recipient/GetupdatedCloseFileRequests", fileid);
  }
  openfilerequest(fileid: CloseRequest) {
    return this.http.post(this.url + "/api/Recipient/GetUpdatedOpenFileRequests", fileid);
  }
  updateallnotications(data) {
    return this.http.post(this._urls.dmsurl + "/api/ContactUs/UpdateAllNotifications/", data);
  }
  removePersonFromEditFilerequest(data: any) {
    return this.http.post(this._urls.dmsurl + "/api/Recipient/PostRemovePersonFromEditFileRequest", data);
  }
  GetUserForEditFileRequest(fid) {
    return this.http.get(this._urls.dmsurl + "/api/Recipient/GetUserForEditFileRequest/" + fid);
  }
  sendRequestforExistingFile(data: SendRequestForExistingFile) {
    return this.http.post(this._urls.dmsurl + "/api/Recipient/PostSaveUserforExisitingFileId", data);
  }
  GetFolderName(fid: any) {
    return this.http.get(this._urls.dmsurl + "/api/Recipient/GetFolderNameInHaspit/" + fid);
  }
  deleteClosedRequest(fid: RemoveClosedRequest) {
    return this.http.post(this._urls.dmsurl + "/api/Recipient/GetRemoveClosedRequest", fid);
  }
  deleteMultiClosedRequest(fid: RemoveClosedMultiRequest) {
    return this.http.post(this._urls.dmsurl + "/api/Recipient/PostRemoveMultiClosedRequest", fid);
  }
  UpdateFileRequest(data: UpdateFileRequestVM) {
    return this.http.post(this._urls.dmsurl + "/api/Recipient/UpdateFileRequest", data);
  }
  closemultifilerequest(fileid: CloseMultiRequest) {
    return this.http.post(this._urls.dmsurl + "/api/Recipient/GetupdatedCloseMultiFileRequests", fileid);
  }
  GetSearchedFiles(data: Search) {
    return this.http.post(this._urls.dmsurl + "/api/Search/PostSearch", data);
  }
  GetTotalData(id) {
    return this.http.get(this._urls.usrmurl + "/api/Payments/UpdateUtilizedData/" + id);
  }
  CheckSession(data: any) {
    return this.http.post(this._urls.usrmurl + "api/UserSession/ChechSession", data);
  }
  Charge(id) {
    return this.http.get(this._urls.usrmurl + "/api/Payments/PostCharge/" + id);
  }
  // Getrequestedfile(data: UserEncData) {
  //   return this.http.post(this._urls.dmsurl + "/api/Recipient/RequestedFilesDetails", data);
  // }
  postUpdateFullnameFR(data: UpdateFullNameForFR) {
    return this.http.post(this._urls.usrmurl + "/api/Signup/PostUpdateFullNameFR", data);
  }
  getdummyresponse(data: phone) {
    return this.http.post(this._urls.usrmurl + "/api/Recipient/PostDummyResponse", data);
  }
  getuserimage(id) {
    return this.http.get(this._urls.usrmurl + "/api/Phone/GetUserImage/" + id);
  }
  GetDataUsed(id) {
    return this.http.get(this._urls.dmsurl + "/api/Search/GetAvailabledata/" + id);
  }
  getsubfolders(ids: ShareIdShow) {
    return this.http.post(this._urls.dmsurl + "/api/UploadDocument/Posttakedocument", ids);
  }
  ShareFilesThroughHaspit(data: ShareThroughHaspit) {
    return this.http.post(this._urls.dmsurl + "/api/Recipient/ShareforFileRequestsFromHaspit", data);
  }
  getpfoldernames(id) {
    return this.http.get(this._urls.dmsurl + "/api/FolderCreation/GetFoldernames/" + id);
  }
}
