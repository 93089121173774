import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class MinorDepartmentService {
  readonly url="https://blocdrivesaas.in/blocdrivesaasapi/api/";

  constructor(private http:HttpClient) { }
  saveDepartment(data){
    return this.http.post(this.url +"MinorDepartment/PostMinorGroupMaster",data);
  }

  getAllDepartments(id){
    return this.http.get(this.url +"MinorDepartment/GetUserMinorGroupData/" + id);
  }

  deleteDepartment(id){
    return this.http.get(this.url +"MinorDepartment/GetDeleteMinorDepartmentData/"+ id);
  }

  UpdateDepartment(data){
    return this.http.post(this.url +"MinorDepartment/PutMinorGroupMaster",data);
  }
  getMajorGrpinMinorGrpTbl(id){
    return this.http.get(this.url +"MinorDepartment/GetMinorGroupData/" + id);

  }
}
