import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class OutwardService {
  readonly baseAppUrl: string = 'https://blocdrivesaas.in/blocdrivesaasapi'

  constructor(private http:HttpClient) { }
  getDocumentType(){
    return this.http.get(this.baseAppUrl + '/api/custom/GetOutwardDocumentType/');
  }
  getOutwardType(){
    return this.http.get(this.baseAppUrl + '/api/custom/GetOutwardType/');
  }
  getSensitivity(){
    return this.http.get(this.baseAppUrl + '/api/custom/GetSensitivity/');
  }
  getDispatchMode(){
    return this.http.get(this.baseAppUrl + '/api/custom/GetDispatchMode/');
  }
  getReplyAwaited(){
    return this.http.get(this.baseAppUrl + '/api/custom/GetReplyAwaited/');
  }
  Getmyprojects(id){
    return this.http.get(this.baseAppUrl + '/api/ProjectRegistration/GetMyProjects/' + id);
  }
  GetHistoryofProject(id){
    return this.http.get(this.baseAppUrl + '/api/ProjectRegistration/GetProjectHistoryDetials/' + id);
  }
  GetTrackingDetails(id){
    return this.http.get(this.baseAppUrl + '/api/ProjectRegistration/GetTrackingDetials/' + id);

  }
}
