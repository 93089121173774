import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class WorkflowStageService {

  readonly _urls: string = 'https://blocdrivesaas.in/blocdrivesaasapi'

  constructor(private http:HttpClient) { }
  saveworkflowstage(workflowstate) {
    return this.http.post(this._urls + "/api/WorkflowStageMaster/PostSaveWorkflowStage", workflowstate);
  }

 
  getworkflowstages(id) {
    return this.http.get(this._urls + "/api/WorkflowStageMaster/GetAllWorkflowStages/" + id);
  } 

  UpdateworkflowStageRow(id){
    return this.http.post(this._urls + "/api/WorkflowStageMaster/PostPutWorkflowStage" , id);
  }

  deleteWorkflowstagedata(id:number){
    return this.http.delete(this._urls +"/api/WorkflowStageMaster/DeleteWorkflowStageData/"+ id);
  }
}
