import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AuditorTransactionService } from '../Shared/auditortransaction/auditor-transaction.service';
import { ClientapprovalService } from '../Shared/ClientCreation/clientapproval.service';
import { MailService } from '../Shared/Mail/mail.service';
import { UserDetailsService } from '../Shared/UserDetails/user-details.service';
import { IndividualChatDetails } from '../ViewModels/IndividualChatDetails';

@Component({
  selector: 'app-auditorview',
  templateUrl: './auditorview.component.html',
  styleUrls: ['./auditorview.component.css']
})
export class AuditorviewComponent implements OnInit {
  GUID: string;
  EnterPriseId: string;
  roleid: string;
  uid: any;
  auditordetails: any;
  p: number = 1;
  blk: any;
    zzz: any;
    current_url: any;
    pdfUrl: any;
    closeResult: string;
    msg: any;
    m: any;
    clientfiledetails: any =[];
    imgdisplay: any;
    clientfiledetails1: any;
    blk1: any;
    zz: any  =[];
    clientfiledetails2: any =[];
    zzzz: any  =[];
    blk2: any;
    zzz1: any;
    blk3: any;
    ppt: any;
    pptview: any;
    clientfiledetails3: any =[];
    blkdoc: any;
    docview: any;
    clientfiledetailsdoc: any =[];
    doc: any;
    blkexcel: any;
    excelview: any;
    clientfiledetailsexcel: any = [];
    excel: any;
    maildata: any =[];
    mailinfo: any;
    mailidd: any;
    mail: any;
    mailsub: any;
    mailmsg: any;
    maildoc: any;
    maildocname: any =[];
    showdoc: boolean ;
    touser: any =[];
  DocName: any;
  showdelfile: boolean=false;
  docc: any;
  transid: any;
  transidone: any;
  tousername: any = [];
  tousernamelist: any = [];
  chatauditid: any;
  ChatAuditId: any;
  chatdata: any;
  chattoguid: any;
  chatfromguid: any;
  chatdatalist: any=[];
  message:any
  clientauditid: any;
  clientdata: any;
  clientid: any;
  clientdatalist: any;
  cliName: any;
  cliemail: any;
  cliaddress: any;
  cliconNo: any;
  projectId: any;
  showclient: boolean = false;
  auditid: any;
  projectdatalist: any ;
  projectid: any;
  showproject: boolean = false;
  proobj: any;
  procode: any;
  propurpose: any;
  prodesc: any;
  proname: any;
  deletedfile: boolean=false;
  addtaskid: any;
  taskdatalist: any;
  taskname: any;
  projname: any;
  priority: any;
  proissue: any;
  prestatus: any;
  showaddtask: boolean = false;
  assigntaskid: any;
  assigntasklist: any;
  assignproname: any;
  Touser: any;
  Comments: any;
  AssignedTime: any;
  showassigntask: boolean;
  cliname: any;
  addtaskname: any;
  showproname: boolean;
  showaddtaskname: boolean;
  showcliname: boolean;
  userdata: any;
  uname: any;
  email: any;
  Role: any;
  Location: any;
  showuser: boolean;
  showdeluser: boolean;
  folderdata: any;
  FolderName: any;
  SubFolname: any;
  showfol: boolean;
  showsubfol: boolean;
  showfilereq: boolean;
  reqmsg: any;
  reqmailid: any;
  reqdata: any;
  userfullname: string;

  constructor(private auditortransactionservice:AuditorTransactionService,private domSanitizer: DomSanitizer,private clientapproval: ClientapprovalService,
    private modalService: NgbModal, private mailservice: MailService,
     private userservice: UserDetailsService) {
    if (localStorage.getItem("IsLoggedIn") == "true") {
      this.uid = localStorage.getItem("userId");
      this.roleid = localStorage.getItem("role");
      this.GUID=localStorage.getItem("GUID");
      this.EnterPriseId=localStorage.getItem("EnterPriseId");
    }
   }

  ngOnInit(): void {
    this.GetAuditorTransactionDetails();
  }

    


onselectmail(){
  // this.blkid= data;
  var info = {
    UID: this.mailidd,
    FromId: this.uid,
    TransId :this.transidone
  }
  this.mailservice.getmaildetailsaudit(info).subscribe((respose: any) => {
    this.maildata = respose;
    this.maildoc = this.maildata[0].documents
    this.tousernamelist = this.maildata[0].memlist
  // for( var i=0; i<= this.maildoc.length; i++){
  //   for( var j=0; j<= this.tousernamelist.length; j++){
    if(this.maildoc != undefined &&  this.maildoc.length != 0){

  //     this.maildocname = this.maildoc[i].documentName



  //    this.tousername.push(this.tousernamelist[j].useName);
 


 this.mail = "Mail"
 this.mailsub = this.maildata[0].subject;
 this.mailmsg = this.maildata[0].message;
 //this.touser = this.tousername;
 this.showdoc = true;
}
 
 else{
   this.mail = "Mail"
   this.mailsub = this.maildata[0].subject;
   this.mailmsg = this.maildata[0].message;
   this.touser = this.maildata[0].toName;
   this.showdoc = false;
 }
    console.log(this.mail);
  });
}


private getDismissReason(reason: any): string {
  if (reason === ModalDismissReasons.ESC) {
    return 'by pressing ESC';
  } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
    return 'by clicking on a backdrop';
  } else {
    return `with: ${reason}`;
  }
}

ViewImageDoc(aa, content) {
  this.blk1 = aa.blockId;
 // this.onselectview1();

  // this.blkid= data;
   this.clientapproval.getclientdata(this.blk1).subscribe((respose: any) => {
     this.clientfiledetails1 = respose;
     this.zzz = this.clientfiledetails1.file ;
   
  

  if (aa.pdfFile != null) {
   // this.ViewDoc(aa)
  }
  else {
    this.imgdisplay = this.clientfiledetails1;
    this.modalService.open(content).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      this.m = this.msg;
    });
  }
});
}
ViewDocumenttxt(aa, pdftesting) {
  this.blk2 = aa.blockId;
//  this.onselectview2();
this.clientapproval.getclientdata(this.blk2).subscribe((respose: any) => {
  this.clientfiledetails2 = respose;
  this.zzz1 = this.clientfiledetails2.file ;
this.zzzz = this.clientfiledetails2;
  if (aa.pdfFile != null) {
    //this.ViewDoc(aa)
  }
  else {

    //this.zzz = aa;
    this.current_url = this.domSanitizer.bypassSecurityTrustResourceUrl(this.zzz1);
    console.log(this.pdfUrl);
    this.pdfUrl = this.zzz1;
    // this.doc="http://docs.google.com/gview?"+this.zzz.file+"&embedded=true";

    this.modalService.open(pdftesting).result.then((result) => {

      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      this.m = this.msg;
      console.log(this.m)

    });
  }
});
}



ViewPpt(aa, pdftesting) {
  this.blk3 = aa.blockId;
  //this.onselectview3();
  this.clientapproval.getclientdata(this.blk3).subscribe((respose: any) => {
    this.clientfiledetails3 = respose;
    this.pptview = this.clientfiledetails3.file ;
  this.ppt = this.clientfiledetails3.documentName;
  if (aa.pdfFile != null) {
    //this.ViewDoc(aa)
  }
  else {

    //this.zzz = aa;
    this.current_url = this.domSanitizer.bypassSecurityTrustResourceUrl(this.pptview);
    console.log(this.pdfUrl);
    this.pdfUrl = this.pptview;

    this.modalService.open(pdftesting).result.then((result) => {

      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      this.m = this.msg;
      console.log(this.m)

    });
  }
});
}



ViewDocx(aa, pdftesting) {
  this.blkdoc = aa.blockId;
  //this.onselectviewdoc(this.blkdoc);
  this.clientapproval.getclientdata(this.blkdoc).subscribe((respose: any) => {
    this.clientfiledetailsdoc = respose;
    this.docview = this.clientfiledetailsdoc.file ;
  this.doc = this.clientfiledetailsdoc;
  if (this.docview == null || this.docview==undefined) {
    //this.ViewDoc(aa)
  }
  else {

    //this.zzz = aa;
    this.current_url = this.domSanitizer.bypassSecurityTrustResourceUrl(this.docview);
    console.log(this.pdfUrl);
    this.pdfUrl = this.docview;
    // this.doc="http://docs.google.com/gview?"+this.zzz.file+"&embedded=true";

    this.modalService.open(pdftesting).result.then((result) => {

      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      this.m = this.msg;
      console.log(this.m)

    });
  }
  });
  
}


ViewExcel(aa, pdftesting) {
  this.blkexcel = aa.blockId;
 // this.onselectexcel();
 this.clientapproval.getclientdata(this.blkexcel).subscribe((respose: any) => {
  this.clientfiledetailsexcel = respose;
  this.excelview = this.clientfiledetailsexcel.file ;
this.excel = this.clientfiledetailsexcel;

  if (aa.pdfFile != null) {
    //this.ViewDoc(aa)
  }
  else {

    //this.zzz = aa;
    this.current_url = this.domSanitizer.bypassSecurityTrustResourceUrl(this.excelview);
    console.log(this.pdfUrl);
    this.pdfUrl = this.excelview;
    // this.doc="http://docs.google.com/gview?"+this.zzz.file+"&embedded=true";

    this.modalService.open(pdftesting).result.then((result) => {

      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      this.m = this.msg;
      console.log(this.m)

    });
  }
});
}

ViewDocument(aa, pdftesting) {
  this.blk = aa.blockId;
  //this.onselectview();

    // this.blkid= data;
     this.clientapproval.getclientdata(this.blk).subscribe((respose: any) => {
       this.clientfiledetails = respose;
       this.zzz = this.clientfiledetails.file ;
       this.zz = this.clientfiledetails 
   

  if (aa.pdfFile != null) {
    //this.ViewDoc(aa)
  }
  else {

    //this.zzz = aa;
    this.current_url = this.domSanitizer.bypassSecurityTrustResourceUrl(this.zzz);
    console.log(this.pdfUrl);
    this.pdfUrl = this.zzz;
    // this.doc="http://docs.google.com/gview?"+this.zzz.file+"&embedded=true";

    this.modalService.open(pdftesting).result.then((result) => {

      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      this.m = this.msg;
      console.log(this.m)

    });
  }
});
}



ViewMail(aa, pdftesting) {
  this.mailinfo = aa;
  this.mailidd = this.mailinfo.mailIdd;
  this.uid = this.mailinfo.customerId;
  this.transidone = this.mailinfo.transId;
  this.onselectmail();
  if (aa.pdfFile != null) {
    //this.ViewDoc(aa)
  }
  else {

    this.modalService.open(pdftesting).result.then((result) => {

      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      this.m = this.msg;
      console.log(this.m)

    });
  }
}
   
ViewDeletefilename(aa,pdftesting){
  this.DocName = aa.documentName;
  this.modalService.open(pdftesting).result.then((result) => {

    this.closeResult = `Closed with: ${result}`;
  }, (reason) => {
    this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    this.m = this.msg;
    console.log(this.m)

  });
}


onselectchat(){
  // this.blkid= data;
 
  this.mailservice.getchatdetailsaudit(this.chatauditid).subscribe((respose: any) => {
    this.chatdata = respose;
    this.chatfromguid = this.chatdata[0].guId;
    this.chattoguid = this.chatdata[0].toGUID;
 
  if(this.chatfromguid !=null && this.chattoguid !=null){
    const inputRequest: IndividualChatDetails = {
      Chatid: null,
      ChatFrom: this.chatfromguid,
      ChatTo: this.chattoguid,
    };
    this.userservice.GetIndividualChatRecivedDetails(inputRequest).subscribe((respose: any) => {
      this.chatdatalist = respose;
      
    })

  }
})
  //this.ChatMethod();
}


ViewChat(aa, chattesting){
this.chatauditid = aa.id;
this.onselectchat();
this.modalService.open(chattesting).result.then((result) => {

  this.closeResult = `Closed with: ${result}`;
}, (reason) => {
  this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
  this.m = this.msg;
  console.log(this.m)

});
}


onselectclient(){
  // this.blkid= data;
 
  this.mailservice.getclientdetailsaudit(this.auditid).subscribe((respose: any) => {
    this.clientdata = respose;
    
    this.clientid = this.clientdata[0].clientId;
    this.projectid = this.clientdata[0].projectId;
    this.addtaskid = this.clientdata[0].addTaskId;
    this.assigntaskid = this.clientdata[0].assignTaskId;
    this.cliname = this.clientdata[0].clientName;
  this.cliemail = this.clientdata[0].clientEmail;
   this.cliaddress = this.clientdata[0].clientAddress;
  this.cliconNo = this.clientdata[0].clientMobNo;
   this.addtaskname = this.clientdata[0].addTaskName
   this.projname = this.clientdata[0].taskProject;
   this.priority = this.clientdata[0].taskPriority;
   this.proissue = this.clientdata[0].taskIssuetype;
   this.prestatus = this.clientdata[0].taskPresentStatus;
   this.proname = this.clientdata[0].projectName
   this.prodesc = this.clientdata[0].projectDescription
   this.propurpose = this.clientdata[0].purposeOfProject
   this.proobj = this.clientdata[0].projectObjectives

   if(this.cliname !=  null && this.cliemail != null){
    this.showcliname = false;
    this.showproname = false;
    this.showaddtaskname = false;
    this.showproject = false;
    this.showclient = true;
    this.showaddtask = false;
    this.showassigntask = false;
   }
  // if(this.clientid !=null && this.clientid != 0){

  //   this.auditortransactionservice.GetClientDetailsAudit(this.clientid ).subscribe((respose: any) => {
  //     this.clientdatalist = respose;
      
  //     this.showclient = true;
  //     this.showproject = false;
  //     this.showaddtask = false;
  //     this.showassigntask = false;
  //     this.showproname = false;
  //     this.showaddtaskname = false;
  //     this.showcliname = false;
  //     this.cliName = this.clientdatalist[0].clientName;
  //     this.cliemail = this.clientdatalist[0].email;
  //     this.cliaddress = this.clientdatalist[0].address;
  //     this.cliconNo = this.clientdatalist[0].contactNumber;
  //   })
  // }

 else if(this.projectid != null && this.projectid != 0) {

  this.auditortransactionservice.GetProjectDetailsAudit(this.projectid ).subscribe((respose: any) => {
    this.projectdatalist = respose;
    this.showproject = true;
    this.showclient = false;
    this.showaddtask = false;
    this.showassigntask = false;
    this.showproname = false;
    this.showaddtaskname = false;
    this.showcliname = false;
    this.proname = this.projectdatalist.projectName;
    this.prodesc = this.projectdatalist.projectDescription;
    this.propurpose = this.projectdatalist.purposeOfProject;
    this.proobj = this.projectdatalist.objectives;
   
  })
 }


 else if(this.proname != null && this.prodesc != null){
  this.showproject = true;
  this.showclient = false;
  this.showaddtask = false;
  this.showassigntask = false;
  this.showproname = false;
  this.showaddtaskname = false;
  this.showcliname = false;
 }
//  else if(this.addtaskid != null && this.addtaskid != 0) {

//   this.auditortransactionservice.GetTaskDetailsAudit(this.addtaskid ).subscribe((respose: any) => {
//     this.taskdatalist = respose;
//     this.showproject = false;
//     this.showclient = false;
//     this.showaddtask = true;
//     this.showassigntask = false;
//     this.showproname = false;
//     this.showaddtaskname = false;
//     this.showcliname = false;
//     this.taskname = this.taskdatalist[0].issue;
//     this.projname = this.taskdatalist[0].projectName;
//     this.priority = this.taskdatalist[0].priority;
//     this.proissue = this.taskdatalist[0].issueType;
//     this.prestatus = this.taskdatalist[0].components;
   
//   })
//  }
else if(this.prestatus != null){
  this.showproject = false;
     this.showclient = false;
    this.showaddtask = true;
      this.showassigntask = false;
      this.showproname = false;
     this.showaddtaskname = false;
    this.showcliname = false;
}
 else if(this.assigntaskid != null && this.assigntaskid != 0) {

  this.auditortransactionservice.GetAssignTaskDetailsAudit(this.assigntaskid ).subscribe((respose: any) => {
    this.assigntasklist = respose;
    this.showproject = false;
    this.showclient = false;
    this.showaddtask = false;
    this.showassigntask = true;
    this.showproname = false;
    this.showaddtaskname = false;
    this.showcliname = false;
    this.assignproname = this.assigntasklist[0].projectName;
    this.Touser = this.assigntasklist[0].toUser;
    this.Comments = this.assigntasklist[0].comments;
    this.AssignedTime = this.assigntasklist[0].assignedTime;
    
   
  })
 }

 else if(this.cliname != null && this.cliemail == null){
   this.showcliname = true;
   this.showproname = false;
   this.showaddtaskname = false;
   this.showproject = false;
   this.showclient = false;
   this.showaddtask = false;
   this.showassigntask = false;
 }
 else if(this.addtaskname != null  && this.prestatus == null || this.prestatus == undefined && this.proname == null){
this.showaddtaskname = true;
this.showproname = false;
this.showcliname = false;
this.showproject = false;
this.showclient = false;
this.showaddtask = false;
this.showassigntask = false;
 }
else if(this.proname != null){
   this.showproname = true;
   this.showaddtaskname = false;
   this.showcliname = false;
   this.showproject = false;
   this.showclient = false;
   this.showaddtask = false;
   this.showassigntask = false;
 }

})
 
}
onselectuser(){
  this.mailservice.getuserdetailsaudit(this.auditid).subscribe((respose: any) => {
    this.userdata = respose;
    if(this.userdata[0].emailId!=null){
      this.showuser = true;
      this.showdeluser = false;
      this.showfilereq= false
      this.uname = this.userdata[0].userName;
      this.email = this.userdata[0].emailId;
      this.Role = this.userdata[0].role;
      this.Location = this.userdata[0].location;
    }
    else if(this.userdata[0].emailId == null && this.userdata[0].requestingForData == null)
    {
      this.showdeluser = true;
      this.showuser = false;
      this.showfilereq= false
      this.uname = this.userdata[0].userName;

    }


else if(this.userdata[0].requestingForData != null){
  this.showdeluser = false;
  this.showuser = false;
  this.showfilereq= true;
  this.reqdata = this.userdata[0].requestingForData;
  this.reqmailid = this.userdata[0].registeredMailId;
  this.reqmsg = this.userdata[0].oplMessage;

}
    
  })
}

ViewUser(aa, usertesting){
  this.auditid = aa.id;
  this.onselectuser();
  this.modalService.open(usertesting).result.then((result) => {
  
    this.closeResult = `Closed with: ${result}`;
  }, (reason) => {
    this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    this.m = this.msg;
    console.log(this.m)
  
  });
}
ViewClient(aa, chattesting){
  this.auditid = aa.id;
this.onselectclient();
this.modalService.open(chattesting).result.then((result) => {

  this.closeResult = `Closed with: ${result}`;
}, (reason) => {
  this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
  this.m = this.msg;
  console.log(this.m)

});
}


onselectfolder(){
  this.mailservice.getclientdetailsaudit(this.auditid).subscribe((respose: any) => {
    this.folderdata = respose;
    this.FolderName = this.folderdata[0].folderName;
    this.SubFolname = this.folderdata[0].subFolderName;
    if(this.FolderName != null){
      this.showfol=true;
      this.showsubfol=false;
    }
    else if(this.SubFolname != null){
      this.showsubfol=true;
      this.showfol=false;
    }
    

  
    
  })
}
ViewFolder(aa,foldertest){
  this.auditid = aa.id;
  this.onselectfolder();
  this.modalService.open(foldertest).result.then((result) => {
  
    this.closeResult = `Closed with: ${result}`;
  }, (reason) => {
    this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    this.m = this.msg;
    console.log(this.m)
  
  });
}
  GetAuditorTransactionDetails() {
     this.auditortransactionservice.getauditviewdetails(this.EnterPriseId).subscribe((respose: any) => {
       this.auditordetails = respose;
     });
   }


}
