import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class MicrosoftService {
  readonly url = "https://blocdrivesaas.in/blocdrivesaasapi";
 // readonly url = "https://blocdrivesaas.in/blocdrivesaasapi";
    constructor(private http:HttpClient) { }

  getActionUrl(data: any) {
    return this.http.get(this.url + "/Home/Detail/"+ data+"?action=edit");
  }
}
