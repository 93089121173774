import { Component, OnInit, ElementRef } from '@angular/core';
import { HttpErrorResponse, HttpClient } from '@angular/common/http';
import { SendData } from '../SendDate';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import FroalaEditor from 'froala-editor';
import { FileviewVM } from 'src/app/ViewModels/FileviewVM';
import { AdminDashboardService } from 'src/app/Shared/AdminDashboard/admin-dashboard.service';
import { Router } from '@angular/router';
import * as $ from 'jquery';
import { NgxSpinnerService } from 'ngx-spinner';
import html2canvas from 'html2canvas';
@Component({
  selector: 'app-comp',
  templateUrl: './comp.component.html',
  styleUrls: ['./comp.component.css']
})
export class CompComponent implements OnInit {
  sMsg: string;
  print: any;
  selText: Selection;
  src = localStorage.file;

  SignFile: string;
  DocumentName: string;
  BlockId: string;
  PdfFile: string="null";
  IsProject: any;
  file: string;
  uid: string;
  gotfile: string;
  pagecount: number;
  pagenum: any;
  isproject: any;
istaskdoc:any
isassigntaskdoc:any
  roleid: string;
  mobileview: boolean;
  InwardFileID: string;
  userName: string;
  resultcontent: string="";
  allcomments: any;
  // src = "https://accountdatastorage.blob.core.windows.net/pictures/SampleXLSFile_Test.xls";
  // src = "https://accountdatastorage.blob.core.windows.net/pictures/task-photo-cc8b0172-3cc7-46ff-a628-b46223cc6f64.pdf";
  
  constructor(private router:Router,private httpService: HttpClient,private elementRef:ElementRef,private admindashboardservice:AdminDashboardService,
    private spinner:NgxSpinnerService){
    this.uid = localStorage.getItem("userId");
    this.file = localStorage.getItem("ViewPdffile");
    this.SignFile = localStorage.getItem("Fileview");
    this.DocumentName = localStorage.getItem("FileNameView");
    this.BlockId = localStorage.getItem("BlockId");
    this.roleid = localStorage.getItem("role");
   this.InwardFileID= localStorage.getItem("InwardFileId")
   this.userName = localStorage.getItem("UserFullName");

    this.PdfFile = localStorage.getItem("Pdffile");
    if(this.PdfFile=="undefined"){
      this.PdfFile="null"
    }
    this.IsProject = localStorage.getItem('FileType');
    this.isassigntaskdoc= localStorage.getItem('IsAssignTaskDoc')
    if(this.IsProject=="true"){
      this.isproject=true;
    }
    else if(this.IsProject=="false"){
      this.isproject=false;
    }
    if(this.isassigntaskdoc=='true'){
      this.istaskdoc=true;
    }
    else if(this.isassigntaskdoc=='false'){
      this.istaskdoc=false
    }
    if (this.file != null) {
      this.gotfile = this.file
    }
    setTimeout(() => {        
      var pg = document.getElementsByClassName("page");
      this.pagecount = pg.length;
      window.addEventListener('scroll', this.curpagenum.bind(this));
      // document.getElementById('showScroll').innerHTML = window.pageYOffset + 'px';
    }, 1000);
  
  this.gotopdfView();
  this.isMobileMenu();
  }

  ngOnInit () {
    FroalaEditor.DefineIcon('alert', { SVG_KEY: 'help' });
    FroalaEditor.RegisterCommand('alert', {
      title: 'Hello',
      focus: false,
      undo: false,
      refreshAfterCallback: false,

      callback: function () {
        alert('Hello!');
      }
    });
    this.GetLatestDocumentComments();
  }

  // Sample 1 models
  public titleOptions: Object = {
    placeholderText: 'Edit Your Content Here!',
    charCounterCount: false,
    toolbarInline: true,
    events: {
      "initialized": () => {
        console.log('initialized');
      },
      "contentChanged": () => {
        console.log("content changed");
      }
    }
  }
  public myTitle: string;


  // Sample 2 model
  public content: string = '<span>Start Typing..</span>';


  // Sample 3 model
   public twoWayContent;

  // Sample 4 models
  public sample3Text;
  public initControls;
  public deleteAll;
  public initialize(initControls) {
    this.initControls = initControls;
    this.deleteAll = function() {
        this.initControls.getEditor().html.set();
        this.initControls.getEditor().undo.reset();
        this.initControls.getEditor().undo.saveStep();
    };
  }

  // Sample 5 model
  public imgModel: Object = {
    src: '/image.jpg'
  };

  public imgOptions: Object = {
    angularIgnoreAttrs: ['style', 'ng-reflect-froala-editor', 'ng-reflect-froala-model'],
    immediateAngularModelUpdate: true,
    events: {
      "contentChanged": () => {
      }
    }
  }

  // Sample 6 model
  public buttonModel: Object = {
    innerHTML: 'Click Me'
  };

  // Sample 7 models
  public inputModel: Object = {
    placeholder: 'I am an input!'
  };

  // Sample 8 model
  public initializeLink = function(linkInitControls) {
   this.linkInitControls = linkInitControls;
  };
  public linkModel: Object = {
    href: 'https://www.froala.com/wysiwyg-editor'
  };

  // Sample 9
  form = new FormGroup({
    formModel: new FormControl('Hello World', Validators.minLength(2)),
  });
  get formModel(): any { return this.form.get('formModel'); }
  onSubmit(): void {
    console.log(this.form.value);
  }
  setValue() { this.form.setValue({formModel: 'Default text'}); }

  // Sample 10
  form2 = new FormGroup({
    formModel: new FormControl('Hello World', Validators.minLength(2)),
  });
  get form2Model(): any { return this.form2.get('formModel'); }
  onSubmit2(): void {
    console.log(this.form2.value);
  }
  setValue2() { this.form2.setValue({formModel: 'Default text'}); }

  // Sample 11
  // Depending on your screen size you may want to use a specific toolbar dimension or all of them.

  // public options: Object = {
  //   charCounterCount: true,
  //   toolbarButtons: ['bold', 'italic', 'underline', 'paragraphFormat','alert'],
  //   toolbarButtonsXS: ['bold', 'italic', 'underline', 'paragraphFormat','alert'],
  //   toolbarButtonsSM: ['bold', 'italic', 'underline', 'paragraphFormat','alert'],
  //   toolbarButtonsMD: ['bold', 'italic', 'underline', 'paragraphFormat','alert'],
  // };
  public options: Object = {
    charCounterCount: true,
    // Set the image upload parameter.
    imageUploadParam: 'image_param',

    // Set the image upload URL.
    imageUploadURL: 'assets/upload_image',

    // Additional upload params.
    imageUploadParams: {id: 'my_editor'},

    // Set request type.
    imageUploadMethod: 'POST',

    // Set max image size to 5MB.
    imageMaxSize: 5 * 1024 * 1024,

    // Allow to upload PNG and JPG.
    imageAllowedTypes: ['jpeg', 'jpg', 'png'],
    events:  {
  'froalaEditor.initialized':  function () {
    console.log('initialized');
  },
  'froalaEditor.image.beforeUpload':  function  (e,  editor,  images) {
    //Your code 
    if  (images.length) {
      // Create a File Reader.
      const  reader  =  new  FileReader();
      // Set the reader to insert images when they are loaded.
      reader.onload  =  (ev)  =>  {
        const  result  =  ev.target['result'];
        editor.image.insert(result,  null,  null,  editor.image.get());
        console.log(ev,  editor.image,  ev.target['result'])
      };
      // Read image as base64.
      reader.readAsDataURL(images[0]);
    }
    // Stop default upload chain.
    return  false;
  }
  }
}
  onClick(event) {
    console.log(event);
  }
  GetLatestDocumentComments(){
    this.spinner.show()
    this.admindashboardservice.GetLatestComments(this.BlockId).subscribe((data:any)=>{
      if(data!=null){
        this.allcomments=data;
        
      }
      this.spinner.hide();
    })
  }
  saveMeAsDraft(){
    this.resultcontent=this.content.replace('<p>','<p>' + this.userName +': ' )
  const id ={
  htmlString:this.resultcontent,
  folderid:localStorage.Fid,
  name:localStorage.FileNameView,
  userid:localStorage.userId,
  blockid:this.BlockId,
  uniquefileid:this.InwardFileID,
  actualhtmlString:this.content
  }
    this.spinner.show();
    this.httpService.post('https://blocdrivesaas.in/blocdrivesaasapi/api/Account/SaveDocString/', id).subscribe(
      data => {
        this.sMsg = data as string;
        this.content = this.sMsg;
        alert("Saved successfully");
        this.spinner.hide();
        this.GetLatestDocumentComments();

      },
      (err: HttpErrorResponse) => {
        alert("Saved successfully");
        console.log(err.message);
        this.spinner.hide();

      });
  }
  saveMeAsPdf(){
    const id : SendData={
  htmlString:this.content,
  folderid:localStorage.Fid,
  name:localStorage.FileNameView,
  userid:localStorage.userId

  }
  
  this.spinner.show();
    this.httpService.post('https://blocdrivesaas.in/blocdrivesaasapi/api/Account/SaveDocStringAsPDF/', id).subscribe(
      data => {
        if(data=='success'){
          this.sMsg = data as string;
          this.spinner.hide();

          alert("Saved PDF successfully!");
        }
     
      },
      (err: HttpErrorResponse) => {
        this.spinner.hide();
          alert("Saved PDF successfully");

        
        console.log(err.message);
      });
  }
//file view
curpagenum() {
  var pg = document.getElementsByClassName("page");
  var sc = window.pageYOffset;
  for (var i = 0; i < pg.length; i++) {
    var pgc = pg[i].getBoundingClientRect();
    if (sc > pgc.top && sc < pgc.bottom) {
      this.pagenum = i + 1;
      break;
    }
  }
}
gotopdfView() {   
  this.gotfile = "";
  const inputRequest: FileviewVM = {
    File: this.SignFile,
    FileName: this.DocumentName,
    BlockId: this.BlockId,
    AliasUserId: this.uid,
    Pdffile: this.PdfFile,
    IsProjectFile:this.isproject,
    IsAssignTaskDocument:this.istaskdoc,
    OnlyProjectDocs:"no"
  }
  this.admindashboardservice.getdocforpdfview(inputRequest).subscribe((data: any) => {
    if (data != null) {
      var dt= data.pdfFileName.split(',');
      this.gotfile = dt[1];
      localStorage.setItem('ViewPdffile', data.pdfFileName);
      localStorage.setItem('Filename', data.file);
      localStorage.setItem('originafile', data.fileName);
      localStorage.setItem('blockid', data.blockId);
      setTimeout(() => {
        var pg = document.getElementsByClassName("page");
        this.pagecount = pg.length;
        window.addEventListener('scroll', this.curpagenum.bind(this));
        // document.getElementById('showScroll').innerHTML = window.pageYOffset + 'px';
      }, 1000);
    }

    else{
      if(this.IsProject=="true"){
        this.router.navigate(['projectview/']);
      }  
      else if(this.IsProject=="false"){
        this.router.navigate(['taskview/']);
  
      }
      else if(this.IsProject=="ALLDOCSINWARD") {
        this.router.navigate(['inward/']);

      } 
      else if(this.IsProject=="ALLDOCSALLOT"){
        this.router.navigate(['workallotment/']);
  
      } 
      else if(this.IsProject=="ALLDOCSOUT"){
        this.router.navigate(['outward/']);
  
      } 
      else if(this.IsProject=="ALLDOCSFOLDER"){
        this.router.navigate(['allfolders/']);
  
      } 
      else if(this.IsProject=="ALLDOCSFILE"){
        this.router.navigate(['allfolders/']);
  
      } 
    }
  });     
}
isMobileMenu() {
  if ($(window).width() > 991) {
     this.mobileview = false;
  }
  else {
    this.mobileview= true;
  }
};

goBack(){
  if(this.IsProject=="true"){
    this.router.navigate(['projectview/']);
  }  
  else if(this.IsProject=="false"){
    this.router.navigate(['taskview/']);

  }
  else if(this.IsProject=="ALLDOCSINWARD") {
    this.router.navigate(['inward/']);

  } 
  else if(this.IsProject=="ALLDOCSALLOT"){
    this.router.navigate(['workallotment/']);

  } 
  else if(this.IsProject=="ALLDOCSOUT"){
    this.router.navigate(['outward/']);

  } 
  else if(this.IsProject=="ALLDOCSFOLDER"){
    this.router.navigate(['allfolders/']);

  } 
  else if(this.IsProject=="ALLDOCSFILE"){
    this.router.navigate(['allfolders/']);

  } 
}
  sndpdftoprint(){
    var data = document.getElementById('print-section');
    html2canvas(data).then(canvas => {
      var imgWidth = 100;
      var imgHeight = canvas.height * imgWidth / canvas.width;
      const contentDataURL = canvas.toDataURL('image/jpg')
      var bs64data = contentDataURL.split(',')[1];
      var blnk = this.base64ToBlob(bs64data);
      const blobUrl = URL.createObjectURL(blnk);
  
      const iframe = document.createElement('iframe');
      iframe.style.display = 'none';
      iframe.src = blobUrl;
      document.body.appendChild(iframe);
      iframe.contentWindow.print();
  })
 }
 base64ToBlob(base64) {
  const binaryString = window.atob(base64);
  const len = binaryString.length;
  const bytes = new Uint8Array(len);
  for (let i = 0; i < len; ++i) {
    bytes[i] = binaryString.charCodeAt(i);
  }
  return new Blob([bytes], { type: 'application/pdf' });
};
}

