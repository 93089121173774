import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { addTask } from 'src/app/ViewModels/addTask';

@Injectable({
  providedIn: 'root'
})
export class TaskService {
  readonly rooturl1 = "https://blocdrivesaas.in/blocdrivesaasapi";

  constructor(private http:HttpClient) { }
  gettask(id){
    return this.http.get(this.rooturl1 + '/api/AddTask/GettblTasks/' + id);
  }
  saveTask(data:addTask){
    return this.http.post(this.rooturl1 + '/api/AddTask/PostSaveTask', data);
  }
  deleteTask(task){
    return this.http.get(this.rooturl1 + '/api/AddTask/GetDeleteTaskData/' + task);
  }
  UpdateTask(data:addTask){
    return this.http.post(this.rooturl1 + '/api/AddTask/PutProjectTask/', data);
  }
  getTeamProject(id){
    return this.http.get(this.rooturl1 + '/api/AddTask/GettblProjects/'+id);
  }
  getProjectModules(projectId){
    return this.http.get(this.rooturl1 + '/api/ProjectModules/GetProjectModules' + projectId);
  }
  getChangeDetails(projectId){
    return this.http.get(this.rooturl1 + '/api/AdminDashboard/GetChangesandModules/' + projectId);
  }
  saveTaskassign(taskassign){
    return this.http.post(this.rooturl1 + '/api/AssignTask/SaveAssignTask', taskassign);
  }
  getprojecttasksforAssign(projectId){
    return this.http.get(this.rooturl1 + '/api/AddTask/GetProjectTasksforAssign/' + projectId);
  }
}
