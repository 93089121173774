import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { NgxSpinnerService } from 'ngx-spinner';
import { UserDetailsService } from 'src/app/Shared/UserDetails/user-details.service';
import { FolderviewService } from 'src/app/Shared/FlderViewService/folderview.service';
import { MovesService } from 'src/app/Shared/MoveService/moves.service';
import { AddNewFolder } from 'src/app/ViewModels/NewFolder';
import { Friend } from 'src/app/ViewModels/Friend';
import { UserIdVM } from 'src/app/ViewModels/UserIdVM';
import { MoveFolderOrDocument } from 'src/app/ViewModels/MoveFolderOrDocument';
import { DownloadFolderVM } from 'src/app/ViewModels/DownloadFolderVM';
import { DeleteMulit } from 'src/app/ViewModels/DeleteMulit';
import { ShareMulti } from 'src/app/ViewModels/ShareMulti';
import { MoveMultiFolder } from 'src/app/ViewModels/MoveMultiFolder';
import { MultiFolderShareVM } from 'src/app/ViewModels/MultiFolderShareVM';
import { FolderShareToUers } from 'src/app/ViewModels/FolderShareToUers';
import { UserInfo } from 'src/app/ViewModels/UserInfo';
import * as $ from 'jquery';
import { DomSanitizer } from '@angular/platform-browser';
import { AddFolderMigrateVM } from 'src/app/ViewModels/FolderMigrateVM';
import { EmailuserValidation, EmailValidation } from 'src/app/ViewModels/EmailValidation';
import { EnterPriseRegisterService } from 'src/app/Shared/EnterPriseRegister/enter-prise-register.service';
import { ClientapprovalService } from 'src/app/Shared/ClientCreation/clientapproval.service';
import { AuditorTransactionService } from 'src/app/Shared/auditortransaction/auditor-transaction.service';
import { IDropdownSettings } from 'ng-multiselect-dropdown';

@Component({
  selector: 'app-allfolders',
  templateUrl: './allfolders.component.html',
  styleUrls: ['./allfolders.component.scss']
})
export class AllfoldersComponent implements OnInit {
  public isLoggedIn: boolean = false;
  uid: any;
  allFolders: any;
  allSubFolders: any;
  roleid: any;
  closeResult: any;
  msg: string; m: string; zzz: any;
  code: string;
  userid: any;
  updatemsg: any; u: any;
  details: any; subf: any;
  searchvalue: any;
  val: any;
  public SearchEnabled: boolean = false;
  SearchResult: any;
  pdfUrl: any;
  username: string;
  DataUSed: any;
  gridvalue: any;
  listvalue: boolean;
  gridclicks: boolean;
  listclicks: boolean;
  shareitem: boolean;
  storedData: any;
  FolderName: any;
  CreatedOn: any;
  CusId: any;
  activateMove: boolean;
  getMoveData: any;
  destinationFolder: any;
  sourceFolder: any;
  notify: string;
  pid: any;
  dismissalert: boolean;
  Res: any;
  downpath: any;
  seleteddownload: boolean;
  Friends: any;
  frndslist: any;
  CurrentFiles: any;
  checkenable: boolean;
  multi: boolean = false;
  sfiles: number;
  sfolder: number;
  selectedSelected: any = [];
  deletemulticontent: string;
  showper: boolean;
  generalprogressbar: boolean;
  folderCreating: boolean;
  Creatingmsg: string;
  public n: number = 20;
  num: any
  createdmsg: string;
  foldercreated: boolean;
  selectedFriend: any = [];
  Press: any;
  allFolders1: any;
  FileFoldID: any;
  fid: any;
  destinationFold: any;
  Showmoveitem: boolean;
  shareId: any;
  shareFName: any;
  sharePID: any;
  shareUniqueID: any;
  R: any;
  mobile = false;
  subf1: any;
  ppopup: any;
  allSubFolders1: any;
  folderid: string;
  DateTime: any;
  copied1 = false;
  folderview: any
  frmData1: FormData;
  uploadfolderclicked: boolean;
  sameNames: any;
  SelectedFolderandFiles: any[] = [];
  folderlist: any[] = [];
  sameFolderName: any[] = [];
  replaceFolderid: any[] = [];
  relaceFolderlist: any;
  SelectedFolderName: any;
  SelectedLevel: any;
  crthr: any;
  pathdt: any;
  frndrqst: boolean;
  accid: string;
  role: any;
  frndintr: any;
  Movetofold: any
  audiocontent: any
  textfileview: any
  Docname: any
  shareSingleFile: any
  GUID: any;
  IPAddress: any;
  dnsIP: any;
  allfoldersview: boolean;
  displaydesign: boolean=true;
  current_url: any;
  EnterPriseId: string;
  MailId: string;
  mail: string;
  mm: string;
  validateusername: any;
  fullname: any;
  usernameresult: any;
  validusername: boolean;
  email: string;
  Website: string;
  validateemail: string;
  mailresult: any;
  validmail: boolean;
  selectedrole: any;
  selecteddesignation: any;
  selecteddepartment: any;
  selectedItems: any[];
  location: string;
  UserList: any;
  userId: any;
  dname: string;
  clientname: string;
  clientemail: string;
  errorMessages: any;
  regexp = new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);

  serchfind: boolean;
  clientmenu: boolean=false;
  clientmenushow: boolean = true;
  showload: boolean=false;
  imgdisplay: any;
  foldernames : any[];
  showbutton: boolean =true;
  selfrnds: any =[];
  frndslistnames: any = [];
  dropdownSettings: IDropdownSettings;
  userfullname: any;

  constructor(private httpService: HttpClient,private auditortransactionservice:AuditorTransactionService, private userservice: UserDetailsService, private router: Router, private moveService: MovesService, private modalService: NgbModal,
    private folderviewservice: FolderviewService,private clientapproval: ClientapprovalService, private spinner: NgxSpinnerService,private route:ActivatedRoute, private domSanitizer: DomSanitizer,private enterpriseservice: EnterPriseRegisterService) {
    this.role = localStorage.getItem("role");
    window.scrollTo(0, 0);
    this.route.queryParams.subscribe((repsonse:any)=>{
      setTimeout(() => {
        if(repsonse['action']==="upload"){
          alert("Click on upload folder")
        }
      }, 1000);
     
    });
    this.userid = localStorage.getItem("userId");


    this.frndrqst = false;
    this.frndintr = setTimeout(() => {
      this.GetFriendDetails();

    }, 3000);


    if (localStorage.loadingpage == "true") {
      localStorage.loadingpage = false;
      location.reload();
    }
    this.spinner.show();
    this.ppopup = true;
    if (localStorage.getItem("copied") == "1") {
      this.copied1 = true;
    }
    else {
    }
    if (localStorage.getItem("IsLoggedIn") == "true") {
      this.isLoggedIn = true;
      this.uid = localStorage.getItem("userId");
      this.roleid = localStorage.getItem("role");
      this.username = localStorage.getItem("UserFullName");
      this.gridvalue = localStorage.getItem("gridvalue");
      this.GUID=localStorage.getItem("GUID");
      this.MailId = localStorage.getItem("MailId");
      this.EnterPriseId = localStorage.getItem("EnterPriseId");
      this.userfullname = localStorage.getItem("UserFullName");

    }

  }
  mob = false;

  resize() {
    if (window.innerWidth > 500) {
      this.mob = true;
    }
    else {
      this.mob = false;
    }
  }
  openmodal() {

  }
  ngOnInit() {

    this.dropdownSettings = {
      singleSelection: false,
      idField: 'id',
      textField: 'assignRigntType',
      selectAllText: 'Select Users',
      unSelectAllText: 'Selected Users',
      itemsShowLimit: 100,
      allowSearchFilter: true,
      searchPlaceholderText: 'Search for Users',
      noDataAvailablePlaceholderText: 'No data available',
    }

    this.resize();
    window.addEventListener('resize', this.resize);
    this.GetAllFolders();
    // this.GetDataUsed();
    this.GetAllFolders1();
    //localStorage.setItem("gridvalue", "true");
    //this.listvalue = false;
    //this.gridvalue = true;
    //localStorage.setItem("gridvalue", "true");
    if (this.gridvalue == true || this.gridvalue == "true") {
      this.gridvalue = true;
      this.listvalue = false;
    }
    else {
      this.listvalue = true;
      this.gridvalue = false;
    }
    this.shareitem = true;
    this.activateMove = false;
    setTimeout(() => {
      if (this.copied1) {
        if (document.getElementById("moveid")) {
          document.getElementById("moveid").style.display = "block";
        }
      }
      else {
        if (document.getElementById("moveid")) {

          document.getElementById("moveid").style.display = "none";
        }
      }
    }, 1000);
    setTimeout(() => {
      var footer = document.getElementById("footer");
      if (footer) {
        footer.style.display = "none";
      }
    }, 1000);
    if (window.innerWidth < 500) {
      setTimeout(() => {
        var tree = document.getElementById("treeview");

        if (tree) {
          tree.style.display = "none";

        }


        var upload = document.getElementById("view");

        if (upload) {
          upload.style.display = "none";

        }


      }, 1000);
      this.mobile = true;
    }
    setTimeout(() => {
      this.spinner.hide();
    }, 6000);
  }

  ViewDocument(aa, pdftesting) {
    if (aa.pdfFile != null) {
      this.ViewDoc(aa)
    }
    else {

      this.zzz = aa;
      this.current_url = this.domSanitizer.bypassSecurityTrustResourceUrl(this.zzz.file);
      console.log(this.pdfUrl);
      this.pdfUrl = this.zzz.file;
      // this.doc="http://docs.google.com/gview?"+this.zzz.file+"&embedded=true";

      this.modalService.open(pdftesting).result.then((result) => {

        this.closeResult = `Closed with: ${result}`;
      }, (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        this.m = this.msg;
        console.log(this.m)

      });
    }
  }
  ViewImageDoc(aa, content) {
    if (aa.pdfFile != null) {
      this.ViewDoc(aa)
    }
    else {
      this.imgdisplay = aa;
      this.modalService.open(content).result.then((result) => {
        this.closeResult = `Closed with: ${result}`;
      }, (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        this.m = this.msg;
      });
    }
  }


  treeviewba() {
    var tree = document.getElementById("treeview");
    if (tree) {
      if (tree.style.display == "none") {
        tree.style.display = "block";
      }
      else {
        tree.style.display = "none";
      }
    }
  }
  uploadview() {
    var tree = document.getElementById("view");
    if (tree) {
      if (tree.style.display == "none") {
        tree.style.display = "block";
      }
      else {
        tree.style.display = "none";
      }
    }
  }
  gridview(value) {
    if (value == "1") {
      this.gridvalue = true;
      this.listvalue = false;
      localStorage.setItem("gridvalue", "true");
      setTimeout(() => {
        if (this.copied1) {
          document.getElementById("moveid").style.display = "block";
        }
        else {
          document.getElementById("moveid").style.display = "none";
        }
      }, 500);
    }
    else if (value == "0") {
      this.listvalue = true;
      this.gridvalue = false;
      localStorage.setItem("gridvalue", "false");
      setTimeout(() => {
        if (this.copied1) {
          document.getElementById("moveid").style.display = "block";
        }
        else {
          document.getElementById("moveid").style.display = "none";
        }
      }, 500);

    }
  }
  sort = "1";

  sortby() {
    if (this.sort == "1") {
      // this.userservice.getfoldersbydate(this.uid).subscribe((respose: any) => {
      //   this.allFolders = respose;
      // });
      this.allFolders.sort((a, b) => a.folderName.toLowerCase() < b.folderName.toLowerCase() ? -1 : a.folderName.toLowerCase() > b.folderName.toLowerCase() ? 1 : 0)
      this.sort = "2";
    }
    else if (this.sort == "2") {
      // this.userservice.getfoldersbyname(this.uid).subscribe((respose: any) => {
      //   this.allFolders = respose;
      // });

      this.allFolders.sort((a, b) => a.folderName.toLowerCase() > b.folderName.toLowerCase() ? -1 : a.folderName.toLowerCase() < b.folderName.toLowerCase() ? 1 : 0)

      this.sort = "1";

    }

  }
  sortname = "1";
  sortbydate() {
    if (this.sortname == "1") {
      // this.userservice.getfoldersbydate(this.uid).subscribe((respose: any) => {
      //   this.allFolders = respose;
      // });
      this.allFolders.sort((a, b) => a.createdOn < b.createdOn ? -1 : a.createdOn > b.createdOn ? 1 : 0)
      this.sortname = "2";
    }
    else if (this.sortname == "2") {
      // this.userservice.getfoldersbyname(this.uid).subscribe((respose: any) => {
      //   this.allFolders = respose;
      // });

      this.allFolders.sort((a, b) => a.createdOn > b.createdOn ? -1 : a.createdOn < b.createdOn ? 1 : 0)

      this.sortname = "1";

    }

  }
  addnewFolder(folderview, subfoldercontent) {
    this.modalService.open(subfoldercontent).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;

    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      this.m = this.msg;
    });
  }

  showclientmenu(){
    this.clientmenu = true;
    this.clientmenushow = false;
  }

  savenewFolder() {
    var offset = new Date().getTimezoneOffset();
    var nd = 0.0;
    nd = -(parseFloat(offset.toString()));
    const inputRequest: AddNewFolder = {
      Id: 0,
      FolderID: "0",
      RoleId: this.roleid,
      FolderName: this.msg,
      UserId: this.uid,
      GUID:this.GUID,
      Code: "Folder",
      ParentID: "0",
      Click: null,
      date: nd,

    }
    this.spinner.show()
    this.userservice.addNewFolder(inputRequest).subscribe((data: any) => {
      this.spinner.hide()
      if (data != null) {

        this.GetAllFolders();
        this.GetAllFolders1();
        const audit = {
          TransactionName:"Folder Management",
          TransactionType:"Folder Create",
          TransactionDesc:"Folder",
          FolderName:this.msg
          }
        this.auditortransactionservice.AuditorDetailsProject(audit);
      }
      else {
        alert("Something went wrong");

      }
      this.msg = "";
    });
  }


  ngOnDestroy() {
    clearInterval(this.frndintr);
  }


  onItemRemoved(value: any) {
    //this.selecteduserlist.pop(value.display);
    for (var i = 0; i < this.frndslist.length; i++) {
      if (this.frndslist[i].Email == value.display) {
        var index = this.selectedFriend.indexOf(value);
        this.selectedFriend.splice(index, 1);
      }
    }
    this.errorMessages = "";
  }

  onItemAdded(value: any) {
    if (value.Email == undefined) {
      this.email = value.value;
    }
    else {
      this.email = value.Email;
    }
    this.serchfind = this.regexp.test(this.email);
    if (!this.serchfind) {
      this.errorMessages = "Please Enter valid Email";
    }
    if (value.uid != null) {
      this.selectedFriend.push(value);
    }
    
    else {
      this.selectedFriend.push(value.value);
    }
  }

  addfrnd() {
    if (this.frndrqst == true) {
      this.frndrqst = false;
    }
    else {
      this.frndrqst = true;
    }
  }
  regnewclient(){
    if(this.clientname==null || this.clientname==undefined){
      alert("Please enter valid User Name")
    }

    else  {

      const inputRequest1 = {
       ClientName:this.clientname,
       ClientEmail:this.clientemail,
       UserEmail:this.MailId,
       UserName:this.username,
       FromUserId:this.uid,
       RequestingFor :"Blocdrive License",
       Role:"15",
       EnterpriseId:this.EnterPriseId
      
      }
   
      this.clientapproval.savenewconsultant(inputRequest1).subscribe(
        (data: any) => {
          if (data == "1") {
            alert( "Mail sent successfully");
            const audit = {
              TransactionName:"Folder Management",
              TransactionType:"Client Registration",
              TransactionDesc:"Client Registration",
              }
            this.auditortransactionservice.AuditorTransactionDetails(audit);
            this.clientname ="";
            this.clientemail="";
           
            setTimeout(() => this.dismissalert = true, 100);
            setTimeout(function () {
              this.dismissalert = false;
            }.bind(this), 3000);
            this.spinner.hide();
            this.Users();
          } else if(data=="fail"){
           alert("User has been already registered with this Email..Try with another!!");
          
            this.clientname="";
            this.clientemail="";
           
            setTimeout(() => this.dismissalert = true, 100);
            setTimeout(function () {
              this.dismissalert = false;
            }.bind(this), 3000);
            this.spinner.hide();
          
          }
        });
    }
  }
  savefrienddetails() {
    this.spinner.show();
    const inputRequest: Friend = {
      Id: 0,
      FriendAccount: this.accid,
      UserId: this.userid,
      Status: 4,
      IsEnabled: true,
      GUID:this.GUID,


    }
    localStorage.accid = this.accid;
    this.userservice.SaveFrienddetails(inputRequest).subscribe((data: any) => {
      if (data == "SameUser") {
        alert("Please enter your Friend MailId!!");

      }
      else if (data == "alreadyfriends") {
        alert("You are already friends!!");

      }
      else if (data == "NoUserFound") {
        alert("We are sorry " + this.accid + " is not using Blocdrive!!");

      }
      else if (data == "SentRequest") {
        alert("You have already sent or recived request from this user!!");

      }
      else if (data == "1") {
        alert("Friend Request sent successfully!");
      }
      else {
        alert("Something went wrong");
        // this.route.navigate(['/allfolders']);
      }
      this.spinner.hide();
      this.accid = "";
    });
  }

  consaltantreg(){
    if(this.clientname==null || this.clientname==undefined){
      alert("Please enter Valid User Name")
    }

    else  {

      const inputRequest1 = {
       ClientName:this.clientname,
       ClientEmail:this.clientemail,
       UserEmail:this.MailId,
       UserName:this.username,
       FromUserId:this.uid,
       RequestingFor :"Blocdrive License",
       Role:"15",
       EnterpriseId:this.EnterPriseId
      
      }
   
      this.clientapproval.savenewconsultant(inputRequest1).subscribe(
        (data: any) => {
          if (data == "1") {
            alert( "Mail sent successfully");
            const audit = {
              TransactionName:"Folder Management",
              TransactionType:"Client Registration",
              TransactionDesc:"Client Registration",
              }
            this.auditortransactionservice.AuditorTransactionDetails(audit);
            this.clientname ="";
            this.clientemail="";
           
            setTimeout(() => this.dismissalert = true, 100);
            setTimeout(function () {
              this.dismissalert = false;
            }.bind(this), 3000);
            this.spinner.hide();
            this.Users();
          } else if(data=="fail"){
           alert("User has been already registered with this Email..Try with another!!");
          
            this.clientname="";
            this.clientemail="";
           
            setTimeout(() => this.dismissalert = true, 100);
            setTimeout(function () {
              this.dismissalert = false;
            }.bind(this), 3000);
            this.spinner.hide();
          
          }
        });
    }
  }



  UpdateFolder(folderview, editfolder) {
    this.updatemsg = folderview.folderName;
    this.details = folderview;
    this.modalService.open(editfolder).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;

    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      this.u = this.updatemsg;
    });
  }


// enterprise migration


addFolderMigrate(folderviewmig, foldercontentmigrate){
  this.modalService.open(foldercontentmigrate).result.then((result) => {
    this.closeResult = `Closed with: ${result}`;

  }, (reason) => {
    this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    this.mm = this.mail;
  });
}

     



//Due Diligence
addDueDeligence(folderviewdeligence, folderdeligence){
  this.modalService.open(folderdeligence).result.then((result) => {
    this.closeResult = `Closed with: ${result}`;

  }, (reason) => {
    this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    this.mm = this.dname;
  });
}


saveduedeligence() {
  var offset = new Date().getTimezoneOffset();
  var nd = 0.0;
  nd = -(parseFloat(offset.toString()));
  if (this.dname == null || this.dname == undefined || this.dname == "") {
    alert("Please add your Diligence name");
  }
  else {
    this.spinner.show();
    const inputRequest: AddNewFolder = {
      Id: 0,
      FolderID: "0",
      RoleId: this.roleid,
      FolderName: this.dname,
      UserId: this.uid,
      GUID:this.GUID,
      Code: "Diligence",
      ParentID: "0",
      Click: null,
      date: nd,

    }
    this.userservice.adddiligence(inputRequest).subscribe((dat: any) => {
      if (dat == 1) {
        alert("Added your Diligence default folders successfully");

        this.GetAllFolders();
        this.GetAllFolders1();
        const audit = {
          TransactionName:"Folder Management",
          TransactionType:"Added Due Diligence",
          TransactionDesc:"Added Due Diligence",
          }
        this.auditortransactionservice.AuditorTransactionDetails(audit);
      
      }
      else {
        alert("Something went wrong!! please Try Again")
      }
      this.spinner.hide();
      this.msg = "";
    });
  }
}


//individual to enterprise migration
savemigrate() {
  const inputRequest: AddFolderMigrateVM = {
    MigrateMail: this.mail,
    Loginguid: this.GUID, 
    LoginUserId: this.uid,
    UserName: this.username,
    Email:this.MailId,
  };
  this.showper = true;
  this.generalprogressbar = true;
  this.folderCreating = true;
  this.getPercent(this.n);
  this.Creatingmsg = "Migrating items within blockchain...";
  this.num = setInterval(() => {
    if (this.n <= 90) {
      this.Creatingmsg = "Migrating items within blockchain...";
      this.n = this.n + this.getPercent(this.n);
      if (this.n == 90) {
        this.n = 90;
        clearInterval(this.num);
      }
    }
  }, 3000);
  this.userservice.Getmigrate(inputRequest).subscribe((data: any) => {
    if (data != "fail") {
      this.n = 100;
      this.createdmsg = "Migrated items successfully within blockchain! "
      this.folderCreating = false;
      this.foldercreated = true;
      setTimeout(function () {
        this.folderCreating = false;
        this.foldercreated = false;
        this.generalprogressbar = false;
        this.errormsg = false;
        this.showper = false;
        this.n = 0;
      }.bind(this), 3000);
      const audit = {
        TransactionName:"Folder Management",
        TransactionType:"Migration",
        TransactionDesc:"Migration",
        }
        this.auditortransactionservice.AuditorTransactionDetails(audit);
    }
    if (data == "fail") {
      
    //  setTimeout(() => this.dismissalert = true, 100);
      setTimeout(function () {
        this.dismissalert = false;
        this.folderCreating = false;
        this.foldercreated = false;
        this.generalprogressbar = false;
        this.errormsg = false;
        this.showper = false;
        this.n = 0;
        alert("Please enter Enterprise MailId");
      }.bind(this), 3000);
      this.GetAllFolders1();
      this.spinner.hide();
    }
    if(data == "fail1"){
      alert("Something went wrong!!! Please try again");

    }
  });

}

  Clientapproval(){
    if(this.clientname==null || this.clientname==undefined){
      alert("Please enter valid Username")
    }

    else  {

      const inputRequest1 = {
       ClientName:this.clientname,
       ClientEmail:this.clientemail,
       UserEmail:this.MailId,
       UserName:this.username,
       FromUserId:this.uid,
       RequestingFor :"Blocdrive License"
      
      }
   
      this.clientapproval.savenewClient(inputRequest1).subscribe(
        (data: any) => {
          if (data == "1") {
            this.notify = "User added Successfully";
             const audit = {
          TransactionName:"Folder Management",
          TransactionType:"Client",
          TransactionDesc:"Client Creation",

          }
          this.auditortransactionservice.AuditorTransactionDetails(audit);
            this.clientname ="";
            this.clientemail="";
           
            setTimeout(() => this.dismissalert = true, 100);
            setTimeout(function () {
              this.dismissalert = false;
            }.bind(this), 3000);
            this.spinner.hide();
            this.Users();
          } else {
            this.notify = "Something went wrong. Try Again.!!"
          
            this.clientname="";
            this.clientemail="";
           
            setTimeout(() => this.dismissalert = true, 100);
            setTimeout(function () {
              this.dismissalert = false;
            }.bind(this), 3000);
            this.spinner.hide();
          
          }
        });
    }
  }

  UpdateFolderName() {
    var offset = new Date().getTimezoneOffset();
    var nd = 0.0;
    nd = -(parseFloat(offset.toString())); const inputRequest: AddNewFolder = {
      Id: this.details.id,
      FolderID: this.details.folderID,
      RoleId: this.roleid,
      FolderName: this.updatemsg,
      UserId: this.uid,
      GUID:this.GUID,
      Code: "Folder",
      ParentID: this.details.parentID,
      Click: null,
      date: nd,

    }
    this.spinner.show()

    this.userservice.UpdateFolder(inputRequest).subscribe((data: any) => {
      this.spinner.hide()

      if (data == true) {
        this.GetAllFolders();
        this.GetAllFolders1();
      }
      else {
        alert("Something went wrong!!!..");
        window.location.reload();
      }
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  GetSubfolder(data) {
    //this.subf = data.fo.userfullname
    var res=data.folderID+","+this.GUID;
    this.userservice.getsubfolders(res).subscribe((respose: any) => {
      this.allSubFolders = respose;
      this.subf = this.allSubFolders.folderName;
      var Pid = this.allSubFolders.parentId;
      //localStorage.setItem(this.allSubFolders);
      localStorage.setItem('retdata', JSON.stringify(this.allSubFolders.retData));
      localStorage.setItem('subfolders', JSON.stringify(this.allSubFolders.subFolders));
      localStorage.setItem('parentid', JSON.stringify(this.allSubFolders.parentId));
      // console.log("user", this.allSubFolders);
      this.router.navigate(['allsubfolders/' + data.folderID]);
    });
  }

  openModal(id: string) {
    this.modalService.open(id);
  }

  Delete(data) {
    var res;
    if (data.folderName == null) {
      res = confirm("Do You Want to Delete " + data.documentName + "!!!");
    }
    else {
      res = confirm("Do You Want to Delete " + data.folderName + "!!!");
    }
    if (res == true) {
      if (data.hash) {
        var info = {
          Id: data.id,
          FolderID: data.folderId,
          FolderName: data.folderName,
          UserId: this.uid,
          GUID:this.GUID,
          Code: "Document",
          ParentID: data.folderId,
          RoleId: this.roleid,
          Click: null
        };
      }
      else {
        info = data;
      }
    }
    this.userservice.deletefolder(info).subscribe((data: any) => {
      this.GetAllFolders();
      this.GetAllFolders1();
    });
  }
  addSubFolder(a, b) {

  }
  UpdateSubFolder(a, b) {

  }
  onSearchChange(searchValue: string) {

    this.val = searchValue;
    if (this.val.length == 0) {
      setTimeout(() => {
        document.getElementById("moveid").style.display = "none";

      }, 1000);

    }
    if (this.val.length != 0) {
      document.getElementById("moveid").style.display = "none";
      this.SearchEnabled = true;
    }
    else {
      document.getElementById("moveid").style.display = "none";
      this.SearchEnabled = false;
    }
    if (this.val.length > 1) {
      const inputRequest: UserIdVM = {
        Fid: this.val,
        UID: this.uid,
        GUID:this.GUID,

      }
      this.userservice.getfolderssearch(inputRequest).subscribe((data: any) => {
        this.SearchResult = data;
        console.log(data);
        //alert("Deleted Successfully");
        //window.location.reload();

      });
    }
  }
  getpdfdocument()
  {
    this.val = ".pdf";
   this.listvalue = false;
      this.gridvalue = false;
      this.SearchEnabled=true;
    
    if (this.val.length > 1) {
      const inputRequest: UserIdVM = {
        Fid: this.val,
        UID: this.uid,
        GUID:this.GUID,

      }
      this.showload = true;
      this.userservice.getfolderssearch(inputRequest).subscribe((data: any) => {
        this.SearchResult = data;
        if(this.SearchResult != undefined && this.SearchResult != null ){
          this.showload = false;
        }  
        console.log(data);
        //alert("Deleted Successfully");
        //window.location.reload();

      });
    }
  }
  getpngdocument()
  {
    //...... this.val = ".png";
this.val = "IMG";
   this.listvalue = false;
      this.gridvalue = false;
      this.SearchEnabled=true;
    
    if (this.val.length > 1) {
      const inputRequest: UserIdVM = {
        Fid: this.val,
        UID: this.uid,
        GUID:this.GUID,

      }
      this.showload = true;
      this.spinner.show();
      this.userservice.getfolderssearch(inputRequest).subscribe((data: any) => {
        this.SearchResult = data;
        if(this.SearchResult != undefined && this.SearchResult != null ){
          this.showload = false;
        }  
        this.spinner.hide();
        console.log(data);
        //alert("Deleted Successfully");
        //window.location.reload();

      });
    }
  }
  gettxtdocument()
  {
    this.val = ".txt";
   this.listvalue = false;
      this.gridvalue = false;
      this.SearchEnabled=true;
    
    if (this.val.length > 1) {
      const inputRequest: UserIdVM = {
        Fid: this.val,
        UID: this.uid,
        GUID:this.GUID,

      }
      this.showload = true;
      this.userservice.getfolderssearch(inputRequest).subscribe((data: any) => {
        this.SearchResult = data;
        if(this.SearchResult != undefined && this.SearchResult != null ){
          this.showload = false;
        }     
        this.spinner.hide();
        console.log(data);
        //alert("Deleted Successfully");
        //window.location.reload();

      });
    }
  }
  getzipdocument()
  {
    this.val = ".zip";
   this.listvalue = false;
      this.gridvalue = false;
      this.SearchEnabled=true;
    
    if (this.val.length > 1) {
      const inputRequest: UserIdVM = {
        Fid: this.val,
        UID: this.uid,
        GUID:this.GUID,

      }
      this.showload = true;
      this.userservice.getfolderssearch(inputRequest).subscribe((data: any) => {
        this.SearchResult = data;
        if(this.SearchResult != undefined && this.SearchResult != null ){
          this.showload = false;
        }     
        this.spinner.hide();
        console.log(data);
        //alert("Deleted Successfully");
        //window.location.reload();

      });
    }
  }
  getsqldocument()
  {
    this.val = ".sql";
   this.listvalue = false;
      this.gridvalue = false;
      this.SearchEnabled=true;
    
    if (this.val.length > 1) {
      const inputRequest: UserIdVM = {
        Fid: this.val,
        UID: this.uid,
        GUID:this.GUID,

      }
      this.showload = true;
      this.userservice.getfolderssearch(inputRequest).subscribe((data: any) => {
        this.SearchResult = data;
        if(this.SearchResult != undefined && this.SearchResult != null ){
          this.showload = false;
        }     
        this.spinner.hide();
        console.log(data);
        //alert("Deleted Successfully");
        //window.location.reload();

      });
    }
  }
  getmpdocument()
  {
    this.val = ".mp4";
   this.listvalue = false;
      this.gridvalue = false;
      this.SearchEnabled=true;
    
    if (this.val.length > 1) {
      const inputRequest: UserIdVM = {
        Fid: this.val,
        UID: this.uid,
        GUID:this.GUID,

      }
      this.showload = true;
      this.userservice.getfolderssearch(inputRequest).subscribe((data: any) => {
        this.SearchResult = data;
        if(this.SearchResult != undefined && this.SearchResult != null ){
          this.showload = false;
        }     
        this.spinner.hide();
        console.log(data);
        //alert("Deleted Successfully");
        //window.location.reload();

      });
    }
  }
  getpptdocument()
  {
    this.val = ".ppt";
   this.listvalue = false;
      this.gridvalue = false;
      this.SearchEnabled=true;
    
    if (this.val.length > 1) {
      const inputRequest: UserIdVM = {
        Fid: this.val,
        UID: this.uid,
        GUID:this.GUID,

      }
      this.showload = true;
      this.userservice.getfolderssearch(inputRequest).subscribe((data: any) => {
        this.SearchResult = data;
        if(this.SearchResult != undefined && this.SearchResult != null ){
          this.showload = false;
        }     
        this.spinner.hide();
        console.log(data);
        //alert("Deleted Successfully");
        //window.location.reload();

      });
    }
  }
  getdocdocument()
  {
    this.val = ".docx";
   this.listvalue = false;
      this.gridvalue = false;
      this.SearchEnabled=true;
    
    if (this.val.length > 1) {
      const inputRequest: UserIdVM = {
        Fid: this.val,
        UID: this.uid,
        GUID:this.GUID,

      }
      this.showload = true;
      this.userservice.getfolderssearch(inputRequest).subscribe((data: any) => {
        this.SearchResult = data;
        if(this.SearchResult != undefined && this.SearchResult != null ){
          this.showload = false;
        }     
        this.spinner.hide();
        console.log(data);
        //alert("Deleted Successfully");
        //window.location.reload();

      });
    }
  }

  getfolderdocument(){
    this.allfoldersview=true;
    this.displaydesign=false;
  
  }

  ViewDoc(file) {
    localStorage.removeItem('BlockId');
    localStorage.removeItem('EmailView');
    localStorage.removeItem('Pdffile');
    localStorage.removeItem('Fid');
    localStorage.removeItem('DocType');
    localStorage.removeItem('UploadedDate');
    localStorage.removeItem('FileType');
    localStorage.removeItem('IsAssignTaskDoc');

    console.log(file);
    this.spinner.show();
    localStorage.setItem('Fileview', file.signFile);
    localStorage.setItem('FileNameView', file.documentName);
    localStorage.setItem('BlockId', file.blockId);
    localStorage.setItem('Pdffile', file.pdfFile);
    localStorage.setItem('Fid', file.folderId);
    localStorage.setItem('DocType', file.documentType);
    localStorage.setItem('UploadedDate', file.date);
    localStorage.setItem('FileType', "ALLDOCSFOLDER");
    localStorage.setItem('IsAssignTaskDoc', "ALLDOCSFOLDER");


    this.router.navigate(['fileview/'])
  }


  percentage: any;
  storage: any;
  GetDataUsed() {
    const inputRequest: UserInfo = {
      UserId: this.uid,
      Accountid: null,
      GUID:this.GUID,

    };
    this.folderviewservice.GetDataUsed(this.GUID).subscribe((respose: any) => {
      this.DataUSed = respose;
      this.storage = +localStorage.storage;
      var data = this.DataUSed.substring(0, this.DataUSed.length - 2);
      var intdata = parseInt(data);
      this.percentage = (intdata * 100) / (+this.storage * 1024 * 1024);

      // console.log(this.percentage);
      document.getElementById("progressbar").style.width = this.percentage + "%";

    });
  }

  // Details(data: any) {
  //   this.FolderName = data.folderName;
  //   this.CreatedOn = data.createdOn;
  //   this.CusId = data.userId;
  //   //this.Type = "Folder";
  //   this.showfolderdetails(data,folderdetails)
  // }

  showfolderdetails(folderview, folderdetails) {
    this.FolderName = folderview.folderName;
    this.CreatedOn = folderview.createdOn;
    this.CusId = folderview.userId;
    this.modalService.open(folderdetails).result.then((result) => {

      this.closeResult = `Closed with: ${result}`;

    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      this.m = this.msg;
    });
  }


  GetAllFolders() {
    this.spinner.show();
    this.userservice.getfolders(this.GUID).subscribe((respose: any) => {
      this.allFolders = respose;
      // console.log("user", this.allFolders)
    });
  }
  foladdednotify = false;
  // Move 
  MoveTo(data: any, id: any) {
    // this.ppopup = false;

    this.activateMove = true;
    // this.moveitem = "true";
    // this.Enablemove = true
    this.storedData = data;
    this.copied = 1;
    this.copied1 = true;
    localStorage.setItem("PARRRID", this.storedData.parentID);
    localStorage.setItem("IsDOCorFolder", id);
    localStorage.setItem("MoveData", JSON.stringify(data));
    localStorage.setItem("copied", this.copied);
    this.notify = "Copied to Clipboard";
    setTimeout(() => (this.foladdednotify = true), 100);
    setTimeout(
      function () {
        this.foladdednotify = false;
      }.bind(this),
      3000)

    document.getElementById("moveid").style.display = "block";


  }
  copied: any;
  MoveDash1() {
    this.shareitem = true;
    var parrrid = localStorage.getItem("PARRRID");
    var getdata = localStorage.getItem("MoveData");
    this.getMoveData = JSON.parse(getdata);
    // this.moveitem = "true";
    // this.Enablemove = true;
    this.destinationFolder = 0;
 //......   this.sourceFolder = this.getMoveData.folderID;
 this.sourceFolder = this.getMoveData.FolderID;
    const inputRequest: MoveFolderOrDocument = {
      SourceId: this.sourceFolder,
      DestinationId: this.destinationFolder,
      Parentidd:parrrid,
      GUID: this.GUID    
    }
    this.moveService.saveMoveFolderOrDocument(inputRequest).subscribe((data: any) => {
      if (data != null) {
        this.pid = data.ParentID;
        this.GetAllFolders();
        this.GetAllFolders1();
        this.notify = "Moved Successfully"
        const audit = {
          TransactionName:"Folder Management",
          TransactionType:"Move",
          TransactionDesc:"Floder",

          }
          this.auditortransactionservice.AuditorTransactionDetails(audit);
        setTimeout(() => this.foladdednotify = true, 100);
        setTimeout(function () {
          this.foladdednotify = false;
        }.bind(this), 3000);
        localStorage.removeItem("copied");
      }
      else {
        this.GetAllFolders();
        this.GetAllFolders1();


      }
    });
  }
  // Download Folder
  DownloadFolder(data) {
    const inputRequest: DownloadFolderVM = {
      Id: data.id,
      AUID: this.uid,
      FolderId: data.folderID,
      FolderName: data.folderName,
      GUID:this.GUID,

    }
    this.spinner.show()

    this.userservice.downloadFolder(inputRequest).subscribe((data: any) => {
      this.spinner.hide()

      if (data != null) {
        this.downpath = data;
        this.seleteddownload = true;
        window.open(this.downpath);
      }

      else {
        this.notify = "Something went wrong!!!. Try Again.."
        setTimeout(() => this.dismissalert = true, 100);
        setTimeout(function () {
          this.dismissalert = false;
        }.bind(this), 3000);
        // this.spinner.hide();
      }
    });

  }

  cancel() {
    localStorage.removeItem("copied");
    document.getElementById("moveid").style.display = "none";
  }

  // ALL Muti Functions Starts

  GetFriendDetails() {
    this.userservice.friendlist(this.GUID).subscribe((respose: any) => {


      this.Friends = respose;
      this.frndslist = this.Friends.friendsList;
      for (var i = 0; i < this.frndslist.length; i++) {
        this.frndslistnames.push(this.frndslist[i].friendName)
      }

      this.Friends = respose;
      this.frndslist = this.Friends.friendsList;
      var idd = this.accid;

      for (let i = 0; i < this.frndslist.length; i++) {
        if (idd != undefined) {
          if (idd.includes('@')) {
            if (this.frndslist[i]['email'] == this.accid) {
              for (i = 0; i < 100; i++) {
                window.clearInterval(i);
              }
              alert(this.accid + " has accepted your friend request");
              this.accid = "";
            }
          }
        }
        if (this.frndslist[i]['friendId'] == this.uid) {

          this.frndslist[i]['friendId'] = this.frndslist[i]['userId']
        }
      }
    });
  }

  getPercent(per) {
    return 10;
  }

  toggleSelect(data) {
    this.CurrentFiles = data;
    this.checkenable = true;
    this.multi = true;
    this.sfiles = 0;
    this.sfolder = 0;
    if (data != null) {
      if (this.selectedSelected.length == 0) {
        this.selectedSelected.push(data);
      }
      else {
        const index = this.selectedSelected.indexOf(data, 0);
        if (index > -1) {
          this.selectedSelected.splice(index, 1);
        }
        else {
          this.selectedSelected.push(data);
        }
      }
      if (this.selectedSelected.length == 0) {
        this.checkenable = false;
        this.multi = false;
      }
    }

    for (var i = 0; i < this.selectedSelected.length; i++) {
      if (this.selectedSelected[i].folderName != null) {
        this.sfolder = this.sfolder + 1;
      }
      else {
        this.sfiles = this.sfiles + 1;
      }
    }


    if (this.sfolder == 0) {
      this.deletemulticontent = this.sfiles + " Files"
    }
    else if (this.sfiles == 0) {
      this.deletemulticontent = this.sfolder + " Folders"
    }
    else {
      this.deletemulticontent = this.sfolder + " Folders " + this.sfiles + " Files"
    }
  }

  //Multi Delete
  DeleteMorecoin(Deletecontent) {
    this.modalService.open(Deletecontent).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;

    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      this.m = this.msg;
    });
  }
  Deleteselected() {
    this.showper = true;
    this.generalprogressbar = true;
    this.folderCreating = true;
    this.getPercent(this.n);
    this.Creatingmsg = "Deleting selected items...";
    this.num = setInterval(() => {
      if (this.n <= 90) {
        this.Creatingmsg = "Deleting selected items...";
        this.n = this.n + this.getPercent(this.n);
        if (this.n == 90) {
          this.n = 90;
          clearInterval(this.num);
        }
      }
    }, 3000);
    const inputRequest: DeleteMulit = {
      SelectedFiles: this.selectedSelected,
      Message: "Delete",
      UserId: this.uid,
      ModifiedOn: null,
      GUID:this.GUID,

    }
    this.userservice.DeleteMore(inputRequest).subscribe((data: any) => {
      this.Res = data;
      var fol = this.CurrentFiles;
      if (data != null) {
        this.n = 100;
        this.createdmsg = "Deleted selected items successfully!"
        const audit = {
          TransactionName:"Folder Management",
          TransactionType:"Delete",
          TransactionDesc:"Folder",

          }
          this.auditortransactionservice.AuditorTransactionDetails(audit);
        this.pid = data;
        this.folderCreating = false;
        this.foldercreated = true;
        setTimeout(function () {
          this.folderCreating = false;
          this.foldercreated = false;
          this.generalprogressbar = false;
          this.errormsg = false;
          this.showper = false;
        }.bind(this), 3000);
        //this.notify = "Deleted Successfully"
        // setTimeout(() => this.foladdednotify = true, 100);
        // setTimeout(function () {
        //   this.foladdednotify = false;
        // }.bind(this), 3000);


        this.selectedSelected = [];
        this.multi = false;
        this.checkenable = false;
        this.GetAllFolders();
        this.GetAllFolders1();
        this.GetDataUsed();
        setTimeout(() => {
        }, 500);
        //this.GetAllFolders();
        //this.spinner.hide();
      }
      else {
        this.GetAllFolders();
        this.GetAllFolders1();
        this.checkenable = false;
        this.multi = false;
        this.notify = "Something went Wrong"
        setTimeout(() => this.foladdednotify = true, 100);
        setTimeout(function () {
          this.foladdednotify = false;
        }.bind(this), 3000);
      }
    });

  }


  //Multi Share

  SharewithMoreFriends(Sharecontent) {
    this.GetFriendDetails();
    this.modalService.open(Sharecontent).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;

    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      this.m = this.msg;
    });
  }

  ShareFileMembers(data) {
    for (let index = 0; index < this.frndslist.length; index++) {
      if (this.frndslist[index].friendGUID == data) {
        this.selectedFriend.push(this.frndslist[index])
        break;
      }
    }
  }



  ShareFile() {
    const inputRequest: ShareMulti = {
      SelectedFiles: this.selectedSelected,
      FriendList: this.selectedFriend,
      UserId: this.uid,
      Message: "share",
      GUID:this.GUID,

    }
    this.userservice.SharemultiFiles(inputRequest).subscribe((data: any) => {
      this.R = data;
      var fol = this.CurrentFiles;
      if (fol.hash) {
        var info = {
          Id: fol.id,
          FolderID: fol.folderId,
          // FolderName: this.folder.FolderName,
          UserId: this.uid,
          GUID:this.GUID,
          Code: "Document",
          ParentID: fol.folderId,
          RoleId: this.roleid,
          Click: this.Press
        };
      }


      alert("File sent");
      this.GetAllFolders();
      this.GetAllFolders1();

    });

  }

  shareAll() {
    const inputRequest: ShareMulti = {
      SelectedFiles: this.allFolders,
      FriendList: this.selectedFriend,
      UserId: this.uid,
      Message: "share",
      GUID:this.GUID,

    }
    this.userservice.SharemultiFiles(inputRequest).subscribe((data: any) => {
      this.R = data;
      var fol = this.CurrentFiles;
      if (fol.hash) {
        var info = {
          Id: fol.id,
          FolderID: fol.folderId,
          // FolderName: this.folder.FolderName,
          UserId: this.uid,
          GUID:this.GUID,
          Code: "Document",
          ParentID: fol.folderId,
          RoleId: this.roleid,
          Click: this.Press
        };
      }


      alert("File sent");
      this.GetAllFolders();
      this.GetAllFolders1();

    });
  }


  GetAllFolders1() {
    this.userservice.getfolders(this.GUID).subscribe((respose: any) => {
      this.allFolders1 = respose;
      // console.log("user", this.allFolders);
      this.spinner.hide();
    });
  }


  getfolders(id1) {

    let id = id1.folderID;
    let elem = document.getElementById(id);
    if (elem.childNodes.length < 2) {

      var icn = document.getElementById(id1.id);
      if (icn != null) {
        icn.setAttribute("class", "fa fa-caret-down");

      }

      this.FileFoldID = id;
      var res = id+','+this.GUID;
      this.userservice.getsubfolders(res).subscribe((respose: any) => {
        this.allSubFolders = respose;
        this.subf = this.allSubFolders.fileName;
        this.fid = this.allSubFolders.parentId;
        // console.log("user", this.allSubFolders);
        // localStorage.setItem('retdata', JSON.stringify(this.allSubFolders.retData));
        //localStorage.setItem('subfolders', JSON.stringify(this.allSubFolders.subFolders));
        //  localStorage.setItem('parentid',JSON.stringify(this.allSubFolders.ParentId) );

        var mdv1 = document.createElement("div");
        for (let i = 0; i < this.allSubFolders.subFolders.length; i++) {
          var mdv = document.createElement("div");
          mdv.setAttribute("id", this.allSubFolders.subFolders[i].folderID);
          mdv.setAttribute("style", "padding-left:20px;");
          var elm = document.createElement("span");
          var img = document.createElement("img");
          img.setAttribute("src", "./assets/img/folder.svg");
          img.setAttribute("width", "30px");
          img.setAttribute("height", "30px");
          var elm1 = document.createElement("span");


          elm1.innerHTML = this.allSubFolders.subFolders[i].folderName;
          var elm12 = document.createElement("i");

          elm12.setAttribute("class", "fa fa-caret-right");
          elm12.setAttribute("id", this.allSubFolders.subFolders[i].id);

          elm12.addEventListener('click', this.getfolders.bind(this, this.allSubFolders.subFolders[i]));

          elm.appendChild(elm12);
          elm.appendChild(img);
          elm.appendChild(elm1);

          mdv.appendChild(elm);

          elm.addEventListener('click', this.gotoFolder.bind(this, this.allSubFolders.subFolders[i]));


          mdv1.appendChild(mdv);
        }
        setTimeout(() => {
          elem.appendChild(mdv1);

        }, 1000);


      });


    }
    else {

      document.getElementById(id1.id).setAttribute("class", "fa fa-caret-right");
      let elem = document.getElementById(id);
      while (elem.childNodes.length > 1) {
        elem.removeChild(elem.lastChild);
      }
    }
  }

  gotoFolder(data, movecontent) {
    this.destinationFold = data;
    alert(data.folderName + " folder is selected")
  }

  gotoFolder12(data) {
    this.folderid = data.folderID;
  }

  gotoFolder40(data) {
    this.folderid = data.folderID;
    this.showbutton = false;
    this.foldernames = this.allFolders1;
    for(var i = 0; i < this.foldernames.length; i++){
        if(this.foldernames[i].folderID == this.folderid){
         alert("Folder will upload within " + this.foldernames[i].folderName )
         break;
        }
    }
  }
  MotoselectedFolde(movecontent) {
    this.ppopup = false;
    document.getElementById("ppup12").click();
    this.GetAllFolders1();
  }


  canceltree() {
    this.ppopup = true;
  }

  MoveMultiFolder(id) {
    document.getElementById("closemove").click();
    this.ppopup = true;
    const inputRequest: MoveMultiFolder = {
      SelectedFiles: this.selectedSelected,
      Message: "Delete",
      DestinationId: id.folderID,
        SourceId : null,
      GUID : this.GUID,
      UserID: this.uid,
      Parentidd:null,
    }
    // if (this.selectedSelected.length == 1) {
    //   this.alert = "Moved " + this.selectedSelected.length + " Item to " + id.FolderName;
    // }
    // else {
    //   this.alert = "Moved " + this.selectedSelected.length + " Items to " + id.FolderName;
    // }
    this.showper = true;
    this.generalprogressbar = true;
    this.folderCreating = true;
    this.getPercent(this.n);
    this.Creatingmsg = "Moving selected items...";
    this.num = setInterval(() => {
      if (this.n <= 90) {

        this.Creatingmsg = "Moving selected items...";
        this.n = this.n + this.getPercent(this.n);
        if (this.n == 90) {
          this.n = 90;
          clearInterval(this.num);
        }
      }
    }, 3000);

    this.userservice.MoveMultiFileFolders(inputRequest).subscribe((data: any) => {
      this.Res = data;
      var fol = this.CurrentFiles;
      this.n = 100;
      this.createdmsg = "Moved selected items successfully!"
      this.Showmoveitem = true;
      this.folderCreating = false;
      this.checkenable = false;
      this.foldercreated = true;
      clearInterval(this.num);
      this.ppopup = true;
      setTimeout(function () {
        this.folderCreating = false;
        this.foldercreated = false;
        this.generalprogressbar = false;
        this.errormsg = false;
        this.showper = false;
      }.bind(this), 3000);
      setTimeout(function () {
        this.Showmoveitem = false;
      }.bind(this), 6000);
      this.selectedSelected = [];
      this.multi = false;
      this.GetAllFolders();
      this.GetAllFolders1();
    });
  }

  open(Sharecontent) {
    this.selectedSelected = null;
    this.selectedFriend = [];
    this.selectedSelected = this.allFolders;
   this.GetFriendDetails();
    this.modalService.open(Sharecontent).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;

    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      this.m = this.msg;
    });
  }

  // ALL Muti Functions Ends
  selectedFriendcancel;

  ShareFileMembers1(data) {
    for (let index = 0; index < this.frndslist.length; index++) {
      if (this.frndslist[index].uid == data) {
        if (this.frndslist[index].userId == this.uid) {
          this.selectedFriendcancel = this.frndslist[index].friendId;
          break;
        }
        this.selectedFriendcancel = this.frndslist[index].userId;
        break;
      }
    }

  }
  cancelshareall = false;
  shareitem1 = false;
  cancelshare() {
    if (this.cancelshareall == true) {
      this.cancelshareall = false;
      this.shareitem1 = false;

    }
    else {
      this.cancelshareall = true;
      this.shareitem1 = true;

    }
  }
  shareallallcancel() {
    if (this.selectedFriendcancel == null || this.selectedFriendcancel == undefined || this.selectedFriendcancel == "") {
      alert("Please select a Friend");
    }
    else {
      const inputRequest: MultiFolderShareVM = {
        Id: 0,
        ToUsers: null,
        USerId: this.uid,
        GUID:this.GUID,
        Code: "MainFolder",
        SelectedFolders: this.selectedSelected,
        AccessType: null,
        BodyContent: null,
        UserName: this.selectedFriendcancel,
        ModifiedOn: null
      }
      //this.spinner.show();
      this.showper = true;
      this.generalprogressbar = true;
      this.folderCreating = true;
      this.getPercent(this.n);
      this.Creatingmsg = "Withdrawing Shared Items...";
      this.num = setInterval(() => {
        if (this.n <= 90) {
          this.Creatingmsg = "Withdrawing Shared Items...";
          this.n = this.n + this.getPercent(this.n);
          if (this.n == 90) {
            this.n = 90;
            clearInterval(this.num);
          }
        }
      }, 3000);

      this.userservice.withdraw(inputRequest).subscribe(
        (data: any) => {
          if (data != null) {
            this.n = 100;
            this.createdmsg = "Withdraw of Shared files successfull!"
            this.folderCreating = false;
            this.foldercreated = true;
            clearInterval(this.num);
            // this.notify = "Folder Shared Succesfully";
            // setTimeout(() => this.dismissalert = true, 100);
            // setTimeout(function () {
            //   this.dismissalert = false;
            // }.bind(this), 3000);
            // this.spinner.hide();
            setTimeout(function () {
              this.folderCreating = false;
              this.foldercreated = false;
              this.generalprogressbar = false;
              this.errormsg = false;
              this.showper = false;
            }.bind(this), 3000);
            this.selectedSelected = [];
            this.multi = false;
          }
          else {
            //alert("Something went wrong!!please try again");
            this.notify = "Something went wrong!!please Try Again";
            setTimeout(() => this.dismissalert = true, 100);
            setTimeout(function () {
              this.dismissalert = false;
            }.bind(this), 3000);
            this.spinner.hide();
          }
        });
    }
  }
  sharetoToUsers(data) {
    this.shareId = data.folderID;
    this.shareFName = data.folderName;
    this.sharePID = data.parentID;
    this.shareUniqueID = data.id;
  }
  ShareFolder(data, Sharecontent) {
    this.sharetoToUsers(data);
    this.GetFriendDetails();
    this.modalService.open(Sharecontent).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;

    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      this.m = this.msg;
    });
  }

  ShareExsistingFolderToUsersForm() {
    //...... if (this.selectedFriend == null || this.selectedFriend == undefined || this.selectedFriend == "") {
    //   alert("Please select a Friend");
    //...... }

    
    for (var i = 0; i < this.selectedItems.length; i++) {
      for (var j = 0; j < this.frndslist.length; j++) {
        if (this.selectedItems[i] == this.frndslist[j].friendName) {
          this.selfrnds.push(this.frndslist[j])
        }
      }
    }
    if (this.selectedItems == null || this.selectedItems == undefined ) {
      alert("Please select a Friend");
    }
    else {
      const inputRequest: FolderShareToUers = {
        Id: this.shareUniqueID,
//...... ToUsers: this.selectedFriend,
        ToUsers: this.selfrnds,
        BodyContent: null,
        FolderType: "2",
        FolderName: this.shareFName,
        Code: "Folder",
        ParentID: this.sharePID,
        FolderID: this.shareId,
        UserId: this.uid,
        GUID:this.GUID,
        AccessType: null,
        UserName: null,
        UserEmail: null,
        CreatedOn: null
      }
      //this.spinner.show();
      this.showper = true;
      this.generalprogressbar = true;
      this.folderCreating = true;
      this.Creatingmsg = "Sharing selected items...";
      this.getPercent(this.n);
      this.num = setInterval(() => {
        if (this.n <= 90) {
          this.Creatingmsg = "Sharing selected items...";
          this.n = this.n + this.getPercent(this.n);
          if (this.n == 90) {
            this.n = 90;
            clearInterval(this.num);
          }
        }
      }, 3000);

      this.userservice.addExsistingMainFolderAndShare(inputRequest).subscribe(
        (data: any) => {
          if (data != null) {
            this.n = 100;
            this.createdmsg = "Shared selected items successfully!"
            this.folderCreating = false;
            this.foldercreated = true;
            clearInterval(this.num);

            setTimeout(function () {
              this.folderCreating = false;
              this.foldercreated = false;
              this.generalprogressbar = false;
              this.errormsg = false;
              this.showper = false;
            }.bind(this), 3000);

            this.GetAllFolders();
            this.GetAllFolders1();


          }
          else {
            //alert("Something went wrong!!please try again");
            this.notify = "Something went wrong!!please Try Again";
            setTimeout(() => this.dismissalert = true, 100);
            setTimeout(function () {
              this.dismissalert = false;
            }.bind(this), 3000);

          }
          this.selectedFriend = [];
          this.accid = "";
        });
    }
  }

  ShareExsistingMultiFolderToUsers() {
    // if (this.selectedFriend == null || this.selectedFriend == undefined || this.selectedFriend == "") {
    //   alert("Please select a Friend");
    // }

    for (var i = 0; i < this.selectedItems.length; i++) {
      for (var j = 0; j < this.frndslist.length; j++) {
        if (this.selectedItems[i] == this.frndslist[j].friendName) {
          this.selfrnds.push(this.frndslist[j])
        }
      }
    }

    if (this.selfrnds == null || this.selfrnds == undefined || this.selfrnds == "") {
      alert("Please select a Friend");
    }

    else {
      const inputRequest: MultiFolderShareVM = {
        Id: this.shareUniqueID,
 //......       ToUsers: this.selectedFriend,
        ToUsers: this.selfrnds,
        USerId: this.uid,
        GUID:this.GUID,
        Code: "MainFolder",
        SelectedFolders: this.selectedSelected,
        AccessType: null,
        BodyContent: null,
        UserName: this.username,
        ModifiedOn: null
      }
      //this.spinner.show();
      this.showper = true;
      this.generalprogressbar = true;
      this.folderCreating = true;
      this.getPercent(this.n);
      this.Creatingmsg = "Sharing selected items...";
      this.num = setInterval(() => {
        if (this.n <= 90) {
          this.Creatingmsg = "Sharing selected items...";
          this.n = this.n + this.getPercent(this.n);
          if (this.n == 90) {
            this.n = 90;
            clearInterval(this.num);
          }
        }
      }, 3000);

      this.userservice.addMultigMainFolderAndShare(inputRequest).subscribe(
        (data: any) => {
          if (data != null) {
            this.n = 100;
            this.createdmsg = "Shared selected items successfully!"
            this.folderCreating = false;
            this.foldercreated = true;
            clearInterval(this.num);
            // this.notify = "Folder Shared Succesfully";
            // setTimeout(() => this.dismissalert = true, 100);
            // setTimeout(function () {
            //   this.dismissalert = false;
            // }.bind(this), 3000);
            // this.spinner.hide();
            setTimeout(function () {
              this.folderCreating = false;
              this.foldercreated = false;
              this.generalprogressbar = false;
              this.errormsg = false;
              this.showper = false;
            }.bind(this), 3000);
            this.selectedSelected = [];
            this.multi = false;
          }
          else {
            //alert("Something went wrong!!please try again");
            this.notify = "Something went wrong!!please Try Again";
            setTimeout(() => this.dismissalert = true, 100);
            setTimeout(function () {
              this.dismissalert = false;
            }.bind(this), 3000);
            this.spinner.hide();
          }
        });
    }
  }

  shareallall() {


    for (var i = 0; i < this.selectedItems.length; i++) {
      for (var j = 0; j < this.frndslist.length; j++) {
        if (this.selectedItems[i] == this.frndslist[j].friendName) {
          this.selfrnds.push(this.frndslist[j])
        }
      }
    }
    //...... if (this.selectedFriend == null || this.selectedFriend == undefined || this.selectedFriend == "") {
    //   alert("Please select a Friend");
    //...... }
    if (this.selfrnds == null || this.selfrnds == undefined || this.selfrnds == "") {
      alert("Please select a Friend");
    }
    else {
      const inputRequest: MultiFolderShareVM = {
        Id: this.shareUniqueID,
//......        ToUsers: this.selectedFriend,
        ToUsers: this.selfrnds,
        USerId: this.uid,
        GUID:this.GUID,
        Code: "MainFolder",
        SelectedFolders: this.selectedSelected,
        AccessType: null,
        BodyContent: null,
        UserName: this.username,
        ModifiedOn: null
      }
      //this.spinner.show();
      this.showper = true;
      this.generalprogressbar = true;
      this.folderCreating = true;
      this.getPercent(this.n);
      this.Creatingmsg = "Sharing selected items...";
      this.num = setInterval(() => {
        if (this.n <= 90) {
          this.Creatingmsg = "Sharing selected items...";
          this.n = this.n + this.getPercent(this.n);
          if (this.n == 90) {
            this.n = 90;
            clearInterval(this.num);
          }
        }
      }, 3000);

      this.userservice.shareall(inputRequest).subscribe(
        (data: any) => {
          if (data != null) {
            this.n = 100;
            this.createdmsg = "Shared selected items successfully!"
            this.folderCreating = false;
            this.foldercreated = true;
            clearInterval(this.num);
            // this.notify = "Folder Shared Succesfully";
            // setTimeout(() => this.dismissalert = true, 100);
            // setTimeout(function () {
            //   this.dismissalert = false;
            // }.bind(this), 3000);
            // this.spinner.hide();
            setTimeout(function () {
              this.folderCreating = false;
              this.foldercreated = false;
              this.generalprogressbar = false;
              this.errormsg = false;
              this.showper = false;
            }.bind(this), 3000);
            this.selectedSelected = [];
            this.multi = false;
          }
          else {
            //alert("Something went wrong!!please try again");
            this.notify = "Something went wrong!!please Try Again";
            setTimeout(() => this.dismissalert = true, 100);
            setTimeout(function () {
              this.dismissalert = false;
            }.bind(this), 3000);
            this.spinner.hide();
          }
        });
    }
  }

  // gotoFolder(data)
  // {

  //  this.folderid = data.folderID;
  // }

  cancelupload() {
    this.ppopup = true;
  }

  UploadFolder() {
    if(this.folderid == null){
      alert("Please select the path to upload folder")
    }
    else{
      this.n = 10;
      document.getElementById("cancelUpload1").click();
      this.showper = true;
      this.generalprogressbar = true;
      this.folderCreating = true;
      this.Creatingmsg = "Uploading Folder to blocdrive...";
      this.getPercent(this.n);
      this.num = setInterval(() => {
        if (this.n <= 90) {
          this.Creatingmsg = "Uploading Folder to blocdrive...";
          this.n = this.n + this.getPercent(this.n);
          if (this.n == 90) {
            this.n = 90;
            clearInterval(this.num);
          }
        }
      }, 3000);
      this.DateTime = new Date().getTimezoneOffset();
      // let latest_date = this.datepipe.transform(this.DateTime, 'MM-dd-yyyy hh:mm:ss a');
      this.uploadfolderclicked = true;
      this.frmData1.append("Level", this.SelectedLevel);
    //  this.frmData1.append("FolderId", this.folderid);
      this.frmData1.append("FolderId", "0");
      this.frmData1.append("FolderName", this.SelectedFolderName);
      this.frmData1.append("UserId", this.uid);
      this.frmData1.append("GUID", this.GUID);
      this.frmData1.append("date", (-(this.DateTime)).toString());
      //this.spinner.show();
      //  this.showper = true;
      //  this.generalprogressbar = true;
      //    this.folderCreating = true;
      //   this.getPercent(this.n);
      //   this.Creatingmsg = "Uploading Folder...";
      // this.num = setInterval(() => {
      //   if (this.n <= 90) {
      //     this.Creatingmsg = "Uploading Folder...";
      //     this.n = this.n + this.getPercent(this.n);
      //     if (this.n == 90) {
      //       this.n = 90;
      //       clearInterval(this.num);
      //     }
      //   }
      // }, 3000);
      this.httpService.post('https://blocdrivesaas.in/blocdrivesaasapi/api/UploadFolder/NewAllFolderUpload/', this.frmData1).subscribe(
        data => {
          if (data != null) {
  
  
            localStorage.setItem("InwardFolderId", data.toString())
            localStorage.setItem("InwardTypeFile", "false")
            localStorage.removeItem('InwardFileId');
  
            this.n = 100;
            this.createdmsg = "Folder uploaded successfully to blocdrive!!!";
            const audit = {
              TransactionName:"Folder Management",
              TransactionType:"Upload",
              TransactionDesc:"Folder",
    
              }
              this.auditortransactionservice.AuditorDetailsProject(audit);
            this.pid = data;
            this.folderCreating = false;
            this.foldercreated = true;
            clearInterval(this.num);
  
            setTimeout(function () {
              this.folderCreating = false;
              this.foldercreated = false;
              this.generalprogressbar = false;
              this.errormsg = false;
              this.showper = false;
              this.GetAllFolders();
              this.GetAllFolders1();
              if (this.roleid == 1 || this.roleid == 8) {
                this.router.navigate(['/inward'])
              }
              this.ppopup = true;
            }.bind(this), 3000);
  
  
          }
        },
        (err: HttpErrorResponse) => {
          // console.log(err.message);
          this.ppopup = true;
         alert("Something went wrong! Try Again!!!")     // this.spinner.hide();
        });
    }
   
  }


  gotoFolder1(data) {
    this.folderid = data.folderID;

  }

  Uploaddocs1() {
    this.UploadFolder();

  }

  uploadhere() {
    var uploadhere = document.getElementById("Uploadhere");
    if (uploadhere.style.backgroundColor == "blue") {
//......     uploadhere.style.backgroundColor = "transparent";
     uploadhere.style.backgroundColor = "blue";
    }
    else {
      uploadhere.style.backgroundColor = "blue";
    }

    let href = window.location.href.split('/');
    let val = href[href.length - 1];
    if (val != "allfolders" && val != "allsubfolders") {
      this.folderid = val;
    }
    else {
      this.folderid = "0";
    }
  }

  filesPicked(files) {
    // this.getFoldersforCompare();
    this.frmData1 = null;
    this.sameNames = null;
    this.uploadfolderclicked = true;
    this.frmData1 = new FormData();
    for (let i = 0; i < files.length; i++) {
      this.SelectedFolderandFiles.push(files[i]);
      this.frmData1.append("fileUpload", files[i]);
    }
    console.log(files);
    //document.getElementById("OpenModalTreeStructure").click();
    this.uploadfolderclicked = false;
    var folname = files[0].webkitRelativePath.split('/');


    for (var i = 0; i < this.folderlist.length; i++) {
      if (this.folderlist[i].FolderName == folname[0]) {
        this.sameFolderName.push(this.folderlist[i].FolderName);
        this.replaceFolderid.push(this.folderlist[i])
        continue;
      }
    }

    if (this.sameFolderName.length != 0) {
      this.sameNames = this.sameFolderName[0];
    }

    if (this.sameNames) {
      for (let index = 0; index < this.replaceFolderid.length; index++) {
        if (index == 0) {
          this.relaceFolderlist = this.replaceFolderid[index].FolderId;
        }
        else {
          this.relaceFolderlist = this.relaceFolderlist + "," + this.replaceFolderid[index].FolderId;
        }
      }
      //  console.log(this.relaceFolderlist);
      document.getElementById("duplicateFolder1").click();
      //this.GetFolderandSubFolder();  
      this.frmData1.append("replaceFolderid", this.relaceFolderlist);
    }
    else {
      //  this.ViewVideo("folderview", "videocontent");
      //   this.addFriend("addfriendcontent");
      this.ppopup = false;

      // this.modalService.open("uploadfolder").result.then((result) => {
      //   this.closeResult = `Closed with: ${result}`;

      // }, (reason) => {
      //   this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      //   this.m = this.msg;
      // });
      document.getElementById("ppup").click();
    }
  }





  getfolders1(id1) {
    let id = id1.folderID;
    let elem = document.getElementById(id);
    if (elem.childNodes.length < 2) {

      var icn = document.getElementById(id1.id);
      if (icn != null) {
        icn.setAttribute("class", "fa fa-caret-down");

      }

      this.FileFoldID = id;

      var res=id + "," + this.GUID
      this.userservice.getsubfolders(res).subscribe((respose: any) => {
        this.allSubFolders = respose;
        this.subf1 = this.allSubFolders.fileName;
        this.fid = this.allSubFolders.parentId;
        // console.log("user", this.allSubFolders);
        // localStorage.setItem('retdata', JSON.stringify(this.allSubFolders.retData));
        //localStorage.setItem('subfolders', JSON.stringify(this.allSubFolders.subFolders));
        //  localStorage.setItem('parentid',JSON.stringify(this.allSubFolders.ParentId) );

        var mdv1 = document.createElement("div");
        for (let i = 0; i < this.allSubFolders.subFolders.length; i++) {
          var mdv = document.createElement("div");
          mdv.setAttribute("id", this.allSubFolders.subFolders[i].folderID);
          mdv.setAttribute("style", "padding-left:20px;color: black;cursor:pointer;white-space: nowrap;width: auto;overflow: hidden;text-overflow: ellipsis;cursor: pointer;");
          var elm = document.createElement("span");

          var img = document.createElement("img");
          img.setAttribute("src", "./assets/img/folder.svg");
          img.setAttribute("width", "30px");
          img.setAttribute("height", "30px");
          var elm1 = document.createElement("span");

          elm1.title = this.allSubFolders.subFolders[i].folderName;
          elm1.innerHTML = this.allSubFolders.subFolders[i].folderName;

          var elm12 = document.createElement("i");

          elm12.setAttribute("class", "fa fa-caret-right");
          elm12.setAttribute("id", this.allSubFolders.subFolders[i].id);

          elm12.addEventListener('click', this.getfolders1.bind(this, this.allSubFolders.subFolders[i]));



          elm.appendChild(elm12);
          elm.appendChild(img);
          elm.appendChild(elm1);

          mdv.appendChild(elm);

          img.addEventListener('click', this.gotoFolder.bind(this, this.allSubFolders.subFolders[i], "Movetofold"));
          elm1.addEventListener('click', this.gotoFolder.bind(this, this.allSubFolders.subFolders[i], "Movetofold"));


          mdv1.appendChild(mdv);
        }





        elem.appendChild(mdv1);


      });


    }
    else {

      document.getElementById(id1.id).setAttribute("class", "fa fa-caret-right");
      let elem = document.getElementById(id);
      while (elem.childNodes.length > 1) {
        elem.removeChild(elem.lastChild);
      }
    }
  }





  getfolders2(id1) {
    let id = id1.folderID;
    let elem = document.getElementById(id);
    if (elem.childNodes.length < 2) {

      var icn = document.getElementById(id1.id);
      if (icn != null) {
        icn.setAttribute("class", "fa fa-caret-down");

      }

      this.FileFoldID = id;
      var res=id + "-" + this.GUID
      this.userservice.getsubfolders(res).subscribe((respose: any) => {
        this.allSubFolders = respose;
        this.subf1 = this.allSubFolders.fileName;
        this.fid = this.allSubFolders.parentId;
        // console.log("user", this.allSubFolders);
        // localStorage.setItem('retdata', JSON.stringify(this.allSubFolders.retData));
        //localStorage.setItem('subfolders', JSON.stringify(this.allSubFolders.subFolders));
        //  localStorage.setItem('parentid',JSON.stringify(this.allSubFolders.ParentId) );

        var mdv1 = document.createElement("div");
        for (let i = 0; i < this.allSubFolders.subFolders.length; i++) {
          var mdv = document.createElement("div");
          mdv.setAttribute("id", this.allSubFolders.subFolders[i].folderID);
          mdv.setAttribute("style", "padding-left:20px");
          var elm = document.createElement("span");
          var img = document.createElement("img");
          img.setAttribute("src", "./assets/img/folder.svg");
          img.setAttribute("width", "30px");
          img.setAttribute("height", "30px");
          var elm1 = document.createElement("span");
          elm1.innerHTML = this.allSubFolders.subFolders[i].folderName;

          var elm12 = document.createElement("i");

          elm12.setAttribute("class", "fa fa-caret-right");
          elm12.setAttribute("id", this.allSubFolders.subFolders[i].id);

          elm12.addEventListener('click', this.getfolders1.bind(this, this.allSubFolders.subFolders[i]));



          elm.appendChild(elm12);
          elm.appendChild(img);
          elm.appendChild(elm1);

          mdv.appendChild(elm);

          img.addEventListener('click', this.gotoFolder12.bind(this, this.allSubFolders.subFolders[i]));
          elm1.addEventListener('click', this.gotoFolder12.bind(this, this.allSubFolders.subFolders[i]));


          mdv1.appendChild(mdv);
        }





        elem.appendChild(mdv1);


      });


    }
    else {

      document.getElementById(id1.id).setAttribute("class", "fa fa-caret-right");
      let elem = document.getElementById(id);
      while (elem.childNodes.length > 1) {
        elem.removeChild(elem.lastChild);
      }
    }
  }
  client: any;
  savenewClient() {
    var offset = new Date().getTimezoneOffset();
    var nd = 0.0;
    nd = -(parseFloat(offset.toString()));
    if (this.client == null || this.client == undefined || this.client == "") {
      alert("Please add your Client name");
    }
    else {
      this.spinner.show();
      const inputRequest: AddNewFolder = {
        Id: 0,
        FolderID: "0",
        RoleId: this.roleid,
        FolderName: this.client,
        UserId: this.uid,
        GUID:this.GUID,
        Code: "Client",
        ParentID: "0",
        Click: null,
        date: nd,

      }
      this.userservice.addclient(inputRequest).subscribe((dat: any) => {
        if (dat == 1) {
          alert("Added your Client default folders successfully");
          this.GetAllFolders();
          this.GetAllFolders1();
        }
        else {
          alert("Something went wrong!!!.. please Try Again")
        }
        this.spinner.hide();
        this.msg = "";
      });
    }
  }

  MoveDoc(a, b) {

  }
  ShareoneFile(a, b) {

  }
  savenewSubFolder() {

  }
  UpdateSubFolderName() {

  }
  CheckDownloadMultiple() {
    if (this.selectedSelected.length == 1) {
      if (this.selectedSelected[0].DocumentName != null) {
        this.DownloadFile(this.selectedSelected[0])
      }
      else if (this.selectedSelected[0].FolderName != null) {
        this.DownloadFolder(this.selectedSelected[0])
      }
      else if (this.selectedSelected[0].folderName != null) {
        this.DownloadFolder(this.selectedSelected[0])
      }
    }
    else {
      this.DownloadMultiple();
    }
  }

//to download file by its original name
  abc(data1){
    this.DownloadFile(data1);
      }



  // Download File
  DownloadFile(data) {
    console.log(data);
    const inputRequest = {
      Id: data.Id,
      AUID: this.uid,
      FolderId: data.FolderId,
      FolderName: data.FolderName,
      BlockID: data.BlockId,
      ipaddress: this.IPAddress,
      dnsip: this.dnsIP,
      browser: this.getBrowserName(),
      transaction: null
    }
    this.showper = true;
    this.generalprogressbar = true;
    this.folderCreating = true;
    this.getPercent(this.n);
    this.Creatingmsg = "Dowloading items within blockchain...";
    this.num = setInterval(() => {
      if (this.n <= 90) {
        this.Creatingmsg = "Dowloading items within blockchain...";
        this.n = this.n + this.getPercent(this.n);
        if (this.n == 90) {
          this.n = 90;
          clearInterval(this.num);
        }
      }
    }, 3000);
    this.userservice.downloadFile(inputRequest).subscribe((data: any) => {
      if (data != null) {
        this.n = 100;
        this.createdmsg = "Dowloaded items successfully within blockchain! "
        this.folderCreating = false;
        this.foldercreated = true;
        setTimeout(function () {
          this.folderCreating = false;
          this.foldercreated = false;
          this.generalprogressbar = false;
          this.errormsg = false;
          this.showper = false;
          this.n = 0;

        }.bind(this), 3000);

        this.downpath = data;
        this.seleteddownload = true;
        window.open(this.downpath);
      }
      else {
        this.notify = "Something Went Wrong. Try again.!!"
        setTimeout(() => this.dismissalert = true, 100);
        setTimeout(function () {
          this.dismissalert = false;
        }.bind(this), 3000);
        this.GetAllFolders1();
        this.spinner.hide();
      }
    });

  }


  // Download Multiple
  DownloadMultiple() {
    const inputRequest = {
      SelectedFiles: this.selectedSelected,
      Message: "Download",
      UserId: this.uid,
      ipaddress: this.IPAddress,
      dnsip: this.dnsIP,
      browser: this.getBrowserName(),
      transaction: null
    }
    //this.spinner.show();
    this.showper = true;
    this.generalprogressbar = true;
    this.folderCreating = true;
    this.getPercent(this.n);
    this.Creatingmsg = "Dowloading items within blockchain...";
    this.num = setInterval(() => {
      if (this.n <= 90) {
        this.Creatingmsg = "Dowloading items within blockchain...";
        this.n = this.n + this.getPercent(this.n);
        if (this.n == 90) {
          this.n = 90;
          clearInterval(this.num);
        }
      }
    }, 3000);
    this.userservice.DownloadMultipleFolders(inputRequest).subscribe((data: any) => {
      if (data != null) {
        this.n = 100;
        this.createdmsg = "Dowloaded items successfully within blockchain! "
        this.folderCreating = false;
        this.foldercreated = true;
        setTimeout(function () {
          this.folderCreating = false;
          this.foldercreated = false;
          this.generalprogressbar = false;
          this.errormsg = false;
          this.showper = false;
          this.n = 0;

        }.bind(this), 3000);
        this.selectedSelected = [];
        this.GetAllFolders1();
        this.downpath = data;
        this.seleteddownload = true;
        window.open(this.downpath);
        //this.spinner.hide();
      }
      else {
        this.notify = "Something went wrong. Try again.!!"
        setTimeout(() => this.dismissalert = true, 100);
        setTimeout(function () {
          this.dismissalert = false;
        }.bind(this), 3000);
        this.selectedSelected = [];
        this.GetAllFolders1();
        this.spinner.hide();
      }
    });

  }
  getBrowserName() {
    const agent = window.navigator.userAgent.toLowerCase();
    switch (true) {
      case agent.indexOf("edge") > -1:
        return "Edge";
      case agent.indexOf("opr") > -1 && !!(<any>window).opr:
        return "Opera";
      case agent.indexOf("chrome") > -1 && !!(<any>window).chrome:
        return "Chrome";
      case agent.indexOf("trident") > -1:
        return "ie";
      case agent.indexOf("firefox") > -1:
        return "Firefox";
      case agent.indexOf("safari") > -1:
        return "Safari";
      default:
        return "Other";
    }
  }
  Validateusername() {
    this.validateusername = this.client;
    this.spinner.show();
    const data: EmailuserValidation = {
      Email: this.validateusername,
     EnterpriseId:null,
      UserId:this.uid,
      UserFullName :this.userfullname 

    }
    this.enterpriseservice.validateusernameclient(data).subscribe((Data: any) => {
      this.usernameresult = Data;
      if (this.usernameresult == "exist") {
        localStorage.setItem("validateval", "false")
        this.validusername = true;
      }
      else {
        localStorage.setItem("validateval", "true")
        this.validusername = false;
        this.email=this.client + "@"+ "blocdrive.com"
      }
      this.spinner.hide();

    })
    //this.SaveUser();
  }

  Validatemail() {
    this.validateemail = this.email;
    this.spinner.show();
    const data: EmailValidation = {
      Email: this.validateemail
    }
    this.enterpriseservice.validateemail(data).subscribe((Data: any) => {
      this.mailresult = Data;
      if (this.mailresult == "exist") {
        this.validmail = true;
      }
      else {
        this.validmail = false;
      }
      this.spinner.hide();
    })
  }
  
  SaveUser() {
    if(this.client==null || this.client==undefined){
      alert("Please enter valid User Name")
    }
    else if(this.validmail == true){
      alert("Please enter valid User Email")
    }
    else if(this.validusername == true){
      alert("Please enter valid User Name")
    }
  

    else  {

      const inputRequest = {
        Id: 0,
        UserId: 0,
        Password: null,
        UserName: this.client,
        Email: this.email,
        Role: null,
        Designation: null,
        Department: null,
        Location: null,
        CreatedOn: "",
        CurrentUserId: this.GUID,
        UserRights:null,
        SubDepartmentId:null
      }
      this.spinner.show();
      this.userservice.saveUserClient(inputRequest).subscribe(
        (data: any) => {
          if (data == "success") {
            this.notify = "User added successfully";
            this.selectedrole = null;
            this.selecteddesignation = null
            this.selecteddepartment = null;
            this.selectedItems=[]
            this.client ="";
            this.email="";
            this.location=""
            setTimeout(() => this.dismissalert = true, 100);
            setTimeout(function () {
              this.dismissalert = false;
            }.bind(this), 3000);
            this.spinner.hide();
            this.Users();
          } else {
            this.notify = "Something went wrong. Try Again.!!"
            this.selectedrole = null;
            this.selecteddesignation = null
            this.selecteddepartment = null;
            this.selectedItems=[]
            this.client="";
            this.email="";
            this.location=""
            setTimeout(() => this.dismissalert = true, 100);
            setTimeout(function () {
              this.dismissalert = false;
            }.bind(this), 3000);
            this.spinner.hide();
            this.Users();
          }
        });
    }
  }
  Users() {
    this.spinner.show();
    this.userservice.getAllUsers(this.GUID).subscribe((Data: any) => {
      this.UserList = Data;
      this.spinner.hide();
    });
  }
  onItemSelect(item: any) {
    var aa = this.selectedItems;
    this.selectedItems= Array.from(new Set(this.selectedItems));

  }
  onSelectAll(items: any) {
    var aa = this.selectedItems;
    this.selectedItems= Array.from(new Set(this.selectedItems));

  }
  onItemDeSelectAll(items: any) {
    var aa = this.selectedItems;
    this.selectedItems= [];

  }
  onItemDeSelect(items) {
    var aa = this.selectedItems;
    this.selectedItems= Array.from(new Set(this.selectedItems));

  }
}