<style>
  .container {
    position: relative;
    width: 50%;
  }

  .image {
    opacity: 1;
    display: block;
    width: 100%;
    height: auto;
    transition: 0.5s ease;
    backface-visibility: hidden;
  }

  .middle {
    transition: 0.5s ease;
    opacity: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    text-align: center;
  }

  .container:hover .image {
    opacity: 0.3;
  }

  .container:hover .middle {
    opacity: 1;
  }

  .text {
    background-color: mediumslateblue;
    color: white;
    font-size: 16px;
    padding: 16px 32px;
  }
</style>
<app-nav-bar></app-nav-bar>
<div class="col-md-12">&nbsp;</div>

<link href="//maxcdn.bootstrapcdn.com/bootstrap/4.1.1/css/bootstrap.min.css" rel="stylesheet" id="bootstrap-css" />
<script src="//maxcdn.bootstrapcdn.com/bootstrap/4.1.1/js/bootstrap.min.js"></script>
<script src="//cdnjs.cloudflare.com/ajax/libs/jquery/3.2.1/jquery.min.js"></script>
<!------ Include the above in your HEAD tag ---------->

<!DOCTYPE html>
<html>

<head>
  <meta name="description" content="This is blocdrive chat using blockchain technology.">
  <title>Chat</title>
  <link rel="stylesheet" href="https://stackpath.bootstrapcdn.com/bootstrap/4.1.3/css/bootstrap.min.css"
    integrity="sha384-MCw98/SFnGE8fJT3GXwEOngsV7Zt27NXFoaoApmYm81iuXoPkFOJwJ8ERdknLPMO" crossorigin="anonymous" />
  <link rel="stylesheet" href="https://use.fontawesome.com/releases/v5.5.0/css/all.css"
    integrity="sha384-B4dIYHKNBt8Bc12p+WXckhzcICo0wtJAoU8YZTY5qE0Id1GSseTk6S+L3BlXeVIU" crossorigin="anonymous" />
  <script src="https://ajax.googleapis.com/ajax/libs/jquery/3.3.1/jquery.min.js"></script>
  <link rel="stylesheet" type="text/css"
    href="https://cdnjs.cloudflare.com/ajax/libs/malihu-custom-scrollbar-plugin/3.1.5/jquery.mCustomScrollbar.min.css" />
  <script type="text/javascript"
    src="https://cdnjs.cloudflare.com/ajax/libs/malihu-custom-scrollbar-plugin/3.1.5/jquery.mCustomScrollbar.min.js"></script>
</head>
<!--Coded With Love By Mutiullah Samim-->

<body>
  <div class="container-fluid h-100">
    <div class="row justify-content-center h-100">
      <div class="col-md-4 col-xl-3 chat">
        <div class="card mb-sm-3 mb-md-0 contacts_card">
          <div class="card-header">
            <div class="input-group">
              <div class="input-group-prepend">
                <span style="color: white; font-size: 28px">Received Messages</span>
              </div>
            </div>
            <div class="col-md-12">&nbsp;</div>
            <div class="col-md-12">&nbsp;</div>
            <div class="panel-body" style="overflow-y: auto;max-height: 300px;" >
              <div class="chat" *ngFor="let chat of chatdetail">
                <div class="left clearfix" (click)="OpenChatWindow(chat)" style="cursor: pointer">
                  <span class="chat-img pull-left" style="color: white">
                    <!-- <img src="./assets/img/chat1.png" style="width: 20px;height: 20px;" class="img-circle"
                                    /> -->
                    <i class="fa fa-comments" aria-hidden="true"></i>
                  </span>
                  <div class="chat-body clearfix" style="color: white">
                    <div>
                      <strong class="primary-font" *ngIf="chat.toName != currentuser">
                        {{ chat.toName }}</strong>
                      <strong class="primary-font" *ngIf="chat.chatFromName != currentuser">
                        {{ chat.chatFromName }}</strong>

                      <small class="pull-right text-muted">
                        <i class="fa fa-clock-o fa-fw"></i>
                        {{ chat.date | date: "short" }}
                        <!-- <div class="container" (click)="OpenChatWindow(chat)">
                                            <img src="./assets/img/msg.png" style="width:30px;height: 30px;" class="image" alt="Los Angeles"
                                               >
                                            <div class="middle" style="font-size: 15x;color:darkmediumslateblue;margin-left: 18px;">
                                                Click here to Chat</div>
                                        </div> -->

                                        &nbsp; &nbsp;  <i class="fa fa-trash-o fa-lg" (click)="Deletechat(chat, delchat)"></i>   &nbsp; &nbsp;
                  
                      </small>
                    </div>
                    <p>
                      {{ chat.chat }}
                    </p>
                    <p>
                      {{ message }}
                    </p>
                  </div>
                </div>
                <hr />
              </div>
            </div>
          </div>

          <div class="card-footer"></div>
        </div>
      </div>
      <div class="col-md-8 col-xl-6 chat">
        <div class="card">
          <div class="card-header msg_head" style="background: black">
            <div class="d-flex bd-highlight">
              <div class="img_cont"></div>
              <!-- <div class="user_info">
                                  <span></span>
                                  <p></p>
                              </div>
                              <div class="video_cam">
                                  <span><i class="fas fa-video"></i></span>
                                  <span><i class="fas fa-phone"></i></span>
                              </div> -->
            </div>
            <!--...... <span id="action_menu_btn"><button class="btn btn-primary"
               style="margin-left: 600px;margin-top: -150px;" >Add</button></span> ......-->
                 <!-- <span id="action_menu_btn"><button class="btn btn-primary">Add</button></span> -->

            <div id="action_menu_btn" >
              <button class="btn btn-primary addbutttonmedia" style="margin-left: 600px;margin-top: -157px;" >Add</button>
            </div>
            
            <div class="action_menu" style="width: 32%; margin: inherit">
              <ul>
                <!-- <button type="button" class="button button5" (click)="addFriend(friendcontent)" style="margin: inherit"
                  id="menuadd">
                  Add Friend
                </button>
                <button type="button" class="button button5" (click)="addFriend(content)" style="margin: inherit">
                  Sent Request
                </button>
                <button type="button" class="button button5" (click)="addFriend(content1)" style="margin: inherit">
                  Pending Request
                </button> -->
                <button type="button" class="button button5" (click)="addFriend(content3)" style="margin: inherit">
                  New Chat
                </button>
                <button type="button" class="button button5" (click)="backup()" style="margin: inherit">
                  Chat Backup
                </button>
              </ul>
            </div>
          </div>
          <div class="col-md-12">
            <div class="panel-heading" style="color: white; margin-top: -11%; font-size: 20px">
              <i class="fa fa-comments fa-fw"></i>Chat Window 
            </div>
            <div class="chat-panel panel panel-default" *ngIf="chatopen">
              <div class="col-md-12" style="
                      padding-bottom: 10px;
                      /* background-color: mediumslateblue; */
                      color: white;
                      padding-top: 10px;
                      font-family: bold;
                    ">
                {{ displyfromuser }}
              </div>
              <div class="panel-body" style="
                      background-image: url(./assets/img/wwp.jpg);
                      overflow-y: auto;
                      max-height: 300px;
                    ">
                <div class="col-md-12">
                  <div class="col-md-12" *ngFor="let c of IndividualChatSentDetails">
                    <div class="col-md-12" *ngIf="c.chatFrom != GUID">
                      <div class="chat">
                        <br /><br />
                        <div>
                          <div class="left clearfix">
                            <!-- <span class="chat-img pull-left">
                                                    <img src="./assets/img/download.png" class="img-circle"
                                                        style="height: 50px;width: 50px;" />
                                                </span> -->
                            <div class="chat-body clearfix">
                              <!-- <div >
                                                          <strong class="primary-font">{{c.chatFromName}}</strong>
                                        <small class="pull-right text-muted">
                                                            <i class="fa fa-clock-o fa-fw"></i> {{c.date | date:'short'}}
              
                                                        </small>
                                    </div> -->
                              <span style="
                                      background-color: orange;
                                      color: black;
                                      line-height: 2.4rem;
                                      padding: 8px 8px;
                                      border-radius: 20px;
                                    ">
                                {{ c.chat }}
                              </span>
                              <p>
                                {{ message }}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="col-md-12" style="float: right" *ngIf="c.chatFrom == GUID">
                      <div>
                        <br /><br />
                        <div style="float: right">
                          <div style="float: right">
                            <div>
                              <!-- <div >
                                                          <strong class="primary-font"  style="float: right;">Me</strong>
                                                          <small class="pull-right text-muted"  style="float: right;">
                                                            <i class="fa fa-clock-o fa-fw"></i> {{c.date | date:'short'}}
                                                        </small>
                                                      </div> -->
                              <span style="
                                      background-color: mediumspringgreen;
                                      color: black;
                                      line-height: 2.4rem;
                                      padding: 8px 8px;
                                      border-radius: 20px;
                                    ">
                                {{ c.chat }}
                              </span>
                              <p>
                                {{ message }}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-12">&nbsp;</div>
              <div class="col-md-12">&nbsp;</div>
              <form #individualchatform="ngForm" (ngSubmit)="SendIndividualMessage(individualchatform)">
                <div class="panel-footer">
                  <div class="input-group" style="width: 90%">
                    <input type="text" id="individualchatmessage" name="individualchatmessage" class="form-control"
                      placeholder="Type your message here..." ngModel />
                    <span class="input-group-btn">
                      <button class="btn btn-warning btn-sm" id="btn-chat" style="height: 100%; width: 184%" >
                        Send
                      </button>
                    </span>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</body>

</html>

<div class="col-md-12 row">
  <!-- <div class="col-md-3 " id="nb" style=" width: 150px;
  max-height: 300px;
  overflow: auto;
  " (click)="scrollFunction">
  
          <div class="col-md-12">
              <div class="chat-panel panel panel-default">
  
  
                  <div class="panel-body">
                      <div class="chat" *ngFor="let chat of chatdetail">
                          <div class="left clearfix" (click)="OpenChatWindow(chat)" style="cursor:pointer;">
                              <span class="chat-img pull-left">
                         
                                <i class="fa fa-comments" aria-hidden="true"></i>
                        </span>
                              <div class="chat-body clearfix">
                                  <div>
                                      <strong class="primary-font" *ngIf="chat.toName != currentuser">  {{chat.toName}}</strong>
                                      <strong class="primary-font" *ngIf="chat.chatFromName != currentuser">  {{chat.chatFromName}}</strong>
  
                                      <small class="pull-right text-muted">
                                    <i class="fa fa-clock-o fa-fw"></i> {{chat.date | date:'short'}}
                                 
                                </small>
                                  </div>
                                  <p>
                                      {{chat.chat}}
                                  </p>
                                  <p>
                                      {{message}}
                                  </p>
                              </div>
                          </div>
                          <hr>
                      </div>
                  </div>
  
              </div>
          </div>
  
      </div> -->

  <!-- <div class="col-md-6">
          <div class="chat-panel panel panel-default" *ngIf="chatopen">
              <div class="panel-heading">
                  <i class="fa fa-comments fa-fw"></i> Individual Chat Window
                 
              </div>
  
              <div class="col-md-12" style="padding-bottom: 10px;
              background-color: mediumslateblue;
              color: white;
              padding-top: 10px;
              font-family: bold;">
                  {{chtnm}}
              </div>
              <div class="panel-body" style="background-image: url(./assets/img/wwp.jpg); overflow-y: auto;max-height: 300px;">
  
  
                  <div class="col-md-12">
  
                   
  
  
                      <div class="col-md-12" *ngFor="let c of IndividualChatSentDetails">
                          <div class="col-md-12" *ngIf="c.chatFrom != userid">
                              <div class="chat">
                                  <br /><br />
                                  <div>
                                      <div class="left clearfix">
                                      
                                          <div class="chat-body clearfix">
                                           
                                              <span style="background-color:  orange;color:black;padding: 8px 8px;border-radius: 20px;">
                                              {{c.chat}}
                                          </span>
                                              <p>
                                                  {{message}}
                                              </p>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                          </div>
  
                          <div class="col-md-12" style="float: right;" *ngIf="c.chatFrom == userid">
                              <div>
                                  <br /><br />
                                  <div style="float: right;">
                                      <div style="float: right;">
  
                                          <div>
                                            
                                              <span style="background-color: mediumspringgreen;color:black;padding: 8px 8px;border-radius: 20px;">
                                              {{c.chat}}
                                          </span>
                                              <p>
                                                  {{message}}
                                              </p>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                          </div>
  
  
  
  
                      </div>
                  </div>
  
  
  
              </div>
              <form #individualchatform="ngForm" (ngSubmit)="SendIndividualMessage(individualchatform)">
                  <div class="panel-footer">
                      <div class="input-group">
                          <input type="text" id="individualchatmessage" name="individualchatmessage" class="form-control" placeholder="Type your message here..." ngModel />
                          <span class="input-group-btn">
                        <button class="btn btn-warning btn-sm" id="btn-chat">
                            Send
                        </button>
                    </span>
                      </div>
                  </div>
              </form>
          </div>
  
      </div> -->

  <style>
    .button5 {
      border-radius: 12px;
    }

    .button {
      background-color: black;
      /* Green */
      border: none;
      width: 100%;
      color: white;
      padding: 8px;
      text-align: center;
      text-decoration: none;
      display: inline-block;
      font-size: 14px;
      margin: 4px 2px;
      cursor: pointer;
    }
  </style>

  <ng-template #friendcontent let-d="dismiss" let-c="dismiss">
    <div
     class="modal-header">
      <h4 class="modal-title" id="modal-basic-title" style="color: mediumslateblue">
        Add Friend
      </h4>
      <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <h5 class="modal-title" id="modal-basic-title" style="color: mediumslateblue">
        Email:
      </h5>
      <input type="text" id="accid" [(ngModel)]="accid" class="form-control" name="dp" />
    </div>
    <br />
    <div class="modal-footer">
      <button type="button" class="btn btn-outline-dark" (click)="savefrienddetails()" (click)="d('Cross click')"
        style="color: black; background-color: grey; color: white">
        Send Request
      </button>
    </div>
    <br />
  </ng-template>

  <ng-template #pdfcontent8 let-d="dismiss" let-c="dismiss">
    <div class="modal-header">
      <h4 class="modal-title" id="modal-basic-title">Recieved Request</h4>
      <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <div>
        <!-- <h2 style="font-family:'Times New Roman', Times, serif;text-align: center;">Recieved Request </h2> -->
        <div>
          <div style="border: solid">
            <div>
              <span style="
                    font-family: 'Times New Roman', Times, serif;
                    text-align: center;
                    font-size: 20x;
                  ">Friends</span>
            </div>
            <div *ngFor="let f of Friends.incomingRequest">
              <span>{{ f.friendName }}</span>
              <button type="button" (click)="acceptrequest(f)" (click)="d('Cross click')">
                <span>Accept</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <br />
    <div class="modal-footer">
      <!-- <button type="button" class="btn btn-outline-dark" (click)="savefrienddetails()" (click)="d('Cross click')" style="color:black;">Send Request</button> -->
    </div>
    <br />
  </ng-template>

  <ng-template #pdfcontent7 let-d="dismiss" let-c="dismiss">
    <div class="modal-header">
      <h4 class="modal-title" id="modal-basic-title">Sent Request</h4>
      <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <!-- <h2 style="font-family:'Times New Roman', Times, serif;text-align: center;">Sent Request</h2> -->

      <div style="
            font-family: 'Times New Roman', Times, serif;
            text-align: center;
            background-color: #6058e2;
          ">
        Friends
      </div>
    </div>
    <br />
    <div class="modal-footer">
      <!-- <button type="button" class="btn btn-outline-dark" (click)="savefrienddetails()" (click)="d('Cross click')" style="color:black;">Send Request</button> -->
    </div>
    <br />
  </ng-template>

  <div class="modal fade" id="feedbackModal">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <button type="button" class="close" data-dismiss="modal" title="Close">
            <span class="glyphicon glyphicon-remove"></span>
          </button>
        </div>
        <div class="modal-body">
          <div class="row text-center">
            <h4>Details</h4>
          </div>

          <div id="myGallery" class="carousel slide" data-interval="false">
            <div class="carousel-inner">
              <div class="item active" *ngFor="let modalData of cnnTableData">
                <div class="row">
                  <div class="col-sm-6">
                    <br />
                    <img src="{{ modalData.Image }}" style="width: 100%; min-height: 211px" />
                  </div>
                  <div class="col-sm-6">
                    <div>
                      <h6><b>Image Name</b></h6>
                      <small>{{ modalData.Name }}</small>
                    </div>
                    <br />
                  </div>
                </div>
              </div>
            </div>
            <a class="left carousel-control" href="#myGallery" role="button" data-slide="prev"
              style="margin-left: -84px">
              <span class="glyphicon glyphicon-chevron-left"></span></a>
            <a class="right carousel-control" href="#myGallery" role="button" data-slide="next"
              style="margin-right: -84px">
              <span class="glyphicon glyphicon-chevron-right"></span></a>
          </div>
        </div>
        <div class="modal-footer"><br /><br /></div>
      </div>
    </div>
  </div>

  <ng-template #content let-modal let-d="dismiss" let-c="dismiss">
    <div class="modal-header">
      <h2 style="
            font-family: 'Times New Roman', Times, serif;
            text-align: center;
            color: mediumslateblue;
          ">
        Sent Request
      </h2>
      <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <div>
        <div>
          <table class="table table-bordered" style="border: solid">
            <tr>
              <th style="
                    font-family: 'Times New Roman', Times, serif;
                    text-align: center;
                    background-color: mediumslateblue;
                    color: white;
                  ">
                Friends
              </th>
            </tr>
            <div *ngIf="Friends">
              <tr *ngFor="let f of Friends.sentRequest">
                <td style="border: solid; text-align: center">
                  <b style="color: mediumslateblue; font-size: 16px">{{
                    f.friendName
                    }}</b>
                </td>
                <!-- <button type="button"
                              class="btn btn-default btn-xs"
                              ng-click="deleteCompany(comp)">
                          <span class="glyphicon glyphicon-trash text-danger">Delete</span>
                      </button> -->
              </tr>
            </div>
          </table>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-outline-dark" (click)="d('Cross click')" style="background-color: darkgray">
        Close
      </button>
    </div>
  </ng-template>

  <!-- <button class="btn btn-lg btn-outline-primary" (click)="addFriend(content)">Launch demo modal</button> -->

  <hr />

  <ng-template #content1 let-modal let-d="dismiss" let-c="dismiss">
    <div class="modal-header">
      <h2 style="
            font-family: 'Times New Roman', Times, serif;
            text-align: center;
            color: mediumslateblue;
          ">
        Pending Requests
      </h2>
      <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <div>
        <div class="table-responsive">
          <table class="table table-bordered" style="border: solid">
            <tr>
              <th style="
                    font-family: 'Times New Roman', Times, serif;
                    text-align: center;
                    font-size: 20x;
                    background-color: mediumslateblue;
                    color: white;
                  ">
                Friends
              </th>
              <th></th>
            </tr>
            <div *ngIf="Friends">
              <tr *ngFor="let f of Friends.incomingRequest">
                <td style="border: solid; padding-top: 13%">
                  <b style="color: mediumslateblue; font-size: 16px">{{
                    f.friendName
                    }}</b>
                </td>
                <td *ngIf="f.status == '4'" style="border: solid">
                  <button type="button" class="btn btn-default btn-xs" (click)="acceptrequest(f)">
                    <span class="glyphicon glyphicon-edit text-success">Accept</span>
                  </button>
                </td>
              </tr>
            </div>
          </table>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-outline-dark" (click)="d('Cross click')" style="background-color: dimgray">
        Close
      </button>
    </div>
  </ng-template>

  <ng-template #content3 let-modal let-d="dismiss" let-c="dismiss">
    <div class="modal-header">
      <h2 style="
            font-family: 'Times New Roman', Times, serif;
            text-align: center;
            color: mediumslateblue;
          ">
        Continue Chatting here!
      </h2>
      <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <form>
        <div class="col-md-12">
          <div class="col-md-12">
            <div class="form-horizontal">
              <div class="">
                <h2 style="text-align: center"></h2>
              </div>
              <div class="col-md-12" style="
                    margin-bottom: 50px;
                    max-width: 100%;
                    background-color: white;
                  ">
                <div class="form-group">
                  <label class="control-label">Chat With:</label>
                  <select style="height: calc(3.25rem + 4px)" class="form-control dropdownsize"
                    (change)="OnSelectUser($event.target.value)">
                    <option value="" hidden>Select User</option>
                    <option *ngFor="let I of Users" value="{{ I.friendGUID }}">
                      {{ I.friendName }}
                    </option>
                  </select>

                  <br />
                  <br />
<!-- 
                  <div class="col-lg-12 text-center">
                    <span style="font-size: 12px">Don't find your friend in the list? &nbsp;</span>
                    <button type="button" class="btn btn-primary" (click)="addfrnd()"
                      style="height: 40px; padding: 5px">
                      Add Friend
                    </button>
                  </div>
                  <div *ngIf="frndrqst">
                    <div>
                     
                      
                      <br />
                      <input type="text" placeholder="Email" id="accid" [(ngModel)]="accid" class="form-control"
                        name="dp" />
                    </div>
                    <br />
                    <div>
                      <button type="button" class="btn btn-outline-dark btn-primary" (click)="savefrienddetails()"
                        style="
                            color: black;
                            color: white;
                            padding: 5px;
                            margin-left: 10px;
                          ">
                        Send Request
                      </button>
                    </div>
                    <br /><br />
                  </div> -->
                  <label class="control-label">Chat Message:</label>
                  <input type="text" id="messa" name="messa" class="form-control" placeholder="Enter the message"
                    [(ngModel)]="messa" />
                </div>
                <div class="form-group">
                  <input type="Submit" class="btn btn-primary btn-round" style="background-color: grey; color: white"
                    (click)="SaveUserChat()" (click)="d('Cross click')" value="Send" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  </ng-template>

  <style>
    .container {
      position: relative;
      width: 50%;
    }

    .image {
      opacity: 1;
      display: block;
      width: 100%;
      height: auto;
      transition: 0.5s ease;
      backface-visibility: hidden;
    }

    .middle {
      transition: 0.5s ease;
      opacity: 0;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
      text-align: center;
    }

    .container:hover .image {
      opacity: 0.3;
    }

    .container:hover .middle {
      opacity: 1;
    }

    .text {
      background-color: mediumslateblue;
      color: white;
      font-size: 16px;
      padding: 16px 32px;
    }
  </style>

  <ng-template #friendcontent let-d="dismiss" let-c="dismiss">
    <div class="modal-header">
      <h4 class="modal-title" id="modal-basic-title" style="color: mediumslateblue">
        Add Friend
      </h4>
      <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <h5 class="modal-title" id="modal-basic-title" style="color: mediumslateblue">
        Email:
      </h5>
      <input type="text" id="accid" [(ngModel)]="accid" class="form-control" name="dp" />
    </div>
    <br />
    <div class="modal-footer">
      <button type="button" class="btn btn-outline-dark" (click)="savefrienddetails()" (click)="d('Cross click')"
        style="color: black; background-color: grey; color: white">
        Send Request
      </button>
    </div>
    <br />
  </ng-template>

  <ng-template #pdfcontent8 let-d="dismiss" let-c="dismiss">
    <div class="modal-header">
      <h4 class="modal-title" id="modal-basic-title">Recieved Request</h4>
      <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <div>
        <!-- <h2 style="font-family:'Times New Roman', Times, serif;text-align: center;">Recieved Request </h2> -->
        <div>
          <div style="border: solid">
            <div>
              <span style="
                    font-family: 'Times New Roman', Times, serif;
                    text-align: center;
                    font-size: 20x;
                  ">Friends</span>
            </div>
            <div *ngFor="let f of Friends.incomingRequest">
              <span>{{ f.friendName }}</span>
              <button type="button" (click)="acceptrequest(f)" (click)="d('Cross click')">
                <span>Accept</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <br />
    <div class="modal-footer">
      <!-- <button type="button" class="btn btn-outline-dark" (click)="savefrienddetails()" (click)="d('Cross click')" style="color:black;">Send Request</button> -->
    </div>
    <br />
  </ng-template>

  <ng-template #pdfcontent7 let-d="dismiss" let-c="dismiss">
    <div class="modal-header">
      <h4 class="modal-title" id="modal-basic-title">Sent Request</h4>
      <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <!-- <h2 style="font-family:'Times New Roman', Times, serif;text-align: center;">Sent Request</h2> -->

      <div style="
            font-family: 'Times New Roman', Times, serif;
            text-align: center;
            background-color: #6058e2;
          ">
        Friends
      </div>
    </div>
    <br />
    <div class="modal-footer">
      <!-- <button type="button" class="btn btn-outline-dark" (click)="savefrienddetails()" (click)="d('Cross click')" style="color:black;">Send Request</button> -->
    </div>
    <br />
  </ng-template>

  <div class="modal fade" id="feedbackModal">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <button type="button" class="close" data-dismiss="modal" title="Close">
            <span class="glyphicon glyphicon-remove"></span>
          </button>
        </div>
        <div class="modal-body">
          <div class="row text-center">
            <h4>Details</h4>
          </div>

          <div id="myGallery" class="carousel slide" data-interval="false">
            <div class="carousel-inner">
              <div class="item active" *ngFor="let modalData of cnnTableData">
                <div class="row">
                  <div class="col-sm-6">
                    <br />
                    <img src="{{ modalData.Image }}" style="width: 100%; min-height: 211px" />
                  </div>
                  <div class="col-sm-6">
                    <div>
                      <h6><b>Image Name</b></h6>
                      <small>{{ modalData.Name }}</small>
                    </div>
                    <br />
                  </div>
                </div>
              </div>
            </div>
            <a class="left carousel-control" href="#myGallery" role="button" data-slide="prev"
              style="margin-left: -84px">
              <span class="glyphicon glyphicon-chevron-left"></span></a>
            <a class="right carousel-control" href="#myGallery" role="button" data-slide="next"
              style="margin-right: -84px">
              <span class="glyphicon glyphicon-chevron-right"></span></a>
          </div>
        </div>
        <div class="modal-footer"><br /><br /></div>
      </div>
    </div>
  </div>

  <ng-template #content let-modal let-d="dismiss" let-c="dismiss">
    <div class="modal-header">
      <h2 style="
            font-family: 'Times New Roman', Times, serif;
            text-align: center;
            color: mediumslateblue;
          ">
        Sent Request
      </h2>
      <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <div>
        <div>
          <table class="table table-bordered" style="border: solid">
            <tr>
              <th style="
                    font-family: 'Times New Roman', Times, serif;
                    text-align: center;
                    background-color: mediumslateblue;
                    color: white;
                  ">
                Friends
              </th>
            </tr>
            <div *ngIf="Friends">
              <tr *ngFor="let f of Friends.sentRequest">
                <td style="border: solid; text-align: center">
                  <b style="color: mediumslateblue; font-size: 16px">{{
                    f.friendName
                    }}</b>
                </td>
                <!-- <button type="button"
                              class="btn btn-default btn-xs"
                              ng-click="deleteCompany(comp)">
                          <span class="glyphicon glyphicon-trash text-danger">Delete</span>
                      </button> -->
              </tr>
            </div>
          </table>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-outline-dark" (click)="d('Cross click')" style="background-color: darkgray">
        Close
      </button>
    </div>
  </ng-template>

  <!-- <button class="btn btn-lg btn-outline-primary" (click)="addFriend(content)">Launch demo modal</button> -->

  <hr />

  <ng-template #content1 let-modal let-d="dismiss" let-c="dismiss">
    <div class="modal-header">
      <h2 style="
            font-family: 'Times New Roman', Times, serif;
            text-align: center;
            color: mediumslateblue;
          ">
        Pending Requests
      </h2>
      <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <div>
        <div class="table-responsive">
          <table class="table table-bordered" style="border: solid">
            <tr>
              <th style="
                    font-family: 'Times New Roman', Times, serif;
                    text-align: center;
                    font-size: 20x;
                    background-color: mediumslateblue;
                    color: white;
                  ">
                Friends
              </th>
              <th></th>
            </tr>
            <div *ngIf="Friends">
              <tr *ngFor="let f of Friends.incomingRequest">
                <td style="border: solid; padding-top: 13%">
                  <b style="color: mediumslateblue; font-size: 16px">{{
                    f.friendName
                    }}</b>
                </td>
                <td *ngIf="f.status == '4'" style="border: solid">
                  <button type="button" class="btn btn-default btn-xs" (click)="acceptrequest(f)">
                    <span class="glyphicon glyphicon-edit text-success">Accept</span>
                  </button>
                </td>
              </tr>
            </div>
          </table>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-outline-dark" (click)="d('Cross click')" style="background-color: dimgray">
        Close
      </button>
    </div>
  </ng-template>

  <ng-template #content3 let-modal let-d="dismiss" let-c="dismiss">
    <div class="modal-header">
      <h2 style="
            font-family: 'Times New Roman', Times, serif;
            text-align: center;
            color: mediumslateblue;
          ">
        Continue Chat here
      </h2>
      <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <form>
        <div class="col-md-12">
          <div class="col-md-12">
            <div class="form-horizontal">
              <div class="">
                <h2 style="text-align: center"></h2>
              </div>
              <div class="col-md-12" style="
                    margin-bottom: 50px;
                    max-width: 100%;
                    background-color: white;
                  ">
                <div class="form-group">
                  <label class="control-label">chat to:</label>
                  <select style="height: calc(3.25rem + 4px)" class="form-control dropdownsize"
                    (change)="OnSelectUser($event.target.value)">
                    <option value="" hidden>Select User</option>
                    <option *ngFor="let I of Users" value="{{ I.friendId }}">
                      {{ I.friendName }}
                    </option>
                  </select>

                  <br />
                  <br />

                  <!-- <div class="col-lg-12 text-center">
                    <span style="font-size: 12px">Don't find your friend in the list? &nbsp;</span>
                    <button type="button" class="btn btn-primary" (click)="addfrnd()"
                      style="height: 40px; padding: 5px">
                      Add Friend
                    </button>
                  </div>
                  <div *ngIf="frndrqst">
                    <div>
                    
                      <br />
                      <input type="text" placeholder="Email" id="accid" [(ngModel)]="accid" class="form-control"
                        name="dp" />
                    </div>
                    <br />
                    <div>
                      <button type="button" class="btn btn-outline-dark btn-primary" (click)="savefrienddetails()"
                        style="
                            color: black;
                            color: white;
                            padding: 5px;
                            margin-left: 10px;
                          ">
                        Send Request
                      </button>
                    </div>
                    <br /><br />
                  </div> -->
                  <label class="control-label">chat message:</label>
                  <input type="text" id="messa" name="messa" class="form-control" placeholder="Enter the message"
                    [(ngModel)]="messa" />
                </div>
                <div class="form-group">
                  <input type="Submit" class="btn btn-primary btn-round" style="background-color: grey; color: white"
                    (click)="SaveUserChat()" (click)="d('Cross click')" value="Send" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  </ng-template>
</div>

<ng-template #delchat let-d="dismiss" let-c="dismiss">
  <div class="modal-header">
      <h4 class="modal-title" id="modal-basic-title">Delete chat</h4>
      <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
    <span aria-hidden="true">&times;</span>
  </button>
  </div>
  <div class="modal-body">
      <h4>Are you sure you want to delete {{chatfromname}}'s conversation? &nbsp;</h4>
  </div>
  <div class="modal-footer">
      <button type="button" class="btn btn-success" (click)="Deletegrouprow()" (click)="d('Cross click')" style="cursor: pointer">
    Ok
  </button>
      <button type="button" class="btn btn-danger" (click)="d('Cross click')" style="cursor: pointer">
    Cancel
  </button>
  </div>
</ng-template>