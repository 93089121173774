import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MessageService1 } from 'src/app/Shared/MessageService/message.service';

@Component({
  selector: 'app-ext-read',
  templateUrl: './ext-read.component.html',
  styleUrls: ['./ext-read.component.css']
})
export class ExtReadComponent implements OnInit {
  fromuser: string;
  touser: string;
  attachments: string;
  transid: string;
  subject: string;
  replaybutto: string;
  emailbody: string;
  firstletter: string;
  docname: string;
  maildate: string;
  mailid: string;
  maillist: any;
  mailthredcount: string;
  uid: string;
  usermail: string;
  DocumnetDetails: any;
  attac: boolean;
  replayenable: boolean;
  replytouser: string;
  selectedFriend: any[]=[];
  frmDataa: FormData;
  message: string | Blob;
  SelectedFiles: any[]=[];

  constructor(private mailboxservice:MessageService1, private route:Router, private httpService:HttpClient) {
    if (localStorage.getItem("IsLoggedIn") == "true") {
      this.fromuser = localStorage.getItem("fromuser");
      this.touser = "To:" + " " + localStorage.getItem("touser");
      this.attachments = localStorage.getItem("attachments");
      this.mailid = localStorage.getItem("mailid");
      this.transid = localStorage.getItem("transid");
      this.maildate = localStorage.getItem("maildate");
      this.firstletter = localStorage.getItem("firstletter");
      this.docname = localStorage.getItem("docname");
      this.emailbody = localStorage.getItem("emailbody");
      this.subject = localStorage.getItem("sub");
      this.replaybutto = localStorage.getItem("rep");
      localStorage.setItem('subject', this.subject);

      this.uid = localStorage.getItem("userId");
      this.usermail = localStorage.getItem("MailId");
      if(this.docname ==null)
      {
        this.attac = false;
      }
    }
   }

  ngOnInit(): void {
    this.GetMaildata();
  }

  GetMaildata() {
    var info = {
      UID: this.uid,
      Mailid: this.mailid,
      Email: this.usermail,
    }
    this.mailboxservice.getallmails(info).subscribe((respose: any) => {

      this.maillist = respose;
      console.log(this.maillist);
      if(this.maillist[0].ThredCount > 1){
        this.mailthredcount = this.maillist[0].ThredCount +" Messages";
      }
      else {
        this.mailthredcount = "1 Message";
      }
      console.log(this.maillist);
    });
  }

  GetDocumnetDetails(data: any) {
    this.mailboxservice.getDocumentdetailsformessages(data).subscribe((respose: any) => {
      this.DocumnetDetails = respose;
      console.log(this.DocumnetDetails);
      // if (this.DocumnetDetails != null) {
      //   localStorage.setItem('Fileview', this.DocumnetDetails.SignFile);
      //   localStorage.setItem('FileNameView', this.DocumnetDetails.DocumentName);
      //   localStorage.setItem('BlockId', this.DocumnetDetails.BlockId);
      //   localStorage.setItem('EmailView', this.DocumnetDetails.Email);
      //   localStorage.setItem('Pdffile', this.DocumnetDetails.PdfFile);
      //   localStorage.setItem('Fid', this.DocumnetDetails.FolderId);
      //   localStorage.setItem('DocType', this.DocumnetDetails.DocumentType);
      //   localStorage.setItem('UploadedDate', this.DocumnetDetails.date);
      //  // this.route.navigate(['documentViewer/']);
      // }
    });
  }

  inbox() {
    this.route.navigate(['extinboxmails']);
  }
  composemail() {
    this.route.navigate(['composeextmsg']);
  }
  deleteitems() {
    this.route.navigate(['delextmails']);
  }
  sentitems() {
    this.route.navigate(['sentextmails']);
  }
  archiveitems() {
    this.route.navigate(['extarchive']);
  }

  replay(mail , aa){
    // var qq = document.getElementById(mail.id);
    // qq.setAttribute(visibility,"visible");
    this.replayenable = true;
    this.replytouser = "";
   
    if(aa == 'single'){
      this.replytouser = mail.from;
      this.selectedFriend.push(mail.from);
    }
    else if(aa == 'all'){
      this.replytouser = mail.from;
      this.selectedFriend.push(mail.from);
      let toemails = mail.to.split(',');
      for (let index = 0; index < toemails.length; index++) {
        if ( toemails[index] != this.usermail) {
          this.replytouser = this.replytouser + " , " + toemails[index];
          this.selectedFriend.push(toemails[index]);
        }         
      }
    }
    
  }

  sendmail()
  {
    var dateFormat;
    var d = new Date();
          var monthD = ("0" + (d.getMonth() + 1)).slice(-2);
     var dateD = ("0" + d.getDate()).slice(-2);
    var ampm = (d.getHours() >= 12) ? "PM" : "AM";
    dateFormat = monthD+"-"+dateD+"-"+d.getFullYear()+" "+d.getHours()+":"+d.getMinutes()+":"+d.getSeconds()+" "+ampm;   
    if (this.frmDataa == null) {
      this.frmDataa = new FormData();
    }
    var toEmails = this.selectedFriend.join(",");
    this.frmDataa.append("Subject", this.subject);
    this.frmDataa.append("Mail_body", this.message);
    this.frmDataa.append("ToAddress", toEmails);
    this.frmDataa.append("SenderEmail", this.usermail);
    this.frmDataa.append("MailType", "Reply");
    this.frmDataa.append("MailId", this.mailid);
    this.frmDataa.append("DateFormat",dateFormat );
    this.httpService.post("https://blocdrivesaas.in/blocdrivesaasapi/api/GoogleExtension/NewComposeMail", this.frmDataa).subscribe(
      data => {
        this.selectedFriend= [];
        alert("Mail Sent Successfully");
        this.route.navigate(['extinboxmails'])
      },
      (err: HttpErrorResponse) => {
        console.log(err.message);
      });
  }

  onChangeDocumnets(event: any) {
    this.frmDataa = new FormData();
    let files = [].slice.call(event.target.files);
    for (var i = 0; i < files.length; i++) {
      this.SelectedFiles.push(files[i]);
      this.frmDataa.append("Docs", files[i]);
    }
  }

}
