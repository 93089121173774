import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IndividualChatDetails } from 'src/app/ViewModels/IndividualChatDetails';

@Injectable({
  providedIn: 'root'
})
export class SupportchatService {

  readonly url="https://blocdrivesaas.in/blocdrivesaasapi/api/";
  constructor(private http:HttpClient) { }
  savesupportChat(ChatDetail) {
    return this.http.post(this.url + "SupportChat/SaveChatSupport", ChatDetail);
    }             

  GetChatDetails(individual: IndividualChatDetails) {
      return this.http.post(this.url + "SupportChat/DisplayChatSupport" , individual);
      }
}
