import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ProjectModule } from 'src/app/ViewModels/ProjectModule ';

@Injectable({
  providedIn: 'root'
})
export class ProjectModuleService {

  readonly url="https://blocdrivesaas.in/blocdrivesaasapi/api/";
  constructor(private http: HttpClient) {}
  saveModule(data: ProjectModule) {
    return this.http.post(this.url + 'ProjectModules/PostSaveProjectModules', data);
  }

  getAllModules(id) {
    return this.http.get(this.url + 'ProjectModules/GetAllProjectModules/' + id);
  }

  deleteModule(id: any) {
    return this.http.get(this.url + 'ProjectModules/GetDeleteProjectModulesData/' + id);
  }

  UpdateModule(data: ProjectModule) {
    return this.http.post(this.url + 'ProjectModules/PutProjectModules', data);
  }
}
