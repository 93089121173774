import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuditorTransactionFolderVM, AuditorTransactionVM } from 'src/app/ViewModels/Auditortransaction';

@Injectable({
  providedIn: 'root'
})

export class AuditorTransactionService {
    readonly _urls="https://blocdrivesaas.in/blocdrivesaasapi/api/";
    ipAddress: any;
  
    deviceInfo: any;
    getDeviceInfo: any;
    isMobile: any;
    isTablet: any;
    isDesktop: any;
  
    constructor(private http:HttpClient, ) { 
     // this.epicFunction();
     this.getIPAddress();
    }
   
  saveauditordetails(AuditorTransactionVM) {
    return this.http.post(this._urls + "AuditorTransaction/PostSaveAuditor", AuditorTransactionVM);
  }

  saveauditordetailschat(AuditorTransactionVM) {
    return this.http.post(this._urls + "AuditorTransaction/PostSaveAuditorchat", AuditorTransactionVM);
  }

  saveauditordetailsproject(AuditorTransactionVM) {
    return this.http.post(this._urls + "AuditorTransaction/PostSaveAuditorProject", AuditorTransactionVM);
  }

  saveauditordetailsfilemail(AuditorTransactionVM) {
    return this.http.post(this._urls + "AuditorTransaction/PostSaveAuditorFileMail", AuditorTransactionVM);
  }
  saveauditoruserdetails(AuditorTransactionVM) {
    return this.http.post(this._urls + "AuditorTransaction/PostSaveUserAuditor", AuditorTransactionVM);
  }

  getauditordetails(id:any) {
    return this.http.get(this._urls + "AuditorTransaction/GetAuditorDetails/"+ id);
  }
  
  getauditviewdetails(id:any) {
    return this.http.get(this._urls + "AuditorTransaction/GetAuditViewTransaction/"+ id);
  }
  getclientdetails(id:any) {
    return this.http.get(this._urls + "AuditorTransaction/GetClientTransaction/"+ id);
  }
  GetClientDetailsAudit(id:any) {
    return this.http.get(this._urls + "AuditorTransaction/GetAllClientsAuditor/"+ id);
  }

  GetProjectDetailsAudit(id:any) {
    return this.http.get(this._urls + "AuditorTransaction/GetProjectDetailsAudit/"+ id);
  }
  GetTaskDetailsAudit(id:any) {
    return this.http.get(this._urls + "AuditorTransaction/GetAllTaskAuditor/"+ id);
  }
  GetAssignTaskDetailsAudit(id:any) {
    return this.http.get(this._urls + "AuditorTransaction/GetAssignTaskAuditor/"+ id);
  }
  getmasterdetails(){
    return this.http.get(this._urls+ "AuditorTransaction/GetAllMasterDetails/");
  }
  getauditortype(){
    return this.http.get(this._urls+ "AuditorTransaction/GetAuditorType/");
  }
  getaudittypedetails(id:any){
    return this.http.get(this._urls + "AuditorTransaction/GetAuditTypeDataDetails/" + id);
  }
  getaudittypedesc(id:any){
    return this.http.get(this._urls + "AuditorTransaction/GetAuditTypeDataDesc/" + id);
  }
  
  postaudittypebydate(searchVM){
    return this.http.post(this._urls + "AuditorTransaction/PostAuditTypeByDate", searchVM);
  }
  
  
  
  AuditorTransactionDetails(data){
  
  let uid = localStorage.getItem("userId");
  let roleid = localStorage.getItem("role");
  let GUID = localStorage.getItem("GUID");
  let MailId=localStorage.getItem("MailId");
  let UserFullName=localStorage.getItem("UserFullName");
  let EnterPriseId  =localStorage.getItem("EnterPriseId");
  
    var one:AuditorTransactionVM={
      Id:0,
      CustomerId:uid,
      EnterpriseId:EnterPriseId,
      GuId:GUID,
      UserName:UserFullName,
      MailId:MailId,
      ContentName:"",
      BrowserName: "Chrome",
      GeoLocation:"",
      IPAddress: this.ipAddress,
      TransactionName:data.TransactionName,
      TransactionType:data.TransactionType,
      TransactionDesc:data.TransactionDesc,
      TransactionDate:null,
      BlockId:data.BlockId,    
      DocumentName:data.DocumentName,
      DocumentType :data.DocumentType,
          
   }
     this.saveauditordetails(one).subscribe(
       (response:any)=>{
        
       }
     )
  }
  AuditorUserDetails(data){
  
    let uid = localStorage.getItem("userId");
    let roleid = localStorage.getItem("role");
    let GUID = localStorage.getItem("GUID");
    let MailId=localStorage.getItem("MailId");
    let UserFullName=localStorage.getItem("UserFullName");
    let EnterPriseId  =localStorage.getItem("EnterPriseId");
    
      var one:AuditorTransactionVM={
        Id:0,
        CustomerId:uid,
        EnterpriseId:EnterPriseId,
        GuId:GUID,
        UserName:UserFullName,
        MailId:MailId,
        ContentName:"",
        BrowserName: "Chrome",
        GeoLocation:"",
        IPAddress: this.ipAddress,
        TransactionName:data.TransactionName,
        TransactionType:data.TransactionType,
        TransactionDesc:data.TransactionDesc,
        TransactionDate:null,
        BlockId:data.BlockId,    
        DocumentName:data.DocumentName,
        DocumentType :data.DocumentType,
       
            
     }
       this.saveauditoruserdetails(one).subscribe(
         (response:any)=>{
          
         }
       )
    }

  AuditorTransactionDetailsFileMail(data){
  
    let uid = localStorage.getItem("userId");
    let roleid = localStorage.getItem("role");
    let GUID = localStorage.getItem("GUID");
    let MailId=localStorage.getItem("MailId");
    let UserFullName=localStorage.getItem("UserFullName");
    let EnterPriseId  =localStorage.getItem("EnterPriseId");
    
      var one={
        Id:0,
        CustomerId:uid,
        EnterpriseId:EnterPriseId,
        GuId:GUID,
        UserName:UserFullName,
        MailId:MailId,
        ContentName:"",
        BrowserName: "Chrome",
        GeoLocation:"",
        IPAddress: this.ipAddress,
        TransactionName:data.TransactionName,
        TransactionType:data.TransactionType,
        TransactionDesc:data.TransactionDesc,
        TransactionDate:null,
        BlockId:data.BlockId,    
        DocumentName:data.DocumentName,
        DocumentType :data.DocumentType,
        Filetype:data.FileType,
            
     }
       this.saveauditordetailsfilemail(one).subscribe(
         (response:any)=>{
          
         }
       )
    }
  
  AuditorTransactionDetailsChat(data){
  
    let uid = localStorage.getItem("userId");
    let roleid = localStorage.getItem("role");
    let GUID = localStorage.getItem("GUID");
    let MailId=localStorage.getItem("MailId");
    let UserFullName=localStorage.getItem("UserFullName");
    let EnterPriseId  =localStorage.getItem("EnterPriseId");
    
      var one:AuditorTransactionVM={
        Id:0,
        CustomerId:uid,
        EnterpriseId:EnterPriseId,
        GuId:GUID,
        UserName:UserFullName,
        MailId:MailId,
        ContentName:"",
        BrowserName: "Chrome",
        GeoLocation:"",
        IPAddress: this.ipAddress,
        TransactionName:data.TransactionName,
        TransactionType:data.TransactionType,
        TransactionDesc:data.TransactionDesc,
        TransactionDate:null,
        BlockId:data.BlockId,    
        DocumentName:data.DocumentName,
        DocumentType :data.DocumentType,
            
     }
       this.saveauditordetailschat(one).subscribe(
         (response:any)=>{
          
         }
       )
    }
    AuditorDetailsProject(data){
  
      let uid = localStorage.getItem("userId");
      let roleid = localStorage.getItem("role");
      let GUID = localStorage.getItem("GUID");
      let MailId=localStorage.getItem("MailId");
      let UserFullName=localStorage.getItem("UserFullName");
      let EnterPriseId  =localStorage.getItem("EnterPriseId");
      
        var one:AuditorTransactionFolderVM={
          Id:0,
          CustomerId:uid,
          EnterpriseId:EnterPriseId,
          GuId:GUID,
          UserName:UserFullName,
          MailId:MailId,
          ContentName:"",
          BrowserName: "Chrome",
          GeoLocation:"",
          IPAddress: this.ipAddress,
          TransactionName:data.TransactionName,
          TransactionType:data.TransactionType,
          TransactionDesc:data.TransactionDesc,
          TransactionDate:null,
          BlockId:data.BlockId,    
          DocumentName:data.DocumentName,
          DocumentType :data.DocumentType,
          FolderName:data.FolderName,
          SubFolderName:data.SubFolderName
              
       }
         this.saveauditordetailsproject(one).subscribe(
           (response:any)=>{
            
           }
         )
      }

  
  public getIP()
  {
    return this.http.get("https://api.ipify.org/?format=json");
  }
  
  getIPAddress()
  {
    this.getIP().subscribe((res:any)=>{
      this.ipAddress = res.ip;
     console.log(this.ipAddress);
       //  return this.ipAddress
    });
  }
  
  
  }