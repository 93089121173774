import { Component, OnInit } from '@angular/core';
import { WorkFlowService } from 'src/app/Shared/WorkFlow/work-flow.service';
import { FileNumberGenerationService } from 'src/app/Shared/FileNumberGeneration/file-number-generation.service';
import { FolderService } from 'src/app/Shared/Folder/folder.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { InwardServiceService } from 'src/app/Shared/Inward/inward-service.service';

@Component({
  selector: 'app-folder-creation',
  templateUrl: './folder-creation.component.html',
  styleUrls: ['./folder-creation.component.css']
})
export class FolderCreationComponent implements OnInit {
  workflowStages: any;
  departmentlist: any;
  yearlist: any;
  divisionlist: any;
  subjectlist: any;
  typelist: any;
  sectionlist: any;
  formcodelist: any;
  statuslist: any;
  fileplanlist: any;
  example15data: any;
  example15model: any;
  folder: any;
  uid: any;

  constructor(private workflowservice:WorkFlowService,private spinner:NgxSpinnerService,
    private fileNumberGenerationService:FileNumberGenerationService,private inwardService:InwardServiceService,
    private folderService:FolderService) { }

  ngOnInit(): void {
  }
  GetWorkflowStages(){
    this.spinner.show();
    this.workflowservice.getWorkflowStagesforfolder("FOLDER").subscribe((response: any) => {
      this.workflowStages = response;
      this.spinner.hide();
      var tooltipsData = this.workflowStages.filter(function (stage) {
        return (stage.id === this.folder.status);
     })[0].workflowStage;
    })

  }
  getDepartments(){
    this.spinner.show();
    this.fileNumberGenerationService.getDepartments().subscribe((response: any) => {
      this.departmentlist = response;
      this.spinner.hide();
       })
  }
  getYears(){
    this.spinner.show();
    this.fileNumberGenerationService.getYears().subscribe((response: any) => {
      this.yearlist = response;
      this.spinner.hide();
       })
  }
  getDivisions(){
    this.spinner.show();
    this.fileNumberGenerationService.getDivisions().subscribe((response: any) => {
      this.divisionlist = response;
      this.spinner.hide();
       })
  }
  getSubjects(){
    this.spinner.show();
    this.fileNumberGenerationService.getSubjects().subscribe((response: any) => {
      this.subjectlist = response;
      this.spinner.hide();
       })
  }
  getTypes(){
    this.spinner.show();
    this.fileNumberGenerationService.getTypes().subscribe((response: any) => {
      this.typelist = response;
      this.spinner.hide();
       })
  }
  getSections(){
    this.spinner.show();
    this.fileNumberGenerationService.getSections().subscribe((response: any) => {
      this.sectionlist = response;
      this.spinner.hide();
       })
  }
  getFormCode(){
    this.spinner.show();
    this.fileNumberGenerationService.getFormCode().subscribe((response: any) => {
      this.formcodelist = response;
      this.spinner.hide();
       })
  }
  getStatus(){
    this.spinner.show();
    this.fileNumberGenerationService.getStatus().subscribe((response: any) => {
      this.statuslist = response;
      this.spinner.hide();
       })
  } 
  GetFilePlan(){
    this.spinner.show();
    this.inwardService.GetFiles().subscribe((response: any) => {
      this.fileplanlist = response;
      this.spinner.hide();
       })
  }
  

  saveFolder(){
    var data = {
      ToUsers: this.example15model,
      FromUser: this.uid,
      Status: this.folder.status,
      Description: this.folder.description,
      FolderName: this.folder.folderName,
      FileId: this.folder.fileNumber,
      MailId: this.folder.mailId == "" ? 0 : this.folder.mailId,
  };
  this.spinner.show();
  this.folderService.saveFolderData(data).subscribe((response: any) => {
    this.example15data = response;
        this.spinner.hide();
        if(this.folder.status=="1"){
          alert("Created successfully!!!");
        }
        else {
          alert("Mail sent successfully!!!");
      }
     })
  }

}
