import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, NgForm, Validators } from '@angular/forms';
import * as $ from 'jquery';
import { NgxSpinnerService } from 'ngx-spinner';
import { AuditorTransactionService } from '../Shared/auditortransaction/auditor-transaction.service';
import { LoginService } from '../Shared/Login/login.service';
import { WorkFlowService } from '../Shared/WorkFlow/work-flow.service';
import { CkycregDetail } from '../ViewModels/CkycregDetails';
@Component({
  selector: 'app-ckycregistration',
  templateUrl: './ckycregistration.component.html',
  styleUrls: ['./ckycregistration.component.css']
})
export class CkycregistrationComponent implements OnInit {
  submitted: boolean = false;
  email: any;
  designation: any;
  firstname: any;
  middlename: any;
  lastname: any;
  Number: any;
  confirm: boolean;
  details: boolean=false;
  SelectedFiles: string;
  files1: any;
  dismissalert: boolean;
  notify: string;
  maidenfirstname: any;
  maidenmiddlename: any;
  maidenlastname: any;
  fathername: any;
  fathermiddlename: any;
  fatherlastname: any;
  mothername: any;
  motherlastname: any;
  mothermiddlename: any;
  dateofbirth: any;
  countryjurisdiction: any;
  place: any;
  tinissued: any;
  isocode: any;
  GenderId: any;
  Genders: any;
  MaritalStatus: any;
  Citizenship: any;
  Occupation: any;
  ResidentialStatus: any;
  status: any;
  occupations: any;
  msid: any;
  citizenid: any;
  occid: any;
  rsid: any;
  idprooftype: any;
  proofnumber: any;
  proofsumbitted: any;
  address: any;
  addressline: any;
  linetwo: any;
  linethree: any;
  citytown: any;
  district: any;
  state: any;
  isocountry: any;
  pincode: any;
  proofoadress: any;
  poa: any;
  lineonedata: any;
  linetwodata: any;
  linethreedata: any;
  village: any;
  districtone: any;
  stateutcode: any;
  isocountrycode: any;
  pincodeone: any;
  Telephone: any;
  Residencenum: any;
  mobilenum: any;
  faxnumber: any;
  Relatedperson: any;
  kycnumber: any;
  personname: any;
  personmiddlename: any;
  personlastname: any;
  Pancard: any;
  passportnum: any;
  Passportexipre: any;
  voterid: any;
  driveing: any;
  driveingdate: any;
  uidaadhaar: any;
  nregajob: any;
  Others: any;
  Identification: any;
  document: any;
  kycverify: any;
  Verification: any;
  Verificationcode: any;
  Verifycodedesignation: any;
  branch: any;
  organisation: any;
  organisationcode: any;
  declaration: any;
  declarationdate: any;
  remark: any;
  remarks: any;
  username: string;
  roleid: string;
  GUID: string;
  uid: string;
  relateds: any;
  relationid: any;
  userfullname: string;


  get f() { return this.RegistrationForm.controls; }
  RegistrationForm = new FormGroup({

    firstname: new FormControl(),
    middlename: new FormControl(),
    lastname: new FormControl(),
    maidenfirstname: new FormControl(),
    maidenmiddlename: new FormControl(),
    maidenlastname: new FormControl(),
    fathername: new FormControl(),
    fathermiddlename: new FormControl(),
    fatherlastname: new FormControl(),
    mothername: new FormControl(),
    motherlastname: new FormControl(),
    mothermiddlename: new FormControl(),
    dateofbirth: new FormControl(),
    countryjurisdiction: new FormControl(),
    place: new FormControl(),
    tinissued: new FormControl(),
    isocode: new FormControl(),
    Gender: new FormControl(),
    MaritalStatus: new FormControl(),
    Citizenship: new FormControl(),
    Occupation: new FormControl(),
    ResidentialStatus: new FormControl(),
    idprooftype: new FormControl(),
    proofnumber: new FormControl(),
    proofsumbitted: new FormControl(),
    address: new FormControl(),
addressline: new FormControl(),
linetwo: new FormControl(),
linethree: new FormControl(),
citytown: new FormControl(),
district: new FormControl(),
state: new FormControl(),
isocountry: new FormControl(),
pincode: new FormControl(),
proofoadress: new FormControl(),
poa: new FormControl(),
lineonedata: new FormControl(),
linetwodata: new FormControl(),
linethreedata: new FormControl(),
village: new FormControl(),
districtone: new FormControl(),
stateutcode: new FormControl(),
isocountrycode: new FormControl(),
pincodeone: new FormControl(),
Telephone: new FormControl(),
Residencenum: new FormControl(),
mobilenum: new FormControl(),
faxnumber: new FormControl(),
email: new FormControl(),
Relatedperson: new FormControl(),
kycnumber: new FormControl(),
personname: new FormControl(),
personmiddlename: new FormControl(),
personlastname: new FormControl(),
Pancard: new FormControl(),
passportnum: new FormControl(),
Passportexipre: new FormControl(),
voterid: new FormControl(),
driveing: new FormControl(),
driveingdate: new FormControl(),
uidaadhaar: new FormControl(),
nregajob: new FormControl(),
Others: new FormControl(),
Identification: new FormControl(),
document: new FormControl(),
kycverify: new FormControl(),
Verification: new FormControl(),
Verificationcode: new FormControl(),
Verifycodedesignation: new FormControl(),
branch: new FormControl(),
organisation: new FormControl(),
organisationcode: new FormControl(),
declaration: new FormControl(),
declarationdate: new FormControl(),
remarks : new FormControl(),
  });

  constructor(private formBuilder: FormBuilder,private auditortransactionservice:AuditorTransactionService,private spinner: NgxSpinnerService,private httpService: HttpClient,
    private userService: LoginService,private workflowservice:WorkFlowService,) { 
    if (localStorage.getItem("IsLoggedIn") == "true") {
      this.uid = localStorage.getItem("userId");
      this.username = localStorage.getItem("UserFullName");
      this.roleid = localStorage.getItem("role");
      this.GUID = localStorage.getItem("GUID");
      this.userfullname=localStorage.getItem("UserFullName");


    }
    this.RegistrationForm = formBuilder.group({

      firstname: ['', Validators.required],
      middlename: [''],
      lastname: [''],
      maidenfirstname: ['', Validators.required],
      maidenmiddlename: [''],
      maidenlastname: [''],
      fathername: ['', Validators.required],
      fathermiddlename: [],
      fatherlastname: [''],
      mothername: ['' ,Validators.required],
      motherlastname: [''],
      mothermiddlename: ['' ],
      dateofbirth: ['', Validators.required],
      countryjurisdiction: ['', Validators.required],
      place: ['', Validators.required],
      tinissued: [''],
      isocode: [''],
      Gender: [''],
      MaritalStatus: [''],
      Citizenship: [''],
      Occupation: [''],
      ResidentialStatus: [''],
      idprooftype: ['', Validators.required],
      proofnumber:  ['', Validators.required],
      proofsumbitted:  ['', Validators.required],

      address:  ['', Validators.required],
      addressline:  ['', Validators.required],
      linetwo:  ['', Validators.required],
      linethree:  ['', Validators.required],
      citytown:  ['', Validators.required],
      district:  ['', Validators.required],
      state:  ['', Validators.required],
      isocountry:  ['', Validators.required],
      pincode:  ['', Validators.required],
      proofoadress:  ['', Validators.required],
      poa:  [''],
      lineonedata: [''],
      linetwodata:  [''],
      linethreedata:  [''],
      village:  [''],
      districtone:  [''],
      stateutcode: [''],
      isocountrycode:  [''],
      pincodeone: [''],
      Telephone:  ['', Validators.required],
      Residencenum:  ['', Validators.required],
      mobilenum:  ['', Validators.required],
      faxnumber:  [''],
      email:  ['', Validators.required],
      Relatedperson:  ['', Validators.required],
      kycnumber:  ['', Validators.required],
      personname:  ['', Validators.required],
      personmiddlename: [''],
      personlastname: [''],
      Pancard:  ['', Validators.required],
      passportnum:  ['', Validators.required],
      Passportexipre: [''],
      voterid:  ['', Validators.required],
      driveing:  ['', Validators.required],
      driveingdate:  ['', Validators.required],
      uidaadhaar:  ['', Validators.required],
      nregajob:[''],
      Others: [''],
      Identification: [''],
      document: [''],
      kycverify: ['' , Validators.required],
      Verification:  ['', Validators.required],
      Verificationcode:  ['', Validators.required],
      Verifycodedesignation: [''],
      branch:  ['', Validators.required],
      organisation:  ['', Validators.required],
      organisationcode:  ['' , Validators.required],
      declaration: ['' , Validators.required],
      declarationdate:  ['' , Validators.required],
   
      remarks :  [''],
    })
 
  }


  ngOnInit(): void {
    this.GetMaritalStatus();
    this.GetGenders();
    this.GetCitizenship();
    this.GetOccupation();
    this.GetResidentialStatus();
    this. GetRelatedperson();
    $(document).ready(function () {

      var current_fs, next_fs, previous_fs; //fieldsets
      var opacity;
      localStorage.setItem("validateval", "true");
      $(".next").click(function () {
        var value = localStorage.getItem("validateval")
        if (value == "true") {

          current_fs = $(this).parent();
          next_fs = $(this).parent().next();

          //Add Class Active
          $("#progressbar li").eq($("fieldset").index(next_fs)).addClass("active");

          //show the next fieldset
          next_fs.show();
          //hide the current fieldset with style
          current_fs.animate({ opacity: 0 }, {
            step: function (now) {
              // for making fielset appear animation
              opacity = 1 - now;

              current_fs.css({
                'display': 'none',
                'position': 'relative'
              });
              next_fs.css({ 'opacity': opacity });
            },
            duration: 600
          });
          localStorage.setItem("validateval", "true");
        }
      });

      $(".previous").click(function () {
        localStorage.setItem("validateval", "true");

        current_fs = $(this).parent();
        previous_fs = $(this).parent().prev();

        //Remove class active
        $("#progressbar li").eq($("fieldset").index(current_fs)).removeClass("active");

        //show the previous fieldset
        previous_fs.show();

        //hide the current fieldset with style
        current_fs.animate({ opacity: 0 }, {
          step: function (now) {
            // for making fielset appear animation
            opacity = 1 - now;

            current_fs.css({
              'display': 'none',
              'position': 'relative'
            });
            previous_fs.css({ 'opacity': opacity });
          },
          duration: 600
        });
      });

      $('.radio-group .radio').click(function () {
        $(this).parent().find('.radio').removeClass('selected');
        $(this).addClass('selected');
      });

      $(".submit").click(function () {
        return false;
      })

    });
  }
 

  onSelectIsConfirm(event) {
    if (event.target.checked) {
      this.confirm = true;
      this.details = true;
    }
    else {
      this.confirm = false;
      this.details = false;
    }
  }
  OnselectGender(id) {
    this.GenderId=id
  }
  onChange(event: any) {
    var fileslist = "";
    this.files1 = [].slice.call(event.target.files);
    console.log(this.files1);
    fileslist = this.files1[0];
    this.SelectedFiles = fileslist;
  }

  RegisterUser(){
    
  }
  
  GetMaritalStatus() {
    this.spinner.show();
    this.workflowservice.getAllMaritalStatus().subscribe((Data: any) => {
      this.status = Data;
      this.spinner.hide();
    })
  }
  Onselectproofsumbit(data: any) {
    this.proofsumbitted = data
  }
  Onselectrelateds(data: any) {
    this.relationid = data
  }
  onSelectmaritalstatus(data: any) {
    this.msid = data
  }
  Onselectcitizen(data: any) {
    this.citizenid = data
  }
  Onselectoccupation(data: any) {
    this.occid = data
  }
  Onselectresidential(data: any) {
    this.rsid = data
  }
  GetGenders(){
    this.spinner.show();
    this.workflowservice.getGenders().subscribe((response: any) => {
      this.Genders = response;
      this.spinner.hide();
    })
  }
  GetCitizenship() {
    this.spinner.show();
    this.workflowservice.getAllCitizenship().subscribe((Data: any) => {
      this.Citizenship = Data;
      this.spinner.hide();
    })
  }

  GetRelatedperson() {
    this.spinner.show();
    this.workflowservice.getAllRelatedPerson().subscribe((Data: any) => {
      this.relateds = Data;
      this.spinner.hide();
    })
  }
  GetOccupation() {
    this.spinner.show();
    this.workflowservice.getAllOccupation().subscribe((Data: any) => {
      this.occupations = Data;
      this.spinner.hide();
    })
  }
  GetResidentialStatus() {
    this.spinner.show();
    this.workflowservice.getAllResidentialStatus().subscribe((Data: any) => {
      this.ResidentialStatus = Data;
      this.spinner.hide();
    })
  }
  Validateone() {
    this.submitted = true;
    if (this.GenderId == null || this.GenderId == undefined) {
      alert("Please select Gender")
    }
    if (this.msid == null || this.msid == undefined) {
      alert("Please select Marital Status")
    }
    if (this.citizenid == null || this.citizenid == undefined) {
      alert("Please select Citizenship")
    }
    if (this.occid == null || this.occid == undefined) {
      alert("Please select Occupation")
    }
    if (this.rsid == null || this.rsid == undefined) {
      alert("Please select Residential Status")
    }
    if (this.RegistrationForm.invalid && (this.firstname == undefined || this.maidenfirstname == undefined || this.fathername == undefined || this.mothername == undefined || this.dateofbirth == undefined || 
       this.firstname == "" || this.maidenfirstname == "" || this.fathername == "" || this.mothername == "" || this.dateofbirth == "" || this.GenderId == null || this.GenderId == undefined || this.msid == null || this.msid == undefined || this.citizenid == null || this.citizenid == undefined
       || this.occid == null || this.occid == undefined || this.rsid == null || this.rsid == undefined )) {
      localStorage.setItem("validateval", "false")
    }
    else {
     
      localStorage.setItem("validateval", "true")
      
    }
  }
  Validatetwo() {
    this.submitted = true;
    if (this.proofsumbitted == null || this.proofsumbitted == undefined) {
      alert("Please select Id Proof Submitted")
    }
    if (this.RegistrationForm.invalid && (this.idprooftype == undefined ||this.proofnumber == undefined || this.proofsumbitted == null || this.proofsumbitted == undefined ||
       this.idprooftype == "" || this.proofnumber == "" || this.proofsumbitted == null || this.proofsumbitted == undefined )) {
      localStorage.setItem("validateval", "false")
    }
    else { 
      localStorage.setItem("validateval", "true")  
    }
  }
  Validatethree() {
    this.submitted = true;
  
    if (this.RegistrationForm.invalid && (this.address == undefined ||this.addressline == undefined || this.linetwo == undefined ||this.linethree == undefined ||
      this.citytown == undefined ||this.district == undefined || this.state == undefined ||this.pincode == undefined ||
      this.proofoadress == undefined ||this.Telephone == undefined || this.mobilenum == undefined ||this.email == undefined ||
       this.address == "" || this.addressline == "" ||  this.linetwo == "" || this.linethree == "" || 
       this.citytown == "" || this.district == "" ||  this.state == "" || this.pincode == "" ||
       this.proofoadress == "" || this.Telephone == "" ||  this.mobilenum == "" || this.email == "")) {
      localStorage.setItem("validateval", "false")
    }
    else { 
      localStorage.setItem("validateval", "true")  
    }
  }

  Validationfour() {
    this.submitted = true;
    if (this.relationid == null || this.relationid == undefined) {
      alert("Please select related person type")
    }
    if (this.RegistrationForm.invalid && (this.kycnumber == undefined ||this.personname == undefined || this.Pancard == undefined ||this.voterid == undefined ||
      this.uidaadhaar == undefined ||this.document == undefined || this.kycverify == undefined ||this.Verification == undefined ||
      this.Verificationcode == undefined ||this.Verifycodedesignation == undefined || this.organisation == undefined ||this.organisationcode == undefined ||
      this.declaration == undefined || this.declarationdate == undefined ||this.branch == undefined ||
      this.declaration == "" || this.declarationdate == "" ||  this.branch == "" || 
       this.kycnumber == "" || this.personname == "" ||  this.Pancard == "" || this.voterid == "" || 
       this.uidaadhaar == "" || this.document == "" ||  this.kycverify == "" || this.Verification == "" ||
       this.Verificationcode == "" || this.Verifycodedesignation == "" ||  this.organisation == "" || this.organisationcode == "" || this.relationid == null || this.relationid == undefined)) {
      localStorage.setItem("validateval", "false")
    }
    else { 
      localStorage.setItem("validateval", "true")  
    }
  }
  CKYCRegisterUser() {
      this.submitted = true;
       // this.spinner.show(); 
        const frmData = new FormData();
        const inputRequest: CkycregDetail = {
        Id:0,
        UserId:this.uid,
          LastName:this.lastname,
          FirstName:this.firstname,
          MiddleName:this.middlename,
          MaidenName:this.maidenfirstname,       
          MaidenMiddlename:this.maidenmiddlename,
          MaidenLastname:this.maidenlastname,
          FatherName:this.fathername,
          FathermiddleName:this.fathermiddlename,
          FatherlastName:this.fatherlastname,
          MotherName:this.mothername,
          Motherlastname:this.motherlastname,
          Mothermiddlename:this.mothermiddlename,
          dateofbirth:this.dateofbirth,
          Countryjurisdiction:this.countryjurisdiction,
          Place:this.place,
          TINIssued:this.tinissued,
          Countrycode:this.isocode,
          Gender:this.GenderId,
         MaritalStatus:this.msid,
          Citizenship:this.citizenid,
          Occupation:this.occid,
          ResidentialStatus:this.rsid,
          Idprooftypr:this.idprooftype,
          ProofNumber:this.proofnumber,
          Proofsubmitted:this.proofsumbitted,
        Addresstype  :this.address,
         Addresslineone :this.addressline,
         Addresslinetwo :this.linetwo,
         Addresslinethree :this.linethree,
        City  :this.citytown,
          District :this.district,
         State :this.state,
         IsoCountryCode :this.isocountry,
        Pincode  :this.pincode,
         ProofOfaddress :this.proofoadress,
         POA :this.poa,
         LineOne :this.lineonedata,
         Linetwo :this.linetwodata,
         LineThree :this.linethreedata,
          Village:this.village,
         DistrictOne :this.districtone,
         Stateone :this.stateutcode,
         Isocodeone :this.isocountrycode,
        Pincodeone  :this.pincodeone,
         Telephone :this.Telephone,
         ResidenceNum :this.Residencenum,
         Mobile :this.mobilenum,
          Fax:this.faxnumber,
        Email  :this.email,
        GUID:this.GUID,
        Relatedperson :this.relationid,
        kycNumber :this.kycnumber,
       PersonName :this.personname,
       PersonMiddleName :this.personmiddlename,
       PersonLastName  :this.personlastname,
       Pancard  :this.Pancard,
       PassportNum  :this.passportnum,
       PassportExpire :this.Passportexipre,
        VoterId:this.voterid,
        Driveing :this.driveing,
        Driveingdate  :this.driveingdate,
       UIDA :this.uidaadhaar,
       Nregajob :this.nregajob,
        Others:this.Others,
        Identification   :this.Identification,
        Document :this.document,
        KycVerify   :this.kycverify,
        Verification :this.Verification,
        VerificationCode    :this.Verificationcode,
        Verifycodedesignation :this.Verifycodedesignation,
        Branch :this.branch,
        Organisation  :this.organisation,
        OrganisationCode  :this.organisationcode,
        Declaration  :this.declaration,
        Declarationdate :this.declarationdate,
        //Picture:this.SelectedFiles,
        Remark :this.remarks,

        }
        frmData.append("RegData", JSON.stringify(inputRequest));
          frmData.append("picture", this.SelectedFiles);
          frmData.append("databasename", this.userfullname)

        // frmData.append("logo", inputRequest);   
        this.httpService.post('https://blocdrivesaas.in/blocdrivesaasapi/api/CKYCRegistration/PostSaveckycregDetails/', frmData).subscribe(

       data => {
        const audit = {
          TransactionName:"Ckycr",
          TransactionType:"Create",
          TransactionDesc:"Ckycr",
  
          }
          this.auditortransactionservice.AuditorTransactionDetails(audit);
        // this.spinner.hide();
        // this.router.navigate(['/login']);
       });
  }



}