import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class GmailmigrationService {
  readonly url="https://blocdrivesaas.in/blocdrivesaasapi/api/";
  constructor(private http:HttpClient) { }

  postgmailmigrate(id){
    return this.http.post("https://blocdrivesaas.in/blocdrivegmailapi/api/" + "GmailMigration/Postgmailmigrate" , id);
  }

  getGmaillist(cid) {
    return this.http.get(this.url + "GmailMigration/Getallmailsfromgmail/" + cid);
  }

  getgmaildetails(cid) {
    return this.http.get(this.url + "GmailMigration/GetReadgmail/" + cid);
  }

  downloadgmailfile(id){
    return this.http.get(this.url + "GmailMigration/PostDownloadgmailfile/" + id);
  }
}
