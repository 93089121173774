import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class WorkFlowMasterService {
  readonly _urls: string = 'https://blocdrivesaas.in/blocdrivesaasapi'

  constructor(private http:HttpClient) { }
  saveworkflow(workflow) {
    return this.http.post(this._urls + "/api/WorkflowMaster/PostSaveWorkflows", workflow);
  }
  getworkflowdata(id) {
    return this.http.get(this._urls + '/api/WorkflowMaster/GetAllWorkflowMasterdata/' + id);
  }
  getworkflows(id) {
    return this.http.get(this._urls + "/api/WorkflowMaster/GetAllWorkflows/" + id);
  }

  UpdateworkflowRow(id){
    return this.http.post(this._urls + "/api/WorkflowMaster/PostPutWorkflows" , id);
  }

  deleteWorkflowsdata(id:number){
    return this.http.delete(this._urls +"/api/WorkflowMaster/DeleteWorkflowsData/"+ id);
  }
}
