import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import html2canvas from 'html2canvas';
import { NgxSpinnerService } from 'ngx-spinner';
import { InwardServiceService } from 'src/app/Shared/Inward/inward-service.service';
import { WorkAllotmentService } from 'src/app/Shared/WorkAllotment/work-allotment.service';

@Component({
  selector: 'app-work-allotment-preview',
  templateUrl: './work-allotment-preview.component.html',
  styleUrls: ['./work-allotment-preview.component.css']
})
export class WorkAllotmentPreviewComponent implements OnInit {
  WorkPreviewData: any;
  selecteduserlist: any;
  filenumber: any;
  wI_Address: any;
  wI_InwardFrom: any;
  wI_Date: any;
  inwarddataid: any;
  wI_InwardNo: any;
  inwarddatainwardnum: any;
  wI_InwardTypeId: any;
  wI_Pages: any;
  wI_DocumentType: any;
  wI_Sensitivity: any;
  remarks: any;
  attachmentdetails: any;
  uid: any;
  inwarddataallotmentid: any;
  selectedworkflowstage: any;
  ExhibhitFileName: any;
  InwardFolderId: any;
  InwardFileId: any;
  ExhibitsId: any;
  fromuser: any;
  username: string;
  Role: string;
  roleid: string;
  printdoc: any;
  gotfile: any;
  FilePlanList: any;
  inwards: any;
  InwardTypeList: any;
  WorkflowStageList: any;
  DocumentTypeList: any;
  UsersList: any;
  sensitivitylist: any;
  InwardedDocumentId: any;
  codestatus: any;
  selecteddeptuserlist: any;
  GUID: string;

  constructor(private router:Router,private spinner:NgxSpinnerService,private inwardservice:InwardServiceService,
    private workallotmentservice:WorkAllotmentService) {
    if (localStorage.getItem("IsLoggedIn") == "true") {
      this.uid = localStorage.getItem("userId");
      this.username = localStorage.getItem("UserFullName");
      this.Role = localStorage.getItem("role");
      this.roleid = localStorage.getItem("role");
      this.GUID=localStorage.getItem("GUID");
    }
    if (localStorage.getItem("WorkAllotementPreviewData") != null || localStorage.getItem("WorkAllotementPreviewData") != undefined) {
      var data = JSON.parse(localStorage.getItem("WorkAllotementPreviewData"))
      this.WorkPreviewData = data
      this.AssignData();

    }
  }

  ngOnInit(): void {
    this.FilePlan();
    this.InwardType();
    this.WorkflowStage();
    this.GetInwardedDocuments();
    this.DocumentTypes();
    this.GetUsers()
    this.Priority()
  }
  AssignData() {

    this.selecteduserlist = this.WorkPreviewData.ToUsers
    this.selecteddeptuserlist=this.WorkPreviewData.ToUsersWithinDept
    this.filenumber = this.WorkPreviewData.Filecode
    this.wI_Address = this.WorkPreviewData.Subject
    this.wI_InwardFrom = this.WorkPreviewData.RecievedFrom
    this.wI_Date = this.WorkPreviewData.Date
    this.inwarddataid = this.WorkPreviewData.InwardNumber
    this.wI_InwardNo = this.WorkPreviewData.OriginalReferenceNumber
    this.inwarddatainwardnum = this.WorkPreviewData.InwardedDoc
    this.wI_InwardTypeId = this.WorkPreviewData.InwardType
    this.wI_Pages = this.WorkPreviewData.Pages
    this.wI_DocumentType = this.WorkPreviewData.DocumentType
    this.wI_Sensitivity = this.WorkPreviewData.Sensitivity
    this.remarks = this.WorkPreviewData.Remarks
    this.attachmentdetails = this.WorkPreviewData.AttachmentDetails
    this.fromuser = this.WorkPreviewData.FromUser
    this.inwarddataallotmentid = this.WorkPreviewData.MailId
    this.selectedworkflowstage = +(this.WorkPreviewData.Status)
    this.ExhibhitFileName = this.WorkPreviewData.ProcessType
    this.ExhibitsId = this.WorkPreviewData.ExhibitId
    this.InwardFolderId = this.WorkPreviewData.InwardFolderId
    this.InwardFileId = this.WorkPreviewData.InwardFileId,
    this.InwardedDocumentId=this.WorkPreviewData.InwardedDocumentId,
    this.codestatus=this.WorkPreviewData.codestatus

  }
  sndpdftoprint(){
    var data = document.getElementById('print-section');
    this.spinner.show();
    html2canvas(data).then(canvas => {
      var imgWidth = 100;
      var imgHeight = canvas.height * imgWidth / canvas.width;
      const contentDataURL = canvas.toDataURL('image/jpg')
      var data45 = {
        htmlString: contentDataURL,
        FormName:this.WorkPreviewData.formTitle

      }
      this.inwardservice.DownloadInwardForm(data45).subscribe((data1: any) => {
        var aa = data1
        this.printdoc=data1
        var data45 = {
          Pdffile:  this.printdoc,
          File: this.printdoc,
        }
        this.spinner.show();
    
        this.inwardservice.PrintInwardForm(data45).subscribe((data1: any) => {
          var aa = data1
          this.gotfile=data1
          this.spinner.hide();
    
          var bs64data = this.gotfile.split(',')[1];
          var blnk = this.base64ToBlob(bs64data);
          const blobUrl = URL.createObjectURL(blnk);
      
          const iframe = document.createElement('iframe');
          iframe.style.display = 'none';
          iframe.src = blobUrl;
          document.body.appendChild(iframe);
          iframe.contentWindow.print();
    
        })

      })
    });

   
      
 }
 DownloadForm() {
   
  var data = document.getElementById('print-section');
  this.spinner.show();
  html2canvas(data).then(canvas => {
    var imgWidth = 100;
    var imgHeight = canvas.height * imgWidth / canvas.width;
    const contentDataURL = canvas.toDataURL('image/jpg')
    var data45 = {
      htmlString: contentDataURL,
      FormName:this.WorkPreviewData.formTitle
    }
    this.inwardservice.DownloadInwardForm(data45).subscribe((data1: any) => {
      var aa = data1
      this.printdoc=data1
   
      window.open(data1);

   
      this.spinner.hide();

    })
  });
}
 base64ToBlob(base64) {
  const binaryString = window.atob(base64);
  const len = binaryString.length;
  const bytes = new Uint8Array(len);
  for (let i = 0; i < len; ++i) {
    bytes[i] = binaryString.charCodeAt(i);
  }
  return new Blob([bytes], { type: 'application/pdf' });
};
goBack(){
  this.router.navigate(['workallotment/']);
}
FilePlan() {
  this.spinner.show();
  this.inwardservice.GetFilePlanList(this.GUID).subscribe((Data: any) => {
    this.FilePlanList = Data;
    this.spinner.hide();
  })
}
GetFileName(id){
  for (let i = 0; i < this.FilePlanList.length; i++) {
    if (id == this.FilePlanList[i].fileUniqueId) {
      return this.FilePlanList[i].filename
    }
  }
}
GetInwardedDocuments() {
  this.spinner.show();
  this.workallotmentservice.getinwardedDocuments(this.GUID).subscribe((respose: any) => {
    this.inwards = respose;
    this.spinner.hide();
  });
}
GetInwardedDocumentName(id){
  for (let i = 0; i < this.inwards.length; i++) {
    if (id == this.inwards[i].id) {
      return this.inwards[i].inwardNumber
    }
  }
}
InwardType() {
  this.spinner.show();
  this.inwardservice.GetInwardTypeList().subscribe((Data: any) => {
    this.InwardTypeList = Data;
    this.spinner.hide();
  })
}
GetInwardTypeName(id){
  for (let index = 0; index < this.InwardTypeList.length; index++) {
    if(id==this.InwardTypeList[index].id){
      return this.InwardTypeList[index].inwardType1
    }
  }
}
WorkflowStage() {
  this.spinner.show();
  this.inwardservice.GetWorkflowStage("Allotment").subscribe((Data: any) => {
    this.WorkflowStageList = Data;
    this.spinner.hide();

  })
}
GetWorkflowStageName(id){
  for (let index = 0; index < this.WorkflowStageList.length; index++) {
    if(id==this.WorkflowStageList[index].id){
      return this.WorkflowStageList[index].workflowStage
    }
  }
}
DocumentTypes() {
  this.spinner.show();
  this.inwardservice.GetDocumentTypeList().subscribe((Data: any) => {
    this.DocumentTypeList = Data;
    this.spinner.hide();

  })
}
GetDocumentTypeName(id){
  for (let index = 0; index < this.DocumentTypeList.length; index++) {
    if(id==this.DocumentTypeList[index].documentTypeID){
      return this.DocumentTypeList[index].documentTypeName
    }
  }
}
GetUsers() {
  this.spinner.show();
  this.workallotmentservice.getUsers(this.uid).subscribe((respose: any) => {
    this.UsersList = respose;
    this.spinner.hide();
  });
}
GetUserName(id){
  for (let index = 0; index < this.UsersList.length; index++) {
    if(id==this.UsersList[index].userId){
      return this.UsersList[index].userName
    }
  }
}
Priority() {
  this.spinner.show();
  this.inwardservice.GetPriority().subscribe((Data: any) => {
    this.sensitivitylist = Data;
    this.spinner.hide();

  })
}
GetPriorityName(id){
  for (let index = 0; index < this.sensitivitylist.length; index++) {
    if(id==this.sensitivitylist[index].id){
      return this.sensitivitylist[index].sensitivityType
    }
  }
}
}
