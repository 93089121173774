import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Client } from 'src/app/ViewModels/Client';

@Injectable({
  providedIn: 'root'
})
export class ClientService {

  readonly url="https://blocdrivesaas.in/blocdrivesaasapi/api/";
  
  constructor(private http: HttpClient) {}
  saveClient(data: Client) {
    return this.http.post(this.url + 'EnterpriseClient/PostSaveClient', data);
  }

  getAllClients(id) {
    return this.http.get(this.url + 'EnterpriseClient/GetAllClients/' + id);
  }

  deleteClient(id: any) {
    return this.http.get(this.url + 'EnterpriseClient/GetDeleteClientData/' + id);
  }

  UpdateClient(data: Client) {
    return this.http.post(this.url + 'EnterpriseClient/PutClient', data);
  }
}
