import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Country } from 'src/app/ViewModels/Country';

@Injectable({
  providedIn: 'root'
})
export class CountryService {

  // readonly url="https://blocdrivesaas.in/blocdrivesaasapi/api/";
  
  readonly url="https://blocdrivesaas.in/blocdrivesaasapi/api/";
  constructor(private http:HttpClient) { }
  saveCountry(data:Country){
    return this.http.post(this.url +"Country/PostSaveCountry",data);
  }

  getAllCountries(){
    return this.http.get(this.url +"Country/GetAllCountries");
  }

  deleteCountry(id: number){
    return this.http.get(this.url +"Country/GetDeleteCountryData/"+ id);
  }

  UpdateCountry(data:Country){
    return this.http.post(this.url +"Country/PutCountry",data);
  }
}
