import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MailService } from 'src/app/Shared/Mail/mail.service';
import { MessageService1 } from 'src/app/Shared/MessageService/message.service';

@Component({
  selector: 'app-extcomposemail',
  templateUrl: './extcomposemail.component.html',
  styleUrls: ['./extcomposemail.component.css']
})
export class ExtcomposemailComponent implements OnInit {
  frmDataa: FormData;
  uid: any;
  selectedFriend: any[]=[];
  mailsubject: any;
  message: any;
  umail:any;
  usermail: string;
  SelectedFiles: any[]=[];
  email: any;
  serchfind: any;
  errorMessages: string;
  regexp = new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);

  
  constructor(private mailboxservice: MessageService1, private mailservice:MailService, private route:Router, private httpService:HttpClient) { }

  ngOnInit(): void {
    this.uid = localStorage.getItem("userId");
      this.usermail = localStorage.getItem("MailId");
  }

  onItemAdded(value: any) {
    if (value.Email == undefined) {
      this.email = value.value;
    }
    else {
      this.email = value.Email;
    }
    this.serchfind = this.regexp.test(this.email);
    if (!this.serchfind) {
      this.errorMessages = "Please Enter valid Email";
    }
    else {
      this.selectedFriend.push(this.email);
    }
  }

  onItemRemoved(value: any) {
    //this.selecteduserlist.pop(value.display);
    for (var i = 0; i < this.selectedFriend.length; i++) {
      if (this.selectedFriend[i].Email == value.display) {
        var index = this.selectedFriend.indexOf(value);
        this.selectedFriend.splice(index, 1);
      }
    }
    this.errorMessages = "";
  }

  sendmail(){

    var dateFormat;
    var d = new Date();
          var monthD = ("0" + (d.getMonth() + 1)).slice(-2);
     var dateD = ("0" + d.getDate()).slice(-2);
    var ampm = (d.getHours() >= 12) ? "PM" : "AM";
    dateFormat = monthD+"-"+dateD+"-"+d.getFullYear()+" "+d.getHours()+":"+d.getMinutes()+":"+d.getSeconds()+" "+ampm;   
    if (this.frmDataa == null) {
      this.frmDataa = new FormData();
    }
    var toEmails = this.selectedFriend.join(",");
    this.frmDataa.append("Subject", this.mailsubject);
    this.frmDataa.append("Mail_body", this.message);
    this.frmDataa.append("ToAddress", toEmails);
    this.frmDataa.append("SenderEmail", this.usermail);
    this.frmDataa.append("MailType", "ComposedMail");
    this.frmDataa.append("DateFormat",dateFormat );
    this.httpService.post("https://blocdrivesaas.in/blocdrivesaasapi/api/GoogleExtension/PostMyDocs", this.frmDataa).subscribe(
      data => {
        this.selectedFriend= [];
        alert("Mail Sent Successfully");
        this.mailsubject = "";
        this.message = "";
      },
      (err: HttpErrorResponse) => {
        console.log(err.message);
      });
  }

  onChangeDocumnets(event: any) {
    this.frmDataa = new FormData();
    let files = [].slice.call(event.target.files);
    for (var i = 0; i < files.length; i++) {
      this.SelectedFiles.push(files[i]);
      this.frmDataa.append("fileUpload", files[i]);
    }
  }

  inbox() {
    this.route.navigate(['extinboxmails']);
  }
  composemail() {
    this.route.navigate(['composeextmsg']);
  }
  deleteitems() {
    this.route.navigate(['delextmails']);
  }
  sentitems() {
    this.route.navigate(['sentextmails']);
  }
  archiveitems() {
    this.route.navigate(['extarchive']);
  }

}
