import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class WorkFlowStateMasterService {
  readonly _urls: string = 'https://blocdrivesaas.in/blocdrivesaasapi'

  constructor(private http:HttpClient) { }
  saveworkflowstate(workflowstate) {
    return this.http.post(this._urls + "/api/Workflowstate/PostSaveWorkflowstate", workflowstate);
  }
  getworkflowstatedata(id) {
    return this.http.get(this._urls + "/api/Workflowstate/GetWorkflowStates/" + id);
  } 
 
  getworkflowstates(id) {
    return this.http.get(this._urls + "/api/Workflowstate/GetAllWorkflowstate/" + id);
  } 

  UpdateworkflowstateRow(id){
    return this.http.post(this._urls + "/api/Workflowstate/PostPutWorkflowstate" , id);
  }

  deleteworkflowstatedata(id:number){
    return this.http.delete(this._urls +"/api/Workflowstate/DeleteWorkflowstateData/"+ id);
  }
}
