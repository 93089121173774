import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SuperadmindashboardService {

  readonly url="https://blocdrivesaas.in/blocdrivesaasapi/api/";
  constructor(private http:HttpClient) { }
  getuserlogindetails(){
    return this.http.get(this.url + 'EnterPrisePublicPrivateKeys/GetAllUserSessionDetails/');
  }
  getenterpriseuserdetails(){
    return this.http.get(this.url + 'EnterPrisePublicPrivateKeys/GetEnterpriseuserDetails/');
  }
  getindividualuserdetails(){
    return this.http.get(this.url + 'EnterPrisePublicPrivateKeys/GetIndividualuserDetails/');
  }
  getalluesrsdetails(){
    return this.http.get(this.url + 'EnterPrisePublicPrivateKeys/GetAllregistereduserDetails/');
  }
  delete(id) {
    return this.http.get(this.url + 'EnterPrisePublicPrivateKeys/delete/' + id);
  }
  getentdetails(enterPriseId){
    return this.http.get(this.url + 'EnterPrisePublicPrivateKeys/GetEnterprisesubDetails/' +enterPriseId);
  }

}
