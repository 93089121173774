import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FileviewVM } from 'src/app/ViewModels/E_Sign';

@Injectable({
  providedIn: 'root'
})

export class DocumentViewerService {


  
    constructor(private http:HttpClient) { }
    readonly  url="https://blocdrivesaas.in/blocdrivesaasapi/api/";
    getdocforpdfview(data: FileviewVM) {
      return this.http.post(this.url + "Documentview/GetdocforView", data);
    }
  
    getdocformobileesign(data: FileviewVM) {
      return this.http.post(this.url + "MobileEsign/GetdocforView", data);
    }
    storecord1(data, fn) {
      var ci = data;
      var c = JSON.parse(ci);
      var d = localStorage.getItem("recipients");
      var uidd = localStorage.getItem("userId");
      var iiid = localStorage.getItem("iid");
      var blid =  localStorage.getItem("blockId");
      var  pdf = localStorage.getItem("PdfFile");
          var  pdf = localStorage.getItem("PdfFile");
          var  GUID = localStorage.getItem("GUID");
      const coordobject = {
        left: c,
        top: blid,
        touser: d,
        uid: uidd,
        iid: iiid,
        pdfid: pdf,
        filename: fn,
        GUID:GUID,
        ofname: localStorage.originafile
      }
      console.log(coordobject);
      //localStorage.removeItem('neleft');
      return this.http.post(this.url + "Esign/pdfCreationIAR", coordobject);
    }
  
    getsignature(id) {
      return this.http.post(this.url + 'Esign/PostActiveSignature', id);
    }
    getsignature1(id) {
      return this.http.post(this.url + 'Esign/PostActiveSignatureinitial', id);
    }
  
    SendEsignMail(data){
      return this.http.post(this.url + "Recipients/PostESignDetails", data);
    }
  
    getusersignaturebuttons(data) {
      return this.http.post(this.url + "Recipients/getEsignbuttons/", data);
    }
  
    savesigneddata(data){
      return this.http.post(this.url + "Recipients/PostrecpESignDetails", data);
    }
  
  }
