import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { DepartmentService } from 'src/app/Shared/Department/department.service';
import { DesignationService } from 'src/app/Shared/Designation/designation.service';
import { RoleService } from 'src/app/Shared/Role/role.service';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { UserDetails } from 'src/app/ViewModels/UserDetails';
import { UserDetailsService } from 'src/app/Shared/UserDetails/user-details.service';
import { EmailuserValidation, EmailValidation } from 'src/app/ViewModels/EmailValidation';
import { EnterPriseRegisterService } from 'src/app/Shared/EnterPriseRegister/enter-prise-register.service';
import * as $ from 'jquery';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { MinorDepartmentService } from 'src/app/Shared/MinorDepartment/minor-department.service';
import { AuditorTransactionService } from 'src/app/Shared/auditortransaction/auditor-transaction.service';
import { BranchService } from 'src/app/Shared/BranchMaster/branch.service';
import { Router } from '@angular/router';
import { UserlocationService } from 'src/app/Shared/UserLocationMaster/userlocation.service';
import { GroupService } from 'src/app/Shared/Group/group.service';

@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.css']
})
export class UserComponent implements OnInit {
  DepartmentList: any = [];
  DesignationList: any = [];
  RoleList: any = [];
  closeResult: any;
  editfullname: any;
  editmail: any;
  edituserid: any;
  createdon: any;
  createdby: any;
  DeleteName: any;
  deleteid: any;
  fullname: any;
  email: any;
  selectedrole: any;
  selecteddesignation: any;
  selecteddepartment: any;
  location: any;
  public dismissalert = false;
  notify: string;
  UserList: any;
  editrole: any;
  editdesignation: any;
  editdepartment: any;
  editlocation: any;
  editemail: any;
  p: number = 1;
  customerid: any;
  pass: any;
  validateemail: any;
  mailresult: any;
  validmail: boolean = false;
  validateusername: any;
  usernameresult: any;
  validusername: boolean;
  uid: string;
  roleid: string;
  mobileview: boolean;
  AllDepartmentList: any;
  AllDesignationList: any;
  AllRights = [];
  selectedItems = [];
  dropdownSettings: IDropdownSettings;
  userrights: any;
  Website: any;
  GUID: string;
  majordeptid: any;
  MajorGroupinMinorTblList: any;
  SelectedMinorDept: any;
  LevelList: any;
  MinorDepartmentList: any;
  EnterPriseId: string;
  BranchList: any;
  Branch: any;
  UserLocationList: any;
  UserLocationData: any;
  validateusername1: any;
  fullnameemail: string;
  Website2: any;
  Website3: any;
  Website1: any;
  GroupList: any;
  Group: any;
  editgroup: any;
  userstaus: string;
  enterpriseacc: boolean;
  userdet: string;
  userfullname: string;
  newid: string;
  constructor(private spinner: NgxSpinnerService,  private BranchService: BranchService,  
    private departmentService: DepartmentService,private UserLocationService: UserlocationService, private designationService: DesignationService,
    private roleService: RoleService,private auditortransactionservice:AuditorTransactionService
    , private enterpriseservice: EnterPriseRegisterService, private modalService: NgbModal, private userservice: UserDetailsService,
    private minordepartmentservice:MinorDepartmentService,private router: Router,
    private groupservice: GroupService) {
    if (localStorage.getItem("IsLoggedIn") == "true") {
      this.uid = localStorage.getItem("userId");
      this.roleid = localStorage.getItem("role");
      this.GUID=localStorage.getItem("GUID");
      this.EnterPriseId=localStorage.getItem("EnterPriseId");
      this.userfullname = localStorage.getItem("UserFullName");

    }
    this.isMobileMenu();


  }

  ngOnInit(): void {

    this.dropdownSettings = {
      singleSelection: false,
      idField: 'id',
      textField: 'assignRigntType',
      selectAllText: 'Select Rights',
      unSelectAllText: 'Selected Rights',
      itemsShowLimit: 100,
      allowSearchFilter: true,
      searchPlaceholderText: 'Search for Rights',
      noDataAvailablePlaceholderText: 'No data available',
    }
    this.Roles();
    this.Branchs();
    this.UserLocation();
    this.Users();
    this.getAllDepartments();
    this.GetAllDesignations();
    this.GetRightTypes();
    this.GetUserWebsite();
    this.MinorDepartments();
    this.UserGroup();

  }
  isMobileMenu() {
    if ($(window).width() > 991) {
      this.mobileview = false;
    }
    else {
      this.mobileview = true;
    }
  };
  MinorDepartments() {
    this.spinner.show();
    this.newid = this.GUID +","+ this.userfullname;

    this.minordepartmentservice.getAllDepartments(this.newid).subscribe((Data: any) => {
      this.MinorDepartmentList = Data;
      this.spinner.hide();
    });
  }
  Branchs() {
    this.spinner.show();
    this.newid = this.GUID +","+ this.userfullname;

    this.BranchService.getAllBranchs(this.newid).subscribe((Data: any) => {
      this.BranchList = Data;
      this.spinner.hide();
    });
  }
  UserLocation() {
    this.spinner.show();
    this.newid = this.GUID +","+ this.userfullname;

    this.UserLocationService.getAllUserLocation(this.newid ).subscribe((Data: any) => {
      this.UserLocationList = Data;
      this.spinner.hide();
    });
  }
  Designations() {
    this.spinner.show();
    this.newid = this.GUID +","+ this.userfullname;

    this.designationService.getAllDesignations(this.newid).subscribe((Data: any) => {
      this.DesignationList = Data;
      this.spinner.hide();
    });
  }
  Roles() {
    this.spinner.show();
    this.newid = this.GUID +","+ this.userfullname;

    this.roleService.getAllRoles(this.newid).subscribe((Data: any) => {
      this.RoleList = Data;
      this.spinner.hide();
    });
  }

  GetRightTypes() {
    this.spinner.show();
    this.userservice.getRights().subscribe((data: any) => {
      this.AllRights = data;
      this.spinner.hide();
    })
  }
  UserGroup() {
    this.spinner.show();
    this.newid = this.GUID +","+ this.userfullname;

    this.groupservice.getgroup(this.newid).subscribe((Data: any) => {
      this.GroupList = Data;
      this.spinner.hide();
    });
  }
  onItemSelect(item: any) {
    var aa=this.selectedItems;
  
  }
  onSelectAll(items: any) {
    var aa=this.selectedItems;
  }
  onItemDeSelectAll(items: any) {
    var aa=this.selectedItems;
 
  }
  OnselectUserbranch(data:any){
    this.Branch = data;
      }
 OnselectUserLocation(data:any){
   this.UserLocationData = data;
     }
  onItemDeSelect(items) {
    var aa=this.selectedItems;
   
  }
  Onselectgroup(data: any) {
    this.Group = data;
  }
  
  Users() {
    this.spinner.show();
    // this.newid = this.EnterPriseId +","+ this.userfullname;

    this.userservice.getAllUsers(this.GUID).subscribe((Data: any) => {
      this.UserList = Data;
      this.spinner.hide();
    });
  }
  GetUserWebsite() {
    this.spinner.show();
    this.userservice.getUserWebsite(this.GUID).subscribe((Data: any) => {
      var splitwebsite=Data.companyWebsite.split('//');
      this.Website = splitwebsite[1];
      if(this.Website.includes('www'))
      {
        this.Website1 = this.Website.split('.');
        this.Website3 = this.Website1[1] + "." +this.Website1[2];
      }
      else{
        this.Website3 = this.Website;
      }
      this.spinner.hide();
    });
  }
  OnselectUserRole(id) {
    this.selectedrole = id
    this.spinner.show();
    this.newid = this.selectedrole +","+ this.userfullname;

    this.departmentService.getParticularDepart(this.newid).subscribe((Data: any) => {
      this.DepartmentList = Data;
      this.spinner.hide();
    });
  }
  OnselectUserDesignation(id) {
    this.selecteddesignation = id;
  }
  OnselectUserMinorDepartment(id){
    this.SelectedMinorDept=id;
  }
  OnselectUserDepartment(id) {
    this.selecteddepartment = id;
    this.spinner.show();
    this.newid = this.selecteddepartment +","+ this.userfullname;
    this.designationService.getParticularDesignations(this.newid).subscribe((Data: any) => {
      this.DesignationList = Data;
      this.spinner.hide();
    });
    this.spinner.show();
    this.newid = id +","+ this.userfullname;
    this.minordepartmentservice.getMajorGrpinMinorGrpTbl(this.newid).subscribe((Data: any) => {
      this.MajorGroupinMinorTblList = Data;
      this.spinner.hide();
    });
  }
  addUser(adduser) {
    this.DepartmentList=[];
    this.DesignationList=[];
    this.MajorGroupinMinorTblList=[];
    this.modalService.open(adduser).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }
  EditUser(updatedata, updateuser) {
    this.editfullname = updatedata.userName
    this.editemail = updatedata.email
    this.edituserid = updatedata.id
    this.editrole = updatedata.role
    this.editdesignation = updatedata.designation
    this.editdepartment = updatedata.department;
    this.SelectedMinorDept=updatedata.subDepartmentId;
    this.editlocation = updatedata.location
    this.customerid = updatedata.userId;
    this.pass = updatedata.password;
    this.createdon = updatedata.createdOn;
    this.selectedItems=updatedata.userRights;
    this.DepartmentList=this.AllDepartmentList;
    this.DesignationList=this.AllDesignationList;
    this.MajorGroupinMinorTblList=this.MinorDepartmentList;
    this.UserLocationData = updatedata.location;
    this.editgroup = updatedata.groupId;

    this.modalService.open(updateuser).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;

    });
  }
  deleteUser(deletedata, deleteuser) {
    this.DeleteName = deletedata.userName;
    this.deleteid = deletedata.id;
    this.modalService.open(deleteuser).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  
  name(){
    if(this.fullname != null){
      var firstChar = this.fullname.charAt(0);
      var remainingStr = this.fullname.slice(1);
      this.fullname = firstChar.toUpperCase() + remainingStr;
    }
    

  }
         
 
  SaveUser() {
    // if(this.fullname==null || this.fullname==undefined){
    //   alert("Please Enter Valid User Name")
    // }
    // else if(this.validmail == true){
    //   alert("Please Enter valid User Email")
    // }
    // else if(this.validusername == true){
    //   alert("Please Enter valid User Name")
    // }
    // else if(this.selectedrole == null ||this.selectedrole==undefined){
    //   alert("Please Select Role")
    // }
    // else if(this.selecteddesignation == null || this.selecteddesignation==undefined){
    //   alert("Please Select Designation")
    // }
    // else if(this.selecteddepartment == null || this.selecteddepartment==undefined){
    //   alert("Please Select Department")
    // }
    // else  {

      const inputRequest = {
        Id: 0,
        UserId: 0,
        Password: null,
        UserName: this.fullname,
        Email: this.email,
        Role: this.selectedrole,
        Designation: this.selecteddesignation,
        Department: this.selecteddepartment,
        Location: this.UserLocationData,
        CreatedOn: "",
        CurrentUserId: this.GUID,
        UserRights:this.selectedItems,
        SubDepartmentId:this.SelectedMinorDept,
        Branch:this.Branch,
        GroupId: parseInt(this.Group),
        UserFullName :this.userfullname 


      }
      this.spinner.show();
      this.userservice.saveUser(inputRequest).subscribe(
        (data: any) => {
          if (data == "success") {
            this.notify = "User added successfully";
            const audit = {
              TransactionName:"User Creation",
              TransactionType:"Create",
              TransactionDesc:"User Master",
    
              }
              this.auditortransactionservice.AuditorUserDetails(audit);
            this.selectedrole = null;
            this.selecteddesignation = null
            this.selecteddepartment = null;
            this.selectedItems=[]
            this.fullname="";
            this.email="";
            this.UserLocationData=""
            setTimeout(() => this.dismissalert = true, 100);
            setTimeout(function () {
              this.dismissalert = false;
            }.bind(this), 3000);
            this.spinner.hide();
            this.Users();
          } else {
            this.notify = "Something Went Wrong. Try again.!!"
            this.selectedrole = null;
            this.selecteddesignation = null
            this.selecteddepartment = null;
            this.selectedItems=[]
            this.fullname="";
            this.email="";
            this.UserLocationData=""
            setTimeout(() => this.dismissalert = true, 100);
            setTimeout(function () {
              this.dismissalert = false;
            }.bind(this), 3000);
            this.spinner.hide();
            this.Users();
          }
        });
      //}
  }
  // GetRightTypes() {
  //   this.spinner.show();
  //   this.userservice.getRights(this.GUID).subscribe((data: any) => {
  //     this.AllRights = data;
  //     this.spinner.hide();
  //   })
  // }
  GetRights(userid){
    this.spinner.show();
    this.userservice.getuserRights(userid).subscribe((data: any) => {
      this.spinner.hide();
      return  this.userrights = data;
    })
  }
  UpdateUser() {
    if (this.selectedrole == null || this.selectedrole == undefined) {
      this.selectedrole = this.editrole
    }
    if (this.selecteddesignation == null || this.selecteddesignation == undefined) {
      this.selecteddesignation = this.editdesignation
    }
    if (this.selecteddepartment == null || this.selecteddepartment == undefined) {
      this.selecteddepartment = this.editdepartment
    }
    if(this.Branch == undefined){
      this.Branch = "0";
    }
    if(this.selectedrole == undefined){
      this.selectedrole = "0";
    }
    if(this.Group == undefined){
      this.Group = "0";
    }
    const inputRequest1= {
      Id: this.edituserid,
      UserId: this.customerid,
      Password: "1",
      UserName: this.editfullname,
      Email: this.editemail,
      Role: parseInt(this.selectedrole),
      Designation: this.selecteddesignation,
      Department: this.selecteddepartment,
      Location: this.UserLocationData,
      CreatedOn: this.createdon,
      CurrentUserId: this.GUID,
      UserRights:this.selectedItems,
      SubDepartmentId:this.SelectedMinorDept,
      Branch:parseInt(this.Branch),
      GroupId: parseInt(this.Group),
      UserFullName :this.userfullname 
    }
    this.spinner.show();
    this.userservice.UpdateUser(inputRequest1).subscribe(
      (data: any) => {
        if (data == "success") {
          this.notify = "User updated successfully";
          const audit = {
            TransactionName:"User Creation",
            TransactionType:"Update",
            TransactionDesc:"User Master",
  
            }
            this.auditortransactionservice.AuditorUserDetails(audit);
          this.selectedrole = null;
          this.selecteddesignation = null
          this.selecteddepartment = null;
          this.selectedItems=[];
          this.fullname="";
          this.email="";
          this.UserLocationData=""
          setTimeout(() => this.dismissalert = true, 100);
          setTimeout(function () {
            this.dismissalert = false;
          }.bind(this), 3000);
          this.Users();
          this.spinner.hide();
        } else {
          this.notify = "Something Went Wrong. Try again.!!"
          this.selectedrole = null;
          this.selecteddesignation = null
          this.selecteddepartment = null;
          this.selectedItems=[]
          this.fullname="";
          this.email="";
          this.UserLocationData=""
          setTimeout(() => this.dismissalert = true, 100);
          setTimeout(function () {
            this.dismissalert = false;
          }.bind(this), 3000);
          this.Users();
          this.spinner.hide();
        }
      });
  }
  DeleteUserRow() {
    this.spinner.show();
    this.newid = this.deleteid +","+ this.userfullname;
    this.userservice.deleteUser(this.newid).subscribe(
      (data: any) => {
        if (data == "success") {
          this.notify = "User deleted successfully"
          const audit = {
            TransactionName:"User Creation",
            TransactionType:"Delete",
            TransactionDesc:"User Master",
  
            }
            this.auditortransactionservice.AuditorUserDetails(audit);
          setTimeout(() => this.dismissalert = true, 100);
          setTimeout(function () {
            this.dismissalert = false;
          }.bind(this), 3000);
          this.Users();
          this.spinner.hide();

        } else {
          this.notify = "Something Went Wrong. Try again.!!"
          setTimeout(() => this.dismissalert = true, 100);
          setTimeout(function () {
            this.dismissalert = false;
          }.bind(this), 3000);
          this.Users();
          this.spinner.hide();
        }
      });
  }
  closealert() {
    this.dismissalert = false;
  }
  getAllDepartments() {
    this.spinner.show();
    this.newid = this.GUID +","+ this.userfullname;

    this.departmentService.getAllDepartments(this.newid).subscribe((Data: any) => {
      this.AllDepartmentList = Data;
      this.spinner.hide();
    });
  }
  GetDepartment(deptid) {
    for (let i = 0; i < this.AllDepartmentList.length; i++) {
      if (deptid == this.AllDepartmentList[i].id) {
        return this.AllDepartmentList[i].majorDepartmentName;
      }
    }
  }
  GetSubDepartment(id){
    for (let i = 0; i < this.MinorDepartmentList.length; i++) {
      if (id == this.MinorDepartmentList[i].id) {
        return this.MinorDepartmentList[i].minorDepartment;
      }
    }
  }
  GetRole(rid) {
    for (let i = 0; i < this.RoleList.length; i++) {
      if (rid == this.RoleList[i].id) {
        return this.RoleList[i].roleName;
      }
    }
  }
  GetGroup(lid) {
    for (let i = 0; i < this.GroupList.length; i++) {
      if (lid == this.GroupList[i].id) {
        return this.GroupList[i].groupName;
      }
    }
  }
  Getbranch(bid) {
    for (let i = 0; i < this.BranchList.length; i++) {
      if (bid == this.BranchList[i].id) {
        return this.BranchList[i].branchName;
      }
    }
  }
  GetUserLocation(lid) {
    for (let i = 0; i < this.UserLocationList.length; i++) {
      if (lid == this.UserLocationList[i].id) {
        return this.UserLocationList[i].locationName;
      }
    }
  }
  GetAllDesignations() {
    this.spinner.show();
    this.newid = this.GUID +","+ this.userfullname;

    this.designationService.getAllDesignations(this.newid).subscribe((Data: any) => {
      this.AllDesignationList = Data;
      this.spinner.hide();
    });
  }
  GetDesignation(desid) {
    for (let i = 0; i < this.AllDesignationList.length; i++) {
      if (desid == this.AllDesignationList[i].id) {
        return this.AllDesignationList[i].designationName;
      }
    }
  }
  GetMajorGrpinMinorGrpTbl(id){
    this.majordeptid=id;
    this.spinner.show();
    this.newid = id +","+ this.userfullname;

    this.minordepartmentservice.getMajorGrpinMinorGrpTbl(this.newid).subscribe((Data: any) => {
      this.MajorGroupinMinorTblList = Data;
      this.spinner.hide();
    });
  }
  Validatemail() {
    //this.toUpper()
    this.validateemail = this.email;
    this.spinner.show();
    const data: EmailValidation = {
      Email: this.validateemail
    }
    this.enterpriseservice.validateemail(data).subscribe((Data: any) => {
      this.mailresult = Data;
      // if (this.mailresult == "exist") {
      //   this.validmail = true;
      // }
      // else {
      //   this.validmail = false;
      // }
      this.spinner.hide();
    })
  }
  toUpper(){
    const str = this.fullname;
    this.fullname = null;
    this.fullname = str.charAt(0).toUpperCase() + str.slice(1);
    //console.log(str2);
  }
  Validateusername() {
   
    this.validateusername = this.fullname;
    this.validateusername1 = this.fullname;
    this.spinner.show();
    const data: EmailuserValidation = {
      Email: this.validateusername,
      EnterpriseId:this.EnterPriseId,
      UserId:null,
      UserFullName:this.userfullname

    }
    this.enterpriseservice.validateusernamedataforindividual(data).subscribe((Data: any) => {
      this.usernameresult = Data;
      if (this.usernameresult == "exist") {
        localStorage.setItem("validateval", "false")
        this.validusername = true;
      }
      else {
        localStorage.setItem("validateval", "true")
        this.validusername = false;
      //......   const stremail = this.validateusername1;
      //......   this.fullnameemail = stremail.charAt(0).toLowerCase() + stremail.slice(1);
      //......   this.email=this.fullnameemail + "@" + this.Website3;
      const stremail = this.validateusername1;
      //to conbvert all letters to lowercase
      this.fullnameemail = stremail.toLowerCase();
      // this.fullnameemail = stremail.charAt(0).toLowerCase() + stremail.slice(1);
      this.email=this.fullnameemail + "@" + this.Website3;
      //to remove the spaces in text input
      this.email = this.email.replace(/\s+/g, '');
         }
      this.spinner.hide();

    })
    this.toUpper()
  }
  deactiveUser(deactivedata, deactiveuser) {
    this.DeleteName = deactivedata.userName;
    if(deactivedata.isactivateuser == "Active" && deactivedata.role != 1){
      this.userstaus = "deactivate";
      this.userdet = "Active User";
      this.enterpriseacc = false;

    }
    else if(deactivedata.isactivateuser == "Deactivated" && deactivedata.role != 1){
      this.userstaus = "activate";
      this.userdet = "Deactivated User";
      this.enterpriseacc = false;

    }
    else if(deactivedata.isactivateuser == "Active" && deactivedata.role == 1){
      this.enterpriseacc = true;
      this.userdet = "Active User"
    }
    this.deleteid = deactivedata.id;
    this.modalService.open(deactiveuser).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  Deactiveuser() {
    this.spinner.show();
    this.newid = this.deleteid +","+ this.userfullname;

    this.userservice.deactiveuser(this.newid).subscribe(
      (data: any) => {
        if (data != null) {

          if(data == false){
            this.notify = "User Deactivated successfully"

          }
          else 
          this.notify = "User Activated successfully"
         
          setTimeout(() => this.dismissalert = true, 100);
          setTimeout(function () {
            this.dismissalert = false;
          }.bind(this), 3000);
          this.Users();
          this.spinner.hide();

        } else {
          this.notify = "Something Went Wrong. Try again.!!"
          setTimeout(() => this.dismissalert = true, 100);
          setTimeout(function () {
            this.dismissalert = false;
          }.bind(this), 3000);
          this.Users();
          this.spinner.hide();
        }
      });
  }

  GetLevels(level){
    this.LevelList=[];
    this.LevelList=level.userDeptlist.reverse()
  }

  //by me
  nextpage(){
    this.router.navigate(['/addnewusers']);
  }
}
