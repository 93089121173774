import { Component, OnInit } from '@angular/core';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { Department } from 'src/app/ViewModels/Department';
import { DepartmentService } from 'src/app/Shared/Department/department.service';
import { RoleService } from 'src/app/Shared/Role/role.service';
import * as $ from 'jquery';
import { AuditorTransactionService } from 'src/app/Shared/auditortransaction/auditor-transaction.service';

@Component({
  selector: 'app-department',
  templateUrl: './department.component.html',
  styleUrls: ['./department.component.css']
})
export class DepartmentComponent implements OnInit {
  closeResult: any;
  DepartmentName: any;
  departmentname: any;
  DepartmentDesc: any;
  departmentdesc: any;
  public dismissalert = false;
  notify: string;
  DepartmentList: any;
  p: number = 1;
  editdepartmentName: any;
  editdesc: any;
  editdepartmentid: any;
  udepartmentname: any;
  udepartmentdesc: any;
  createdon: any;
  createdby: any;
  DeleteName: any;
  deleteid: any;
  RoleList: any=[];
  selectedrole: any;
  editrole: any;
  uid: string;
  roleid: string;
  mobileview: boolean;
  GUID: string;
  departmentcode: any;
  editdepartmentcode: any;
  userfullname: string;
  newid: string;
  constructor(private modalService: NgbModal,private auditortransactionservice:AuditorTransactionService
    ,private spinner: NgxSpinnerService,private departmentService: DepartmentService,
    private roleService:RoleService) {
    
      if (localStorage.getItem("IsLoggedIn") == "true") {
        this.uid = localStorage.getItem("userId");
        this.roleid = localStorage.getItem("role");
        this.GUID = localStorage.getItem("GUID");
        this.userfullname = localStorage.getItem("UserFullName");
      }
      this.isMobileMenu();

     }
     isMobileMenu() {
      if ($(window).width() > 991) {
         this.mobileview = false;
      }
      else {
        this.mobileview= true;
      }
    };
  
  ngOnInit(): void {
    this.Departments();
    this.Departmentdata();
    this.Roles();
    this.DepartmentName = "";
    this.DepartmentDesc = "";

  }
  Roles(){
    this.spinner.show();
    this.newid = this.GUID +","+ this.userfullname;

    this.roleService.getAllRoles(this.newid).subscribe((Data: any) => {
      this.RoleList = Data;
      this.spinner.hide();
    });
  }
  onSelectedRole(id){
    this.selectedrole=id;
  }
  Departments() {
    this.spinner.show();
    this.newid = this.GUID +","+ this.userfullname;
    this.departmentService.getAllDepartments(this.newid).subscribe((Data: any) => {
      this.DepartmentList = Data;
      this.spinner.hide();
    });
  }
  Departmentdata() {
    this.spinner.show();
    this.departmentService.getAllDepartmentdata(this.roleid).subscribe((Data: any) => {
      this.DepartmentList = Data;
      this.spinner.hide();
    });
  }
  addDepartment(adddepartment) {
    this.modalService.open(adddepartment).result.then(
      (result) => {
        this.closeResult = `Closed with: ${result}`;
      },
      (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      }
    );
  }
  EditDepartment(updatedata, updatedepartment) {
    this.editdepartmentName = updatedata.majorDepartmentName;
    this.editdesc = updatedata.departmentDesc;
    this.editdepartmentid = updatedata.id;
    this.editrole=updatedata.roleId;
    this.createdon = updatedata.createdOn;
    this.createdby = updatedata.createdBy;
    this.editdepartmentcode=updatedata.code
    this.modalService.open(updatedepartment).result.then(
      (result) => {
        this.closeResult = `Closed with: ${result}`;
      },
      (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      }
    );
  }
  deleteDepartment(deletedata, adddepartment) {
    this.DeleteName = deletedata.departmentName;
    this.deleteid = deletedata.id;
    this.modalService.open(adddepartment).result.then(
      (result) => {
        this.closeResult = `Closed with: ${result}`;
      },
      (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      }
    );
  }
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return "by pressing ESC";
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return "by clicking on a backdrop";
    } else {
      return `with: ${reason}`;
    }
  }
  SaveDepartment() {
    const inputRequest: Department = {
      Id: 0,
      MajorDepartmentName: this.departmentname,
      DepartmentDesc: this.departmentdesc,
      CreatedBy: "",
      CreatedOn: "",
      ModifiedBy: "",
      ModifiedOn: "",
      RoleId:this.selectedrole,
      UserId:this.uid,
      GUID:this.GUID,
      Code:this.departmentcode,
      UserFullName :this.userfullname 

    };
    this.spinner.show();
    this.departmentService
      .saveDepartment(inputRequest)
      .subscribe((data: any) => {
        if (data == "success") {
          this.notify = "Department added successfully";
          const audit = {
            TransactionName:"Master",
            TransactionType:"Create",
            TransactionDesc:"Department master",

            }
            this.auditortransactionservice.AuditorTransactionDetails(audit);
          setTimeout(() => (this.dismissalert = true), 100);
          setTimeout(
            function () {
              this.dismissalert = false;
            }.bind(this),
            3000
          );
          this.spinner.hide();
          this.Departments();
        } else {
          this.notify = "Something went wrong!!! Please Try Again.!!";
          setTimeout(() => (this.dismissalert = true), 100);
          setTimeout(
            function () {
              this.dismissalert = false;
            }.bind(this),
            3000
          );
          this.spinner.hide();
          this.Departments();
        }
      });
  }

  UpdateDepartment() {
    if(this.selectedrole==null || this.selectedrole==""){
      this.selectedrole=this.editrole
    }
    const inputRequest1: Department = {
      Id: this.editdepartmentid,
      MajorDepartmentName: this.editdepartmentName,
      DepartmentDesc: this.editdesc,
      CreatedBy: this.createdby,
      CreatedOn: this.createdon,
      ModifiedBy: "",
      ModifiedOn: "",
      RoleId:this.selectedrole,
      UserId:this.uid,
      GUID:this.GUID,
      Code:this.editdepartmentcode,
      UserFullName :this.userfullname 


    };
    this.spinner.show();
    this.departmentService
      .UpdateDepartment(inputRequest1)
      .subscribe((data: any) => {
        if (data == "success") {
          this.notify = "Department updated successfully";
          const audit = {
            TransactionName:"Master",
            TransactionType:"Update",
            TransactionDesc:"Department master",

            }
            this.auditortransactionservice.AuditorTransactionDetails(audit);
          setTimeout(() => (this.dismissalert = true), 100);
          setTimeout(
            function () {
              this.dismissalert = false;
            }.bind(this),
            3000
          );
          this.Departments();
          this.spinner.hide();
        } else {
          this.notify = "Something went wrong!!! Please Try Again.!!";
          setTimeout(() => (this.dismissalert = true), 100);
          setTimeout(
            function () {
              this.dismissalert = false;
            }.bind(this),
            3000
          );
          this.Departments();
          this.spinner.hide();
        }
      });
  }
  DeleteDepartmentRow() {
    this.spinner.show();
    this.newid = this.deleteid +","+ this.userfullname;

    this.departmentService
      .deleteDepartment(this.newid)
      .subscribe((data: any) => {
        if (data == "success") {
          this.notify = "Department deleted successfully";
          const audit = {
            TransactionName:"Master",
            TransactionType:"Delete",
            TransactionDesc:"Department master",

            }
            this.auditortransactionservice.AuditorTransactionDetails(audit);
          setTimeout(() => (this.dismissalert = true), 100);
          setTimeout(
            function () {
              this.dismissalert = false;
            }.bind(this),
            3000
          );
          this.Departments();
          this.spinner.hide();
        } else {
          this.notify = "Something Went Wrong. Try again.!!";
          setTimeout(() => (this.dismissalert = true), 100);
          setTimeout(
            function () {
              this.dismissalert = false;
            }.bind(this),
            3000
          );
          this.Departments();
          this.spinner.hide();
        }
      });
  }
  closealert(){
    this.dismissalert=false;
  }
  GetRole(rid) {
    for (let i = 0; i < this.RoleList.length; i++) {
      if (rid == this.RoleList[i].id) {
        return this.RoleList[i].roleName;
      }
    }
  }
}
