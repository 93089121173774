import { Component, OnInit } from '@angular/core';
import { DatePipe } from '@angular/common';
import { NgxSpinnerService } from 'ngx-spinner';
import { HttpClient } from '@angular/common/http';
import { UserDashboardService } from '../Shared/UserDashboard/user-dashboard.service';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { DownloadFileVM } from '../ViewModels/DownloadFileVM';
import { status } from '../ViewModels/status';
import { DomSanitizer } from '@angular/platform-browser';
import { AdminDashboardService } from '../Shared/AdminDashboard/admin-dashboard.service';
import { comment } from '../ViewModels/comment';
import { Router } from '@angular/router';
import { DocumentsForTask } from '../ViewModels/DocumentsForTask';
import * as $ from 'jquery';

@Component({
  selector: 'app-task-view',
  templateUrl: './task-view.component.html',
  styleUrls: ['./task-view.component.css']
})
export class TaskViewComponent implements OnInit {
  DateTime: Date;
  public dismissalert = false;
  notify: string
  uid: string;
  userrole: string;
  getMoveData: any;
  alldocuments: any;
  closeResult: string;
  downpath: any;
  pdfdata: any;
  pdffile: any;
  worddata: any;
  wordfile: any;
  pptdata: any;
  pptfile: any;
  xlssantizedisplay: any;
  xlsiframedata: string;
  displayxls: any;
  xlsfile: any;
  xlsdata: any;
  imgdata: any;
  imagefile: any;
  TaskCurrentStatus: any;
  taskcommentslist: any;
  comment: any
  taskName: any;
  username: string;
  projectDisplayName: any;
  statusid: any;
  ProjectCurrentStatus: any;
  projectstatus: any;
  roleid: string;
  mobileview: boolean;
  GUID: any;
  projectteammembers: any;
  showteam: boolean = false;
  projectteamname: any;

  constructor(private datepipe: DatePipe, private spinner: NgxSpinnerService, private httpService: HttpClient,
    private userdashboardservice: UserDashboardService, private modalService: NgbModal, private domSanitizer: DomSanitizer,
    private admindashboardservice: AdminDashboardService,private router:Router) {
    this.uid = localStorage.getItem("userId");
    this.username = localStorage.getItem("UserFullName");
    this.userrole = localStorage.getItem("role");
    var getdata = localStorage.getItem("TaskData");
    this.getMoveData = JSON.parse(getdata);
    this.roleid = localStorage.getItem("role");
    this.GUID = localStorage.getItem("GUID");

      this.isMobileMenu();
  }

  ngOnInit(): void {
    this.GetTaskStatus();
    this.GetProjectName();
    this.GetTaskComments();
    this.GetAllDocuments();
    this.GetProjectTeamName();
    this.GetProTeamMembers();
  }
  isMobileMenu() {
    if ($(window).width() > 991) {
       this.mobileview = false;
    }
    else {
      this.mobileview= true;
    }
  };

  onChangeUploadDoc(event: any) {
    this.DateTime = new Date();
    let latest_date = this.datepipe.transform(this.DateTime, 'dd-MM-yyyy hh:mm:ss a');
    let files = [].slice.call(event.target.files);
    const frmData = new FormData();
    if(files.length==0){
      alert("please select atleast one file!")
    }
    else{
 
    for (var i = 0; i < files.length; i++) {
      frmData.append("fileUpload", files[i]);
    }
    frmData.append("ProjectId", this.getMoveData.project.uniqueProjectId)
    frmData.append("TaskId", this.getMoveData.task.id)
    frmData.append("userId", this.uid);
    frmData.append("folderid", '0');
    frmData.append("latestdate", latest_date);
    this.spinner.show();
    this.httpService.post('https://blocdrivesaas.in/blocdrivesaasapi/api/Documents/NewDocumentUploadForTasks/', frmData).subscribe(
      data => {
        if (data == 1) {
          this.notify = "File uploaded successfully"
          setTimeout(() => this.dismissalert = true, 100);
          setTimeout(function () {
            this.dismissalert = false;
          }.bind(this), 3000);
          this.spinner.hide();
          this.GetAllDocuments();
        }
        else {
          this.notify = "Something went wrong";
          this.notify = "Comment added successfully"
          setTimeout(() => this.dismissalert = true, 100);
          setTimeout(function () {
            this.dismissalert = false;
          }.bind(this), 3000);
          this.spinner.hide();
          this.GetAllDocuments();

        }
      });
    }
  }
  closealert() {
    this.dismissalert = false;
  }
  GetAllDocuments() {
    const data:DocumentsForTask={
      ProjectId:this.getMoveData.project.uniqueProjectId,
      TaskId:this.getMoveData.task.id,
      UserId:this.uid
    }
    this.spinner.show();
    this.userdashboardservice.getalldocuments(data).subscribe((respose: any) => {
      this.alldocuments = respose;
      this.spinner.hide();
    });
  }
  GetTaskStatus() {
    this.userdashboardservice.getTaskstatus(this.getMoveData.task.id + ',' + this.GUID).subscribe((respose: any) => {
      this.TaskCurrentStatus = respose;
    });
  }
  addTaskcomment(commentscontent) {
    this.modalService.open(commentscontent).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;

    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }
  GetTaskComments() {
    this.userdashboardservice.getCurrentTaskComments(this.getMoveData.task.id).subscribe((respose: any) => {
      this.taskcommentslist = respose;
    });
  }
  GetProjectTeamName() {
    this.admindashboardservice.getProjectTeamName(this.getMoveData.project.uniqueProjectId).subscribe((respose: any) => {
      this.projectteamname = respose;
      if(this.projectteamname != null){
        this.showteam = true;
      }
    });
  }
  GetProTeamMembers() {
    this.admindashboardservice.getProjectTeamMembers(this.getMoveData.project.uniqueProjectId).subscribe((respose: any) => {
      this.projectteammembers = respose;
    });
  }
  DownloadFile(ret) {
    const inputRequest: DownloadFileVM = {
      Id: null,
      AUID: this.uid,
      FolderId: null,
      FolderName: null,
      BlockID: ret.blockId,
      GUID:this.GUID
    }
    this.spinner.show();
    this.userdashboardservice.downloadFile(inputRequest).subscribe((data: any) => {
      if (data != null) {
        this.downpath = data;
        window.open(this.downpath);
        this.spinner.hide();
      }
      else {
        this.notify = "Something went wrong. Try again.!!"
        setTimeout(() => this.dismissalert = true, 100);
        setTimeout(function () {
          this.dismissalert = false;
        }.bind(this), 3000);
        this.spinner.hide();
      }
    });

  }
   DownloadFileforAssignTask(ret) {
    const inputRequest: DownloadFileVM = {
      Id: null,
      AUID: this.uid,
      FolderId: null,
      FolderName: null,
      BlockID: ret.blockId,
      GUID:this.GUID
    }
    this.spinner.show();
    this.userdashboardservice.downloadFileForAssignTask(inputRequest).subscribe((data: any) => {
      if (data != null) {
        this.downpath = data;
        window.open(this.downpath);
        this.spinner.hide();
      }
      else {
        this.notify = "Something went wrong. Try again.!!"
        setTimeout(() => this.dismissalert = true, 100);
        setTimeout(function () {
          this.dismissalert = false;
        }.bind(this), 3000);
        this.spinner.hide();
      }
    });

  }
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }
  ProjPassdata() {
    //this.ProjectCompletionPercentage = percentage;
    this.userdashboardservice.getProjData(this.getMoveData.task.projectName)
      .subscribe((respose: any) => {
        localStorage.setItem("ProjectData", respose);
       // localStorage.setItem("ProjectList", list);
        localStorage.setItem("ProjectData", JSON.stringify(respose));
        this.router.navigate(['/projectview']);
      });
  }
  Savecomment() {
    const inputRequest: comment = {
      ID: 0,
      Comment: this.comment,
      CommentFor: null,
      CommentedBy: this.username,
      CommentedOn:this.DateTime,
      ProjectID: this.getMoveData.project.uniqueProjectId,
      TaskID: this.getMoveData.task.id,
      GUID: this.GUID
    }
    this.userdashboardservice.saveTaskcomments(inputRequest).subscribe((data: any) => {
      if (data != null) {
        this.notify = "Comment added successfully"
        setTimeout(() => this.dismissalert = true, 100);
        setTimeout(function () {
          this.dismissalert = false;
        }.bind(this), 3000);
        this.spinner.hide();
        this.GetTaskComments();
      }
      else {
        this.notify = "Something went wrong. Try again.!!"
        setTimeout(() => this.dismissalert = true, 100);
        setTimeout(function () {
          this.dismissalert = false;
        }.bind(this), 3000);
        this.spinner.hide();
        this.GetTaskComments();

      }
    });
  }
  GetProjectName() {
    this.userdashboardservice.GetProject(this.getMoveData.task.projectName).subscribe((respose: any) => {
      this.projectDisplayName = respose;
    });
  }
  onSelectstatus(data: any) {
    this.statusid = data
  }
  addstatusdata() {
    const inputRequest: status = {
      ID: 0,
      ProjectID: this.getMoveData.project.uniqueProjectId,
      TaskID: this.getMoveData.task.id,
      ChangedBy: this.username,
      Status: this.statusid,
      GUID:this.GUID
    }
    this.userdashboardservice.savestatustask(inputRequest).subscribe((data: any) => {
      if (data != null) {
        this.notify = "Status updated successfully"
        setTimeout(() => this.dismissalert = true, 100);
        setTimeout(function () {
          this.dismissalert = false;
        }.bind(this), 3000);
        this.spinner.hide();
        this.GetTaskStatus();
      }
      else {
        this.notify = "Something went wrong"
        setTimeout(() => this.dismissalert = true, 100);
        setTimeout(function () {
          this.dismissalert = false;
        }.bind(this), 3000);
        this.spinner.hide();
        this.GetTaskStatus();
      }
    });
  }
  GetProjectStatus() {
    this.admindashboardservice.getProjectstatus(this.getMoveData.project.uniqueProjectId).subscribe((respose: any) => {
      this.ProjectCurrentStatus = respose;
    });
  }
  Getstatus() {
    this.admindashboardservice.getstatus().subscribe((respose: any) => {
      this.projectstatus = respose;
    });
  }
  addTaskStatus(statuscontent) {
    this.modalService.open(statuscontent).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;

    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
    this.Getstatus();
  }
  gotopdfView(file) {
    localStorage.removeItem('BlockId');
    localStorage.removeItem('EmailView');
    localStorage.removeItem('Pdffile');
    localStorage.removeItem('Fid');
    localStorage.removeItem('DocType'); 
    localStorage.removeItem('UploadedDate');
    localStorage.removeItem('FileType');
        localStorage.removeItem('IsAssignTaskDoc');

    console.log(file);
    this.spinner.show();
    localStorage.setItem('Fileview', file.signFile);
    localStorage.setItem('FileNameView', file.documentName);
    localStorage.setItem('BlockId', file.blockId);
    localStorage.setItem('Pdffile', file.pdfFile);
    localStorage.setItem('Fid', file.folderId);
    localStorage.setItem('DocType', file.documentType); 
    localStorage.setItem('UploadedDate', file.date);
    localStorage.setItem('FileType', "false");
    localStorage.setItem('IsAssignTaskDoc', "false");
    this.router.navigate(['fileview/'])
  }
  gotopdfViewForAssignTask(file) {
    localStorage.removeItem('BlockId');
    localStorage.removeItem('EmailView');
    localStorage.removeItem('Pdffile');
    localStorage.removeItem('Fid');
    localStorage.removeItem('DocType'); 
    localStorage.removeItem('UploadedDate');
    localStorage.removeItem('FileType');
      localStorage.removeItem('IsAssignTaskDoc');

    console.log(file);
    this.spinner.show();
    localStorage.setItem('Fileview', file.signFile);
    localStorage.setItem('FileNameView', file.documentName);
    localStorage.setItem('BlockId', file.blockId);
    localStorage.setItem('Pdffile', file.pdfFile);
    localStorage.setItem('Fid', file.folderId);
    localStorage.setItem('DocType', file.documentType); 
    localStorage.setItem('UploadedDate', file.date);
    localStorage.setItem('FileType', "false");
   localStorage.setItem('IsAssignTaskDoc', "true");
    this.router.navigate(['fileview/'])
  }
}
