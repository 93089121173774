import { Component, OnInit } from '@angular/core';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { ProjectChange } from 'src/app/ViewModels/ProjectChange';
import { ProjectChangeService } from 'src/app/Shared/projectchangemaster/project-change.service';
import { ProjectService } from 'src/app/Shared/Project/project.service';
import { DatePipe } from '@angular/common';
import * as $ from 'jquery';
import { AuditorTransactionService } from 'src/app/Shared/auditortransaction/auditor-transaction.service';

@Component({
  selector: 'app-project-change',
  templateUrl: './project-change.component.html',
  styleUrls: ['./project-change.component.css']
})
export class ProjectChangeComponent implements OnInit {
  closeResult: any;
  ID: any;
  ProjectId: any;
  ChangeRequestName: any;
  Description: any;
  EffectiveFrom: any;
  IsEnabled: any;
  projectId: any;
  changeRequestName: any;
  description: any;
  effectiveFrom: any;
  public dismissalert = false;
  notify: string;
  ChangeList: any;
  p: number = 1;
  editchangeRequestName: any;
  editid: any;
  uchangename: any;
  uchangedesc: any;
  createdon: any;
  createdby: any;
  DeleteName: any;
  deleteid: any;
  editprojectId: any;
  editdescription: any;
  editeffectiveFrom: any;
  ProjectList: any;
  converttostring: any;
  converteddate: any;
  uid: string;
  username: string;
  editcreatedby: any;
  roleid: string;
  mobileview: boolean;
  GUID: string;

  constructor(private modalService: NgbModal,private auditortransactionservice:AuditorTransactionService
    ,
    private spinner: NgxSpinnerService,
    private changeService: ProjectChangeService,private projectservice:ProjectService,private datePipe:DatePipe) { 
      if (localStorage.getItem("IsLoggedIn") == "true") {
        this.uid = localStorage.getItem("userId");
        this.username = localStorage.getItem("UserFullName");
        this.roleid = localStorage.getItem("role");
        this.GUID = localStorage.getItem("GUID");

      }
      this.isMobileMenu();
    }
    isMobileMenu() {
      if ($(window).width() > 991) {
         this.mobileview = false;
      }
      else {
        this.mobileview= true;
      }
    };
  
  ngOnInit(): void {
    this.Changes();
    this.Changesdata();
    this.ProjectId = '';
    this.ChangeRequestName = '';
    this.Description = '';
    this.EffectiveFrom = '';
    this.Projects();
  }
  Changes() {
    // this.spinner.show();
    this.changeService.getAllChanges(this.GUID).subscribe((Data: any) => {
      this.ChangeList = Data;
      this.spinner.hide();
    });
  }
  Changesdata() {
    // this.spinner.show();
    this.changeService.getAllChangesdata(this.roleid).subscribe((Data: any) => {
      this.ChangeList = Data;
      this.spinner.hide();
    });
  }
  Projects() {
    this.spinner.show();
    this.projectservice.getAllProjects(this.GUID).subscribe((Data: any) => {
      this.ProjectList = Data;
      this.spinner.hide();

    })
  }
  GetProjectName(id){
    for (let i = 0; i <  this.ProjectList.length; i++) {
    if(id==this.ProjectList[i].uniqueProjectId){
      return this.ProjectList[i].projectName;
    }
      
    }
  }
  addChange(addchange) {
    this.modalService.open(addchange).result.then(
      (result) => {
        this.closeResult = `Closed with: ${result}`;
      },
      (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      }
    );
  }
  EditChange(updatedata, updatechange) {
    this.editprojectId = updatedata.projectId;
    this.editchangeRequestName = updatedata.changeRequestName;
    this.editdescription = updatedata.description;
    this.editeffectiveFrom = updatedata.effectiveFrom;
    this.converttostring = this.datePipe.transform(this.editeffectiveFrom, 'yyyy-MM-dd');
    this.converteddate = this.converttostring.toString();
    this.editid=updatedata.id;
    this.editcreatedby=updatedata.createdBy
    this.modalService.open(updatechange).result.then(
      (result) => {
        this.closeResult = `Closed with: ${result}`;
      },
      (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      }
    );
  }
  deleteChange(deletedata, addchange) {
    this.DeleteName = deletedata.changeRequestName;
    this.deleteid = deletedata.id;
    this.modalService.open(addchange).result.then(
      (result) => {
        this.closeResult = `Closed with: ${result}`;
      },
      (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      }
    );
  }
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }
  SaveChange() {
    const inputRequest: ProjectChange = {
      ID: 0,
      ProjectId: this.projectId,
      ChangeRequestName: this.changeRequestName,
      Description: this.description,
      EffectiveFrom: this.effectiveFrom,
      IsEnabled:true,
      UserId:this.uid,
      CreatedBy:"",
      GUID:this.GUID
    };
    // this.spinner.show();
    this.changeService.saveChange(inputRequest).subscribe((data: any) => {
      if (data == 'success') {
        this.notify = 'Change added successfully';
        const audit = {
          TransactionName:"Master",
          TransactionType:"Create",
          TransactionDesc:"Project Change master",

          }
          this.auditortransactionservice.AuditorTransactionDetails(audit);
        this.projectId =null;
        setTimeout(() => (this.dismissalert = true), 100);
        setTimeout(
          function () {
            this.dismissalert = false;
          }.bind(this),
          3000
        );
        this.spinner.hide();
        this.Changes();
      } else {
        this.notify = 'Something Went Wrong. Try again.!!';
        this.projectId =null;
        setTimeout(() => (this.dismissalert = true), 100);
        setTimeout(
          function () {
            this.dismissalert = false;
          }.bind(this),
          3000
        );
        this.spinner.hide();
        this.Changes();
      }
    });
  }

  UpdateChange() {
    if (this.projectId == null || this.projectId == undefined) {
      this.projectId = this.editprojectId
    }
    const inputRequest1: ProjectChange = {
      ID: this.editid,
      ProjectId: this.projectId,
      ChangeRequestName: this.editchangeRequestName,
      Description: this.editdescription,
      EffectiveFrom: this.converteddate,
      IsEnabled: true,
      UserId:this.uid,
      CreatedBy: this.editcreatedby,
      GUID:this.GUID

    };
    // this.spinner.show();
    this.changeService.UpdateChange(inputRequest1).subscribe((data: any) => {
      if (data == 'success') {
        this.notify = 'Change updated successfully';
        const audit = {
          TransactionName:"Master",
          TransactionType:"Update",
          TransactionDesc:"Project Change master",

          }
          this.auditortransactionservice.AuditorTransactionDetails(audit);
        this.projectId =null;
        setTimeout(() => (this.dismissalert = true), 100);
        setTimeout(
          function () {
            this.dismissalert = false;
          }.bind(this),
          3000
        );
        this.Changes();
        this.spinner.hide();
      } else {
        this.notify = 'Something Went Wrong. Try again.!!';
        this.projectId =null;
        setTimeout(() => (this.dismissalert = true), 100);
        setTimeout(
          function () {
            this.dismissalert = false;
          }.bind(this),
          3000
        );
        this.Changes();
        this.spinner.hide();
      }
    });
  }
  DeleteChangeRow() {
    // this.spinner.show();
      var res=this.deleteid +"," + this.GUID
    this.changeService.deleteChange(this.deleteid).subscribe((data: any) => {
      if (data == 'success') {
        this.notify = 'Folder deleted successfully';
        const audit = {
          TransactionName:"Master",
          TransactionType:"Delete",
          TransactionDesc:"Project Change master",

          }
          this.auditortransactionservice.AuditorTransactionDetails(audit);
        setTimeout(() => (this.dismissalert = true), 100);
        setTimeout(
          function () {
            this.dismissalert = false;
          }.bind(this),
          3000
        );
        this.Changes();
        this.spinner.hide();
      } else {
        this.notify = 'Something Went Wrong. Try again.!!';
        setTimeout(() => (this.dismissalert = true), 100);
        setTimeout(
          function () {
            this.dismissalert = false;
          }.bind(this),
          3000
        );
        this.Changes();
        this.spinner.hide();
      }
    });
  }
  closealert(){
    this.dismissalert=false;
  }
  onSelectedProject(id) {
    this.projectId = id;
  }
}
