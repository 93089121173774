import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { GroupVM } from 'src/app/ViewModels/group';

@Injectable({
  providedIn: 'root'
})
export class GroupService {
  readonly url="https://blocdrivesaas.in/blocdrivesaasapi";
  constructor(private http:HttpClient) { }
  savegroup(data:GroupVM){
    return this.http.post(this.url +"/api/GroupMaster/Postsavegroup",data);
  }

  getgroup(id){
    return this.http.get(this.url +"/api/GroupMaster/Getallgroup/" + id);
  }

  deletegroup(id: any){
    return this.http.get(this.url +"/api/GroupMaster/Deletegroup/"+ id);
  }

  updategroup(data:GroupVM){
    return this.http.post(this.url +"/api/GroupMaster/Updategroup",data);
  }

  getgrpusers(id){
    return this.http.get(this.url +"/api/GroupMaster/Getgrpusers/" + id);

  }
}
