import { Component, ElementRef, OnInit } from '@angular/core';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup, NgForm } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { UserDetailsService } from 'src/app/Shared/UserDetails/user-details.service';
import { FilerequestService } from 'src/app/Shared/FileRequest/filerequest.service';
import { SendRequest } from 'src/app/ViewModels/SendRequest';
import { CloseRequest } from 'src/app/ViewModels/closerequest';
import { WorkFlowService } from 'src/app/Shared/WorkFlow/work-flow.service';
import { Router } from '@angular/router';
import { MessageService } from 'src/app/Shared/MessageService';
import { DatePipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { TagInputModule } from 'ngx-chips';
import { SendFidUidVM } from 'src/app/ViewModels/SendFidUidVM';
import { PushData } from 'src/app/ViewModels/PushData';
import { RemovePersonEditRequest } from 'src/app/ViewModels/RemovePersonEditRequest';
import { SendRequestForExistingFile } from 'src/app/ViewModels/SendRequestForExistingFile';
import { RemoveClosedMultiRequest, RemoveClosedRequest } from 'src/app/ViewModels/RemoveClosedMultiRequest';
import { UpdateFileRequestVM } from 'src/app/ViewModels/UpdateFileRequestVM';
import { CloseMultiRequest } from 'src/app/ViewModels/CloseMultiRequest';
import { Search } from 'src/app/ViewModels/Search';
import { UserLogin } from 'src/app/ViewModels/UserLogin';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { AuditorTransactionService } from 'src/app/Shared/auditortransaction/auditor-transaction.service';

@Component({
  selector: 'app-file-requests',
  templateUrl: './file-requests.component.html',
  styleUrls: ['./file-requests.component.scss']
})
export class FileRequestsComponent implements OnInit { 
    Requsetingfiles: any;
    FolderName: any;
    requestedfoldername: any;
    dropdownSettings = {};
    tempArr: any = [];
    alluserss: any;
    requiredalluserss: any;
    uid: string;
    unregisteredmails: string;
    optionalmsg: string;
    UsersList: any;
    emailid: any;
    Folders: any;
    Email: any;
    data: { AliasUserId: string; Email: string; };
    temp: any = [];
    selectedItems: any = [];
    count: any = 0;
    dropdownList = [];
    settings = {};
    SendFileFolderDetails: FormGroup;
    submitted = false;
    registerForm: any;
    OpenedFiles: any;
    ClosedFiles: any
    requestingfor: any;
    folderid: any;
    FilereqId: any;
    optionalmessage: any;
    tousermailid: any;
    touserid: any;
    openrequest: any;
    OpenReturnDetails1: any;
    OpenReturnDetails: any = [];
    ClosedReturnDetails1: any;
    ClosedReturnDetails: any = [];
    ClosedResult: any;
    CloseButton: boolean;
    OpenedResult: any;
    FileRequestedId: any;
    fileRequestedid: any;
    createdon: any;
    uname: string;
    isLoggedIn: boolean;
    public dismissalert = false;
    notify: string
    OnClickCheckboxvalue: any;
    marked: any = true;
    theCheckbox = true;
    Checkboxmarked: any;
    checkboxvalue: any;
    valuesss: any;
    public selecteduserlist: any = [];
    public NewArrr: any = [];
    public NewlyAddedUsersFromEdit: any = [];
    NoFileRequests: boolean;
    SearchResult: any; search: any;
    SearchEnabled: boolean;
    Searchresults1: any;
    Searchresults: any = [];
    searchfiles: boolean;
    TotalData: any;
    DataUsed: any;
    percentage: number;
    imagetrue: boolean;
    UserImage: string;
    remainingtime: number;
    showper: boolean;
    startTime: any; endTime: any;
    timePassed: number;
    DummyResponse: any;
    cupage: boolean;
    Creatingmsg: string;
    createdmsg: string;
    generalprogressbar: boolean;
    folderCreating: boolean;
    foldercreated: boolean;
    public n: any = 20;
    num: any; num1: any;
    errormsg: boolean;
    showunlimited: boolean;
    TotalSpace: any;
    selectedTab: any;
    likes: any;
    users: any;
    users1: any;
    private toggleButton: any;
    private sidebarVisible: boolean;
    mobile_menu_visible: any = 0;
    particularusers: any;
    dataa: any;
    DateTime: Date;
    FId: any;
    width = 0;
    width1 = 0;
    width2 = 0;
    width3 = 0;
    width4 = 0;
    regexp = new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
    serchfind: boolean;
    errorMessages: string;
    email: any;
    sendidfromShare: string;
    vapply: any;
    currentpage: string;
    CurrentFiles: any;
    multi: boolean;
    checkenable: boolean;
    selectedSelected: any = [];
    selectedSelectedagain: any = [];
    rename: boolean;
    sfolder: any;
    sfiles: any;
    filelinks: any = [];
    gridvalue: any;
    previewfile: boolean;
    selecteddataName: any;
    selecteddataModified: any;
    selecteddatasize: any;
    selectedfilelevel: any;
    showdelete: boolean;
    FolderNameInHaspit: any;
    showchecked: boolean;
    isnotificationpage: string;
    notificationsresponse: any;
    toSendEmailIDtoMarkUp: string;
    ForEditFileRequestedId: any;
    SearchEnabledone: boolean;
    useremail: string;
    fullname: string;
    dnsIP: any;
    IPAddress: any;
    showBreadcrumb: boolean;
  GUID: string;
  foldernamelist: any;
  roleid: string;
  userName: string;
  pid: string;
  usermail: string;
  newid: string;
  
    constructor(private messageService: MessageService, private filerequestservice: FilerequestService,
      private element: ElementRef,private auditortransactionservice:AuditorTransactionService, private spinner: NgxSpinnerService, private formBuilder: FormBuilder,
      private datepipe: DatePipe, private router: Router,  private http: HttpClient,private userservice: UserDetailsService,) {
  
      if (localStorage.getItem("userDisplayImage") == "null") {
        this.imagetrue = false;
      }
      else {
        this.imagetrue = true;
        this.UserImage = localStorage.getItem("userDisplayImage");
      }
      var ns = document.getElementById("navsidebardisp");
      if (ns != null) {
        ns.style.visibility = "hidden";
      }
      var ns = document.getElementById("sidebardisp");
      if (ns != null) {
        ns.style.visibility = "visible";
      }
      var fp = document.getElementById("footerprivacy");
      if (fp) {
        fp.style.visibility = "hidden";
      }
      if (localStorage.getItem("IsLoggedIn") == "true") {
        this.isLoggedIn = true;
        this.uid = localStorage.getItem("userId");
        this.roleid = localStorage.getItem("role");
        this.userName = localStorage.getItem("UserName");
        this.pid = localStorage.getItem("parentid");
        this.usermail = localStorage.getItem("MailId");
        this.gridvalue = localStorage.getItem("gridvalue");
        this.GUID = localStorage.getItem("GUID");
  
      }
      else{
        this.router.navigate(['signin/']);
      }
      
      this.http.get('https://api.ipify.org?format=json').subscribe(data => {
        this.dnsIP = data['ip'];
      });
  
      this.http.get('https://jsonip.com/').subscribe(data => {
        this.IPAddress = data['ip'];
      });
      TagInputModule.withDefaults({
        tagInput: {
          placeholder: 'Add email address (press return to add multiple)',
          secondaryPlaceholder: 'Add email address (press return to add multiple)',
          // add here other default values for tag-input
        },
  
      });
      this.showper = false;
  
    }
    ngOnInit() {
      this.GetWindowWidrh();
      this.checkSession();
      const navbar: HTMLElement = this.element.nativeElement;
      this.toggleButton = navbar.getElementsByClassName('navbar-toggler')[0];
      this.GetHaspitUsers();
      this.GetUserFolders();
      this.GetOpenRequest();
      this.GetCloseRequest();
      this.GetTotalData();
      this.requiredalluserss = [];
      this.unregisteredmails = "";
      this.optionalmsg = "";
      this.FolderName = "";
      this.Requsetingfiles = "";
      this.requestingfor = "";
      this.searchfiles = false;
      this.cupage = true;
      this.showdelete = false;
      // this.currentpage = "File Requests";
      localStorage.setItem("currentpage", "File Requests")
      this.multi = false;
  
      var a = document.querySelectorAll(".ng2-tag-input");
      for (let i = 0; i < a.length; i++) {
        (<HTMLElement>a[i]).style.borderBottom = 'none';
      }
  
  
      // var b = document.querySelectorAll(".ng2-tag-input__text-input");
      // for (let i = 0; i < b.length; i++) {
      //   (<HTMLElement>b[i]).style.fontFamily = 'DIN Next Rounded LT Pro';
      //    (<HTMLElement>b[i]).style.marginTop = '-3%';
      //    (<HTMLElement>b[i]).style.paddingRight = '12%';
      //    (<HTMLElement>b[i]).style.width = '194%';
      //    (<HTMLElement>b[i]).style.border = 'none';
      //    (<HTMLElement>b[i]).style.cursor = 'text';
  
  
      //   //  (<HTMLElement>b[i]).style.width = '195%';
      //   //  (<HTMLElement>b[i]).style.border = '2px solid #BECBD5';
  
      // }
      this.toSendEmailIDtoMarkUp = localStorage.getItem("useremail");
  
      this.isnotificationpage = localStorage.getItem("IsNotificationsPage");
      if (this.isnotificationpage == "true") {
        this.UpdateAllNotifications();
      }
  
  
      this.SearchEnabledone = false;
      this.GetSearchstatus(); 
    }
    GetPFolderlist(id){
      //this.spinner.show();
      this.userservice.getpfoldernames(id).subscribe((respose: any) => {
       // this.spinner.hide();
        this.foldernamelist = respose;  
        console.log(this.foldernamelist);
      });
    }
    get f() { return this.SendFileFolderDetails.controls; }
    GetSearchstatus() {
      setInterval(() => {
        var dd = this.getSearchEnabled();
        if (dd == "false") {
          this.SearchEnabledone = false;
        }
        else {
          this.SearchEnabledone = true;
        }
        var bb = this.getBreadcrumb();
        if (bb == "true") {
          this.showBreadcrumb = false;
        }
        else {
          this.showBreadcrumb = true;
        }
      }, 1000);
    }
  
    getSearchEnabled() {
      var res = localStorage.getItem("SearchEnabledone");
      return res;
    }
  
    getBreadcrumb() {
      var res = localStorage.getItem("hidebc1");
      return res;
    }
    GetUserEmailID() {
     // //this.spinner.show();
      this.filerequestservice.getuseremailid(this.uid).subscribe((respose: any) => {
        this.emailid = respose;
       //// this.spinner.hide();
      });
    }
  
    UpdateAllNotifications() {
      const inputRequest: SendFidUidVM = {
        Fid: null,
        Uid: this.toSendEmailIDtoMarkUp
      }
     // //this.spinner.show();
      this.filerequestservice.updateallnotications(inputRequest).subscribe((respose: any) => {
        this.notificationsresponse = respose;
        localStorage.setItem('IsNotificationsPage', "false");
       //// this.spinner.hide();
      });
    }
  
    GetHaspitUsers() {
     // //this.spinner.show();
      this.filerequestservice.getHaspitUsers(this.uid).subscribe((respose: any) => {
        this.UsersList = respose; 
        this.count = this.UsersList.length;
        console.log(this.UsersList);
       //// this.spinner.hide();
      });
    }
    OnSelectFolder(id) {
      this.requestedfoldername = id;
      console.log(this.requestedfoldername);
    }
    fileandfoldername(form: NgForm) {
  
      if (form.controls["requestingfor"].value == undefined || form.controls["requestingfor"].value == null) {
        alert("Please enter file to be requested!")
      }
  
      else {
        this.Requsetingfiles = form.controls["requestingfor"].value
        this.FolderName = this.requestedfoldername;
      }
    }
  
    // multiple input filed adding and removing 
    onItemRemoved(value: any) {
  
      for (let ri = 0; ri < this.selecteduserlist.length; ri++) {
        if (value.Email == this.selecteduserlist[ri].Email) {
          const index1 = this.selecteduserlist.indexOf(this.selecteduserlist[ri], 0);
          this.selecteduserlist.splice(index1, 1);
        }
      }
  
  
      this.errorMessages = "";
    }
    onItemRemovedFromEdit(value: any) {
    }
    onItemAdded(value: any) {
      if (value.email == undefined) {
        this.emailid = value.value;
      }
      else {
        this.emailid = value.email;
      }
      this.serchfind = this.regexp.test(this.emailid);
      if (!this.serchfind) {
        this.errorMessages = "Please Enter valid Email";
      }
      this.selecteduserlist.push(value);
    }
    onItemAddedFromEdit(value: any) {
      this.users = [];
      if (value.Email == undefined) {
        this.emailid = value.value;
        const pushData: PushData = {
          AccessType: null,
          FirstLetter: "",
          Owner: false,
          UserImage: null,
          color: "NEW",
          email: this.emailid,
          name: "",
          FromGUID:this.GUID,
          FriendGUID:value.guid
        }
        this.particularusers.unshift(pushData);
        this.selecteduserlist.push(value);
        // this.NewArrr.unshift(value);
        this.NewlyAddedUsersFromEdit.push(pushData);
        this.users = [];
      }
      else {
        this.emailid = value.Email;
        const pushData: PushData = {
          AccessType: null,
          FirstLetter: "",
          Owner: false,
          UserImage: null,
          color: "NEW",
          email: value.Email,
          name: "",
          FromGUID:this.GUID,
          FriendGUID:value.guid
        }
        this.particularusers.unshift(pushData);
        this.selecteduserlist.push(value);
        // this.NewArrr.unshift(value);
        this.NewlyAddedUsersFromEdit.push(pushData);
        this.users = [];
      }
      this.serchfind = this.regexp.test(this.emailid);
      if (!this.serchfind) {
        this.errorMessages = "Please Enter valid Email";
      }
  
    }
    RemovePersonFromEdit(data: any) {
      if (data.color == 'NEW') { //just a code check if it is a newly added user
        const index = this.particularusers.indexOf(data, 0);
        if (index > -1) {
          this.particularusers.splice(index, 1);
          for (let ri = 0; ri < this.selecteduserlist.length; ri++) {
            if (data.email == this.selecteduserlist[ri].Email) {
              const index1 = this.selecteduserlist.indexOf(this.selecteduserlist[ri], 0);
              this.selecteduserlist.splice(index1, 1);
            }
          }
          for (let eri = 0; eri < this.NewlyAddedUsersFromEdit.length; eri++) {
            if (data.email == this.NewlyAddedUsersFromEdit[eri].email) {
              const index2 = this.NewlyAddedUsersFromEdit.indexOf(this.NewlyAddedUsersFromEdit[eri], 0);
              this.NewlyAddedUsersFromEdit.splice(index2, 1);
            }
          }
        }
      }
      else {
        this.searchfiles = false;
        this.ClosedReturnDetails = [];
        this.OpenReturnDetails = [];
        this.DateTime = new Date();
        let latest_date = this.datepipe.transform(this.DateTime, 'dd-MM-yyyy hh:mm:ss a');
        const inp: RemovePersonEditRequest =
        {
          RqId: this.ForEditFileRequestedId,
          date: latest_date,
          ToId: data.email
        }
        this.filerequestservice.removePersonFromEditFilerequest(inp).subscribe((respose: any) => {
          if (respose != null) {
            this.filerequestservice.GetUserForEditFileRequest(this.ForEditFileRequestedId).subscribe((response: any) => {
             //// this.spinner.hide();
              this.particularusers = response;
              if (this.particularusers.length != 0) {
                for (let ri = 0; ri < this.NewlyAddedUsersFromEdit.length; ri++) {
                  this.particularusers.unshift(this.NewlyAddedUsersFromEdit[ri]);
                }
              }
              else {
                for (let ri = 0; ri < this.NewlyAddedUsersFromEdit.length; ri++) {
                  this.particularusers.push(this.NewlyAddedUsersFromEdit[ri]);
                }
              }
              // this.particularusers.unshift(this.selecteduserlist);     
  
            });
            this.searchfiles = false;
            this.GetCloseRequest();
            this.GetOpenRequest();
          }
        });
      }
    }
    // send request to recipient
    SendRequestTo(form: NgForm) {
      this.generalprogressbar = true;
      this.showper = true;
      console.log("form", form);
      if (this.requestingfor == undefined || this.requestingfor == null) {
        alert("Please enter file to be requested!");
      }
  
      else {
        this.Requsetingfiles = this.requestingfor;
        this.FolderName = this.requestedfoldername;
      }
  
      this.ClosedReturnDetails = [];
      this.OpenReturnDetails = [];
      this.requiredalluserss = this.selecteduserlist;
      this.DateTime = new Date();
      let latest_date = this.datepipe.transform(this.DateTime, 'dd-MM-yyyy hh:mm:ss a');
      if (this.requiredalluserss == "" || this.requiredalluserss == null || this.requiredalluserss == undefined) {
        alert("Please select atleast one user!!")
      }
      else {
        const inputRequest: SendRequest = {
          Id: 0,
          FromAccountNo: this.uid,
          RegisteredMailIds: this.requiredalluserss,
          RequestingFor: this.Requsetingfiles,
          OptionalMessage: form.controls["optionalmsg"].value,
          RequestedFolderId: this.FolderName,
          CheckboxMarked: this.marked,
          CreatedOn: latest_date,
          FullName: this.fullname,
          Email: this.useremail,
          ipaddress: this.IPAddress,
          dnsip: this.dnsIP,
          browser: this.getBrowserName(),
          transaction: "Requested File for " + this.Requsetingfiles + "",
          FromGUID:this.GUID
        }
        ////this.spinner.show();
        this.showper = true;
        this.generalprogressbar = true;
        this.folderCreating = true;
        this.getthePercent(this.n);
        this.Creatingmsg = "Sending file request secured with blockchain...";
        this.num = setInterval(() => {
          if (this.n <= 90) {
            this.Creatingmsg = "Sending file request secured with blockchain...";
            this.n = this.n + this.getthePercent(this.n);
            if (this.n == 90) {
              this.n = 90;
              clearInterval(this.num);
            }
          }
        }, 3000);
        console.log(inputRequest);
        this.filerequestservice.sendFileRequest(inputRequest).subscribe(
          (data: any) => {
            this.users = [];
            this.requiredalluserss = [];
            this.selecteduserlist = [];
            this.NewlyAddedUsersFromEdit = [];
            this.requestingfor = "";
            this.FolderName = "";
            this.requestedfoldername = "";
            this.requestedfoldername = null;
            if (data == 1) {
              this.n = 100;
              this.createdmsg = "Sent File requests secured with blockchain!"
              const audit = {
                TransactionName:"File Request",
                TransactionType:"send",
                TransactionDesc:"file request ",
    
                }
                this.auditortransactionservice.AuditorTransactionDetails(audit);
              this.folderCreating = false;
              this.foldercreated = true;
              this.requiredalluserss = [];
              this.selecteduserlist = [];
              this.NewlyAddedUsersFromEdit = [];
              this.optionalmsg = "";
              this.marked = true;
              this.Requsetingfiles = "";
              this.count = 0;
              this.GetOpenRequest();
              this.GetCloseRequest();
              this.requestingfor = "";
              this.likes = "";
              this.optionalmsg = "";
              this.requestedfoldername = "";
              this.requestedfoldername = null;
              setTimeout(function () {
                this.Creatingmsg="";
                this.folderCreating = false;
                this.foldercreated = false;
                this.generalprogressbar = false;
                this.errormsg = false;
                this.marked = true;
                this.showper = false;
                this.n = 10;
              }.bind(this), 3000);
              //this.spinner.hide();
  
            }
            else {
              this.requiredalluserss = [];
              this.selecteduserlist = [];
              this.NewlyAddedUsersFromEdit = [];
              this.marked = true;
              this.notify = "Something went wrong!!! Please Try Again..";
              setTimeout(() => this.dismissalert = true, 100);
              setTimeout(function () {
                this.dismissalert = false;
              }.bind(this), 3000);
             //// this.spinner.hide();
              this.requestingfor = "";
              this.likes = "";
              this.optionalmsg = "";
              this.users = "";
              this.requestedfoldername = "";
              this.requestedfoldername = null;
  
            }
          }
        );
      }
    }
  
    // send request to recipient for existing file request id
    SendRequestToExisitingFile(form: NgForm) {
      this.DateTime = new Date();
      let latest_date = this.datepipe.transform(this.DateTime, 'dd-MM-yyyy hh:mm:ss a');
      this.ClosedReturnDetails = [];
      this.OpenReturnDetails = [];
      this.requiredalluserss = this.selecteduserlist
      if (this.marked == undefined) {
        this.marked = this.checkboxvalue
      }
      if (this.requiredalluserss == "" || this.requiredalluserss == null || this.requiredalluserss == undefined) {
        alert("Please select atleast one user!!");
        this.GetOpenRequest();
      }
      else {
        const inputRequest: SendRequestForExistingFile = {
          Id: 0,
          FromAccountNo: this.uid,
          RegisteredMailIds: this.requiredalluserss,
          RequestingFor: this.requestingfor,
          OptionalMessage: form.controls["optionalmsg"].value,
          RequestedFolderId: this.folderid,
          FileRequestId: this.FileRequestedId,
          OpenRequest: this.openrequest,
          CheckboxMarked: this.marked,
          CreatedOn: latest_date,
          FullName: this.fullname,
          Email: this.useremail,
          ipaddress: this.IPAddress,
          dnsip: this.dnsIP,
          browser: this.getBrowserName(),
          transaction: "Edited File Request for " + this.requestingfor + ""
        }
        ////this.spinner.show();
        this.showper = true;
        this.generalprogressbar = true;
        this.folderCreating = true;
        this.getthePercent(this.n);
        this.Creatingmsg = "Sending file request secured with blockchain...";
        this.num = setInterval(() => {
          if (this.n <= 90) {
            this.Creatingmsg = "Sending file request secured with blockchain...";
            this.n = this.n + this.getthePercent(this.n);
            if (this.n == 90) {
              this.n = 90;
              clearInterval(this.num);
            }
          }
        }, 3000);
  
        this.filerequestservice.sendRequestforExistingFile(inputRequest).subscribe(
          (data: any) => {
  
            if (data == 1) {
              this.n = 100;
              this.createdmsg = "Sent File requests secured with blockchain! "
              const audit = {
                TransactionName:"File Request",
                TransactionType:"send",
                TransactionDesc:"file request ",
    
                }
                this.auditortransactionservice.AuditorTransactionDetails(audit);
              this.folderCreating = false;
              this.foldercreated = true;
  
              this.requiredalluserss = [];
              this.selecteduserlist = [];
              this.optionalmsg = "";
              this.FolderName = "";
              this.Requsetingfiles = "";
              this.requestingfor = "";
              this.count = 0;
              this.tempArr = [];
              this.GetOpenRequest();
              this.GetCloseRequest();
  
              this.likes = "";
              this.users = "";
              this.selecteduserlist = "";
  
              setTimeout(function () {
                this.folderCreating = false;
                this.foldercreated = false;
                this.generalprogressbar = false;
                this.errormsg = false;
                this.showper = false;
                this.Creatingmsg="";
                this.n = 0;
              }.bind(this), 3000);
  
            }
            else {
              this.requiredalluserss = [];
              this.selecteduserlist = [];
              this.notify = "Something went wrong!!! Please Try Again..";
              setTimeout(() => this.dismissalert = true, 100);
              setTimeout(function () {
                this.dismissalert = false;
              }.bind(this), 3000);
             //// this.spinner.hide();
              this.likes = "";
              this.users = "";
              this.selecteduserlist = "";
            }
          }
        );
      }
    }
    GetUserFolders() {
      // //this.spinner.show();
       this.searchfiles = false;
       this.filerequestservice.getuserfolders(this.uid).subscribe((respose: any) => {
         this.Folders = respose;
       //sorts name by alphabetical order
       var resss = this.Folders.sort((a,b) => a.folderName.localeCompare(b.folderName))

       });
     }
    GetOpenRequest() {
      //// //this.spinner.show();
      this.filerequestservice.getopenrequest(this.uid).subscribe((respose: any) => {
        this.OpenedFiles = respose;
        console.log(this.OpenedFiles);
        this.searchfiles = false;
        for (let index = 0; index < this.OpenedFiles.length; index++) {
          this.OpenReturnDetails1 = this.OpenedFiles[index].fileRequestList;
          this.OpenReturnDetails.push(this.OpenReturnDetails1[0]);
        }
        if (this.OpenedFiles.length == 0 && this.ClosedFiles.length == 0) {
          this.NoFileRequests = true;

        }
        else if (this.OpenedFiles.length != 0 && this.ClosedFiles.length == 0) {
          this.NoFileRequests = false;
          console.log(this.OpenedFiles);
          console.log(this.ClosedFiles);
        }
        else if (this.OpenedFiles.length == 0 && this.ClosedFiles.length != 0) {
          this.NoFileRequests = false;
        }
        ////// this.spinner.hide();
      });
    }
    GetCloseRequest() {
      //// //this.spinner.show();
      this.filerequestservice.getcloserequest(this.uid).subscribe((respose: any) => {
        this.ClosedFiles = respose;
        console.log(this.ClosedFiles);
        this.searchfiles = false;
        for (let index = 0; index < this.ClosedFiles.length; index++) {
          if (this.ClosedFiles[index].fileRequestList.length != 0) {
            this.ClosedReturnDetails1 = this.ClosedFiles[index].fileRequestList;
            for (let i = 0; i < this.ClosedReturnDetails1.length; i++) {
              this.ClosedReturnDetails.push(this.ClosedReturnDetails1[i]);
            }
          }
  
        }
        if (this.OpenedFiles.length == 0 && this.ClosedFiles.length == 0) {
          this.NoFileRequests = true;
        }
        else if (this.OpenedFiles.length != 0 && this.ClosedFiles.length == 0) {
          this.NoFileRequests = false;
        }
        else if (this.OpenedFiles.length == 0 && this.ClosedFiles.length != 0) {
          this.NoFileRequests = false;
        }
        ////// this.spinner.hide();
  
      });
    }
    addpeople(data) {
      this.Requsetingfiles = data.RequestingFor,
        this.FolderName = data.RequestedFolderId,
        this.fileRequestedid = data.FileRequestId,
        this.openrequest = data.OpenRequest,
        this.checkboxvalue = data.CheckboxMarked
     // //this.spinner.show();
      this.filerequestservice.GetUserForEditFileRequest(this.fileRequestedid).subscribe((response: any) => {
       //// this.spinner.hide();
        this.particularusers = response;
      });
    }
    reqFiles() {
      this.requestingfor = "";
      this.requestedfoldername = "";
      this.requestedfoldername = null;
  
      var b = document.querySelectorAll(".ng2-tag-input__text-input");
      for (let i = 0; i < b.length; i++) {
        (<HTMLElement>b[i]).style.fontFamily = 'DIN Next Rounded LT Pro';
        //...... (<HTMLElement>b[i]).style.marginTop = '-9%';
        (<HTMLElement>b[i]).style.marginTop = '2%';
        (<HTMLElement>b[i]).style.paddingRight = '12%';
        (<HTMLElement>b[i]).style.marginLeft = '-4%';
        (<HTMLElement>b[i]).style.width = '158%';
        (<HTMLElement>b[i]).style.height = '8px';
        (<HTMLElement>b[i]).style.border = '1px solid #BECBD5';
        (<HTMLElement>b[i]).style.padding = '20px';
        (<HTMLElement>b[i]).style.cursor = 'text';
      //......  (<HTMLElement>b[i]).style.position = 'absolute';
        (<HTMLElement>b[i]).style.borderRadius = '3px';
        (<HTMLElement>b[i]).style.paddingBottom = '3%';
  
      }
    }
  
    EditRequest(data) {
      this.particularusers = [];
      this.selecteduserlist = [];
      this.NewlyAddedUsersFromEdit = [];
  
      var b = document.querySelectorAll(".ng2-tag-input__text-input");
      for (let i = 0; i < b.length; i++) {
        (<HTMLElement>b[i]).style.fontFamily = 'DIN Next Rounded LT Pro';
        (<HTMLElement>b[i]).style.marginTop = '-1%';
        (<HTMLElement>b[i]).style.marginLeft = '1%';
        (<HTMLElement>b[i]).style.paddingRight = '12%';
        (<HTMLElement>b[i]).style.padding = '3px';
        (<HTMLElement>b[i]).style.width = '91%';
        (<HTMLElement>b[i]).style.height = '38px';
        (<HTMLElement>b[i]).style.border = '1px solid #BECBD5';
        (<HTMLElement>b[i]).style.position = 'absolute';
        (<HTMLElement>b[i]).style.borderRadius = '3px';
      }
  
  
  
      this.ForEditFileRequestedId = data.FileRequestId;
  
      this.FilereqId = data.Id;
      this.FileRequestedId = data.FileRequestId;
      this.requestingfor = data.RequestingFor;
      this.folderid = data.RequestedFolderId;
      // this.optionalmsg = data.OptionalMessage;
      this.tousermailid = data.ToAccountMail;
      this.touserid = data.ToAccountNo;
      this.openrequest = data.OpenRequest;
      this.createdon = data.CreatedOn;
      this.Checkboxmarked = data.CheckboxMarked;
  
      if (data.OpenRequest == true) {
        this.CloseButton = true;
      }
      else if (data.OpenRequest == false) {
        this.CloseButton = false;
      }
      this.newid = this.folderid + "," + this.GUID;
      this.filerequestservice.GetFolderName(this.folderid).subscribe((response: any) => {
        this.FolderNameInHaspit = response;
      });
     // //this.spinner.show();
      this.filerequestservice.GetUserForEditFileRequest(this.FileRequestedId).subscribe((response: any) => {
        this.particularusers = response;
       //// this.spinner.hide();
  
      });
    }
  
    GetWindowWidrh() {
      if (window.innerWidth > 780) {
        this.width1 = 1;
        this.width2 = 0;
        this.width3 = 0;
        this.width4 = 0;
      }
      else if (window.innerWidth > 500) {
        this.width1 = 0;
        this.width2 = 1;
        this.width3 = 0;
        this.width4 = 0;
      }
      else if (window.innerWidth <= 500 && window.innerWidth > 335) {
        this.width2 = 0;
        this.width1 = 0;
        this.width3 = 1;
        this.width4 = 0;
      }
      else if (window.innerWidth < 335) {
        this.width3 = 0;
        this.width2 = 0;
        this.width1 = 0;
        this.width4 = 1;
      }
    }
    expandwidht() {
      if (window.innerWidth > 780) {
        this.cupage = false;
        document.getElementById("laptop").style.marginLeft = "17%";
        document.getElementById("laptop").style.width = "100%";
      }
      else if (window.innerWidth > 500) {
        this.cupage = false;
        document.getElementById("tab").style.marginLeft = "10%";
        document.getElementById("tab").style.width = "300%";
      }
      else if (window.innerWidth <= 500 && window.innerWidth > 335) {
        this.cupage = false;
        document.getElementById("bigmobile").style.marginLeft = "16px";
        document.getElementById("bigmobile").style.width = "300px";
      }
      else {
        this.cupage = false;
        document.getElementById("othermobile").style.marginLeft = "0px";
        document.getElementById("othermobile").style.width = "300px";
      }
    }
    RemoveClosedRequest(data) {
      
      this.FilereqId = data.fileRequestId;
      const det: RemoveClosedRequest = {
        FilereqId: this.FilereqId,
        UserId: this.uid,
        FullName: this.fullname,
        Email: this.useremail,
        ipaddress: this.IPAddress,
        dnsip: this.dnsIP,
        browser: this.getBrowserName(),
        transaction: null
      }
  
      this.FId = data.fileRequestId,
        this.requestingfor = data.requestingFor,
        this.folderid = data.requestedFolderId,
        this.optionalmessage = data.optionalMessage,
        this.tousermailid = data.toAccountMail,
        this.touserid = data.toAccountNo,
        this.openrequest = data.openRequest
      this.createdon = data.createdOn
      if (data.OpenRequest == true) {
        this.CloseButton = true;
      }
      else if (data.OpenRequest == false) {
        this.CloseButton = false;
      }
  
      this.showper = true;
      this.generalprogressbar = true;
      this.folderCreating = true;
      this.getPercent(this.n);
      this.Creatingmsg = "Deleting File Request...";
      this.num = setInterval(() => {
        if (this.n <= 90) {
          this.Creatingmsg = "Deleting File Request...";
          this.n = this.n + this.getPercent(this.n);
          if (this.n == 90) {
            this.n = 90;
            clearInterval(this.num);
          }
        }
      }, 3000);
  
  
      //// //this.spinner.show();
      this.filerequestservice.deleteClosedRequest(det).subscribe((response: any) => {
        if (response == "1") {
          this.n = 100;
          this.createdmsg = "Deleted File Request Successfully";
          const audit = {
            TransactionName:"File Request",
            TransactionType:"Delete",
            TransactionDesc:"Deleted file request ",

            }
            this.auditortransactionservice.AuditorTransactionDetails(audit);
          this.folderCreating = false;
          this.foldercreated = true;
          this.ClosedReturnDetails = [];
          this.OpenReturnDetails = [];
          this.GetOpenRequest();
          this.GetCloseRequest();
          this.selectedSelected = [];
          clearInterval(this.num);
          setTimeout(function () {
            this.n=10;
            this.folderCreating = false;
            this.foldercreated = false;
            this.generalprogressbar = false;
            this.errormsg = false;
            this.showper = false;
          }.bind(this), 3000);
        }
        else {
        }
      });
    }
  
    RemoveClosedMultiRequest() {
      let latest_date = this.datepipe.transform(this.DateTime, 'dd-MM-yyyy hh:mm:ss a');
  
      const det: RemoveClosedMultiRequest = {
        MultiRemoveCloseList: this.selectedSelectedagain,
        date: latest_date,
        UserId: this.uid,
        FullName: this.fullname,
        Email: this.useremail,
        ipaddress: this.IPAddress,
        dnsip: this.dnsIP,
        browser: this.getBrowserName(),
        transaction: null
      }
      this.showper = true;
      this.generalprogressbar = true;
      this.folderCreating = true;
      this.getPercent(this.n);
      this.Creatingmsg = "Deleting File Request...";
      this.num = setInterval(() => {
        if (this.n <= 90) {
          this.Creatingmsg = "Deleting File Request...";
          this.n = this.n + this.getPercent(this.n);
          if (this.n == 90) {
            this.n = 90;
            clearInterval(this.num);
          }
        }
      }, 3000);
  
  
      //// //this.spinner.show();
      this.filerequestservice.deleteMultiClosedRequest(det).subscribe((response: any) => {
        if (response == "1") {
          this.n = 100;
          this.createdmsg = "Deleted File Requests Successfully";
          const audit = {
            TransactionName:"File Request",
            TransactionType:"Delete",
            TransactionDesc:"Deleted file request ",

            }
            this.auditortransactionservice.AuditorTransactionDetails(audit);
          this.folderCreating = false;
          this.foldercreated = true;
  
          this.ClosedReturnDetails = [];
          this.OpenReturnDetails = [];
          this.selectedSelected = [];
          this.multi = false;
          this.GetOpenRequest();
          this.GetCloseRequest();
  
          clearInterval(this.num);
          setTimeout(function () {
            this.n=10;
            this.folderCreating = false;
            this.foldercreated = false;
            this.generalprogressbar = false;
            this.errormsg = false;
            this.showper = false;
          }.bind(this), 3000);
  
          //this.spinner.hide();
        }
        else {
         //// this.spinner.hide();
        }
      });
    }
  
  
    OnClickCheckbox(value: any) {
      this.marked = value.target.checked;
    }
    Updatefileandfoldername(form: NgForm) {
      this.DateTime = new Date();
      let latest_date = this.datepipe.transform(this.DateTime, 'dd-MM-yyyy hh:mm:ss a');
      if (this.requestedfoldername == null || this.requestedfoldername == "") {
        this.requestedfoldername = this.folderid
      }
      this.ClosedReturnDetails = [];
      this.OpenReturnDetails = [];
      const inputRequest: UpdateFileRequestVM = {
        Id: this.FilereqId,
        FromAccountNo: this.uid,
        ToAccountNo: this.touserid,
        ToAccountMail: this.tousermailid,
        RequestingFor: form.controls["requestingfor"].value,
        OptionalMessage: this.optionalmessage,
        RequestedFolderId: this.requestedfoldername,
        OpenRequest: this.openrequest,
        FileRequestId: this.FileRequestedId,
        CreatedOn: this.createdon,
        ModifiedOn: latest_date
      }
      ////this.spinner.show();
      this.showper = true;
      this.generalprogressbar = true;
      this.folderCreating = true;
      this.getthePercent(this.n);
      this.Creatingmsg = "Updating ...";
      this.num = setInterval(() => {
        if (this.n <= 90) {
          this.Creatingmsg = "Updating ...";
          this.n = this.n + this.getthePercent(this.n);
          if (this.n == 90) {
            this.n = 90;
            clearInterval(this.num);
          }
        }
      }, 3000);
  
      this.filerequestservice.UpdateFileRequest(inputRequest).subscribe(
        (data: any) => {
          if (data == 1) {
            this.n = 100;
            this.createdmsg = "Updated Successfully!!"
            const audit = {
              TransactionName:"File Request",
              TransactionType:"Update",
              TransactionDesc:"Updated file request ",
  
              }
              this.auditortransactionservice.AuditorTransactionDetails(audit);
            this.folderCreating = false;
            this.foldercreated = true;
            this.requiredalluserss = [];
            this.unregisteredmails = "";
            this.requestingfor = "";
            this.optionalmsg = "";
            this.FolderName = "";
            this.Requsetingfiles = "";
            this.GetOpenRequest();
            this.GetCloseRequest();
            //this.spinner.hide();
            setTimeout(function () {
              this.folderCreating = false;
              this.foldercreated = false;
              this.generalprogressbar = false;
              this.errormsg = false;
              this.n = 0;
            }.bind(this), 3000);
          }
          else {
            this.notify = "Something went wrong!!! Please Try Again...";
            setTimeout(() => this.dismissalert = true, 100);
            setTimeout(function () {
              this.dismissalert = false;
            }.bind(this), 3000);
           //// this.spinner.hide();
          }
        }
      );
    }
    CloseRequest(data: any) {
      this.searchfiles = false;
      this.ClosedReturnDetails = [];
      this.OpenReturnDetails = [];
      this.DateTime = new Date();
      let latest_date = this.datepipe.transform(this.DateTime, 'dd-MM-yyyy hh:mm:ss a');
      this.dataa = data.FileRequestId + "," + latest_date;
      const inp: CloseRequest =
      {
        RqId: data.fileRequestId,
        date: latest_date,
        UserId: this.uid,
        FullName: this.fullname,
        Email: this.useremail,
        ipaddress: this.IPAddress,
        dnsip: this.dnsIP,
        browser: this.getBrowserName(),
        transaction: null
      }
      ////this.spinner.show();
      this.showper = true;
      this.generalprogressbar = true;
      this.folderCreating = true;
      this.getthePercent(this.n);
      this.Creatingmsg = "Closing Request ...";
      this.num = setInterval(() => {
        if (this.n <= 90) {
          this.Creatingmsg = "Closing Request ...";
          this.n = this.n + this.getthePercent(this.n);
          if (this.n == 90) {
            this.n = 90;
            clearInterval(this.num);
          }
        }
      }, 3000);
      this.filerequestservice.closefilerequest(inp).subscribe((respose: any) => {
        if (respose != null) {
          this.n = 100;
          this.createdmsg = "Closed Successfully!!"
          this.folderCreating = false;
          this.foldercreated = true;
          this.requestingfor = "";
          this.searchfiles = false;
          this.selectedTab = 1;
          this.GetCloseRequest();
          this.GetOpenRequest();
          setTimeout(function () {
            this.folderCreating = false;
            this.foldercreated = false;
            this.generalprogressbar = false;
            this.errormsg = false;
            this.showper = false;
            this.n = 0;
          }.bind(this), 3000);
        }
      });
    }
    OpenRequest() {
      this.searchfiles = false;
      this.ClosedReturnDetails = [];
      this.OpenReturnDetails = [];
      this.DateTime = new Date();
      let latest_date = this.datepipe.transform(this.DateTime, 'dd-MM-yyyy hh:mm:ss a');
      this.dataa = this.FileRequestedId + "," + latest_date;
      const inp: CloseRequest =
      {
        RqId: this.FileRequestedId,
        date: latest_date,
        UserId: this.uid,
        FullName: this.fullname,
        Email: this.useremail,
        ipaddress: this.IPAddress,
        dnsip: this.dnsIP,
        browser: this.getBrowserName(),
        transaction: null
      }
      //// //this.spinner.show();
      this.filerequestservice.openfilerequest(inp).subscribe((respose: any) => {
        this.OpenedResult = respose;
        this.searchfiles = false;
        this.GetOpenRequest();
        this.GetCloseRequest();
        this.selectedTab = 0;
       //// this.spinner.hide();
      });
    }
  
  
    CloseMultiRequest() {
      this.searchfiles = false;
      this.ClosedReturnDetails = [];
      this.OpenReturnDetails = [];
      this.DateTime = new Date();
      let latest_date = this.datepipe.transform(this.DateTime, 'dd-MM-yyyy hh:mm:ss a');
      this.dataa = this.FileRequestedId + "," + latest_date;
      const inp: CloseMultiRequest =
      {
        MultiCloseList: this.selectedSelected,
        date: latest_date,
        UserId: this.uid,
        FullName: this.fullname,
        Email: this.useremail,
        ipaddress: this.IPAddress,
        dnsip: this.dnsIP,
        browser: this.getBrowserName(),
        transaction: null
      }
      ////this.spinner.show();
      this.showper = true;
      this.generalprogressbar = true;
      this.folderCreating = true;
      this.getthePercent(this.n);
      this.Creatingmsg = "Closing Request ...";
      this.num = setInterval(() => {
        if (this.n <= 90) {
          this.Creatingmsg = "Closing Request ...";
          this.n = this.n + this.getthePercent(this.n);
          if (this.n == 90) {
            this.n = 90;
            clearInterval(this.num);
          }
        }
      }, 3000);
      this.filerequestservice.closemultifilerequest(inp).subscribe((respose: any) => {
        if (respose != null) {
          this.n = 100;
          this.createdmsg = "Closed Successfully!!"
          this.folderCreating = false;
          this.foldercreated = true;
          this.searchfiles = false;
          this.selectedSelected = [];
          this.multi = false;
          this.GetCloseRequest();
          this.GetOpenRequest();
          this.selectedTab = 1;
          this.requestingfor = "";
          setTimeout(function () {
            this.folderCreating = false;
            this.foldercreated = false;
            this.generalprogressbar = false;
            this.errormsg = false;
            this.showper = false;
            this.n = 0;
          }.bind(this), 3000);
        }
      });
    }
    RouteToPlans() {
      this.router.navigate(['plans/']);
    }
  
    Logout() {
      this.messageService.sendMessage('false');
      this.isLoggedIn = false;
      localStorage.setItem("IsLoggedIn", "false");
      localStorage.removeItem('userToken');
      this.router.navigate(['/']);
    }
  
  
    //SEARCH BAR
    onSearch(searchValue: string) {
      this.searchfiles = true;
      ////this.spinner.show();
      this.search = searchValue;
      if (this.search.length != 0) {
        this.SearchEnabled = true;
      }
      // else if (this.search == "") {
      //   this.SearchEnabled = false;
      //   this.NoFileRequests = false;
      // }
      else {
        this.SearchEnabled = false;
        this.NoFileRequests = false;
      }
      if (this.search.length >= 2) {
        const inputRequest: Search = {
          Value: this.search,
          UserId: this.uid
        }
        ////this.spinner.show();
        this.filerequestservice.GetSearchedFiles(inputRequest).subscribe((data: any) => {
          if (data.response != 0) {
            this.SearchResult = data;
            for (let index = 0; index < this.SearchResult.length; index++) {
              this.Searchresults1 = this.SearchResult[index].FileRequestList;
              this.Searchresults.push(this.Searchresults1[0]);
            }
            //this.spinner.hide();
          }
          else {
            this.notify = "No results found";
            setTimeout(() => this.dismissalert = true, 100);
            setTimeout(function () {
              this.dismissalert = false;
            }.bind(this), 3000);
            this.GetOpenRequest();
            //this.spinner.hide();
          }
        });
      }
    }
  
    Install() {
      this.router.navigate(['/install']);
    }
  
    EditProfile() {
      this.router.navigate(['EditProfile/']);
    }
  
    plans() {
      this.router.navigate(['plans']);
    }
    GetTotalData() {
      this.filerequestservice.GetTotalData(this.uid).subscribe((respose: any) => {
        if (respose != null) {
          this.TotalData = "You have" + " " + respose + " Storage";
          this.TotalSpace = respose;
        }
        else {
          this.TotalData = 0;
        }
        if (respose == "Unlimited") {
          this.showunlimited = true;
        }
        else {
          this.showunlimited = false;
          //this.GetDataUsed();
        }
      });
    }
    // GetDataUsed() {
    //   this.allfilesservice.GetDataUsed(this.uid).subscribe((respose: any) => {
    //     if (respose != null) {
    //       this.DataUsed = respose;
    //     }
    //     else {
    //       this.DataUsed = 0;
    //     }
    //     var utdt = Number(this.DataUsed.slice(3, -2));
    //     var td = Number(this.TotalSpace);
    //     this.percentage = Number((utdt / (td * 1024)) * 100);
  
    //     document.getElementById("progressbar").style.width = this.percentage + "%";
    //     this.TotalSpace = this.TotalSpace + "GB"
    //   });
    // }
  
  
    getthePercent(per) {
      return 10;
    }
  
  
    Close() {
      this.folderCreating = false;
      this.foldercreated = false;
      this.generalprogressbar = false;
      this.errormsg = false;
      this.n = 0;
    }
  
    getPercent(per) {
      return 10;
    }
  
    cancelrequest() {
      this.requestingfor = "";
      this.likes = "";
      this.selecteduserlist = [];
      this.requiredalluserss = [];
      this.NewlyAddedUsersFromEdit = [];
      this.FolderName = "";
      this.optionalmsg = "";
      this.requestedfoldername = "";
      this.GetUserFolders();
      this.users = [];
    }
  
    canceladdpeople() {
      this.optionalmsg = "";
      this.likes = "";
      this.users = "";
      this.selecteduserlist = "";
      this.GetHaspitUsers()
      // this.removeElementsByClass("tag__text inline");
      // this.removeElementsByClass("tag-wrapper ng-star-inserted")
      // this.removeElements("tag-ripple");
      //this.removedata("ng-tns")
    }
    //tag__text inline
    //ng-star-inserted,tag-ripple
  
    // removeElementsByClass(className) {
    //    var elements = document.getElementsByClassName(className);
    //   // var ss = elements[0].setAttribute("class", "demo");
    //   // elements[0].appendChild(ss);
    //   if (elements.length > 0) {
    //     for(let index = 0; index <= elements.length; index++){
    //       elements[0].parentNode.removeChild(elements[0]);
    //       //this.onItemRemoved(elements[0].innerHTML);
    //     }
    //   }
    // }
  
    // removeElements(tagname){
    //   var elements = document.getElementsByTagName(tagname);
    //   if (elements.length > 0) {
    //     for(let index = 0; index <= elements.length; index++){
    //       elements[0].style.background = "white";
    //       this.selecteduserlist = [];
    //     }
    //   }
    // }
  
  
    sidebarOpen() {
      const toggleButton = this.toggleButton;
      const body = document.getElementsByTagName('body')[0];
      setTimeout(function () {
        toggleButton.classList.add('toggled');
      }, 500);
  
      body.classList.add('nav-open');
  
      this.sidebarVisible = true;
    };
    sidebarClose() {
      const body = document.getElementsByTagName('body')[0];
      this.toggleButton.classList.remove('toggled');
      this.sidebarVisible = false;
      body.classList.remove('nav-open');
    };
    sidebarToggle() {
      // const toggleButton = this.toggleButton;
      // const body = document.getElementsByTagName('body')[0];
      var $toggle = document.getElementsByClassName('navbar-toggler')[0];
  
      if (this.sidebarVisible === false) {
        this.sidebarOpen();
      } else {
        this.sidebarClose();
      }
      const body = document.getElementsByTagName('body')[0];
  
      if (this.mobile_menu_visible == 1) {
        // $('html').removeClass('nav-open');
        body.classList.remove('nav-open');
        if ($layer) {
          $layer.remove();
        }
        setTimeout(function () {
          $toggle.classList.remove('toggled');
        }, 400);
  
        this.mobile_menu_visible = 0;
      } else {
        setTimeout(function () {
          $toggle.classList.add('toggled');
        }, 430);
  
        var $layer = document.createElement('div');
        $layer.setAttribute('class', 'close-layer');
  
  
        if (body.querySelectorAll('.main-panel')) {
          document.getElementsByClassName('main-panel')[0].appendChild($layer);
        } else if (body.classList.contains('off-canvas-sidebar')) {
          document.getElementsByClassName('wrapper-full-page')[0].appendChild($layer);
        }
  
        setTimeout(function () {
          $layer.classList.add('visible');
        }, 100);
  
        $layer.onclick = function () { //asign a function
          body.classList.remove('nav-open');
          this.mobile_menu_visible = 0;
          $layer.classList.remove('visible');
          setTimeout(function () {
            $layer.remove();
            $toggle.classList.remove('toggled');
          }, 400);
        }.bind(this);
  
        body.classList.add('nav-open');
        this.mobile_menu_visible = 1;
  
      }
    };
  
  
  
  
    checkSession() {
      let browser = this.getBrowserName();
      let token = localStorage.getItem("userToken");
      let userid = localStorage.getItem("userId");
  
  
      const inp: UserLogin = {
        Browser: browser,
        Token: token,
        UserId: userid,
        Id: 0,
        lastUseddt: null,
        logindt: null,
      };
  
      this.filerequestservice.CheckSession(inp).subscribe((data: any) => {
  
        if (data == "1") {
          this.Logout();
        }
      });
    }
  
    getBrowserName() {
      const agent = window.navigator.userAgent.toLowerCase();
      switch (true) {
        case agent.indexOf("edge") > -1:
          return "Edge";
        case agent.indexOf("opr") > -1 && !!(<any>window).opr:
          return "Opera";
        case agent.indexOf("chrome") > -1 && !!(<any>window).chrome:
          return "Chrome";
        case agent.indexOf("trident") > -1:
          return "IE";
        case agent.indexOf("firefox") > -1:
          return "Firefox";
        case agent.indexOf("safari") > -1:
          return "Safari";
        default:
          return "Other";
      }
    }
  
    // New Items added by Chaithanya
  
    isMobileMenu() {
      if ($(window).width() > 991) {
        return false;
      }
      return true;
    };
    toggleSelect(data) {
  
      this.CurrentFiles = data;
      this.checkenable = true;
     
      if (data != null) {
  
        if (data.OpenRequest == true) {
          this.selectedSelectedagain = [];
          this.showdelete = false;
          if (this.selectedSelected.length == 0) {
            document.getElementById(data.FileRequestId.toString()).style.backgroundColor = "#F6F3FD";
            this.selectedSelected.push(data);
            this.multi = false;
          }
          else {
            const index = this.selectedSelected.indexOf(data, 0);
            if (index > -1) {
              this.selectedSelected.splice(index, 1);
              document.getElementById(data.FileRequestId.toString()).style.backgroundColor = "";
              document.getElementById(data.FileRequestId.toString()).style.color = "";
            }
            else {
              this.selectedSelected.push(data);
              document.getElementById(data.FileRequestId.toString()).style.backgroundColor = "#F6F3FD";
            }
          }
          if (this.selectedSelected.length == 0) {
            this.checkenable = false;
            this.multi = false;
          }
          else if (this.selectedSelected.length == 1) {
            this.multi = false;
          }
          else{
            this.multi = true;
          }
        }
        else if (data.OpenRequest == false) {
          this.showdelete = true;
          this.selectedSelected = [];
          if (this.selectedSelectedagain.length == 0) {
  
            document.getElementById(data.FileRequestId.toString()).style.backgroundColor = "#F6F3FD";
            this.selectedSelectedagain.push(data);
            this.multi = false;
          }
          else {
            const index = this.selectedSelectedagain.indexOf(data, 0);
            if (index > -1) {
              this.selectedSelectedagain.splice(index, 1);
              document.getElementById(data.FileRequestId.toString()).style.backgroundColor = "";
              document.getElementById(data.FileRequestId.toString()).style.color = "";
            }
            else {
              this.selectedSelectedagain.push(data);
              document.getElementById(data.FileRequestId.toString()).style.backgroundColor = "#F6F3FD";
            }
          }
          if (this.selectedSelectedagain.length == 0) {
            this.checkenable = false;
            this.multi = false;
          }
          else if (this.selectedSelectedagain.length == 1) {
            this.multi = false;
          }
          else{
            this.multi = true;
          }
        }
  
      }
  
    }
  
    tabChanged = (tabChangeEvent: MatTabChangeEvent): void => {
      if (tabChangeEvent.index == 1) {
        document.getElementById("mat-tab-label-0-1").style.color = "#4F44C5";
        document.getElementById("mat-tab-label-0-0").style.color = "#66737E";
        document.getElementById("mat-tab-label-0-1").style.opacity = "1";
        document.getElementById("mat-tab-label-0-0").style.opacity = "1";
        document.getElementById("mat-tab-label-0-0").style.cursor = "pointer";
        document.getElementById("mat-tab-label-0-1").style.cursor = "pointer";
      }
      else if (tabChangeEvent.index == 0) {
        document.getElementById("mat-tab-label-0-0").style.color = "#4F44C5";
        document.getElementById("mat-tab-label-0-1").style.color = "#66737E";
        document.getElementById("mat-tab-label-0-1").style.opacity = "1";
        document.getElementById("mat-tab-label-0-0").style.opacity = "1";
        document.getElementById("mat-tab-label-0-0").style.cursor = "pointer";
        document.getElementById("mat-tab-label-0-1").style.cursor = "pointer";
      }
      console.log('tabChangeEvent => ', tabChangeEvent);
      console.log('index => ', tabChangeEvent.index);
    }
  
      //By Malleswari
      CloseProgressbar() {
        this.folderCreating = false;
        this.foldercreated = false;
        this.generalprogressbar = false;
        this.errormsg = false;
        this.n=0;
        this.showper = false;
      }
  
  EditUserImage(data:any){

      }
  }