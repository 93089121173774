import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, NgForm } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ClientapprovalService } from '../Shared/ClientCreation/clientapproval.service';
import { LoginService } from '../Shared/Login/login.service';
import { MailService } from '../Shared/Mail/mail.service';
import { SuperadmindashboardService } from '../Shared/SuperAdmin-Dashboard/superadmindashboard.service';
import { accountactivate } from '../ViewModels/clientcreation';

@Component({
  selector: 'app-clientdetails',
  templateUrl: './clientdetails.component.html',
  styleUrls: ['./clientdetails.component.css']
})
export class ClientdetailsComponent implements OnInit {
  logindetails: any;
  userid: any;
  clientdatalist: any;
  userId: any;
  USERID: any;
  p: number = 1;
  allclientvalue: boolean = false;
  auditorlist: any;

  auditId: any;
  auditorType: string;
  audittype: any;
  isValidFormSubmitted = false;
  auditordetails: any =[];
  alluservalue: boolean = false;
  username: string;
  reactiveForm: FormGroup = new FormGroup({
    reactiveRadio: new FormControl(true)
  })
  userForm = new FormGroup({
    active: new FormControl('',),
    deactive: new FormControl('',),
  
 });
  deactive: any;
  active: any;
  data: any;
  submit: boolean = false;
  cusid: any;
  showfile: boolean = false;
  labels: any= [];
  files: any;
  blkid: any;
  blk: any;
  zzz: any;
  current_url: any;
  pdfUrl: any;
  closeResult: string;
  msg: any;
  m: any;
  clientfiledetails: any =[];
  imgdisplay: any;
  clientfiledetails1: any;
  blk1: any;
  zz: any  =[];
  clientfiledetails2: any =[];
  zzzz: any  =[];
  blk2: any;
  zzz1: any;
  blk3: any;
  ppt: any;
  pptview: any;
  clientfiledetails3: any =[];
  blkdoc: any;
  docview: any;
  clientfiledetailsdoc: any =[];
  doc: any;
  blkexcel: any;
  excelview: any;
  clientfiledetailsexcel: any = [];
  excel: any;
  maildata: any =[];
  mailinfo: any;
  mailidd: any;
  uid: any;
  mail: any;
  mailsub: any;
  mailmsg: any;
  maildoc: any;
  maildocname: any =[];
  showdoc: boolean ;
  touser: any;
  EnterPriseId: string;

  constructor(private clientapproval: ClientapprovalService,private userService: LoginService,private router: ActivatedRoute,
  private route: Router, private domSanitizer: DomSanitizer,
  private modalService: NgbModal, private mailservice: MailService) {



   
      this.username = localStorage.getItem("UserFullName");
    this.router.params.subscribe(params => {
      if (params["id"]) {
        // localStorage.folderid = params['id'];
        this.GetClient(params["id"]);
      //  this.GetAuditorTransactionDetails(params["id"]);
        this.cusid = (params["id"]);
        this.EnterPriseId=localStorage.getItem("EnterPriseId");
      }
    });



   }

  ngOnInit(): void {
 this.GetAuditorTransactionDetails();
    this.GetALLClientsDetails();
  }
 
   aa="";
  GetClient(id){    
    this.allclientvalue=true;
   // this.userid = data.userId;
    this.clientapproval.getclientlogindetails(id).subscribe((respose: any) => {
      this.logindetails = respose;
       this.aa = this.logindetails[0].userName;
    });  
   }
   GetClientData(){
    this.allclientvalue=true;
    this.alluservalue= false;
   // this.GetClient(id);
   }

   GetALLClientsDetails() {
    this.clientapproval.getclientdetails( this.EnterPriseId).subscribe((respose: any) => {
      this.clientdatalist = respose;
     
      this.alluservalue= false;
      this.USERID =this.userId;
    });
  }

  onItemChange(value){
    this.data = value;
    this.submit = true;
  }

  onFormSubmit(form) {
    const inputRequest: accountactivate = {
      userid:this.cusid,
      status:this.data,  
    }
    this.clientapproval.deactiveateuser(inputRequest).subscribe((respose: any) => {
      this.logindetails = respose;
      if (respose == "false") {
        alert('Deactivated successfully!');
       }
       else{
        alert('Activated successfully!');
       }
    });
  
  }
 





onselectmail(){
  // this.blkid= data;
  var info = {
    UID: this.mailidd,
    FromId: this.uid
  }
  this.mailservice.getmaildetails(info).subscribe((respose: any) => {
    this.maildata = respose;
    this.maildoc = this.maildata[0].documents
    if(this.maildoc[0] != undefined){
     // for(var i=0;i<=this.maildoc.length;i++){
         this.maildocname = this.maildoc[0].documentName
       // this.maildocname.push(this.maildoc[i].documentName);
    //  }
    this.mail = "Mail"
    this.mailsub = this.maildata[0].subject;
    this.mailmsg = this.maildata[0].message;
    this.touser = this.maildata[0].toName;
    this.showdoc = true;
    }
    else{
      this.mail = "Mail"
      this.mailsub = this.maildata[0].subject;
      this.mailmsg = this.maildata[0].message;
      this.touser = this.maildata[0].toName;
      this.showdoc = false;
    }

    console.log(this.mail);
  });
}


private getDismissReason(reason: any): string {
  if (reason === ModalDismissReasons.ESC) {
    return 'by pressing ESC';
  } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
    return 'by clicking on a backdrop';
  } else {
    return `with: ${reason}`;
  }
}

ViewImageDoc(aa, content) {
  this.blk1 = aa.blockId;
 // this.onselectview1();

  // this.blkid= data;
   this.clientapproval.getclientdata(this.blk1).subscribe((respose: any) => {
     this.clientfiledetails1 = respose;
     this.zzz = this.clientfiledetails1.file ;
   
  

  if (aa.pdfFile != null) {
   // this.ViewDoc(aa)
  }
  else {
    this.imgdisplay = this.clientfiledetails1;
    this.modalService.open(content).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      this.m = this.msg;
    });
  }
});
}
ViewDocumenttxt(aa, pdftesting) {
  this.blk2 = aa.blockId;
//  this.onselectview2();
this.clientapproval.getclientdata(this.blk2).subscribe((respose: any) => {
  this.clientfiledetails2 = respose;
  this.zzz1 = this.clientfiledetails2.file ;
this.zzzz = this.clientfiledetails2;
  if (aa.pdfFile != null) {
    //this.ViewDoc(aa)
  }
  else {

    //this.zzz = aa;
    this.current_url = this.domSanitizer.bypassSecurityTrustResourceUrl(this.zzz1);
    console.log(this.pdfUrl);
    this.pdfUrl = this.zzz1;
    // this.doc="http://docs.google.com/gview?"+this.zzz.file+"&embedded=true";

    this.modalService.open(pdftesting).result.then((result) => {

      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      this.m = this.msg;
      console.log(this.m)

    });
  }
});
}



ViewPpt(aa, pdftesting) {
  this.blk3 = aa.blockId;
  //this.onselectview3();
  this.clientapproval.getclientdata(this.blk3).subscribe((respose: any) => {
    this.clientfiledetails3 = respose;
    this.pptview = this.clientfiledetails3.file ;
  this.ppt = this.clientfiledetails3.documentName;
  if (aa.pdfFile != null) {
    //this.ViewDoc(aa)
  }
  else {

    //this.zzz = aa;
    this.current_url = this.domSanitizer.bypassSecurityTrustResourceUrl(this.pptview);
    console.log(this.pdfUrl);
    this.pdfUrl = this.pptview;

    this.modalService.open(pdftesting).result.then((result) => {

      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      this.m = this.msg;
      console.log(this.m)

    });
  }
});
}



ViewDocx(aa, pdftesting) {
  this.blkdoc = aa.blockId;
  //this.onselectviewdoc(this.blkdoc);
  this.clientapproval.getclientdata(this.blkdoc).subscribe((respose: any) => {
    this.clientfiledetailsdoc = respose;
    this.docview = this.clientfiledetailsdoc.file ;
  this.doc = this.clientfiledetailsdoc;
  if (this.docview == null || this.docview==undefined) {
    //this.ViewDoc(aa)
  }
  else {

    //this.zzz = aa;
    this.current_url = this.domSanitizer.bypassSecurityTrustResourceUrl(this.docview);
    console.log(this.pdfUrl);
    this.pdfUrl = this.docview;
    // this.doc="http://docs.google.com/gview?"+this.zzz.file+"&embedded=true";

    this.modalService.open(pdftesting).result.then((result) => {

      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      this.m = this.msg;
      console.log(this.m)

    });
  }
  });
  
}


ViewExcel(aa, pdftesting) {
  this.blkexcel = aa.blockId;
 // this.onselectexcel();
 this.clientapproval.getclientdata(this.blkexcel).subscribe((respose: any) => {
  this.clientfiledetailsexcel = respose;
  this.excelview = this.clientfiledetailsexcel.file ;
this.excel = this.clientfiledetailsexcel;

  if (aa.pdfFile != null) {
    //this.ViewDoc(aa)
  }
  else {

    //this.zzz = aa;
    this.current_url = this.domSanitizer.bypassSecurityTrustResourceUrl(this.excelview);
    console.log(this.pdfUrl);
    this.pdfUrl = this.excelview;
    // this.doc="http://docs.google.com/gview?"+this.zzz.file+"&embedded=true";

    this.modalService.open(pdftesting).result.then((result) => {

      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      this.m = this.msg;
      console.log(this.m)

    });
  }
});
}

ViewDocument(aa, pdftesting) {
  this.blk = aa.blockId;
  //this.onselectview();

    // this.blkid= data;
     this.clientapproval.getclientdata(this.blk).subscribe((respose: any) => {
       this.clientfiledetails = respose;
       this.zzz = this.clientfiledetails.file ;
       this.zz = this.clientfiledetails 
   

  if (aa.pdfFile != null) {
    //this.ViewDoc(aa)
  }
  else {

    //this.zzz = aa;
    this.current_url = this.domSanitizer.bypassSecurityTrustResourceUrl(this.zzz);
    console.log(this.pdfUrl);
    this.pdfUrl = this.zzz;
    // this.doc="http://docs.google.com/gview?"+this.zzz.file+"&embedded=true";

    this.modalService.open(pdftesting).result.then((result) => {

      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      this.m = this.msg;
      console.log(this.m)

    });
  }
});
}



ViewMail(aa, pdftesting) {
  this.mailinfo = aa;
  this.mailidd = this.mailinfo.mailIdd;
  this.uid = this.mailinfo.customerId;
  this.onselectmail();
  if (aa.pdfFile != null) {
    //this.ViewDoc(aa)
  }
  else {



    this.modalService.open(pdftesting).result.then((result) => {

      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      this.m = this.msg;
      console.log(this.m)

    });
  }
}

// ViewMail(aa,mailview) {
//   this.mailinfo = aa;
//   var info = {
//     UID: this.mailinfo.mailIdd,
//     FromId: this.mailinfo.customerId
//   }
//   this.mailservice.getmaildetails(info).subscribe((respose: any) => {
//     this.maildata = respose;
    
//   });
// }
GetAllAuditorDetails(){
  this.allclientvalue=false;
this.alluservalue= true;
 
this.GetAuditorTransactionDetails();

}
 GetAuditorTransactionDetails() {
  this.alluservalue= true;
  this.allclientvalue=false;
  var res=this.cusid+","+this.EnterPriseId
   this.clientapproval.getclientdetailsdashboard(res).subscribe((respose: any) => {
     this.auditordetails = respose;
    //  for (var i = 0; i <= this.auditordetails.length; i++) {
    //   this.labels.push(this.auditordetails[i].transactionDesc);
    //  if(this.labels[i] == "File"){
    //   this.showfile = true;
    //  }
    //   }
   
   });
 }




}