import { Component, OnInit, ElementRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { DatePipe } from '@angular/common';
import { HttpErrorResponse, HttpClient } from '@angular/common/http';
import { DomSanitizer } from '@angular/platform-browser';
import { UserDetailsService } from 'src/app/Shared/UserDetails/user-details.service';
import { FilerequestService } from 'src/app/Shared/FileRequest/filerequest.service';
import { MessageService } from 'src/app/Shared/MessageService';
import { ShareIdShow } from 'src/app/ViewModels/shareidshow';
import { UserEncData } from 'src/app/ViewModels/UserEncData';
import { NgxSpinnerService } from 'ngx-spinner';
import { AppGlobals } from 'src/app/app.global';
import { SendFidUidVM } from 'src/app/ViewModels/SendFidUidVM';
import { phone } from 'src/app/ViewModels/phone';
import { ShareThroughHaspit } from 'src/app/ViewModels/ShareThroughHaspit';

@Component({
  selector: 'app-uploadfileforrequest',
  templateUrl: './uploadfileforrequest.component.html',
  styleUrls: ['./uploadfileforrequest.component.scss']
})
export class UploadfileforrequestComponent implements OnInit { 
    enablecomputer: boolean;
    UserDetails: string;
    userdetails: any;
    RequestedFileDetails: any;
    uid: string;
    currentuser: string;
    UserEmail: any;
    requestedfolderid: any;
    allFolders: any;
    FileFoldID: any;
    allSubFolders: any;
    currentfoldername: any;
    fid: any;
    subfolderdetails: any;
    Pid: any;
    allfoldersdisplay: boolean;
    checkfolid: any;
    splitfolid: any;
    folderdisaply: boolean;
    subfolderdisaply: any;
    pdffile: any;
    pdfdocname: any;
    xlsdata: any;
    xlssignfile: any;
    worddata: any;
    wordsignfile: any;
    videodata: any;
    videofile: any;
    pptdata: any;
    pptfile: any;
    zipdata: any;
    txtdata: any;
    txtfile: any;
    anyfiledata: any;
    imgdata: any;
    imagefile: any;
    selected = []; CurrentFiles: any;
    public remove: any = false;
    SharedResult: any;
    isLoggedIn: boolean;
    uname: string;
    public dismissalert = false;
    notify: string
    imagetrue: boolean;
    UserImage: any;
    TotalData: any;
    DataUsed: any;
    showper: any;
    errormsg: boolean;
    DummyResponse: any;
    timePassed: number;
    filename: any;
    filesize: any;
    public percent: any = 20;
    timetaken: number;
    remainingtime: number;
    showprogressbar: boolean;
    showuploading: boolean;
    showuploaded: boolean;
    public n: any = 20;
    num: any; num1: any;
    startingTime: number;
    time: number;
    showunlimited: boolean;
    TotalSpace: any;
    percentage: number;
    private toggleButton: any;
    private sidebarVisible: boolean;
    mobile_menu_visible: any = 0;
    selectedSelected: any = [];
    DateTime: Date;
    uploadingmsg: string;
    uploadedmsg: string;
    marked: any = false;
    AllDone: boolean;
    toSendEmailIDtoMarkUp: string;
    isnotificationpage: string;
    notificationsresponse: any;
    IPAddress: any;
    dnsIP: any;
    useremail: string;
    fullname: string;
    uploadshowper: boolean;
    uploadMsg: string;
    UploaddoneMsg: string;
    minimizedprogressbar: boolean;
    foldernamelist: any;
    currentpopuppageone: string;
    theCheckbox:any=false;
    username: string;
    GUID: string;
    constructor(private router: Router, private element: ElementRef, private messageService: MessageService, private httpService: HttpClient, private domSanitizer: DomSanitizer,
      private route: ActivatedRoute, private filerequestService: FilerequestService, private spinner: NgxSpinnerService,
      private datepipe: DatePipe, private _urls: AppGlobals) {
      this.route.params.subscribe(params => {
        if (params["id"]) {
          this.userdetails = params["id"];
          // localStorage.setItem("UserDetails", this.userdetails);
        }
      });
  
      if (localStorage.getItem("IsLoggedIn") == "true") {
        this.uid = localStorage.getItem("userId");
        this.uname = localStorage.getItem("Email");
        this.currentuser = localStorage.getItem("Email");
        this.username = localStorage.getItem("UserFullName");
        this.GUID = localStorage.getItem("GUID");
  
        if(this.currentuser==null || this.currentuser=="" ||this.currentuser==undefined){
          this.currentuser = localStorage.getItem("phoneNumber");
        }
        this.GetRequestedFiles();
        this.GetUserMailId();
      }
      //document.getelementbyid("sidebardisp").style.visibility = "hidden";
      var ns = document.getElementById("navsidebardisp");
      if (ns != null) {
        ns.style.visibility = "hidden";
      }
      var side = document.getElementById("sidebardisp");
      if (side != null) {
        side.style.visibility = "hidden";
      }
      var fp = document.getElementById("footerprivacy");
      if (fp) {
        fp.style.visibility = "hidden";
      }
  
      this.httpService.get('https://api.ipify.org?format=json').subscribe(data => {
        this.dnsIP = data['ip'];
      });
  
      this.httpService.get('https://jsonip.com/').subscribe(data => {
        this.IPAddress = data['ip'];
      });
  
      if (localStorage.getItem("IsLoggedIn") == "true") {
        this.uid = localStorage.getItem("userId");
        this.uname = localStorage.getItem("Email");
        this.currentuser = localStorage.getItem("MailId");
        this.fullname = localStorage.getItem("fullname");
        this.useremail = localStorage.getItem("useremail");
        this.GetRequestedFiles();
        this.GetUserMailId();
      }
      else {
        this.router.navigate(['signin/']);
      }
  
      this.filerequestService.getfolders(this.uid).subscribe((respose: any) => {
        this.allFolders = respose;
        this.folderdisaply = true;
        this.spinner.hide();
      });
      this.showper = false;
    }
  
    ngOnInit() {
      const navbar: HTMLElement = this.element.nativeElement;
      this.toggleButton = navbar.getElementsByClassName('navbar-toggler')[0];
      this.enablecomputer = false
      this.allfoldersdisplay = false;
      this.folderdisaply = true;
      this.subfolderdisaply = false;
      this.AllDone = false;
      this.getuserImage();
  
      this.toSendEmailIDtoMarkUp = localStorage.getItem("useremail");
  
      this.isnotificationpage = localStorage.getItem("IsNotificationsPage");
      if (this.isnotificationpage == "true") {
        this.UpdateAllNotifications();
      }
      this.GetPFolderlist("0");
    }
  
    UpdateAllNotifications() {
      const inputRequest: SendFidUidVM = {
        Fid: null,
        Uid: this.toSendEmailIDtoMarkUp
      }
      this.spinner.show();
      this.filerequestService.updateallnotications(inputRequest).subscribe((respose: any) => {
        this.notificationsresponse = respose;
        localStorage.setItem('IsNotificationsPage', "false");
        this.spinner.hide();
      });
    }
    onnchanHover() {
      document.getElementById("FromComputerBtn").style.background = "#9BAAB6";
    }
  
    sidebarOpen() {
      const toggleButton = this.toggleButton;
      const body = document.getElementsByTagName('body')[0];
      setTimeout(function () {
        toggleButton.classList.add('toggled');
      }, 500);
  
      body.classList.add('nav-open');
  
      this.sidebarVisible = true;
    };
    sidebarClose() {
      const body = document.getElementsByTagName('body')[0];
      this.toggleButton.classList.remove('toggled');
      this.sidebarVisible = false;
      body.classList.remove('nav-open');
    };
    sidebarToggle() {
      // const toggleButton = this.toggleButton;
      // const body = document.getElementsByTagName('body')[0];
      var $toggle = document.getElementsByClassName('navbar-toggler')[0];
  
      if (this.sidebarVisible === false) {
        this.sidebarOpen();
      } else {
        this.sidebarClose();
      }
      const body = document.getElementsByTagName('body')[0];
  
      if (this.mobile_menu_visible == 1) {
        // $('html').removeClass('nav-open');
        body.classList.remove('nav-open');
        if ($layer) {
          $layer.remove();
        }
        setTimeout(function () {
          $toggle.classList.remove('toggled');
        }, 400);
  
        this.mobile_menu_visible = 0;
      } else {
        setTimeout(function () {
          $toggle.classList.add('toggled');
        }, 430);
  
        var $layer = document.createElement('div');
        $layer.setAttribute('class', 'close-layer');
  
  
        if (body.querySelectorAll('.main-panel')) {
          document.getElementsByClassName('main-panel')[0].appendChild($layer);
        } else if (body.classList.contains('off-canvas-sidebar')) {
          document.getElementsByClassName('wrapper-full-page')[0].appendChild($layer);
        }
  
        setTimeout(function () {
          $layer.classList.add('visible');
        }, 100);
  
        $layer.onclick = function () { //asign a function
          body.classList.remove('nav-open');
          this.mobile_menu_visible = 0;
          $layer.classList.remove('visible');
          setTimeout(function () {
            $layer.remove();
            $toggle.classList.remove('toggled');
          }, 400);
        }.bind(this);
  
        body.classList.add('nav-open');
        this.mobile_menu_visible = 1;
  
      }
    };
  
    getuserImage() {
      this.filerequestService.getuserimage(this.uid).subscribe((respose: any) => {
        this.UserImage = respose;
        if (this.UserImage == null) {
          this.imagetrue = false;
          localStorage.setItem("userDisplayImage", "null");
        }
        else {
          this.imagetrue = true;
          localStorage.setItem("userDisplayImage", this.UserImage);
        }
  
        // this.spinner.hide();
      });
  
    }
  
    OnClickCheckbox(value: any) {
      this.marked = value.target.checked;
    }
    plans() {
      this.router.navigate(['plans']);
    }
    GetTotalData() {
      this.filerequestService.GetTotalData(this.uid).subscribe((respose: any) => {
        if (respose != null) {
          this.TotalData = "You have" + " " + respose + " Storage";
          this.TotalSpace = respose;
        }
        else {
          this.TotalData = 0;
        }
        if (respose == "Unlimited") {
          this.showunlimited = true;
        }
        else {
          this.showunlimited = false;
          this.GetDataUsed();
        }
      });
    }
  
    GetDataUsed() {
      this.filerequestService.GetDataUsed(this.uid).subscribe((respose: any) => {
        if (respose != null) {
          this.DataUsed = respose;
        }
        else {
          this.DataUsed = 0;
        }
        var utdt = Number(this.DataUsed.slice(3, -2));
        var td = Number(this.TotalSpace);
        this.percentage = Number((utdt / (td * 1024)) * 100);
  
        document.getElementById("progressbar").style.width = this.percentage + "%";
        this.TotalSpace = this.TotalSpace + "GB"
      });
    }
  
  
    EditProfile() {
      this.router.navigate(['EditProfile/']);
    }
    Logout() {
      this.messageService.sendMessage('false');
      this.isLoggedIn = false;
      localStorage.setItem("IsLoggedIn", "false");
      localStorage.removeItem('userToken');
      this.router.navigate(['/']);
      localStorage.clear();
    }
    Install() {
      this.router.navigate(['/install']);
    }
    GetDetails(details) {
      this.folderdisaply = false;
      this.subfolderdisaply = true;
      this.FileFoldID = details.FolderID;
      this.currentpopuppageone = details.FolderName;
      this.spinner.show();
      this.allfoldersdisplay = false;
      const dataaa: ShareIdShow = {
        EID: "",
        Fid: details.FolderID,
        LoginUID: this.uid
      }
      this.filerequestService.getsubfolders(dataaa).subscribe((respose: any) => {
        this.allSubFolders = respose;
        this.currentfoldername = this.allSubFolders.FileName;
        this.fid = this.allSubFolders.ParentId;
        this.spinner.hide();
        localStorage.setItem('retdata', JSON.stringify(this.allSubFolders.RetData));
        localStorage.setItem('subfolders', JSON.stringify(this.allSubFolders.SubFolders));
        for (let i = 0; i < this.allSubFolders.RetData.length; i++) {
          if (this.allSubFolders.RetData[i].file != null) {
            this.allSubFolders.RetData[i].file = this.domSanitizer.bypassSecurityTrustResourceUrl(this.allSubFolders.RetData[i].file)
          }
        }
      });
    }
  
    // get folders and files from subfolder
    GetSubfolderdetails(data) {
      this.folderdisaply = false;
      this.subfolderdisaply = true;
      this.allfoldersdisplay = false;
      this.subfolderdetails = data;
      this.currentfoldername = data.FolderName;
      this.currentpopuppageone = data.FolderName;
      this.FileFoldID = data.FolderID;
      this.spinner.show();
      const dataaa: ShareIdShow = {
        EID: "",
        Fid: data.FolderID,
        LoginUID: this.uid
      }
      this.filerequestService.getsubfolders(dataaa).subscribe((respose: any) => {
        this.allSubFolders = respose;
        this.spinner.hide();
        localStorage.setItem('retdata', JSON.stringify(this.allSubFolders.RetData));
        localStorage.setItem('subfolders', JSON.stringify(this.allSubFolders.SubFolders));
        localStorage.setItem('parentid', JSON.stringify(this.allSubFolders.ParentId));
        this.GetPFolderlist(this.allSubFolders.ParentId);
        for (let i = 0; i < this.allSubFolders.RetData.length; i++) {
          if (this.allSubFolders.RetData[i].file != null) {
            this.allSubFolders.RetData[i].file = this.domSanitizer.bypassSecurityTrustResourceUrl(this.allSubFolders.RetData[i].file)
          }
        }
  
      });
    }
    GetUserMailId() {
      this.filerequestService.getuseremailid(this.uid).subscribe(
        (data: any) => {
          this.UserEmail = data
          this.spinner.hide();
  
        }
      );
    }
    GetRequestedFiles() {
      this.spinner.show();
      const inputrequest = {
        UserDetails: this.userdetails,
      uid:this.uid
      }
      this.filerequestService.Getrequestedfile(inputrequest).subscribe(
        (data: any) => {
          this.RequestedFileDetails = data;
          this.requestedfolderid = this.RequestedFileDetails.requestedFolderId
          this.spinner.hide();
  
        }
      );
    }
    clickcomputer() {
      this.enablecomputer = true;
    }
  
    onChange(event: any) {
      this.DateTime = new Date();
      let latest_date = this.datepipe.transform(this.DateTime, 'dd-MM-yyyy hh:mm:ss a');
      this.showprogressbar = true;
      this.n = 1;
      this.filesize = 0;
      this.showper = false;
      let files = [].slice.call(event.target.files);
      const frmData = new FormData();
      for (var i = 0; i < files.length; i++) {
        frmData.append("fileUpload", files[i]);
      }
      this.filename = files[0].name;
      for (var j = 0; j < files.length; j++) {
        this.filesize = this.filesize + (files[j].size / 1024);
      }
  
      frmData.append("FromUserId", this.uid);
      frmData.append("ToUserId", this.RequestedFileDetails.fromAccountNo);
      frmData.append("folderid", this.requestedfolderid);
      frmData.append("filereqid", this.RequestedFileDetails.id);
      frmData.append("CheckMark", this.marked);
      frmData.append("date", latest_date);
  
      frmData.append("ipaddress", this.IPAddress);
      frmData.append("dnsip", this.dnsIP);
      frmData.append("browser", this.getBrowserName());
      frmData.append("fullname", this.fullname);
      frmData.append("useremail", this.currentuser);
      this.showprogressbar = true;
      this.showuploading = true;
      var startingTime = new Date().getTime();
      var imgdata = "MQkxMDAtMzAwS2IJMTk6MDA6MDguNzk0NDc1Nwk0Nzc5DQoyCTEwMC0zMDBLYgkxOTowMDozOS42NzAyNzMxCTUwNDgNCjMJMTAwLTMwMEtiCTE5OjAwOjUyLjcyNDk5MjUJNTE1MA0KNAkxMDAtMzAwS2IJMTk6MDE6MzcuNDI4NzMwMgk0MDg3DQo1CTEwMC0zMDBLYgkxOTowMjoxMC40NDMxMzYxCTM5NjINCjYJMC0xMDBLYgkxOTowNDo0OS4xNDQzNTMxCTQ2MzUNCjcJMC0xMDBLYgkxOTowNjozMy4zODIwNTI4CTU0NjgNCjgJMC0xMDBLYgkxOTowNzoxNS45NDQxODAxCTM2MDINCjkJMC0xMDBLYgkxOTowODoxMi45Njc0Mjc1CTMzNTYNCjEwCTAtMTAwS2IJMTk6MDk6NDkuNTkwOTU3NwkzOTkxDQoxMQkzMDAtNTAwS2IJMTk6MTE6NDguNDcxODI5Nwk1NDc5DQoxCTEwMC0zMDBLYgkxOTowMDowOC43OTQ0NzU3CTQ3NzkNCjIJMTAwLTMwMEtiCTE5OjAwOjM5LjY3MDI3MzEJNTA0OA0KMwkxMDAtMzAwS2IJMTk6MDA6NTIuNzI0OTkyNQk1MTUwDQo0CTEwMC0zMDBLYgkxOTowMTozNy40Mjg3MzAyCTQwODcNCjUJMTAwLTMwMEtiCTE5OjAyOjEwLjQ0MzEzNjEJMzk2Mg0KNgkwLTEwMEtiCTE5OjA0OjQ5LjE0NDM1MzEJNDYzNQ0KNwkwLTEwMEtiCTE5OjA2OjMzLjM4MjA1MjgJNTQ2OA0KOAkwLTEwMEtiCTE5OjA3OjE1Ljk0NDE4MDEJMzYwMg0KOQkwLTEwMEtiCTE5OjA4OjEyLjk2NzQyNzUJMzM1Ng0KMTAJMC0xMDBLYgkxOTowOTo0OS41OTA5NTc3CTM5OTENCjExCTMwMC01MDBLYgkxOToxMTo0OC40NzE4Mjk3CTU0NzkNCjEJMTAwLTMwMEtiCTE5OjAwOjA4Ljc5NDQ3NTcJNDc3OQ0KMgkxMDAtMzAwS2IJMTk6MDA6MzkuNjcwMjczMQk1MDQ4DQozCTEwMC0zMDBLYgkxOTowMDo1Mi43MjQ5OTI1CTUxNTANCjQJMTAwLTMwMEtiCTE5OjAxOjM3LjQyODczMDIJNDA4Nw0KNQkxMDAtMzAwS2IJMTk6MDI6MTAuNDQzMTM2MQkzOTYyDQo2CTAtMTAwS2IJMTk6MDQ6NDkuMTQ0MzUzMQk0NjM1DQo3CTAtMTAwS2IJMTk6MDY6MzMuMzgyMDUyOAk1NDY4DQo4CTAtMTAwS2IJMTk6MDc6MTUuOTQ0MTgwMQkzNjAyDQo5DQo=";
      const dataa: phone = {
        uid: "",
        phone: imgdata,
        ipaddress: null,
        dnsip: null,
        browser: null,
        transaction: null
      }
  
      this.uploadshowper = true;
      this.showprogressbar = true;
      this.showuploading = true;
      this.uploadMsg = "Uploading new files with blockchain conversion in progress..."
  
      this.filerequestService.getdummyresponse(dataa).subscribe((response: any) => {
        this.timePassed = ((new Date().getTime() - startingTime)) / 1000;
        console.log(this.timePassed);//timepassed is in milliseconds
        this.DummyResponse = response;
        if (this.DummyResponse != null) {
          this.timetaken = (this.timePassed / 1000) * this.filesize;
          this.remainingtime = (Math.ceil(this.timetaken)) + 5;
          this.getthePercent(this.timetaken);
  
          this.num = setInterval(() => {
            if (this.n <= 95) {
              this.n = this.getthePercent(this.n);
              if (this.remainingtime <= 1) {
                this.remainingtime = 5;
              }
              this.remainingtime = (Math.ceil(this.remainingtime));
              this.remainingtime = this.remainingtime - 1;
              if (this.n > 95) {
                this.n = 95;
                this.percent = this.n;
              }
              this.percent = (Math.ceil(this.n));
            }
            else {
              if (this.remainingtime <= 1) {
                this.remainingtime = 5;
              }
              this.n = 95;
              this.percent = this.n;
            }
          }, 1000);
  
          this.httpService.post(this._urls.dmsurl + '/api/Recipient/DocumentUploadForFileRequest/', frmData).subscribe(
            data => {
              if (data == "1") {
                this.percent = 100;
                this.showuploading = false;
                this.showuploaded = true;
                this.UploaddoneMsg = "Uploaded new files with blockchain conversion completed!"
  
                setTimeout(function () {
                  this.showuploading = false;
                  this.showuploaded = false;
                  this.showprogressbar = false;
                  this.errormsg = false;
                  this.percent = 0;
                  this.showper = false;
                  this.uploadshowper = false;
                  this.minimizedprogressbar = false;
                  this.n = 10;
                }.bind(this), 5000);
  
                clearInterval(this.num);
                this.AllDone = true;
  
              }
              else {
                this.notify = "Something went wrong. Try again";
                setTimeout(() => this.dismissalert = true, 100);
                setTimeout(function () {
                  this.dismissalert = false;
                }.bind(this), 3000);
                this.spinner.hide();
                this.percent = 0;
              }
            },
            (err: HttpErrorResponse) => {
              console.log(err.message);
            });
        }
      });
    }
  
    getBrowserName() {
      const agent = window.navigator.userAgent.toLowerCase();
      switch (true) {
        case agent.indexOf("edge") > -1:
          return "Edge";
        case agent.indexOf("opr") > -1 && !!(<any>window).opr:
          return "Opera";
        case agent.indexOf("chrome") > -1 && !!(<any>window).chrome:
          return "Chrome";
        case agent.indexOf("trident") > -1:
          return "ie";
        case agent.indexOf("firefox") > -1:
          return "Firefox";
        case agent.indexOf("safari") > -1:
          return "Safari";
        default:
          return "Other";
      }
    }
  
    Close() {
      this.showuploading = false;
      this.showuploaded = false;
      this.showprogressbar = false;
      this.errormsg = false;
      this.percent = 0;
    }
  
    // onChange(event: any) {
    //   let files = [].slice.call(event.target.files);
    //   const frmData = new FormData();
    //   for (var i = 0; i < files.length; i++) {
    //     frmData.append("fileUpload", files[i]);
    //   }
    //   frmData.append("FromUserId", this.uid);
    //   frmData.append("ToUserId", this.RequestedFileDetails.FromAccountNo);
    //   frmData.append("folderid", this.requestedfolderid);
    //   frmData.append("filereqid", this.RequestedFileDetails.Id);
  
    //   this.spinner.show();
    //   this.httpService.post(this._urls.dmsurl +'/api/Recipient/DocumentUploadForFileRequest/', frmData).subscribe(
    //     
    //     data => {
  
    //       if (data == "1") {
    //         this.notify = "File Uploaded Successfully";
    //         setTimeout(() => this.dismissalert = true, 100);
    //         setTimeout(function () {
    //           this.dismissalert = false;
    //         }.bind(this), 3000);
    //         this.spinner.hide();
    //       }
    //       else {
    //         this.notify = "Something went wrong. Try again";
    //         setTimeout(() => this.dismissalert = true, 100);
    //         setTimeout(function () {
    //           this.dismissalert = false;
    //         }.bind(this), 3000);
    //         this.spinner.hide();
    //       }
    //     },
    //     (err: HttpErrorResponse) => {
    //       console.log(err.message);
    //     });
    // }
    //Go back from subfolder to its parent folder
    GetBack(allSubFolders) {
      this.spinner.show();
      this.filerequestService.getbackdata(allSubFolders.ParentId).subscribe((respose: any) => {
        this.allSubFolders = respose;
        this.currentfoldername = this.allSubFolders.FileName
        this.Pid = this.allSubFolders.ParentId;
        this.FileFoldID = this.allSubFolders.ParentId;
        this.allSubFolders.folderID = this.allSubFolders.ParentId;
        this.spinner.hide();
        if (this.allSubFolders.ParentId == "0" && this.allSubFolders.FolderType == "1") {
          localStorage.setItem('formdata', JSON.stringify(this.allSubFolders));
          this.folderdisaply = true;
          this.subfolderdisaply = false;
        }
      });
    }
  
    toggle(data) {
      this.CurrentFiles = data;
      if (data != null) {
        for (var i = 0; i < this.selected.length; i++) {
          if (this.selected[i].Id == data.Id) {
            var index = this.selected.indexOf(data);
            this.selected.splice(index, 1);
            this.remove = true;
          }
        }
        if (this.remove == false) {
          this.selected.push(data);
        }
      }
      this.remove = false;
    }
  
    toggleSelect(data) {
      this.CurrentFiles = data;
      if (data != null) {
  
        if (this.selectedSelected.length == 0) {
          this.selectedSelected.push(data);
        }
        else {
          const index = this.selectedSelected.indexOf(data, 0);
          if (index > -1) {
            this.selectedSelected.splice(index, 1);
          }
          else {
            this.selectedSelected.push(data);
          }
        }
        if (this.selectedSelected.length == 0) {
        }
      }
      console.log(this.selectedSelected);
    }
  
  
    OnSelecttoShareFiles() {
      this.DateTime = new Date();
      let latest_date = this.datepipe.transform(this.DateTime, 'dd-MM-yyyy hh:mm:ss a');
      // console.log(latest_date);
      this.showprogressbar = true;
      this.n = 1;
      this.filesize = 0;
      this.showper = true;
      const inputRequest: ShareThroughHaspit = {
        SelectedFiles: this.selected,
        SelectedFolders: this.selectedSelected,
        FromUserId: this.uid,
        ToUserId: this.RequestedFileDetails.FromAccountNo,
        filereqid: this.RequestedFileDetails.Id,
        folderid: this.requestedfolderid,
        Createdon: latest_date,
        CheckboxStatus: this.marked,
  
        FullName: this.fullname,
        Email: this.useremail,
        ipaddress: this.IPAddress,
        dnsip: this.dnsIP,
        browser: this.getBrowserName(),
        transaction: null
      }
      if (this.selected.length == 0 && this.selectedSelected.length == 0) {
        alert("Please Select atleast one file")
      }
      else {
        //this.spinner.show();
        if (this.selected.length != 0) {
          this.filename = this.selected[0].DocumentName;
        }
        else {
          this.filename = this.selectedSelected[0].FileName;
        }
        for (var j = 0; j < this.selected.length; j++) {
          this.filesize = this.filesize + (this.selected[j].DocumentSize / 1024);
        }
        this.uploadshowper = true;
        this.showprogressbar = true;
        this.showuploading = true;
        var startingTime = new Date().getTime();
        var imgdata = "MQkxMDAtMzAwS2IJMTk6MDA6MDguNzk0NDc1Nwk0Nzc5DQoyCTEwMC0zMDBLYgkxOTowMDozOS42NzAyNzMxCTUwNDgNCjMJMTAwLTMwMEtiCTE5OjAwOjUyLjcyNDk5MjUJNTE1MA0KNAkxMDAtMzAwS2IJMTk6MDE6MzcuNDI4NzMwMgk0MDg3DQo1CTEwMC0zMDBLYgkxOTowMjoxMC40NDMxMzYxCTM5NjINCjYJMC0xMDBLYgkxOTowNDo0OS4xNDQzNTMxCTQ2MzUNCjcJMC0xMDBLYgkxOTowNjozMy4zODIwNTI4CTU0NjgNCjgJMC0xMDBLYgkxOTowNzoxNS45NDQxODAxCTM2MDINCjkJMC0xMDBLYgkxOTowODoxMi45Njc0Mjc1CTMzNTYNCjEwCTAtMTAwS2IJMTk6MDk6NDkuNTkwOTU3NwkzOTkxDQoxMQkzMDAtNTAwS2IJMTk6MTE6NDguNDcxODI5Nwk1NDc5DQoxCTEwMC0zMDBLYgkxOTowMDowOC43OTQ0NzU3CTQ3NzkNCjIJMTAwLTMwMEtiCTE5OjAwOjM5LjY3MDI3MzEJNTA0OA0KMwkxMDAtMzAwS2IJMTk6MDA6NTIuNzI0OTkyNQk1MTUwDQo0CTEwMC0zMDBLYgkxOTowMTozNy40Mjg3MzAyCTQwODcNCjUJMTAwLTMwMEtiCTE5OjAyOjEwLjQ0MzEzNjEJMzk2Mg0KNgkwLTEwMEtiCTE5OjA0OjQ5LjE0NDM1MzEJNDYzNQ0KNwkwLTEwMEtiCTE5OjA2OjMzLjM4MjA1MjgJNTQ2OA0KOAkwLTEwMEtiCTE5OjA3OjE1Ljk0NDE4MDEJMzYwMg0KOQkwLTEwMEtiCTE5OjA4OjEyLjk2NzQyNzUJMzM1Ng0KMTAJMC0xMDBLYgkxOTowOTo0OS41OTA5NTc3CTM5OTENCjExCTMwMC01MDBLYgkxOToxMTo0OC40NzE4Mjk3CTU0NzkNCjEJMTAwLTMwMEtiCTE5OjAwOjA4Ljc5NDQ3NTcJNDc3OQ0KMgkxMDAtMzAwS2IJMTk6MDA6MzkuNjcwMjczMQk1MDQ4DQozCTEwMC0zMDBLYgkxOTowMDo1Mi43MjQ5OTI1CTUxNTANCjQJMTAwLTMwMEtiCTE5OjAxOjM3LjQyODczMDIJNDA4Nw0KNQkxMDAtMzAwS2IJMTk6MDI6MTAuNDQzMTM2MQkzOTYyDQo2CTAtMTAwS2IJMTk6MDQ6NDkuMTQ0MzUzMQk0NjM1DQo3CTAtMTAwS2IJMTk6MDY6MzMuMzgyMDUyOAk1NDY4DQo4CTAtMTAwS2IJMTk6MDc6MTUuOTQ0MTgwMQkzNjAyDQo5DQo=";
        const dataa: phone = {
          uid: "",
          phone: imgdata,
          ipaddress: null,
          dnsip: null,
          browser: null,
          transaction: null
        }
        this.showper = true;
        this.showprogressbar = true;
        this.showuploading = true;
        this.uploadMsg = "Uploading new files with blockchain conversion in progress..."
        this.filerequestService.getdummyresponse(dataa).subscribe((response: any) => {
          this.timePassed = ((new Date().getTime() - startingTime)) / 1000;
          console.log(this.timePassed);//timepassed is in milliseconds
          this.DummyResponse = response;
          if (this.DummyResponse != null) {
            this.timetaken = (this.timePassed / 1000) * this.filesize;
  
            this.remainingtime = (Math.ceil(this.timetaken)) + 5;
            this.getthePercent(this.timetaken);
            this.num = setInterval(() => {
              if (this.n <= 95) {
                this.n = this.getthePercent(this.n);
                if (this.remainingtime <= 1) {
                  this.remainingtime = 5;
                }
                this.remainingtime = (Math.ceil(this.remainingtime));
                this.remainingtime = this.remainingtime - 1;
                if (this.n > 95) {
                  this.n = 95;
                  this.percent = this.n;
                }
                this.percent = (Math.ceil(this.n));
              }
              else {
                if (this.remainingtime <= 1) {
                  this.remainingtime = 5;
                }
                this.n = 95;
                this.percent = this.n;
              }
            }, 1000);
  
          }
          this.filerequestService.ShareFilesThroughHaspit(inputRequest).subscribe((data: any) => {
            this.SharedResult = data;
            if (this.SharedResult == "sent") {
              this.percent = 100;
              this.showuploading = false;
              this.showuploaded = true;
              this.UploaddoneMsg = "Uploaded new files with blockchain conversion completed!"
  
              setTimeout(function () {
                this.showuploading = false;
                this.showuploaded = false;
                this.showprogressbar = false;
                this.errormsg = false;
                this.percent = 0;
                this.showper = false;
                this.uploadshowper = false;
                this.minimizedprogressbar = false;
              }.bind(this), 5000);
  
              clearInterval(this.num);
              this.AllDone = true;
  
            }
            else {
              this.notify = "Something went wrong. Try again";
              setTimeout(() => this.dismissalert = true, 100);
              setTimeout(function () {
                this.dismissalert = false;
              }.bind(this), 3000);
              this.spinner.hide();
            }
          });
        });
      }
  
    }
    AllDoneClose() {
      this.router.navigate(['allfolders']);
    }
  
    RouteToPlans() {
      this.router.navigate(['plans/']);
    }
  
    Logoutfromhaspit() {
      this.messageService.sendMessage('false');
      this.isLoggedIn = false;
      localStorage.setItem("IsLoggedIn", "false");
      localStorage.removeItem('userToken');
      this.router.navigate(['/']);
    }
  
    getthePercent(per) {
      //this.timetaken =this.filesize/(this.timePassed / 1000)
      //this.remainingtime = this.timetaken;
      this.n = this.n + ((100 - this.n) / this.remainingtime);
      //this.n = this.n * 100;
      return (Math.ceil(this.n));
      //*100 is for percentage and /1000 is for converting ms to secs
      //divededby 9 is for 9 divisions of progress bar
    }
  
    gobackToFR() {
      this.router.navigate(['filerequests']);
    }
  
    minimizeprogressbar() {
      this.minimizedprogressbar = true;
      this.uploadshowper = false;
    }
    viewmainprogressbar() {
      this.minimizedprogressbar = false;
      this.uploadshowper = true;
    }
    sort = "1";
    sortby() {
      if (this.sort == "1") {
        this.allFolders.RetData.sort((a, b) => a.SortName.toLowerCase() < b.SortName.toLowerCase() ? -1 : a.SortName.toLowerCase() > b.SortName.toLowerCase() ? 1 : 0)
        this.allFolders.SubFolders.sort((a, b) => a.FolderName.toLowerCase() < b.FolderName.toLowerCase() ? -1 : a.FolderName.toLowerCase() > b.FolderName.toLowerCase() ? 1 : 0)
  
        this.sort = "2";
      }
      else if (this.sort == "2") {
        this.allFolders.RetData.sort((a, b) => a.SortName.toLowerCase() > b.SortName.toLowerCase() ? -1 : a.SortName.toLowerCase() < b.SortName.toLowerCase() ? 1 : 0)
        this.allFolders.SubFolders.sort((a, b) => a.FolderName.toLowerCase() > b.FolderName.toLowerCase() ? -1 : a.FolderName.toLowerCase() < b.FolderName.toLowerCase() ? 1 : 0)
        this.sort = "1";
      }
    }
    sortname = "1";
    sortbydate() {
      if (this.sortname == "1") {
        this.allFolders.RetData.sort((a, b) => a.date < b.date ? -1 : a.date > b.date ? 1 : 0)
        this.allFolders.SubFolders.sort((a, b) => a.ModifiedOn < b.ModifiedOn ? -1 : a.ModifiedOn > b.ModifiedOn ? 1 : 0)
  
        this.sortname = "2";
      }
      else if (this.sortname == "2") {
        this.allFolders.RetData.sort((a, b) => a.date > b.date ? -1 : a.date < b.date ? 1 : 0)
        this.allFolders.SubFolders.sort((a, b) => a.ModifiedOn < b.ModifiedOn ? -1 : a.ModifiedOn > b.ModifiedOn ? 1 : 0)
  
        this.sortname = "1";
  
      }
    }
  
    sortone = "1";
    sortbyforsubfolder() {
      if (this.sortone == "1") {
        this.allSubFolders.RetData.sort((a, b) => a.SortName.toLowerCase() < b.SortName.toLowerCase() ? -1 : a.SortName.toLowerCase() > b.SortName.toLowerCase() ? 1 : 0)
        this.allSubFolders.SubFolders.sort((a, b) => a.FolderName.toLowerCase() < b.FolderName.toLowerCase() ? -1 : a.FolderName.toLowerCase() > b.FolderName.toLowerCase() ? 1 : 0)
  
        this.sortone = "2";
      }
      else if (this.sortone == "2") {
        this.allSubFolders.RetData.sort((a, b) => a.SortName.toLowerCase() > b.SortName.toLowerCase() ? -1 : a.SortName.toLowerCase() < b.SortName.toLowerCase() ? 1 : 0)
        this.allSubFolders.SubFolders.sort((a, b) => a.FolderName.toLowerCase() > b.FolderName.toLowerCase() ? -1 : a.FolderName.toLowerCase() < b.FolderName.toLowerCase() ? 1 : 0)
  
        this.sortone = "1";
      }
    }
    sortnameone = "1";
    sortbydateforsubfolder() {
      if (this.sortnameone == "1") {
        this.allSubFolders.RetData.sort((a, b) => a.date < b.date ? -1 : a.date > b.date ? 1 : 0)
        this.allSubFolders.SubFolders.sort((a, b) => a.ModifiedOn < b.ModifiedOn ? -1 : a.ModifiedOn > b.ModifiedOn ? 1 : 0)
  
        this.sortnameone = "2";
      }
      else if (this.sortnameone == "2") {
        this.allSubFolders.RetData.sort((a, b) => a.date > b.date ? -1 : a.date < b.date ? 1 : 0)
        this.allSubFolders.SubFolders.sort((a, b) => a.ModifiedOn > b.ModifiedOn ? -1 : a.ModifiedOn < b.ModifiedOn ? 1 : 0)
  
        this.sortnameone = "1";
  
      }
    }
  
    // Get Folders for Breadcrumb by Chaithanya
  
    GetFolderdetails() {
      this.foldernamelist = null;
      this.currentpopuppageone = "";
      this.filerequestService.getfolders(this.uid).subscribe((respose: any) => {
        this.allFolders = respose;
        this.folderdisaply = true;
        this.subfolderdisaply = false;
        this.spinner.hide();
      });
    }
  
    // Get Subfolders and files from subfolder for BC
  
    GetSubfoldersforBC(data) {
      this.folderdisaply = false;
      this.subfolderdisaply = true;
      this.allfoldersdisplay = false;
      this.subfolderdetails = data;
      this.currentfoldername = data.FolderName;
      this.currentpopuppageone = data.FolderName;
      this.FileFoldID = data.FolderId;
      this.spinner.show();
      const dataaa: ShareIdShow = {
        EID: "",
        Fid: data.FolderId,
        LoginUID: this.uid
      }
      this.filerequestService.getsubfolders(dataaa).subscribe((respose: any) => {
        this.allSubFolders = respose;
        this.spinner.hide();
        localStorage.setItem('retdata', JSON.stringify(this.allSubFolders.RetData));
        localStorage.setItem('subfolders', JSON.stringify(this.allSubFolders.SubFolders));
        localStorage.setItem('parentid', JSON.stringify(this.allSubFolders.ParentId));
        this.GetPFolderlist(this.allSubFolders.ParentId);
        for (let i = 0; i < this.allSubFolders.RetData.length; i++) {
          if (this.allSubFolders.RetData[i].file != null) {
            this.allSubFolders.RetData[i].file = this.domSanitizer.bypassSecurityTrustResourceUrl(this.allSubFolders.RetData[i].file)
          }
        }
  
      });
    }
  
    GetPFolderlist(id) {
      this.spinner.show();
      this.filerequestService.getpfoldernames(id).subscribe((respose: any) => {
        this.spinner.hide();
        this.foldernamelist = respose;
        if (this.foldernamelist.length == 0) {
          var ar = document.getElementById("arrowhide");
          if (ar) {
            ar.style.visibility = "hidden";
          }
        }
        //this.currentpopuppage = this.currentpopuppage + " > " + this.foldernamelist[0].FolderName;
        console.log(this.foldernamelist);
      });
    }
    onResize(data:any){
      
    }
    gotopdfView(data:any){
      
    }
    ViewVideo(data:any){
  
    }
    Viewzip(data:any){
  
    }
    Viewanyfile(data:any){
  
    }
  }