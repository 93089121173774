import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-smartcontractlist',
  templateUrl: './smartcontractlist.component.html',
  styleUrls: ['./smartcontractlist.component.css']
})
export class SmartcontractlistComponent implements OnInit {

  inbox: any=[];
dataa:any=[];
  constructor(private httpservice:HttpClient) { }

  ngOnInit(): void {
    this.mailopen();
  }
  mailopen(){
    this.httpservice.get('https://blocdrivesaas.in/blocdrivesaasapi/api/Account/getsmartcontractlist').subscribe(
      (data: any) => {
       for(var i=0;i<=data.response.length;i++){
        this.inbox = data.response[i].record;
this.dataa.push(this.inbox);
        console.log(this.dataa) 
       }
      
            
    
      })
  }

}
