import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { FilerequestService } from 'src/app/Shared/FileRequest/filerequest.service';
import { OtpViewModel } from 'src/app/ViewModels/OtpViewModel';
import { UserDetailsService } from 'src/app/Shared/UserDetails/user-details.service';
import { MessageService } from 'src/app/Shared/MessageService';
import { LoginService } from 'src/app/Shared/Login/login.service';
import { SendEncode } from 'src/app/ViewModels/SendEncode';
import { Socialusers } from 'src/app/ViewModels/Socialusers';
import { FormGroup, FormControl } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import * as $ from 'jquery';

var authorizationData;
@Component({
  selector: 'app-logintouploadfiles',
  templateUrl: './logintouploadfiles.component.html',
  styleUrls: ['./logintouploadfiles.component.scss']
})
export class LogintouploadfilesComponent implements OnInit {
  
  socialusers: any;
  response: any;
  userdetails: any;
  uid: string;
  docdata: any;
  folid: any;
  email: any;
  password: any;
  loginForm = new FormGroup({
    email: new FormControl(),
    password: new FormControl()
  });
  result: any;
  mobileview: boolean;
  constructor(private route: ActivatedRoute, private filerequestservice:FilerequestService,  private http: HttpClient, private userService: UserDetailsService, private messageService: MessageService, private router: Router, private loginService: LoginService,
    private spinner:NgxSpinnerService) {
    var adata = localStorage.getItem('authdata');
    authorizationData = JSON.parse(adata);

    window.scrollTo(0,0);
      this.isMobileMenu();
    //let url = window.location.href;
    // if (url.includes('?')) {
    //   let spd = url.split('?');
    //   let acstsp = spd[1].split('=');
    //   let accesstoken = acstsp[1];

    //   let acst = accesstoken.split('&')[0];

    //   // let authURL ="https://www.linkedin.com/oauth/v2/accessToken?client_id=81fsghpn98dxwx&client_secret=ECnoIag5FdZ9hE0e&grant_type=authorization_code&code="+acst+"&redirect_uri=https://blocdrivesaas.in/loginf";

    //   let authURL = "https://blocdrivesaas.in/blocdrivesaasapi/api/Test/postacct1";
    //   const abc: tst = {
    //     val: acst
    //   }
    //   this.http.post(authURL, abc).subscribe((res: any) => {
    //     if (res != "1") {


    //       const inputRequest: OtpViewModel = {
    //         Id: 0,
    //         Email: null,
    //         Otp: "2",
    //         FirstName: res.fname,
    //         LastName: res.lname,
    //         UserName: null,
    //         PhoneNumber: null,
    //         Password: null,
    //         ConfirmPassword: null,
    //         ProvinceId: null,
    //         PlanId: null,
    //         AccessToken: res.actk
    //       }
    //       this.userService.LoginVerification(inputRequest).subscribe(
    //         (data: any) => {
    //           if (data != "1") {
    //             localStorage.setItem('IsLoggedIn', "true");
    //             localStorage.setItem('userToken', data.accessToken);
    //             localStorage.setItem("UserName", data.firstName);

    //             localStorage.setItem("role", data.roleName);
    //             localStorage.setItem("userId", data.userId);
    //             localStorage.setItem("area", data.area);
    //             localStorage.setItem("isMember", data.IsMember);

    //             alert("Login Succesfully");
    //             this.router.navigate(['/newhome']);
    //           }

    //           else {
    //             this.router.navigate(['/newregister']);
    //           }
    //         });




    //       // this.router.navigate(['/login']);
    //     }
    //     else {
    //       this.router.navigate(['/newhome']);
    //     }
    //   });
    // }

    this.route.params.subscribe(params => {
      if (params["id"]) {
        this.userdetails = params["id"];
      }
    })


  }
  role: any;
  isLoginError: any;
  ngOnInit() {
     
  }
  isMobileMenu() {
    if ($(window).width() > 991) {
      this.mobileview = false;
    }
    else {
      this.mobileview = true;
    }
  };
  OnSubmit() {
    localStorage.setItem('loadingpage', "true");

    this.email = this.loginForm.controls["email"].value;
    this.password = this.loginForm.controls["password"].value;
    this.spinner.show();
    if (this.email == null || this.email == "") {
      alert("Please enter Your Email");
    }
    else if (this.password == null || this.password == "") {
      alert("Please enter Your Password");
    }
    else {
      this.loginService.userAuthentication(this.email, this.password).subscribe((data: any) => {
        this.result = data;
        localStorage.setItem('IsLoggedIn', "true");
        localStorage.setItem('userToken', data.access_token);
        localStorage.setItem('authorizationData', data);
        localStorage.setItem('Token_type', data.token_type);
        localStorage.setItem("UserFullName", data.fullName);
        localStorage.setItem("role", data.roleName);
        localStorage.setItem("userId", data.userId);
        localStorage.setItem("MailId", data.mailId);
        localStorage.setItem("GUID", data.GUID);

        //   localStorage.setItem('authdata', JSON.stringify(movies));
        this.spinner.hide();

        // send message to subscribers via observable subject
        this.messageService.sendMessage('true');
        this.messageService.sendName(data.userName);

        const inputt = {
          Data: this.userdetails,
          Email: this.email,
          uid:data.userId
        }
        this.filerequestservice.getextratSeqlink(inputt).subscribe((response: any) => {
          this.docdata = response;
          var role = localStorage.getItem("role");
          var loggedin = localStorage.getItem("IsLoggedIn");
          var user = localStorage.getItem("userId");
    
          this.folid = this.docdata.folderId;
          if (this.docdata.linkType == "FR" && loggedin == "true" && user == this.docdata.toUser) {
            var landingUrl = "http://" + window.location.host + "#/uploadfileforrequest/" + this.userdetails;
            window.location.href = landingUrl;
          }         
          else if (this.docdata.linkType == "RR"&&  loggedin == "true"  && this.folid != "0" && user == this.docdata.toUser) {
           
           var landingUrl = "http://" + window.location.host + "#/allsubfolders/" + this.folid;
           window.location.href = landingUrl;
         }
         else if (this.docdata.linkType == "RR"&& loggedin == "true"  && this.folid == "0" && user == this.docdata.toUser) {
 
           var landingUrl = "http://" + window.location.host + "#/allfolders/";
           window.location.href = landingUrl;
         }

    
        });

      },
        (err: HttpErrorResponse) => {
          this.spinner.hide();
          this.isLoginError = true;
        }

      )
    }
  }
 
 

  // savefromsocio(fname, lname, email, actk) {
  //   const inputRequest: OtpViewModel = {
  //     Id: 0,
  //     Email: email,
  //     Otp: "1",
  //     FirstName: fname,
  //     LastName: lname,
  //     UserName: null,
  //     PhoneNumber: null,
  //     Password: null,
  //     ConfirmPassword: null,
  //     ProvinceId: null,
  //     PlanId: null,
  //     AccessToken: actk
  //   }
  //   this.userService.LoginVerification(inputRequest).subscribe(
  //     (data: any) => {
  //       if (data != "1") {
  //         localStorage.setItem('IsLoggedIn', "true");
  //         localStorage.setItem('IsLoggedIn', "true");
  //         localStorage.setItem('userToken', data.accessToken);
  //         localStorage.setItem("UserName", data.firstName);

  //         localStorage.setItem("role", data.roleName);
  //         localStorage.setItem("userId", data.userId);
  //         localStorage.setItem("area", data.area);
  //         localStorage.setItem("isMember", data.IsMember);

  //         alert("Login Succesfully");
  //         this.router.navigate(['/newhome']);
  //       }

  //       else {
  //         this.router.navigate(['/newregister']);
  //       }
  //     });
  // }

  // public socialSignIn(socialProvider: string) {
  //   let socialPlatformProvider;
  //   if (socialProvider === 'facebook') {
  //     socialPlatformProvider = FacebookLoginProvider.PROVIDER_ID;
  //     this.OAuth.signIn(socialPlatformProvider).then(socialusers => {
  //       console.log(socialProvider, socialusers);
  //       console.log(socialusers);
  //       let name = socialusers.name.split(" ");
  //       let fname = name[0];
  //       let lname = name[1];
  //       let email = socialusers.email;
  //       let actk = socialusers.token;
  //       this.savefromsocio(fname, lname, email, actk);
  //       // this.router.navigate(['/home']);  

  //       //this.Savesresponse(socialusers);  
  //     });
  //   } else if (socialProvider === 'google') {
  //     socialPlatformProvider = GoogleLoginProvider.PROVIDER_ID;
  //     this.OAuth.signIn(socialPlatformProvider).then(socialusers => {
  //       console.log(socialProvider, socialusers);
  //       console.log(socialusers);
  //       let name = socialusers.name.split(" ");
  //       let fname = name[0];
  //       let lname = name[1];
  //       let email = socialusers.email;
  //       let actk = socialusers.token;
  //       this.savefromsocio(fname, lname, email, actk);
  //       //this.router.navigate(['/home']);  

  //       //this.Savesresponse(socialusers);  
  //     });
  //   }

  //   else if (socialProvider === "linkedin") {
  //     window.open("https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id=81fsghpn98dxwx&redirect_uri=https://blocdrivesaas.in/newlogin&state=gfvtfvgfv&scope=r_liteprofile%20r_emailaddress%20w_member_social", "_self");

  //   }

  // }
  // Savesresponse(socialusers: Socialusers) {
  //   this.SocialloginService.Savesresponse(socialusers).subscribe((res: any) => {
  //     debugger;
  //     console.log(res);
  //     this.socialusers = res;
  //     this.response = res.userDetail;
  //     localStorage.setItem('socialusers', JSON.stringify(this.socialusers));
  //     console.log(localStorage.setItem('socialusers', JSON.stringify(this.socialusers)));
  //     this.router.navigate(['/']);
  //   })
  // }


  getLinks() {
    var useremail = localStorage.getItem("MailId");
   // localStorage.setItem("ViewFrom", "Email");
    const inputt: SendEncode = {
      Data: this.userdetails,
      Email: useremail
    }
    this.filerequestservice.getextratSeqlink(inputt).subscribe((response: any) => {
      this.docdata = response;
      var role = localStorage.getItem("role");
      var loggedin = localStorage.getItem("IsLoggedIn");
      var user = localStorage.getItem("userId");
    
      if (this.docdata.linkType == "FR" && loggedin == "true" && user == this.docdata.toUser) {
        var landingUrl = "http://" + window.location.host + "#/uploadfileforrequest/" + this.userdetails;
        window.location.href = landingUrl;
      }   
     
    });
  }

}
